import { createTheme, ThemeProvider } from '@mui/material';
import MuiIconButton from '@mui/material/IconButton';

const iconButtonTheme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#008AFC',
        },
      },
    },
  },
});

export const IconButton = ({ children, ...props }) => (
  <ThemeProvider theme={iconButtonTheme}>
    <MuiIconButton {...props}>{children}</MuiIconButton>
  </ThemeProvider>
);