import { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { useDrag } from 'react-dnd';
import { socket } from '../../../context/socket';
import getOrderCode from '../../../utils/orderCode';
import { deleteOrder } from 'utils/api/Orders';
import { useSnackbar } from 'notistack';
import WarningIcon from 'reusable/WarningIcon';
import { OrderPopup } from 'reusable/OrderPopup/OrderPopup';

//Maybe move to separate file

const deletePopupStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '960px',
  width: '30%',
  height: '35%',
  bgcolor: 'background.paper',
  border: '1px transparent',
  borderRadius: '20px',
  color: '#003057',
  gap: '5px',
};

var stringToColour = function (str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

function ColorLuminance(hex, lum) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }

  return rgb;
}

String.prototype.hashCode = function () {
  var hash = 0,
    i,
    chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export default function OrderButton(props) {
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  let currentOrder = props.currentOrder;

  const orderCode = getOrderCode(currentOrder);

  const deleteOrderHelper = async () => {
    const orderId = currentOrder._id;
    try {
      const res = await deleteOrder(orderId);
      if (res.status === 200) {
        socket.emit('deleteOrder', orderId);
        window.location.reload(true);
        enqueueSnackbar(orderCode + ' successfully deleted!', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(orderCode + ' failed to delete!', {
        variant: 'error',
      });
    }
    setDeleteModalOpen(false);
  };

  const handleContextMenu = (event) => {
    if (!deleteModalOpen) {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : null
      );
    }
  };

  const handleCloseContext = () => {
    setContextMenu(null);
  };

  const handleContextDelete = () => {
    setContextMenu(null);
    setDeleteModalOpen(true);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    socket.off('updateSaveCR');
    socket.off('updateOrder');
    socket.off('saveCR');
    socket.off('orderSubmitted');
    socket.off('submitCR');
  };

  const [{ isDraggingOrder }, dragOrder] = useDrag(
    () => ({
      type: 'order',
      item: { id: currentOrder },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [props.currentOrder]
  );

  let comboRunNo;
  let stringCode;
  let color;
  let style;
  let darkerColor;
  let disabled = false;
  if (currentOrder.inComboRun || currentOrder.isComboRun) {
    comboRunNo = currentOrder.comboRunNo;
    stringCode =
      comboRunNo + 'CR' + comboRunNo + 'adw1' + comboRunNo + 'RC' + comboRunNo;
    color = stringToColour(stringCode);
    darkerColor = ColorLuminance(color, -0.1);
    style = {
      backgroundColor: color,
      borderColor: color,
      '&:hover': {
        background: darkerColor,
      },
    };
  } else {
    style = {};
  }
  if (props.currentOrder.orderCode === '.') {
    style = {
      '&.Mui-disabled': {
        backgroundColor: 'white',
      },
    };
    disabled = true;
  }

  return (
    <div>
      {currentOrder.inComboRun ? (
        <Button
          variant="contained"
          onClick={handleOpen}
          style={{ maxWidth: '10px', zIndex: 2 }}
          sx={style}
          disabled={disabled}
          ref={dragOrder}
          onContextMenu={handleContextMenu}
        >
          {orderCode}
        </Button>
      ) : currentOrder.temp === true ? (
        <Button
          variant="contained"
          onClick={handleOpen}
          style={{ maxWidth: '10px', zIndex: 0, cursor: 'context-menu' }}
          sx={style}
          disabled={disabled}
          onContextMenu={handleContextMenu}
        >
          {orderCode}
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={handleOpen}
          style={{ maxWidth: '10px', zIndex: 2, cursor: 'context-menu' }}
          sx={style}
          disabled={disabled}
          ref={dragOrder}
          onContextMenu={handleContextMenu}
        >
          {orderCode}
        </Button>
      )}
      {/*
          Right-click menu component
      */}
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContext}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleContextDelete}>Delete</MenuItem>
      </Menu>

      {/*
          Modal for 'delete' confirmation
      */}
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        style={{
          display: 'flex',
          width: '100%',
          textAlign: 'center',
        }}
        aria-labelledby="Delete-Warning-Popup"
        aria-describedby="Popup to confirm order deletion"
        disableScrollLock={false}
      >
        <Box sx={deletePopupStyles}>
          <div style={{ width: '17%' }}>
            <WarningIcon />
          </div>
          <div style={{ padding: '0 10px' }}>
            <p style={{ margin: '5px' }}>
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.25em',
                }}
              >
                ARE YOU SURE ABOUT DELETING THIS ORDER?
              </span>
            </p>
            <p>
              <span style={{ fontSize: '1.em' }}>
                There are risks of losing information.
              </span>
            </p>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              fontSize: '1.25em',
              textAlign: 'center',
              overflowWrap: 'normal',
              flexWrap: 'wrap',
            }}
          >
            <button
              onClick={() => setDeleteModalOpen(false)}
              style={{
                backgroundColor: '#B3B3B3',
                padding: '3px 40px',
                borderRadius: '10px',
                borderColor: 'transparent',
                color: 'white',
              }}
            >
              <p style={{ margin: 0 }}>Cancel</p>
            </button>
            <button
              onClick={deleteOrderHelper}
              style={{
                backgroundColor: '#008AFC',
                padding: '3px 40px',
                borderRadius: '10px',
                borderColor: 'transparent',
                color: 'white',
              }}
            >
              Confirm
            </button>
          </div>
        </Box>
      </Modal>

      {/*
          Modal for order information
      */}
      <Modal open={open} onClose={handleClose} style={{ display: 'flex' }}>
        <OrderPopup
          currentOrder={currentOrder}
          handleClose={handleClose}
          modalOpen={open}
          workflow={true}
        />
      </Modal>
    </div>
  );
}
