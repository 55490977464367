const priceColor = 'rgb(125, 170, 185)';
const blueTextColor = 'rgb(0, 48, 87)';

export const BillingDetails = ({ entries, phoneSize }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {entries.map((entry, index) => {
        if (index % 2 === 0) {
          return (
            <div
              key={index}
              style={{
                alignItems: 'center',
                letterSpacing: phoneSize ? '0em' : '0.2em',
                fontSize: 25,
                fontWeight: 'bolder',
                color: priceColor,
                flexDirection: phoneSize ? 'column' : '',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                textAlign: ' center',
                marginBottom: 30,
              }}
            >
              <div style={{ width: phoneSize ? '100%' : '35%' }}>
                {entries[index]}
              </div>
              <div
                style={{
                  width: phoneSize ? '100%' : '20%',
                  whiteSpace: 'nowrap',
                  letterSpacing: '0em',
                  fontSize: 20,
                  fontWeight: 'normal',
                  color: blueTextColor,
                }}
              >
                billing occurs in
              </div>
              <div style={{ width: phoneSize ? '100%' : '40%' }}>
                {entries[index + 1]}
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};
