import { useState } from 'react';
import './FaqComponent.css';
import FaqOverview from './FaqOverview';
import FaqCategory from './FaqCategory';

export default function FaqComponent() {
  const [faqCategory, setFaqCategory] = useState(0);
  const [categoryVis, setCategoryVis] = useState(false);

  const showFaqCategoryAt = (index) => {
    setCategoryVis(true);
    setFaqCategory(index);
  };

  const setCategory = (category) => {
    setFaqCategory(category);
  };

  return (
    <div className="faq__container">
      <div className="page__title">FAQ</div>
      <div className="faq__content">
        <FaqOverview
          showFaqCategoryAt={showFaqCategoryAt}
          visibility={categoryVis}
        />
        <FaqCategory
          idx={faqCategory}
          vis={categoryVis}
          setVis={setCategoryVis}
          setCategory={setCategory}
        />
      </div>
    </div>
  );
}
