import React, { useState } from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';
import { BillingDetails } from './BillingDetails';

const techGold = 'rgb(179, 163, 105)';
const priceColor = 'rgb(125, 170, 185)';
const blueTextColor = 'rgb(0, 48, 87)';
const titleBackgroundColor = 'rgb(233, 235, 230)';
const detailBackgroundColor = 'rgb(246, 249, 245)';

const Rectangle = ({ title, details }) => {
  return (
    <div style={{ display: 'flex', marginBottom: '50px', minHeight: '350px' }}>
      <div
        style={{
          flexBasis: '35%',
          textAlign: 'center',
          padding: '10px',
          backgroundColor: titleBackgroundColor,
          minHeight: '350px',
        }}
      >
        <h3
          style={{
            color: blueTextColor,
            fontSize: 65,
            margin: 0,
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          {title}
        </h3>
      </div>
      <div
        style={{
          flexBasis: '65%',
          padding: '10px',
          backgroundColor: detailBackgroundColor,
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          minHeight: '350px',
          justifyContent: 'center',
        }}
      >
        {details}
      </div>
    </div>
  );
};

const Pricing = () => {
  const billingEntries = [
    'Summer',
    'August',
    'Fall',
    'January',
    'Spring',
    'May',
  ];

  const [width, height] = useWindowSize();
  const phoneSize = width < 985;

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
      <div style={{ width: '80%' }}>
        {phoneSize ? (
          <>
            <h3
              style={{
                color: blueTextColor,
                fontSize: 40,
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Sample Pricing
            </h3>
            <div
              style={{
                marginBottom: 30,
                padding: '10px',
                backgroundColor: detailBackgroundColor,
                display: 'flex',
                alignItems: 'center',
                minHeight: '350px',
              }}
            >
              <Columns phoneSize={phoneSize} />
            </div>
          </>
        ) : (
          <Rectangle
            title="Sample Pricing"
            details={<Columns phoneSize={phoneSize} />}
          />
        )}
        {phoneSize ? (
          <>
            <h3
              style={{
                color: blueTextColor,
                fontSize: 40,
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Billing
            </h3>
            <div
              style={{
                padding: '10px',
                backgroundColor: detailBackgroundColor,
                display: 'flex',
                alignItems: 'center',
                minHeight: '350px',
              }}
            >
              <BillingDetails entries={billingEntries} phoneSize={phoneSize} />
            </div>
          </>
        ) : (
          <Rectangle
            title="Billing"
            details={
              <BillingDetails entries={billingEntries} phoneSize={phoneSize} />
            }
          />
        )}
      </div>
    </div>
  );
};

export default Pricing;

const Columns = (props) => {
  const phoneSize = props.phoneSize;
  const [entries, setEntries] = useState([
    { id: 1, content: <>Sanger {phoneSize ? <br></br> : <></>} Sequencing</> },
    { id: 2, content: '$5/TUBE' },
    { id: 2, content: '$4/TUBE' },
    { id: 3, content: '$3.50/TUBE' },
    { id: 5, content: '' },
    { id: 6, content: phoneSize ? '<20' : 'Less than 20 samples sequenced' },
    { id: 7, content: phoneSize ? '≥20' : '20+ samples sequenced' },
    { id: 8, content: phoneSize ? '≥96' : '96+ samples sequenced' },
    { id: 9, content: <>Fragment{phoneSize ? <br></br> : <></>} Analysis</> },
    { id: 10, content: '$4/TUBE' },
    { id: 11, content: '$4/TUBE' },
    { id: 12, content: '$3.50/TUBE' },
  ]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          textAlign: 'center',
          marginTop: 40,
          position: 'relative',
        }}
      >
        <div
          style={{
            fontSize: '18px',
            transform: 'translateY(-16%)',
            color: priceColor,
            fontWeight: 'bolder',
            height: '150',
            width: phoneSize ? '37.5%' : '30%',
            letterSpacing: phoneSize ? '0em' : '0.2em',
          }}
        >
          <div
            style={{
              letterSpacing: '0em',
              color: blueTextColor,
              fontSize: '20px',
              transform: 'translateY(-50%)',
              whiteSpace: 'nowrap',
            }}
            key={entries[0].id}
          >
            {entries[0].content}
          </div>
          {entries.slice(1, 4).map((entry) => (
            <div
              key={entry.id}
              style={{ marginBottom: 30, whiteSpace: 'nowrap' }}
            >
              {entry.content}
            </div>
          ))}
        </div>
        <div
          style={{
            fontWeight: phoneSize ? 'bolder' : 'normal',
            fontSize: phoneSize ? 25 : '',
            transform: phoneSize ? 'translateY(10%)' : '',
            color: phoneSize ? techGold : blueTextColor,
            width: phoneSize ? '25%' : '40%',
            height: '80%',
            borderLeft: phoneSize ? '' : `3px solid ${techGold}`,
            borderRight: phoneSize ? '' : `3px solid ${techGold}`,
          }}
        >
          {entries.slice(5, 8).map((entry) => (
            <div
              key={entry.id}
              style={{
                marginBottom: phoneSize ? 20 : 30,
                whiteSpace: 'nowrap',
              }}
            >
              {entry.content}
            </div>
          ))}
        </div>
        <div
          style={{
            fontSize: '18px',
            transform: 'translateY(-16%)',
            color: priceColor,
            fontWeight: 'bolder',
            height: '150',
            width: phoneSize ? '37.5%' : '30%',
            letterSpacing: phoneSize ? '0em' : '0.2em',
          }}
        >
          <div
            style={{
              letterSpacing: '0em',
              color: blueTextColor,
              fontSize: '20px',
              transform: 'translateY(-50%)',
              whiteSpace: 'nowrap',
            }}
            key={entries[8].id}
          >
            {entries[8].content}
          </div>
          {entries.slice(9, 12).map((entry) => (
            <div
              key={entry.id}
              style={{ marginBottom: 30, whiteSpace: 'nowrap' }}
            >
              {entry.content}
            </div>
          ))}
        </div>
        <div
          style={{
            fontSize: phoneSize ? 15 : 18,
            fontWeight: 'bolder',
            color: priceColor,
            position: 'absolute',
            width: '100%',
            left: '50%',
            bottom: phoneSize ? '-8%' : '-10%',
            transform: 'translateX(-50%)',
          }}
        >
          Once 480+ samples have been sequenced per a billing cycle, each sample
          is priced at $3.50 until the start of the next billing cycle.
        </div>
        <div
          style={{
            fontSize: phoneSize ? 15 : 18,
            fontWeight: 'bolder',
            color: priceColor,
            position: 'absolute',
            width: '100%',
            left: '50%',
            bottom: phoneSize ? '-17%' : '-23%',
            transform: 'translateX(-50%)',
          }}
        >
          *First 4 samples free for new customers
        </div>
      </div>
    </>
  );
};
