import './FaqComponent.css';

const FaqButton = ({ children, onClick }) => {
  return (
    <button
      className="faq__button"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default FaqButton;