import { useState, useRef, useEffect } from 'react';

export const ReactionTableSample = ({ sampleName, setSampleName, width }) => {
  const [hoverable, setHoverable] = useState(false);
  const [hover, setHover] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setHoverable(contentRef.current.offsetWidth > width);
    }
  }, [contentRef.current, width]);

  return (
    <div
      style={{
        width: width ? width : '100%',
        minHeight: 55,
        height: '100%',
        padding: 5,
        overflow: hoverable && hover ? 'visible' : 'hidden',
        position: 'relative',
        alignContent: 'center',
      }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        style={{
          width: 'fit-content',
          whiteSpace: 'nowrap',
          position: hoverable && hover ? 'absolute' : 'static',
          zIndex: hover ? 1 : 0,
          transform: hoverable && hover ? 'translateY(-50%)' : '',
          padding: 10,
          backgroundColor: 'white',
          boxShadow: hoverable && hover ? '#00000020 2px 2px 6px' : 'none',
        }}
        ref={contentRef}
      >
        {sampleName}
      </div>
    </div>
  );
};
