import { useEffect, useState } from 'react';
import FragmentRowComponent from './components/FragmentForm/FragmentRowComponent';
import FragmentInfoForm from './components/FragmentForm/FragmentRowComponents/FragmentInfoForm';
import ErrorFormPopup from './components/ErrorFormPopup';
import ExcelUploadPopup from './components/ExcelUploadPopup';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import RotateLoader from 'react-spinners/RotateLoader';
import Backdrop from '@mui/material/Backdrop';
import { Grid } from '@mui/material';
import { Button } from 'reusable/UI/Button';
import { useLogin } from 'hooks/useLogin';
import {
  getFragmentForm,
  submitFragmentForm,
  updateFragmentForm,
  uploadFragmentExcel,
} from 'utils/api/FragmentForm';

const { v4: uuidv4 } = require('uuid');

const Fragment = () => {
  useLogin();

  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const fragmentData = {
    id: uuidv4(),
    sampleName: '',
    vol: '',
    sampleType: '',
    sizeStandard: '',
    dyeSet: '',
    runModule: '',
    touched: new Array(6).fill(false),
  };

  const FragmentValidationSchema = Yup.object().shape({
    fragments: Yup.array().of(
      Yup.object({
        sampleName: Yup.string()
          .max(70, 'Name should be 70 characters or less')
          .required('Sample Name is required'),
        vol: Yup.number()
          .typeError('Volume must be a number')
          .min(10, 'Volume should be 10 - 40 mL')
          .max(40, 'Volume should be 10 - 40 mL')
          .required('Volume is required'),
        sampleType: Yup.string().required('A Type must be picked'),
        sizeStandard: Yup.string().required('A Size Standard must be picked'),
        dyeSet: Yup.string().required('A Dye Set must be picked'),
        runModule: Yup.string().required('A Run Module must be picked'),
      })
    ),
  });

  const [submitShowError, setSubmitShowError] = useState(false);
  const [showAllErrors, setShowAllErrors] = useState(true);
  const [reactCount, setReactCount] = useState(0);

  const [fragments, setFragments] = useState([
    {
      id: uuidv4(),
      sampleName: '',
      sampleType: '',
      sizeStandard: '',
      dyeSet: '',
      runModule: '',
      touched: new Array(6).fill(false),
    },
  ]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(true);
  const [errorPopup, setErrorPopup] = useState(false);

  const handleErrorPopup = async () => {
    const errors = await formik.validateForm();
    setSubmitShowError(true);
    if (Object.keys(errors).length > 0) {
      // alert("There are validation errors!");
      setErrorPopup(true);
    } else {
      formik.submitForm();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: { fragments },
    validationSchema: FragmentValidationSchema,
    onSubmit: async (values, errors) => {
      setIsLoadingForm(true);
      try {
        const res = await submitFragmentForm(fragments);
        if (res.status == 200) {
          navigate('/Drop_off_code', {
            state: { dropOffCode: res.data.dropOffCode },
          });
        }
      } catch (error) {
        console.log('ALERT ERROR');
        alert(
          JSON.stringify(
            'Error. You have either reached the maximum limit of 5 orders or an issue has occurred. Please check your orders and drop them off. Otherwise, contact us if' +
              ' the issue persists'
          )
        );
      }
      setIsLoadingForm(false);
    },
  });

  const addNewFragment = () => {
    //assume you converted data to right format/structure
    if (reactCount < 96) {
      const newFragments = [...fragments];
      newFragments.push(fragmentData);
      setFragments(newFragments);
    } else {
      alert('You have reached 96 fragments limit');
    }
    // formik.validateForm();
  };

  const addNewFragmentIndex = (rowIndex) => {
    if (reactCount < 96) {
      const newFragments = [...fragments];
      newFragments.splice(rowIndex, 0, fragmentData);
      setFragments(newFragments);
    } else {
      alert('You have reached 96 fragments limit');
    }
  };

  const updateFragment = (updatedField, id) => {
    setFragments((prevFragments) =>
      prevFragments.map(
        (item) =>
          item.id === id
            ? { ...item, ...updatedField } // Create a new object with the updated value
            : item // Leave the item unchanged if it's not the one to update
      )
    );
  };

  const removeFragment = (id) => {
    setFragments((prevFragments) =>
      prevFragments.filter((item) => item.id !== id)
    );
  };

  async function loadFragmentForm() {
    const res = await getFragmentForm();
    if (res.status === 200) {
      setFragments(res.data.fragments);
      setIsLoadingForm(false);
      if (res.data.fragments.length !== 0) {
        enqueueSnackbar('Your last saved data was loaded!', {
          variant: 'info',
        });
      }
    }
  }

  useEffect(() => {
    loadFragmentForm();
  }, []);

  async function uploadExcelFile(selectedFile) {
    const formData = new FormData();
    formData.append('clientFragmentExcelUploadBody', selectedFile);
    const res = await uploadFragmentExcel(formData);
    if (res.status === 200) {
      const newFragments = res.data.fragments.fragmentData;
      setFragments(newFragments);
      setSubmitShowError(true);
    }
    return res;
  }

  useEffect(() => {
    updateFragmentForm();
  }, [fragments]);

  useEffect(() => {
    var reactCountNum = fragments.length;
    setReactCount(reactCountNum);
  }, [fragments]);

  return (
    <div>
      <FragmentInfoForm />
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={isLoadingForm}>
        <RotateLoader />
      </Backdrop>
      <ErrorFormPopup
        open={errorPopup}
        handler={setErrorPopup}
        samples={formik.errors.fragments}
      />
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ marginLeft: 25 }}>
          <h3>Your current reaction count: {reactCount}</h3>
        </Grid>
        <Grid item xs={2} style={{ marginBottom: 0, paddingLeft: 50 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (showAllErrors == false) {
                setSubmitShowError(true);
              }
              setShowAllErrors(!showAllErrors);
            }}
          >
            {showAllErrors ? <>Hide Errors</> : <> Show all errors </>}
          </Button>
        </Grid>
        <Grid item xs={2}>
          <ExcelUploadPopup
            uploadExcelFile={uploadExcelFile}
            formType={'fragment'}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setFragments([
                {
                  id: uuidv4(),
                  sampleName: '',
                  sampleType: '',
                  sizeStandard: '',
                  dyeSet: '',
                  runModule: '',
                  touched: new Array(6).fill(false),
                },
              ]);
            }}
          >
            Clear Form
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={0} style={{ overflowX: 'auto' }}>
        <div style={{ minWidth: 200 }}>
          <h1 style={{ fontSize: 32, marginBottom: 0 }}>Fragment Analysis</h1>
          <br />
          <Grid
            container
            style={{
              paddingRight: 7,
              paddingLeft: 10,
              marginTop: 0,
              paddingTop: 0,
              marginBottom: 0,
            }}
          >
            <Grid
              item
              xs={1}
              style={{ fontSize: 14, marginLeft: 15, fontWeight: 'bolder' }}
            >
              Well
            </Grid>
            <Grid
              item
              xs={2}
              style={{ fontSize: 14, marginLeft: -30, fontWeight: 'bolder' }}
            >
              Sample Name
            </Grid>
            <Grid
              item
              xs={1}
              style={{ fontSize: 14, marginLeft: -10, fontWeight: 'bolder' }}
            >
              Volume
            </Grid>
            <Grid
              item
              xs={1}
              style={{ fontSize: 14, marginLeft: -20, fontWeight: 'bolder' }}
            >
              Type
            </Grid>
            <Grid
              item
              xs={1}
              style={{ fontSize: 14, marginLeft: 50, fontWeight: 'bolder' }}
            >
              Size Standard
            </Grid>
            <Grid
              item
              xs={1}
              style={{ fontSize: 14, marginLeft: 120, fontWeight: 'bolder' }}
            >
              Dye Set
            </Grid>
            <Grid
              item
              xs={3}
              style={{ fontSize: 14, marginLeft: 40, fontWeight: 'bolder' }}
            >
              Run Module
            </Grid>
          </Grid>
          {fragments.map((data, i) => {
            return (
              <FragmentRowComponent
                key={data.id}
                id={data.id}
                index={i}
                data={data}
                updateFragment={updateFragment}
                removeFragment={removeFragment}
                addNewFragment={addNewFragment}
                addNewFragmentIndex={addNewFragmentIndex}
                formik={formik}
                submitShowError={submitShowError}
                showAllErrors={showAllErrors}
                reactCount={reactCount}
              />
            );
          })}
          ;
          <Button
            disabled={reactCount >= 95}
            variant="contained"
            color="primary"
            onClick={() => addNewFragment()}
            style={{ marginLeft: '500px', marginTop: 10 }}
          >
            Add new sample
          </Button>
        </div>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <div style={{ padding: 20 }}>
          <form onSubmit={formik.handleSubmit}>
            <LoadingButton
              size="large"
              variant="outlined"
              onClick={handleErrorPopup}
              disabled={fragments.length === 0}
              loading={isSubmitting}
              loadingIndicator={
                <RotateLoader
                  cssOverride={{ left: '100%' }}
                  size={7}
                  margin={-15}
                />
              }
            >
              Submit
            </LoadingButton>
          </form>
        </div>
      </Grid>
    </div>
  );
};

export default Fragment;
