import { Box } from '@mui/material';
import { MapNumRow } from './MapComponents/MapNumRow';
import { MapRow } from './MapComponents/MapRow';
import { updateSample as updateSampleApi } from 'utils/api/Samples';
import { useEffect, useRef, useState } from 'react';

export const Map = ({
  samples,
  setSamples,
  cartridge,
  usedSections,
  scroll,
  canDrag = true,
  variableWidth,
}) => {
  const [dragging, setDragging] = useState(false);
  const hexScroll = scroll.toString(16).padStart(2, '0');

  const move = (startIdx, endIdx) => {
    setSamples((samples) => {
      const newSamples = [...samples];
      const startSample = newSamples.findIndex(
        (sample) => sample.well === startIdx
      );
      const endSample = newSamples.findIndex(
        (sample) => sample.well === endIdx
      );
      if (startSample >= 0) {
        newSamples[startSample] = { ...newSamples[startSample], well: endIdx };
        updateSampleApi(newSamples[startSample]._id, { well: endIdx });
      }
      if (endSample >= 0) {
        newSamples[endSample] = { ...newSamples[endSample], well: startIdx };
        if (!newSamples[endSample]._id) console.log(newSamples[endSample]);
        updateSampleApi(newSamples[endSample]._id, { well: startIdx });
      }
      return newSamples;
    });
  };

  const widthRef = useRef(null);
  const [mapWidth, setMapWidth] = useState(0);

  useEffect(() => {
    const updateMapWidth = () => {
      if (widthRef.current) {
        setMapWidth(widthRef.current.offsetWidth - 40);
      }
    };

    // Initial call to set the width
    updateMapWidth();

    // Add resize event listener
    window.addEventListener('resize', updateMapWidth);
    return () => {
      window.removeEventListener('resize', updateMapWidth);
    };
  }, []);

  return (
    <Box
      sx={{ pb: canDrag ? 5 : 0 }}
      style={{
        boxShadow: scroll ? `0 15px 20px -25px #404040${hexScroll}` : 'none',
        transition: 'all 0.5s',
        zIndex: 10,
      }}
    >
      <table
        style={
          variableWidth
            ? { tableLayout: 'fixed', width: '100%' }
            : { width: mapWidth }
        }
      >
        <tbody>
          <MapNumRow />
          {[...Array(8)].map((_, i) => (
            <MapRow
              key={i}
              row={i + 1}
              samples={samples}
              setSamples={setSamples}
              broken={
                cartridge &&
                cartridge.capillaries &&
                cartridge.capillaries[i % 4] === 'broken'
              }
              usedSections={usedSections}
              move={move}
              dragging={dragging}
              setDragging={setDragging}
              canDrag={canDrag}
              width={variableWidth ? undefined : mapWidth}
            />
          ))}
        </tbody>
      </table>
      <div style={{ width: '100%' }} ref={widthRef} />
    </Box>
  );
};
