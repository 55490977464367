import { Box, Card, CardContent, Typography, Tab, Tabs} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const TechnicianPage = ({ assignedOrders, unassignedOrders, loadingAll, chosenUser, setDisplayTechnicianPage }) => {
    // Variables and States used throughout this file
    const [leftTab, setLeftTab] = useState(0); 
    const [rightTab, setRightTab] = useState(0); 
    const inProgressOrdersTable = [];
    const processedOrdersTable = [];
    if (chosenUser[3]) {
        for (let i = 0; i < chosenUser[3].length; ++i) {
            if (chosenUser[3][i][1].taskStatus === "IN PROGRESS") inProgressOrdersTable.push(chosenUser[3][i][0]); 
        } 
        for (let i = 0; i < chosenUser[3].length; ++i) {
            if (chosenUser[3][i][1].taskStatus === "PROCESSED") processedOrdersTable.push(chosenUser[3][i][0]); 
        } 
    }
    
    // Handeling methods
    const handleChangeLeftTab = (event, newValue) => {
        setLeftTab(newValue); 
    }

    const handleChangeRightTab = (event, newValue) => {
        setRightTab(newValue); 
    }

    const technicianCard = (
        <React.Fragment>
            <CardContent>
                <div style={{ marginBottom: 15 }}>
                    <Typography component="div" style={{ color: "#003057" }}>
                        Start of work - <b>N/A</b>
                    </Typography>
                    <Typography component="div" style={{ color: "#003057" }}>
                        Class of - <b>N/A</b>
                    </Typography>
                    <Typography component="div" style={{ color: "#003057" }}>
                        Major - <b>N/A</b>
                    </Typography>
                </div>
                <div className="trainingProgress">
                    <Typography component="div" style={{ color: "#003057" }}>
                        <b>PCR Preparation Trainings</b>
                    </Typography>
                    <table>
                        <tbody>
                        <tr>
                            <th>Completed</th>
                            <th>In Progress</th>
                            <th>Not Started</th>
                        </tr>
                        </tbody>
                    </table>
                    <Typography component="div" style={{ color: "#003057" }}>
                        <b>Purification Training</b>
                    </Typography>
                    <table>
                        <tbody>
                        <tr>
                            <th>Completed</th>
                            <th>In Progress</th>
                            <th>Not Started</th>
                        </tr>
                        </tbody>
                    </table>
                    <Typography component="div" style={{ color: "#003057" }}>
                        <b>SS Machine Training</b>
                    </Typography>
                    <table>
                        <tbody>
                        <tr>
                            <th>Completed</th>
                            <th>In Progress</th>
                            <th>Not Started</th>
                        </tr>
                        </tbody>
                    </table>
                    <Typography component="div" style={{ color: "#003057" }}>
                        <b>Flex Machine Traning</b>
                    </Typography>
                    <table>
                        <tbody>
                        <tr>
                            <th>Completed</th>
                            <th>In Progress</th>
                            <th>Not Started</th>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </CardContent>
        </React.Fragment>
    ); 

    function TabTable(props) {
        const { children, value, index, ...other } = props;
      
        return (
            <div
                role="tabTable"
                hidden={value !== index}
                id={`tabTable-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
      }
      
        TabTable.propTypes = {
            children: PropTypes.node,
            index: PropTypes.number.isRequired,
            value: PropTypes.number.isRequired,
    };

    // Start rendering...
    if (loadingAll) {
        return (
            <h2>Loading Data for Calendar ...</h2>
        )
    }
    
    return ( 
        <div style={{ width: "100%" }}>
            {/* Information Box with profile picture and information card */}
            <div style={{width: "100%", display: "flex", justifyContent: "space-evenly", marginBottom: 15}}>
                <Box
                    sx={{ 
                        borderRadius: "50%",
                        bgcolor: 'gray',
                        border: 0,
                        width: 200,
                        height: 200,
                    }}
                />
                <div style={{display: "flex", flexDirection: 'column', justifyContent: "space-evenly", marginBottom: 15}}>
                    <h2 style={{ color: "#003057" }}>{chosenUser[1].firstName + " " + chosenUser[1].lastName}</h2>
                    <Box>
                        <Card variant="plain">{technicianCard}</Card>
                    </Box>
                </div>
            </div>
            {/* Left Tabs Panel: Assigned and Unassigned Orders */}
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <div>
                    <Box sx={{ width: '100%' }}>
                        <Tabs
                            value={leftTab}
                            onChange={handleChangeLeftTab}
                            TabIndicatorProps={{style: {backgroundColor: leftTab == 0 ? 'rgba(123, 185, 236, 0.3)' : '#F6A7A7'}}}
                        >
                            <Tab value={0} label={<span style={{ color: '#003057' }}>Assigned</span>} />
                            <Tab value={1} label={<span style={{ color: '#003057' }}>Unassigned</span>} />
                        </Tabs>
                    </Box>
                    <TabTable value={leftTab} index={0}>
                        <div className="assignedOrdersTable">
                            <table>
                                <tbody>
                                <tr>
                                    <th>Assigned Orders</th>
                                </tr>
                                {
                                    assignedOrders.map((value, idx) => {
                                    return (
                                        <tr key={idx}>
                                        <td>{value}</td>
                                        </tr>
                                    )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </TabTable>
                    <TabTable value={leftTab} index={1}>
                        <div className="unassignedOrdersTableTab">
                            <table>
                                <tbody>
                                <tr>
                                    <th>Unassigned Orders</th>
                                </tr>
                                {
                                    unassignedOrders.map((value, idx) => {
                                    return (
                                        <tr key={idx}>
                                        <td>{value}</td>
                                        </tr>
                                    )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </TabTable>
                </div>
                {/* Right Tabs Panel: In Progressed and Processed Orders */}
                <div>
                    <Box sx={{ width: '100%' }}>
                        <Tabs
                            value={rightTab}
                            onChange={handleChangeRightTab}
                            TabIndicatorProps={{style: {backgroundColor: rightTab == 0 ? '#F2D77A' : '#69B58C'}}}
                        >
                            <Tab value={0} label={<span style={{ color: '#003057' }}>In Progress</span>} />
                            <Tab value={1} label={<span style={{ color: '#003057' }}>Processed</span>} />
                        </Tabs>
                    </Box>
                    <TabTable value={rightTab} index={0}>
                        {/* Same className as Unassigned Orders Table to inherit the same styling */}
                        <div className="inprogressOrdersTable">
                            <table>
                                <tbody>
                                <tr>
                                    <th>In Progress</th>
                                </tr>
                                {
                                    inProgressOrdersTable.length > 0 ? inProgressOrdersTable.map((value, idx) => {
                                    return (
                                        <tr key={idx}>
                                        <td>{value}</td>
                                        </tr>
                                    )
                                    }) : 
                                    <tr key={0}>
                                        <td>No In Progress Orders</td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </TabTable>
                    <TabTable value={rightTab} index={1}>
                        <div className="processedOrdersTable">
                            <table>
                                <tbody>
                                <tr>
                                    <th>Processed</th>
                                </tr>
                                {
                                    processedOrdersTable.length > 0 ? processedOrdersTable.map((value, idx) => {
                                    return (
                                        <tr key={idx}>
                                        <td>{value}</td>
                                        </tr>
                                    )
                                    }) : 
                                    <tr key={0}>
                                        <td>No Processed Orders</td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </TabTable>
                </div>
            </div>
        </div>
    );

};

export default TechnicianPage;