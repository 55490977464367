import Faq from 'react-faq-component';
import './FaqComponent.css';
import { useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { getData } from './FaqData';

const blueTextColor = 'rgb(0, 48, 87)';
const styles = {
  titleTextSize: '3.09rem',
  rowTitleTextSize: '18pt',
  rowContentTextSize: '12pt',
  extendRowFullWidth: true,
  rowTitleColor: blueTextColor,
  titleTextColor: blueTextColor,
  rowContentColor: blueTextColor,
};

/**
 * Component for a category of FAQ
 * @param idx index of the category in the data array
 * @param vis visibility of the category
 * @param setVis set visibility of the category
 * @param setCategory set the category of the FAQ
 * @returns
 */
const FaqCategory = ({ idx, vis, setVis, setCategory }) => {
  const goToRow = (category, row) => {
    console.log(`Going to ${category} ${row}`);
    setCategory(category);
    setShowRow(row);
  };

  const data = getData(goToRow);

  const faqs = data[idx];
  const visibilityClass = vis
    ? 'faq__category--shown'
    : 'faq__category--hidden';

  const [showRow, setShowRow] = useState(null);
  // Show row if there is only one row
  const [rowOptions, setRowsOptions] = useState(null);

  if (rowOptions) {
    rowOptions.forEach((r) => r.close());
    if (vis && faqs.rows.length === 1) rowOptions[0].expand();
    if (showRow !== null) {
      console.log(`Shown row: ${showRow}`);
      rowOptions[showRow].expand();
    }
  }

  if (!vis && showRow !== null) {
    setShowRow(null);
  }

  return (
    <div className={`faq__category ${visibilityClass}`}>
      <div className="faq__details">
        <div className="faq__details-wrapper">
          <Faq data={faqs} styles={styles} getRowOptions={setRowsOptions} />
        </div>
        <div className="faq__category-back" onClick={() => setVis(false)}>
          <KeyboardArrowLeftIcon />
          <>Back</>
        </div>
      </div>
    </div>
  );
};

export default FaqCategory;
