import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

const updateNotes = async (values) => {
  try {
    const res = await axios.put(
      `${API_ENDPOINT}/Inventory/processingOrder/updateNotes`,
      values
    );
    return res.status;
  } catch (error) {
    console.error('Error updating notes:', error);
    throw error;
  }
};

export { updateNotes };
