import Grid from '@mui/material/Grid';

export function PopupGridItem(props) {
  return (
    <>
      <Grid container rowSpacing={0} spacing={0}>
        <Grid item xs={6}>
          <h3 className="PopupGridItem">{props.attribute}</h3>
        </Grid>
        <Grid item xs={6}>
          <h6 className="PopupGridItem" style={{ float: 'right' }}>
            {props.data}
          </h6>
        </Grid>
        <Grid item xs={12}>
          <hr
            style={{
              background: 'black',
              height: '2px',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export function PopupNullClient() {
  return {
    username: '',
    firstName: '',
    lastName: '',
    SUMSworktag: '',
    email: '',
    phoneNumber: '',
    labName: '',
    university: '',
    universityTag: '',
    noOfOrders: '',
    orderTag: '',
  };
}
