import TeamProfile from './TeamProfile';

import nicole from 'images/Headshots/Nicole.jpg';
import helya from 'images/Headshots/helya.jpg';
import bakr from 'images/Headshots/Bakr.jpg';
import christine from 'images/Headshots/Christine.jpg';
import clara from 'images/Headshots/Clara.jpg';
import david from 'images/Headshots/David.jpg';
import lao from 'images/Headshots/Lao.jpg';
import santosh from 'images/Headshots/santosh.jpg';
import tanishq from 'images/Headshots/tanishq.jpg';
import chris from 'images/Headshots/Chris.jpg';
import ishani from 'images/Headshots/ishani.jpg';
import nancy from 'images/Headshots/nancy.jpg';
import aaron from 'images/Headshots/aaron.jpg';
import azamat from 'images/Headshots/Azamat.jpg';

const MEMBERS = [
  {
    imageSrc: nicole,
    role: 'Co-Head of Initiative',
    name: 'Nicole Diaz',
    email: 'ndiaz31@gatech.edu',
    linkedin: 'https://www.linkedin.com/in/diaznicole/',
  },
  {
    imageSrc: helya,
    role: 'Co-Head of Initiative',
    name: 'Helya Taghian',
    email: 'helya@gatech.edu',
    linkedin: 'https://www.linkedin.com/in/helyataghian/',
  },
  {
    imageSrc: bakr,
    role: 'Head of Software Engineering',
    name: 'Bakr Redwan',
    email: 'b.redwan@gatech.edu',
    linkedin: 'https://www.linkedin.com/in/b-redwan/',
  },
  {
    imageSrc: christine,
    role: 'Operational Manager',
    name: 'Christine Cheng',
    email: 'ccheng330@gatech.edu',
    linkedin: 'https://www.linkedin.com/in/christine-cheng3/',
  },
  {
    imageSrc: clara,
    role: 'Head of Design',
    name: 'Clara McAuley',
    email: 'cmcauley7@gatech.edu',
    linkedin: 'https://www.linkedin.com/in/cmcauley7',
    portfolio: 'http://www.claramcauley.myportfolio.com',
  },
  {
    imageSrc: aaron,
    role: 'Sanger Sequencing',
    name: 'Aaron Kent',
    email: 'akent31@gatech.edu',
    linkedin: 'https://www.linkedin.com/in/a-aron-kent',
  },
  {
    imageSrc: tanishq,
    role: 'Sanger Sequencing',
    name: 'Tanishq Moondra',
    email: 'tmoondra3@gatech.edu',
    linkedin: 'https://www.linkedin.com/in/tanishq-moondra-77973524b/',
  },
  {
    imageSrc: ishani,
    role: 'Sanger Sequencing',
    name: 'Ishani Saha',
    email: 'isaha7@gatech.edu',
    linkedin: 'https://www.linkedin.com/in/ishani-saha-5a19a9254',
  },
  {
    imageSrc: azamat,
    role: 'Sanger Sequencing',
    name: 'Azamat Alibekov',
    email: 'aalibekov3@gatech.edu',
    linkedin: 'https://www.linkedin.com/in/azamat-alibekov/',
  },
  {
    imageSrc: santosh,
    role: 'Sanger Sequencing',
    name: 'Santosh Nachimuthu',
    email: 'snachimuthu7@gatech.edu',
    linkedin: 'https://www.linkedin.com/in/santoshnachimuthu/',
  },
  {
    imageSrc: nancy,
    role: 'Software Engineer',
    name: 'Mengqui Zou',
    email: 'mzou36@gatech.edu',
    linkedin: 'https://www.linkedin.com/in/mengqi-zou-8172b21b9/',
  },
  {
    imageSrc: david,
    role: 'Software Engineer',
    name: 'David Pang',
    email: 'david.pang@gatech.edu',
    linkedin: 'https://www.linkedin.com/in/dpang314/',
    github: 'https://github.com/dpang314',
  },
  {
    imageSrc: chris,
    role: 'Software Engineer',
    name: 'Chris Wei',
    email: 'chris.wei@gatech.edu',
    linkedin: 'https://www.linkedin.com/in/chris-wei-2001/',
  },
  {
    imageSrc: lao,
    role: 'Software Engineer',
    name: 'Laolu Dada',
    email: 'odada@gatech.edu',
    linkedin: 'https://www.linkedin.com/in/olaolu-dada-b80467192/',
  },
];

const Team = () => {
  return (
    <>
      <div
        style={{
          textAlign: 'center',
          fontSize: '60px',
          color: 'rgb(3, 68, 89)',
          marginTop: 30,
          marginBottom: 0,
        }}
      >
        Team
      </div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '20px',
          color: 'rgb(3, 68, 89)',
        }}
      >
        Dedication. Expertise. Passion.
      </div>
      <TeamProfile items={MEMBERS} />
    </>
  );
};

export default Team;
