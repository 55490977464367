import { Button, TextField, Divider, Select, MenuItem, InputLabel, FormControl, Box, Modal } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import React, { useState, useEffect } from 'react';
import { createCalendarPreset, getAllCalendarPresets, getAllUsers, updateCalendarPreset, getColorCategory } from 'utils/api/Calendar'; 
import { DateTime } from 'luxon'; 
import { useFormik } from 'formik';
import UserAutoComplete from './UsersDropDown'; 
import CustomColorForm from './CustomColorForm';

const CustomPresetForm = ({ setDisplayModalCustomPreset }) => {
    // Variables and States used throughout this file
    const [presetId, setPresetId] = useState(); 
    const [presetName, setPresetName] = useState('');
    const [eventColor, setEventColor] = useState(''); 
    const [eventColorObj, setEventColorObj] = useState(''); 
    const [customColorArray, setCustomColorArray] = useState(''); 
    const originalColorArr = ["+"]; 
    const [eventColorArray, setEventColorArray] = useState(["+"]); 
    const [startDateTime, setStartDateTime] = useState(DateTime.now());
    const [endDateTime, setEndDateTime] = useState(DateTime.now());
    const [taskLocation, setTaskLocation] = useState(); 
    const [description, setDescription] = useState(); 
    const [preset, setPreset] = useState(''); 
    const originalPresetArray = ["Pickup - Default, CANNOT customize", "PCR Prep - Default, CANNOT customize", "Purification - Default, CANNOT customize", "Maintenance - Default, CANNOT customize", "New Preset"]; 
    const [presetArray, setPresetArray] = useState(["Pickup", "PCR Prep", "Purification", "Maintenance", "New Preset"]); 
    const [customPresetsObj, setCustomPresetsObj] = useState(); 
    const [usersForCalendar, setUsersForCalendar] = useState([]); 
    const [selectedUsers, setSelectedUsers] = useState(); 
    const [openUsers, setOpenUsers] = React.useState(false);
    const [submitLoadUsers, setSubmitLoadUsers] = useState(false);
    const loadingUsersDropdown = openUsers && usersForCalendar.length === 0; 
    const [isCreatePreset, setIsCreatePreset] = useState(false); 
    const [category, setCategory] = useState(); 
    const [displayModalColorForm, setDisplayModalColorForm] = useState(false); 
    
    // Handeling methods
    useEffect(() => {
       fetchPresetsForCalendar(); 
       fetchUsersForCalendar(); 
    }, []); 

    useEffect(() => {
        fetchColorCategory(); 
    }, [displayModalColorForm]); 

    const fetchPresetsForCalendar = async () => {
        const res = await getAllCalendarPresets(); 
        if (res.status === 200) {
            let newPresetArray = originalPresetArray; 
            let customPresetsTitle = []; 
            for (let i = 0; i < res.data.length; ++i) {
                customPresetsTitle.push(res.data[i].title); 
            }
            newPresetArray.splice.apply(newPresetArray, [newPresetArray.indexOf("New Preset"), 0].concat(customPresetsTitle)); 
            setPresetArray(newPresetArray); 
            setCustomPresetsObj(res.data); 
        } else {
            console.log('Error in getting Presets for calendar...')
        }
    }; 

    const fetchUsersForCalendar = async () => {
        const res = await getAllUsers(); 
        if (res.status === 200) {
            setUsersForCalendar(res.data); 
        } else {
            console.log('Error in getting Users for calendar...')
        }
    }; 

    const fetchColorCategory = async () => {
        const res = await getColorCategory(); 
        if (res.status === 200) {
            setCustomColorArray(res.data); 
            let originalArr = originalColorArr; 
            for (let i = 0; i < res.data.length; ++i) originalArr.splice(originalArr.indexOf("+"), 0, {color: res.data[i].color, category: res.data[i].category}); 
            setEventColorArray(originalArr); 
        } else {
            console.log('Error in getting Color-Category for calendar...'); 
        }
    }

    const formik = useFormik({
        initialValues: {
          users: []
        },
        onSubmit: async (values) => {
            let selectedUsersIds = []; 
            for (let i = 0; i < selectedUsers.length; ++i) {
                selectedUsersIds.push(selectedUsers[i]._id); 
            }

            if (isCreatePreset) {
                const newPreset = {
                    title: presetName, 
                    eventName: presetName, 
                    start: startDateTime,
                    end: endDateTime,
                    description: description, 
                    eventColor: eventColor, 
                    location: taskLocation, 
                    receivingUserId: selectedUsersIds
                }
                const res = await createCalendarPreset(newPreset);
                if (res.status === 200) {
                    alert('Create New Preset Successfully!');
                    setDisplayModalCustomPreset(false);
                } else {
                    alert('Error in creating a new preset!');
                }
            }
            else {
                const newPreset = {
                    id: presetId, 
                    title: presetName, 
                    eventName: presetName, 
                    start: startDateTime,
                    end: endDateTime,
                    description: description, 
                    eventColor: eventColor, 
                    location: taskLocation, 
                    receivingUserId: selectedUsersIds
                }
                const res = await updateCalendarPreset(newPreset); 
                if (res.status === 200) {
                    alert('Modify Preset Successfully!');
                    setDisplayModalCustomPreset(false);
                } else {
                    alert('Error in modifying this preset!');
                }
            }
        }
    });

    const handleChangeEventColor = (event) => {
        if (event.target.value === "+") {
            setDisplayModalColorForm(true); 
        } else {
            setEventColorObj(event.target.value); 
            setEventColor(event.target.value.color); 
        }
    }

    const handleChangeUsers = (event, newValues) => {
        formik.setFieldValue('users', newValues);
        setSelectedUsers(newValues); 
    };

    const handleChangePreset = (event) => {
        setPreset(event.target.value); 
        switch(event.target.value) {
            case "Pickup":
                setPresetName("Pickup (Order #)"); 
                // Set everything back to default
                formik.setFieldValue('users', []); 
                setSelectedUsers([]); 
                setTaskLocation(""); 
                setDescription(""); 
                setEventColor(""); 
                setEventColorObj(); 
                setStartDateTime(DateTime.now()); 
                setEndDateTime(DateTime.now()); 
                break; 
            case "PCR Prep":
                setPresetName("PCR Prep (Order #)");
                // Set everything back to default
                formik.setFieldValue('users', []); 
                setSelectedUsers([]); 
                setTaskLocation(""); 
                setDescription(""); 
                setEventColor(""); 
                setEventColorObj(); 
                setStartDateTime(DateTime.now()); 
                setEndDateTime(DateTime.now()); 
                break; 
            case  "Purification":
                setPresetName("Purification (Order #)"); 
                // Set everything back to default
                formik.setFieldValue('users', []); 
                setSelectedUsers([]); 
                setTaskLocation(""); 
                setDescription(""); 
                setEventColor(""); 
                setEventColorObj(); 
                setStartDateTime(DateTime.now()); 
                setEndDateTime(DateTime.now()); 
                break; 
            case "Maintenance": 
                setPresetName("Maintenance Shift");
                // Set everything back to default
                formik.setFieldValue('users', []); 
                setSelectedUsers([]); 
                setTaskLocation(""); 
                setDescription(""); 
                setEventColor(""); 
                setEventColorObj(); 
                setStartDateTime(DateTime.now()); 
                setEndDateTime(DateTime.now()); 
                break;  
            case "New Preset":
                setPresetName("New Preset Name*"); 
                // Set everything back to default
                formik.setFieldValue('users', []); 
                setSelectedUsers([]); 
                setTaskLocation(""); 
                setDescription(""); 
                setEventColor(""); 
                setEventColorObj(); 
                setStartDateTime(DateTime.now()); 
                setEndDateTime(DateTime.now()); 
                setIsCreatePreset(true); 
                break; 
            default: 
                for (let i = 0; i < customPresetsObj.length; ++i) {
                    if (customPresetsObj[i].title === event.target.value) {
                        let chosenPreset = customPresetsObj[i]; 
                        setPresetId(chosenPreset._id); 
                        setPresetName(chosenPreset.eventName); 
                        
                        // Set color category related variables 
                        setEventColor(chosenPreset.eventColor); 
                        for (let i = 0; i < eventColorArray.length; ++i) {
                            if (eventColorArray[i] !== "+") {
                                let currObj = eventColorArray[i]; 
                                if (currObj.color === chosenPreset.eventColor) {
                                    setEventColorObj(currObj); 
                                    break; 
                                }
                            }
                        }
                        
                        setStartDateTime(DateTime.fromISO(chosenPreset.start)); // If not doing conversion to DateTime, duration will be invalid
                        setEndDateTime(DateTime.fromISO(chosenPreset.end)); // If not doing conversion to DateTime, duration will be invalid
                        setTaskLocation(chosenPreset.location); 
                        setDescription(chosenPreset.description); 
                        let selectedUsersArr = []; 
                        for (let i = 0; i < chosenPreset.receivingUserId.length; ++i) {
                            let currUserId = chosenPreset.receivingUserId[i]; 
                            for (let j = 0; j < usersForCalendar.length; ++j) {
                                if (usersForCalendar[j]._id === currUserId) {
                                    selectedUsersArr.push(usersForCalendar[j]); 
                                    break; 
                                }
                            }
                        }
                        formik.setFieldValue('users', selectedUsersArr); 
                        setSelectedUsers(selectedUsersArr); 
                        break; 
                    }
                }
        }
    }

    const handleDiscard = (event) => {
        setDisplayModalCustomPreset(false); 
    }

    // Start rendering...
    return ( 
        <div>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <h2>Manage Event Presets</h2>
            <FormControl sx={{width: "100%", mb: 3}}>
                <InputLabel id="color-select-bar">Color & Category</InputLabel>
                <Select
                    id="color-select-bar"
                    value={eventColorObj}
                    onChange={handleChangeEventColor}
                >
                    {eventColorArray.map((value, index) => {
                        return  <MenuItem key={index} value={value} style={{textAlign: 'center'}}>
                                    { value !== "+" ? 
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <Box
                                            sx={{
                                            width: 20,
                                            height: 20,
                                            borderRadius: 1,
                                            bgcolor: value.color,
                                            mr: 1
                                            }}
                                        />
                                        <div>
                                            {"- " + value.category}
                                        </div>
                                    </div> : value}
                                </MenuItem>
                    })}
                </Select>
            </FormControl>
            <FormControl sx={{width: "100%", mb: 3}}>
                <InputLabel id="preset-select-bar">Select Preset</InputLabel>
                <Select
                    id="preset-select-bar"
                    value={preset}
                    onChange={handleChangePreset}
                    sx={{
                        color: preset === 'New Preset' ? '#003057' : 'default', 
                        fontWeight: preset === 'New Preset' ? 600 : 'default'
                    }}
                >
                    {presetArray.map((value, index) => {
                        return <MenuItem key={index} 
                                        value={value} 
                                        style={{
                                            color: value === 'New Preset' ? '#003057' : 'default', 
                                            fontWeight: value === 'New Preset' ? 600 : 'default'
                                        }}
                                >
                                {value}
                            </MenuItem>
                    })}
                </Select>
            </FormControl>
            <TextField
                label="Preset Name"
                onChange={(e) => setPresetName(e.target.value)}
                required
                variant="outlined"
                color="secondary"
                type="Title"
                sx={{ mb: 3 }}
                fullWidth
                value={presetName}
            />
            <Divider component="li" style={{marginBottom: 15, borderBottomWidth: 3, borderBottomColor: 'gray'}}/>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateTimePicker
                label="Start"
                value={startDateTime}
                onChange={(newValue) => setStartDateTime(newValue)}
                renderInput={(params) => <TextField {...params} sx={{ mb: 3 }} />}
                />
                <DateTimePicker
                label="End"
                value={endDateTime}
                onChange={(newValue) => setEndDateTime(newValue)}
                renderInput={(params) => <TextField {...params} sx={{ mb: 3 }} />}
                />
            </LocalizationProvider>
            <h6>Duration:</h6>
                <div style={{marginBottom: 15}}>
                   {(endDateTime - startDateTime) >= 0 ? ((endDateTime - startDateTime) / (60 * 60 * 1000)).toFixed(2) + " hour(s)"
                    : "Invalid Duration"
                   }
            </div>
            <h6>Assign to technicians:</h6>
            <UserAutoComplete
                value={formik.values.users}
                onChange={(event, value) => handleChangeUsers(event, value)}
                open={openUsers}
                setOpen={setOpenUsers}  // This prop assumes that the OrderAutocomplete component accepts this prop to handle open state changes
                submitLoad={submitLoadUsers}
                options={usersForCalendar}
                loading={loadingUsersDropdown}
                error={formik.touched.users && Boolean(formik.errors.users)}
                helperText={formik.touched.users && formik.errors.users}
            />
            <TextField
                label="Location"
                onChange={(e) => setTaskLocation(e.target.value)}
                required
                variant="outlined"
                color="secondary"
                type="Title"
                sx={{ mb: 3, mt: 3 }}
                fullWidth
                value={taskLocation || ''}
            />
            <Divider component="li" style={{marginBottom: 15, borderBottomWidth: 3, borderBottomColor: 'gray'}}/>
            <TextField
                label="Notes"
                onChange={(e) => setDescription(e.target.value)}
                variant="outlined"
                color="secondary"
                sx={{ mb: 3 }}
                type="Title"
                fullWidth
                value={description || ''}
                multiline={true}
                rows={5}
            />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button 
                    variant="contained"
                    style={{ marginBottom: 15, backgroundColor: "#B3B3B3", color: 'white' }} 
                    type="button"
                    onClick={handleDiscard}
                >
                    Discard
                </Button>
                <Button 
                    variant="contained"
                    type="submit" 
                    style={{ marginBottom: 15, backgroundColor: "#008AFC", color: 'white' }} 
                >
                    Save
                </Button>
            </div>
        </form>
         {/* Modal for Custom Color Page */}
         <Modal
          open={displayModalColorForm}
          onClose={() => {
            setDisplayModalColorForm(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal">
            <CustomColorForm
              setDisplayModalCustomColor={setDisplayModalColorForm}
            />
          </Box>
        </Modal>
        </div>
    );
    
};

export default CustomPresetForm;