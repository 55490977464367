import { memo, useContext } from 'react';
import { Field, FormikProvider } from 'formik';
import { isEqual } from 'lodash';
import { ErrorVisContext } from '../../../contexts/DataContext';

const SangerPrimerRowErrors = memo(
  ({ data, index, formik }) => {
    const { showAllErrors, submitShowError } = useContext(ErrorVisContext);

    const willRenderError = (meta) => {
      return (
        meta.error.count ||
        ((data.touched[0] || submitShowError) &&
          showAllErrors &&
          meta.error.primerName) ||
        ((data.touched[1] || submitShowError) &&
          showAllErrors &&
          meta.error.vol)
      );
    };

    return (
      <FormikProvider value={formik}>
        <Field name={`primerMapToList.${index}`}>
          {({ field, form: { touched, errors }, meta }) =>
            meta.error !== undefined && willRenderError(meta) ? (
              <tr>
                <td colSpan={3}>
                  <ul
                    style={{
                      listStyleType: 'disc',
                      verticalAlign: 'middle',
                      color: '#d8000c',
                      margin: 0,
                      backgroundColor: '#ffd2d2',
                    }}
                  >
                    {meta.error.count ? (
                      <>
                        ● {meta.error.count}
                        <br></br>
                      </>
                    ) : (
                      <></>
                    )}
                    {(data.touched[0] || submitShowError) && showAllErrors ? (
                      <li>
                        {meta.error.primerName ? (
                          <>● {meta.error.primerName}</>
                        ) : (
                          <></>
                        )}
                      </li>
                    ) : (
                      <></>
                    )}
                    {(data.touched[1] || submitShowError) && showAllErrors ? (
                      <li>
                        {meta.error.vol ? <>● {meta.error.vol}</> : <></>}
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </td>
              </tr>
            ) : (
              <></>
            )
          }
        </Field>
      </FormikProvider>
    );
  },
  (prevProps, nextProps) => {
    let oldIndex = prevProps.index;
    let newIndex = nextProps.index;
    if (
      nextProps.formik.errors?.primerMapToList?.[oldIndex] !==
      prevProps.formik.errors?.primerMapToList?.[newIndex]
    ) {
      return false; // re-render
    }
    return (
      prevProps.id === nextProps.id &&
      isEqual(prevProps.data, nextProps.data) &&
      prevProps.data.count === nextProps.data.count &&
      prevProps.showAllErrors === nextProps.showAllErrors &&
      prevProps.reactCount === nextProps.reactCount
    );
  }
);

export default SangerPrimerRowErrors;
