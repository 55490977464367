import { IconButton, TextField, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import {
  borderLeft,
  borderLeftRight,
  borderRight,
  rowContainerStyle,
} from 'reusable/TableStyles';
import RefreshIcon from '@mui/icons-material/Refresh';

export const MasterMixTableRow = ({
  name,
  reactionCount,
  setReactionCount,
  resetReactionCount,
  changed,
  volume,
}) => {
  const [editing, setEditing] = useState(false);
  const reactionCountTextfieldRef = useRef(null);

  useEffect(() => {
    // handle click events when clicking outside of the instrument dropdown
    const handleClickOutside = (event) => {
      if (
        reactionCountTextfieldRef.current &&
        !reactionCountTextfieldRef.current.contains(event.target)
      ) {
        setEditing(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [reactionCountTextfieldRef]);

  return (
    <tr style={rowContainerStyle}>
      <td style={{ ...borderRight, padding: 6 }}>{name}</td>
      {editing ? (
        <TextField
          label="Reaction Count"
          variant="outlined"
          value={reactionCount}
          type="number"
          sx={{ width: 100, margin: 2 }}
          onChange={(e) => setReactionCount(e.target.value)}
          ref={reactionCountTextfieldRef}
        />
      ) : (
        <td
          style={{
            ...borderLeftRight,
            height: 55,
            padding: 6,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Tooltip title="Click to edit" sx={{ height: '100%' }}>
            <div onClick={() => setEditing(true)}>{reactionCount}</div>
          </Tooltip>
          {changed && (
            <Tooltip title="Reset the reaction count">
              <IconButton
                label="Reset reaction count"
                onClick={resetReactionCount}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}
        </td>
      )}
      <td style={{ ...borderLeft, padding: 6 }}>{volume}</td>
    </tr>
  );
};
