import React from 'react';
import MEClBeaker from '../../images/MEClBeaker.png';
import { useWindowSize } from '../../hooks/useWindowSize';
import { Link } from 'react-router-dom';

const lightBlueTextColor = 'rgb(0, 138, 252)';
const Footer = () => {
  const [width, height] = useWindowSize();
  const phoneSize = width < 985;
  const items = [
    {
      title: 'LINKS',
      details: (
        <>
          {phoneSize ? (
            <></>
          ) : (
            <>
              <br></br>
            </>
          )}
          <NavLinks links={links} />
        </>
      ),
    },
    {
      title: 'CONTACT',
      details: (
        <>
          <a href="mailto:Sanger@ibb.gatech.edu">Sanger@ibb.gatech.edu</a>
          {phoneSize ? (
            <></>
          ) : (
            <>
              <br></br>
            </>
          )}
          <br></br>
          950 Atlantic Dr NW,<br></br>
          Krone Engineered Biosystems Building,<br></br>
          Atlanta, Georgia, 30332
        </>
      ),
    },
  ];

  return (
    <div style={{ transform: phoneSize ? 'scale(0.7)' : 'scale(0.8)' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            display: 'inline-block',
            transform: phoneSize
              ? 'translateY(20%) translateX(-15%)'
              : 'translateY(15%)',
          }}
        >
          <a
            href="https://research.gatech.edu/bio/research/core-facilities/molecular-evolution-core"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={MEClBeaker} alt="Beaker" height={'170'} />
          </a>
        </div>
        <div
          style={{
            display: 'inline-block',
            marginLeft: phoneSize ? '16px' : '16px',
          }}
        >
          {items.map((item, index) => (
            <div
              key={index}
              style={{
                display: 'inline-block',
                float: 'left',
                minWidth: '170px',
                maxWidth: '170px',
                marginLeft: phoneSize ? 20 : 50,
                marginRight: phoneSize ? 20 : 50,
              }}
            >
              <h3
                style={{
                  display: 'inline-block',
                  borderTop: `8px solid ${lightBlueTextColor}`,
                  width: '100%',
                  marginTop: phoneSize ? 0 : 15,
                }}
              ></h3>
              <h3
                style={{
                  color: lightBlueTextColor,
                  fontSize: 15,
                  float: 'left',
                  paddingTop: '8px',
                  marginTop: phoneSize ? 0 : 15,
                  marginBottom: phoneSize ? 0 : 20,
                }}
              >
                {item.title}{' '}
              </h3>
              <p
                style={{
                  clear: 'both',
                  fontWeight: 'lighter',
                  whiteSpace: 'nowrap',
                  overflow: 'visible',
                }}
              >
                {item.details}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;

const NavLinks = ({ links }) => (
  <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
    {links.map(({ text, to }, index) => (
      <li key={index} style={{ marginBottom: '0px' }}>
        <Link
          to={to}
          onClick={() => window.scrollTo(0, 0)}
          style={{ color: 'black', textDecoration: 'none' }}
          onMouseEnter={(e) => {
            e.target.style.color = lightBlueTextColor;
          }}
          onMouseLeave={(e) => {
            e.target.style.color = 'black';
          }}
        >
          {text}
        </Link>
      </li>
    ))}
  </ul>
);

const links = [
  { text: 'Home', to: '/' },
  { text: 'About Us', to: '/aboutus' },
  { text: 'FAQ', to: '/faq' },
  { text: 'Contact Us', to: '/Contact-Us' },
];
