import React, { useState, useContext } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { socket } from 'context/socket';
import Grid from '@mui/material/Grid';
import RotateLoader from 'react-spinners/RotateLoader';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import AuthContext from 'utils/auth/auth';
import './styles/styles.css';
import { Alert } from '@mui/material';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  float: 'left',
}));

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="formError">{meta.error}</div>
      ) : null}
    </>
  );
};

export default function AddEmployee(props) {
  const usernameRegExp = /^[a-zA-Z0-9]+$/;
  const [getLoad, setGetLoad] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const initialData = { username: '' };
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [usernameExists, setUsernameExists] = useState(false);
  const [stayAdmin, setStayAdmin] = useState(false);

  const user = useContext(AuthContext);

  const handleClose = () => {
    setSubmitStatus(false);
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .min(4, 'Must be at least 4 characters')
      .max(20, "Can't be larger than 20 characters")
      .matches(usernameRegExp, 'Invalid username')
      .required('Required'),
  });

  return (
    <Grid
      className="add"
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
        <PropagateLoader />
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
              window.location.reload(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>
      <Formik
        initialValues={initialData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setSubmitLoad(true);
          try {
            values.admin = props.type == 'employee' ? false : true;
            if (!values.admin && values.username == user.casUser) {
              setStayAdmin(true);
              setSubmitSucess(false);
              setSubmitResponse(
                'Cannot remove yourself as an Admin. Please try another.'
              );
              setSubmitStatus(true);
              setSubmitLoad(false);
              return;
            }
            var res = await axios.put(
              API_ENDPOINT + '/employee/addEmployee',
              values
            );
            var status = res.status;
            await sleep(1e3);
            if (status === 202) {
              setUsernameExists(true);
              setSubmitSucess(false);
              setSubmitResponse(
                'Cannot add a duplicate user. Please try another.'
              );
              setSubmitStatus(true);
            }
            if (status === 200) {
              socket.emit('addEmployee', values);
              setSubmitSucess(true);
              setSubmitResponse('Employee sucessfully added!');
              setSubmitStatus(true);
            }
          } catch {
            setSubmitSucess(false);
            setSubmitResponse(
              'Unsucessful! Please try again later or contact adminsitrator if problem persists'
            );
            setSubmitStatus(true);
          }
          setSubmitLoad(false);
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={0} width={600}>
              <Grid item xs={12}>
                <Item>
                  <TextInput
                    disabled={submitLoad}
                    label="Username"
                    name="username"
                    type="text"
                  />
                  {usernameExists === true ? (
                    <div className="usernameWarning">
                      Username already exists. Please try another.{' '}
                    </div>
                  ) : (
                    <></>
                  )}
                  {stayAdmin === true ? (
                    <div className="usernameWarning">
                      Cannot remove yourself as an Admin. Please try another.{' '}
                    </div>
                  ) : (
                    <></>
                  )}
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <Box>
                    <LoadingButton
                      size="large"
                      classes="profileSubmit"
                      type="submit"
                      variant="outlined"
                      loading={submitLoad}
                      loadingIndicator={
                        <RotateLoader
                          cssOverride={{ left: '100%' }}
                          size={7}
                          margin={-15}
                        />
                      }
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};
