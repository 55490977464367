import * as React from 'react';
import { useContext } from 'react';
import AuthContext from './auth';
import { Outlet, Navigate } from 'react-router-dom';

export const PrivateRoute = () => {
  const user = useContext(AuthContext);
  const auth = user && user.casUser != 'Not authenticated'; // determine if authorized, from context or however you're doing it

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? <Outlet /> : <Navigate to="/protectedPage" />;
};

export const PrivateRouteEmployee = () => {
  const user = useContext(AuthContext);
  const auth =
    user && user.casUser != 'Not authenticated' && user.employee === true; // determine if authorized, from context or however you're doing it

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? <Outlet /> : <Navigate to="/protectedPage" />;
};

export const PrivateRouteAdmin = () => {
  const user = useContext(AuthContext);
  const auth =
    user && user.casUser != 'Not authenticated' && user.admin === true; // determine if authorized, from context or however you're doing it

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? <Outlet /> : <Navigate to="/protectedPage" />;
};
