import * as React from 'react';
import { useEffect, createContext, useState } from 'react';

import useAxios from 'axios-hooks';

const defaultUser = {
  casUser: 'Not authenticated',
  employee: false,
  admin: false,
  firstName: null,
  lastName: null,
};

export const AuthContext = createContext(defaultUser);

export const AuthProvider = ({ children }) => {
  // const [user, setUser] = useState<User>(defaultUser);
  const [isLoading, setLoading] = useState(true);

  const [{ data }, fetchUser] = useAxios(
    {
      url: '/cas/user',
    },
    { manual: true }
  );

  async function onLoad() {
    try {
      await fetchUser();
    } catch (e) {}

    setLoading(false);
  }

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      {!isLoading && (
        <AuthContext.Provider value={data}>{children}</AuthContext.Provider>
      )}
    </>
  );
};

export default AuthContext;
