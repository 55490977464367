import { Button, TableBody, TableCell } from '@mui/material';
import { Table, TableHead, TableRow } from '@mui/material';
import { socket } from 'context/socket';
import { useContext } from 'react';
import SampleListView from 'reusable/List/SampleListView';
import { deleteOrder } from 'utils/api/ClientOrders';
import AuthContext from 'utils/auth/auth';
import useSortableData from 'hooks/useSortableData';
import { TimeRemaining } from './TimeRemaining';

export const UploadedOrdersTable = ({
  setSubmitLoad,
  setSubmitSuccess,
  setSubmitResponse,
  setSubmitStatus,
  uploadedOrders,
}) => {
  const { items, requestSort, sortConfig } = useSortableData(uploadedOrders);
  const user = useContext(AuthContext);

  const getClassNamesFor = (dropOffCode) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === dropOffCode ? sortConfig.direction : undefined;
  };

  const handleDelete = (dropOffCode) => {
    return async () => {
      setSubmitLoad(true);
      try {
        let res = deleteOrder(dropOffCode);
        let status = res.status;
        if (status === 201) {
          socket.emit(
            'deleteUploadedOrders',
            dropOffCode,
            user.firstName,
            user.lastName
          );
          setSubmitSuccess(true);
          setSubmitResponse('Delete sucessfully!');
        }
      } catch {
        console.error('Error getting client orders');
        setSubmitSuccess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      setSubmitStatus(true);
      setSubmitLoad(false);
    };
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="500">
            <button
              type="button"
              onClick={() => requestSort('dropOffCode')}
              className={getClassNamesFor('dropOffCode')}
            >
              Drop-Off Code
            </button>
          </TableCell>
          <TableCell width="400">
            <button
              type="button"
              onClick={() => requestSort('timer')}
              className={getClassNamesFor('timer')}
            >
              Timer
            </button>
          </TableCell>
          <TableCell width="300">
            <button
              type="button"
              onClick={() => requestSort('excelFile')}
              className={getClassNamesFor('excelFile')}
            >
              Sample Data
            </button>
          </TableCell>
          <TableCell width="300">
            <button
              type="button"
              onClick={() => requestSort('delete')}
              className={getClassNamesFor('delete')}
            >
              Delete
            </button>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item) => (
          <TableRow hover key={item._id}>
            <TableCell>{item.dropOffCode}</TableCell>
            <TableCell>
              <TimeRemaining dateUploaded={item.dateUploaded} />
            </TableCell>
            <TableCell>
              {console.log(item)}
              <SampleListView
                orderType={item.submissionType}
                dropOffCode={item.dropOffCode}
              />
            </TableCell>
            <TableCell>
              <Button
                variant="outlined"
                onClick={handleDelete(item.dropOffCode)}
              >
                {' '}
                Delete
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
