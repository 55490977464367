import RecipeReviewCard from './RecipeReviewCard';
import RecipeReviewCardRecent5 from './RecipeReviewCardRecent5';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export default function Dashboard(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 16 }}
      >
        <Grid item xs={2} sm={4} md={4} key={1}>
          <RecipeReviewCard
            deliverOrderStatus={props.deliverOrderStatus}
            orderStatus={'Need to Order'}
            CatologFilter={props.CatologFilter}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4} key={2}>
          <RecipeReviewCard
            deliverOrderStatus={props.deliverOrderStatus}
            orderStatus={'Running Low'}
            CatologFilter={props.CatologFilter}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={8} key={4}>
          <RecipeReviewCardRecent5 />
        </Grid>
      </Grid>
    </Box>
  );
}
