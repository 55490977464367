import { IconButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import MUIDataTable from 'mui-datatables';
import CheckIcon from '@mui/icons-material/Check';
import { socket } from 'context/socket';
import EditButtonPopup from './EditButtonPopup';
import DeleteConfirmation from 'reusable/DeleteConfirmation';
import AuthContext from 'utils/auth/auth';
import React, { useState, useContext, useEffect } from 'react';
import { deletePI, getAllPIs } from 'utils/api/Pi';

async function getInitialData({
  setGetLoad,
  setData,
  setPIsReceived,
  pisReceived,
  user,
}) {
  if (pisReceived === false) {
    try {
      const dataToBeUsed = await getAllPIs();
      setData(dataToBeUsed);
      setGetLoad(false);
      setPIsReceived(true);
    } catch (error) {
      console.log(error);
    }
  }
}

export const CurrentPIsTable = () => {
  const [getLoad, setGetLoad] = useState(true);
  const [data, setData] = useState([]);
  const [pisReceived, setPIsReceived] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [currPI, setCurrPI] = useState(null);

  const user = useContext(AuthContext);

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleClose = () => {
    setSubmitStatus(false);
  };
  const handleDelete = (thisPI) => {
    return async (event) => {
      setSubmitLoad(true);
      try {
        const res = await deletePI(thisPI);
        const status = res.status;
        if (status === 201) {
          socket.emit('deletepi', currPI.PIName, user.firstName, user.lastName);
          setSubmitSucess(true);
          setSubmitResponse('Delete sucessfully!');
        }
      } catch {
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      setDisplayConfirmationModal(false);
      setSubmitStatus(true);
      setSubmitLoad(false);
    };
  };

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setData,
      setPIsReceived,
      pisReceived,
      user,
    }).catch(console.error);
  }, [pisReceived]);

  const columns = [
    {
      label: 'PI Name',
      name: 'PIName',
      options: {
        filter: false,
      },
    },
    {
      label: 'University',
      name: 'university',
      options: {
        filter: false,
      },
    },
    {
      label: 'University Tag',
      name: 'universityTag',
      options: {
        filter: false,
      },
    },
    {
      label: 'Worktag',
      name: 'workTag',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'ID',
      name: 'piid',
      options: {
        filter: false,
      },
    },
    {
      label: 'User',
      name: 'user',
      options: {
        filter: false,
      },
    },
    {
      label: 'Expand',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          return <IconButton></IconButton>;
        },
        display: 'excluded',
      },
    },
    {
      label: 'Operation',
      name: 'operation',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              <EditButtonPopup pis={data[dataIndex]} />
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'multiselect',
    print: false,
    viewColumns: false,
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
          <PropagateLoader />
        </Backdrop>
        <Backdrop
          sx={{ color: '#fff', zIndex: 1 }}
          open={submitStatus}
          onClick={handleClose}
        >
          {submitSucess ? (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
                window.location.reload(false);
              }}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              {submitResponse}
            </Alert>
          ) : (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              severity="error"
            >
              {submitResponse}
            </Alert>
          )}
        </Backdrop>
      </Grid>
      <MUIDataTable
        title={'PIs'}
        data={data.map((item) => {
          return [
            item.PIName,
            item.university,
            item.universityTag,
            item.workTag,
            item.piid,
            item.username,
          ];
        })}
        columns={columns}
        options={options}
      />
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleDelete}
        hideModal={hideConfirmationModal}
        id={currPI}
        message={deleteMessage}
      />
    </div>
  );
};
