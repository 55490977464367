export const ReactionTableConc = ({ conc, width }) => {
  return (
    <div
      style={{
        width: width ? width : '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {conc}
    </div>
  );
};
