import { SangerSampleTableHeadings } from './SampleComponents/SangerSampleTableHeadings';
import SangerSampleRowComponent from './SampleComponents/SangerSampleRowComponent';
import { Typography } from 'reusable/UI/Typography';
import { Button } from 'reusable/UI/Button';
import Grid from '@mui/material/Grid';
import { ClearFormButton, ErrorVisButton } from './SangerFormControls';
import ExcelUploadPopup from '../ExcelUploadPopup';
import { useContext } from 'react';
import { ErrorVisContext } from '../../contexts/DataContext';
import {
  FormMethodContext,
  SampleMethodContext,
} from '../../contexts/MethodContext';
import { EmptyTableRow } from 'reusable/UI/EmptyTableRow';

export const SangerSampleTable = ({
  samples,
  formik,
  reactionCount,
  primerMap,
  primerColors,
}) => {
  const { clearSangerForm } = useContext(FormMethodContext);

  const { showAllErrors } = useContext(ErrorVisContext);

  const { addSample, setShowAllErrors } = useContext(SampleMethodContext);

  const { uploadExcelFile } = useContext(FormMethodContext);

  const containerSx = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <>
      <Grid container sx={{ paddingLeft: 5, paddingRight: 10 }} rowSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ textAlign: 'left' }}>
            Sample Table
          </Typography>
        </Grid>
        <Grid item xs={6} md={3} sx={containerSx}>
          <ErrorVisButton
            showAllErrors={showAllErrors}
            toggleShowAllErrors={() => setShowAllErrors(!showAllErrors)}
          />
        </Grid>
        <Grid item xs={6} md={3} sx={containerSx}>
          <ExcelUploadPopup
            uploadExcelFile={uploadExcelFile}
            formType="sanger"
          />
        </Grid>
        <Grid item xs={6} md={3} sx={containerSx}>
          <ClearFormButton clearForm={clearSangerForm} />
        </Grid>
        <Grid item xs={6} md={3} sx={containerSx}>
          <Typography variant="h2">Rxn Ct: {reactionCount}</Typography>
        </Grid>
      </Grid>
      <table
        style={{
          position: 'relative',
          width: 'calc(100% - 120px)',
          left: 40,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <SangerSampleTableHeadings />
        <tbody>
          {samples.length > 0 ? (
            samples.map((sample, index) => (
              <SangerSampleRowComponent
                key={sample.id}
                sample={sample}
                index={index}
                formik={formik}
                primerMap={primerMap}
                primerColors={primerColors}
              />
            ))
          ) : (
            <EmptyTableRow cols={9} />
          )}
        </tbody>
      </table>
      <Button
        variant="contained"
        color="secondary"
        onClick={addSample}
        sx={{ marginLeft: 5 }}
      >
        Add New Sample
      </Button>
    </>
  );
};
