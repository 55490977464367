import { headingStyle } from 'reusable/TableStyles';

export const ControlTableHeading = () => {
  return (
    <thead>
      <tr>
        <th style={{ ...headingStyle }}>Name</th>
        <th style={{ ...headingStyle }}>Type</th>
        <th style={{ ...headingStyle }}>Prep Date</th>
        <th style={{ ...headingStyle }}>Template (µL)</th>
        <th style={{ ...headingStyle }}>Primer (µL)</th>
      </tr>
    </thead>
  );
};
