import { MenuItem, Select } from '@mui/material';
import { ListItemText } from '@mui/material';
import { Checkbox } from '@mui/material';
import { InputLabel } from '@mui/material';
import { FormControl } from '@mui/material';

export const DropLocationComponent = ({
  filterList,
  index,
  column,
  onChange,
}) => {
  const dropOffLocations = [
    'EBB',
    'Cherry Emerson',
    'MoSE',
    'IBB',
    'ES&T',
    'Marcus',
  ];

  return (
    <FormControl>
      <InputLabel htmlFor="select-multiple-chip">Drop-off Location</InputLabel>
      <Select
        multiple
        value={filterList[index]}
        renderValue={(selected) => selected.join(', ')}
        onChange={(event) => {
          filterList[index] = event.target.value;
          onChange(filterList[index], index, column);
        }}
      >
        {dropOffLocations.map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox
              color="primary"
              checked={filterList[index].indexOf(item) > -1}
            />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
