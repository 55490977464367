import { TextField } from '@mui/material';
import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { FormGroup } from '@mui/material';
import { FormLabel } from '@mui/material';

export const SubmissionComponent = ({
  filterList,
  index,
  column,
  onChange,
  isChecked,
  setIsChecked,
}) => (
  <div>
    <FormLabel>Date of Submission</FormLabel>
    <br></br>
    <FormGroup row>
      <TextField
        id="startDate"
        label="Start Date"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        value={filterList[index][0] || ''}
        onChange={(event) => {
          filterList[index][0] = event.target.value;
          onChange(filterList[index], index, column);
        }}
        style={{ width: '60%', marginRight: '5%' }}
      />
      <TextField
        id="endDate"
        label="End Date"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        value={filterList[index][1] || ''}
        onChange={(event) => {
          filterList[index][1] = event.target.value;
          onChange(filterList[index], index, column);
        }}
        style={{ width: '60%', marginRight: '5%' }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={(event) => setIsChecked(event.target.checked)}
          />
        }
        label="Separate Values"
        style={{ marginLeft: '0px' }}
      />
    </FormGroup>
  </div>
);
