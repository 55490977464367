import { useState, useEffect } from 'react';
import { socket } from 'context/socket';
import Grid from '@mui/material/Grid';
import RotateLoader from 'react-spinners/RotateLoader';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PropagateLoader from 'react-spinners/PropagateLoader';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { OrderPopup } from 'reusable/OrderPopup/OrderPopup';
import Modal from '@mui/material/Modal';
import OrderAutocomplete from './OrderAutocomplete';
import { useSnackbar } from 'notistack';
import {
  deleteComboRun,
  getAllActiveComboRuns,
  getComboRun,
  deleteOrderFromCR,
  updateComboRunOrders,
} from 'utils/api/ComboRun';
import { getActiveOrders, getOrder } from 'utils/api/Orders';

async function getActiveComboRuns(
  setComboRuns,
  comboRunsReceived,
  setComboRunReceived,
  setLoadComboRuns,
  setDeleteConfirm
) {
  if (comboRunsReceived === false) {
    const response = await getAllActiveComboRuns();
    const data = response.data;
    if (data.length === 0) {
      setComboRuns(null);
    } else {
      setComboRuns(data);
      var newList = [];
      for (let i = 0; i < data.length; i++) {
        newList.push(false);
      }
      setDeleteConfirm(newList);
    }
    setLoadComboRuns(false);
    setComboRunReceived(true);
    return;
  }
}

const getComboRunOrders = async (comboRunOrders) => {
  let result = [];
  if (comboRunOrders != undefined) {
    for (let i = 0; i < comboRunOrders.length; i++) {
      const data = await getOrder({ orderCode: comboRunOrders[i] });
      result[i] = data;
    }
  }
  return result;
};

const style = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  p: 4,
};

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function EditComboRun(props) {
  const [open, setOpen] = useState(false);
  const [PopupOpen, setPopupOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [comboRunsReceived, setComboRunReceived] = useState(false);
  const [comboRuns, setComboRuns] = useState([]);
  const [success, setSuccess] = useState(false);
  const [showRes, setShowRes] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [loadComboRuns, setLoadComboRuns] = useState(true);
  const [comboRunEdit, setComboRunEdit] = useState(false);
  const [comboRunNo, setComboRunNo] = useState(null);
  const [CRbeingEdited, setCRbeingEdited] = useState(null);
  const [comboRunOrdersList, setComboRunorders] = useState([]);
  const [update, setUpdate] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState([false]);
  const [options, setOptions] = useState([]);
  const [ordersReceived, setOrderReceived] = useState(false);
  const [orderView, setOrderView] = useState(null);
  const loading = open && options.length === 0;
  const { enqueueSnackbar } = useSnackbar();
  const toggleChecked = () => setUpdate((value) => !value);
  const handleClose = props.handleClose;

  async function getActiveOrdersCR() {
    if (ordersReceived === false) {
      const data = await getActiveOrders({ inComboRun: false });
      if (data.length === 0) {
        data.push({ orderCode: 'No orders available', noOfSamples: 0 });
      }
      const currentOrders = formik.values.orders;
      const newOptions = data.filter(
        (d) => !currentOrders.some((co) => co.orderCode === d.orderCode)
      );
      setOptions([...currentOrders, ...newOptions]);
      setOrderReceived(true);
      return;
    }
  }

  const handleOrderOpen = (orderView) => {
    setOrderView(orderView);
    setPopupOpen(true);
  };
  const handleOrderClose = () => setPopupOpen(false);

  getActiveComboRuns(
    setComboRuns,
    comboRunsReceived,
    setComboRunReceived,
    setLoadComboRuns,
    setDeleteConfirm
  );

  function setDeleteConfirmIndex(index) {
    var newList = [];
    for (let i = 0; i < deleteConfirm.length; i++) {
      if (i === index) {
        newList.push(true);
      } else {
        newList.push(false);
      }
    }
    setDeleteConfirm(newList);
  }

  function setDeleteConfirmReset() {
    var newList = [];
    for (let i = 0; i < deleteConfirm.length; i++) {
      newList.push(false);
    }
    setDeleteConfirm(newList);
  }

  async function deleteCR(comboRunNo) {
    const status = await deleteComboRun({ comboRunNo: comboRunNo });
    if (status === 200) {
      setDeleteConfirmReset();
      setComboRunReceived(false);
      enqueueSnackbar('CR' + comboRunNo + ' has been deleted', {
        variant: 'warning',
      });
    }
  }

  async function removeOrderFromCR(orderCode) {
    const status = await deleteOrderFromCR({ orderCode: orderCode });
    if (status === 200) {
      setDeleteConfirmReset();
      setComboRunReceived(false);
      enqueueSnackbar(orderCode + ' has been removed from the combo run', {
        variant: 'warning',
      });
    }
  }

  async function editMode(comboRunNo) {
    setComboRunNo(comboRunNo);
    const result = comboRuns.filter(
      (comboRun) => comboRun.comboRunNo === comboRunNo
    );
    setCRbeingEdited(result);
    setComboRunEdit(true);
  }

  const formik = useFormik({
    initialValues: {
      orders: [],
      existingCount: 0,
      totalCount: 0,
    },
    validationSchema: Yup.object({
      orders: Yup.array()
        .min(1, 'Must be at least 1 order')
        .required('Required'),
      existingCount: Yup.number(),
      totalCount: Yup.number().test(
        'superior',
        'Count cannot go over 96',
        function (f) {
          const ref = Yup.ref('existingCount');
          return f < 96 - this.resolve(ref);
        }
      ),
    }),

    onSubmit: async (values) => {
      setShowRes(false);
      setSubmitLoad(true);
      var result = values.orders.map(({ orderCode }) => orderCode);
      const status = await updateComboRunOrders(comboRunNo, result);
      if (status === 200) {
        setSuccess(true);
        setShowRes(true);
        setSubmitLoad(true);
      } else {
        setSubmitLoad(false);
        setSuccess(false);
        setShowRes(true);
      }
    },
  });

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    (async () => {
      await sleep(1e3);
      await getActiveOrdersCR(setOptions, ordersReceived, setOrderReceived);

      if (active) {
        setOptions([...options]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
      setOrderReceived(false);
    }
  }, [open]);

  useEffect(() => {
    setAddOpen(false);
    setDeleteConfirmReset();
    if (comboRunNo !== null) {
      getComboRun(comboRunNo)
        .then((data) => {
          return getComboRunOrders(data.orders);
        })
        .then((result) => {
          setComboRunorders(result);
          var existingCount = 0;
          for (let i = 0; i < result.length; i++) {
            existingCount = existingCount + result[i].noOfSamples;
          }
          formik.setFieldValue('existingCount', existingCount);
        });
    }
  }, [CRbeingEdited, comboRunEdit, update]);

  useEffect(() => {
    socket.on('createCR', (comboRunNoRes) => {
      setComboRunReceived(false);
      if (comboRunEdit === false) {
        enqueueSnackbar(
          'CR' + comboRunNoRes + ' has been created, changes loaded',
          { variant: 'info' }
        );
      }
    });
  }, []);

  useEffect(() => {
    socket.on('deleteCR', (comboRunNoRes) => {
      setComboRunReceived(false);
      toggleChecked();
      if (comboRunEdit === true && comboRunNoRes === comboRunNo) {
        enqueueSnackbar('CR' + comboRunNoRes + ' has been deleted', {
          variant: 'warning',
        });
        setComboRunEdit(false);
      }
    });
  }, []);

  useEffect(() => {
    socket.on('orderAddRemoveCR', (comboRunNoRes) => {
      toggleChecked();
      if (comboRunEdit === true && comboRunNoRes === comboRunNo) {
        enqueueSnackbar('Orders have been edited for CR' + comboRunNo, {
          variant: 'info',
        });
      }
    });
  }, []);

  const handleChange = (event, values) => {
    formik.setFieldValue('orders', values);
    var totalCount = 0;
    for (let i = 0; i < values.length; i++) {
      totalCount += values[i].noOfSamples;
    }
    formik.setFieldValue('totalCount', totalCount);
  };

  return (
    <>
      <Modal
        open={PopupOpen}
        onClose={handleOrderClose}
        style={{ display: 'flex' }}
      >
        <OrderPopup
          currentOrder={orderView}
          handleClose={handleOrderClose}
          viewOnly={true}
        />
      </Modal>
      <Grid
        container
        spacing={0}
        justifyContent="center"
        alignItems="center"
        maxWidth={600}
        sx={style}
        style={{ minHeight: '50vh', margin: 'auto' }}
      >
        {!loadComboRuns ? (
          <>
            {!comboRunEdit ? (
              <>
                <Grid item xs={10}>
                  <h2>Edit or delete a combo run</h2>
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => handleClose()}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
                {comboRuns != null ? (
                  <>
                    {comboRuns.map(function (combo, i) {
                      return (
                        <>
                          <Grid item xs={12} align="center">
                            CR{combo.comboRunNo}
                            <IconButton
                              onClick={() => editMode(combo.comboRunNo)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => setDeleteConfirmIndex(i)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={12} align="center">
                            {deleteConfirm[i] ? (
                              <>
                                <Alert severity="warning">
                                  Are you sure you want to delete CR
                                  {combo.comboRunNo}?
                                </Alert>
                                <Button
                                  sx={{ mr: 10 }}
                                  onClick={() => deleteCR(combo.comboRunNo)}
                                >
                                  Yes
                                </Button>
                                <Button
                                  sx={{ ml: 10 }}
                                  onClick={() => setDeleteConfirmReset()}
                                >
                                  No
                                </Button>
                              </>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <Grid item xs={12} align="center">
                    No Combo runs currently active right now
                  </Grid>
                )}
              </>
            ) : (
              <>
                <Grid item xs={2}>
                  <IconButton onClick={() => setComboRunEdit(false)}>
                    <ArrowBackIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={8}>
                  <h2>Editing CR{comboRunNo}</h2>
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => handleClose()}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
                {comboRunOrdersList.map(function (order, i) {
                  return (
                    <>
                      <Grid item xs={12} align="center">
                        {order.orderCode}
                        <IconButton onClick={() => handleOrderOpen(order)}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => removeOrderFromCR(order.orderCode)}
                          disabled={comboRunOrdersList.length <= 1}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </>
                  );
                })}
                {addOpen ? (
                  <>
                    <Grid item xs={12} align="center">
                      Count space left
                      <br></br>
                      {96 -
                        formik.values.totalCount -
                        formik.values.existingCount}
                      <br></br>
                    </Grid>
                    <Grid item xs={12} align="center">
                      <div className="formErrorCREdit">
                        {formik.errors.totalCount}
                      </div>
                    </Grid>
                    <OrderAutocomplete
                      value={formik.values.orders}
                      onChange={(event, value) => handleChange(event, value)}
                      open={open}
                      setOpen={setOpen} // This prop assumes that the OrderAutocomplete component accepts this prop to handle open state changes
                      submitLoad={submitLoad}
                      options={options}
                      loading={loading}
                      error={
                        formik.touched.orders && Boolean(formik.errors.orders)
                      }
                      helperText={formik.touched.orders && formik.errors.orders}
                    />

                    <Grid item xs={12} align="center">
                      {success ? (
                        <></>
                      ) : (
                        <LoadingButton
                          size="medium"
                          classes="CCRSubmit"
                          variant="outlined"
                          type="submit"
                          onClick={formik.handleSubmit}
                          loading={submitLoad}
                          loadingIndicator={
                            <RotateLoader
                              cssOverride={{ left: '100%' }}
                              size={7}
                              margin={-15}
                            />
                          }
                        >
                          Add to combo run
                        </LoadingButton>
                      )}
                      {showRes ? (
                        <>
                          {success ? (
                            <Alert
                              severity="success"
                              icon={<CheckIcon fontSize="inherit" />}
                            >
                              {' '}
                              Orders added!{' '}
                            </Alert>
                          ) : (
                            <Alert severity="error">
                              Failed to add orders!
                            </Alert>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </>
                ) : (
                  <>
                    <IconButton onClick={() => setAddOpen(true)}>
                      <AddIcon />
                    </IconButton>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <PropagateLoader />
          </>
        )}
      </Grid>
    </>
  );
}
