import Grid from '@mui/material/Grid';
import { Formik, Form } from 'formik';
import { PopupGridItem } from './ClientPopupImports';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

export const ClientPopupContent = ({ client, handleClose }) => {
  var object = new Object();
  object['username'] = client.username;

  return (
    <>
      <Grid container>
        <Grid item xs={11}>
          <h2 className="clientsPopupUsernameTitle">{client.username}</h2>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          container
          style={{ maxHeight: 650, overflowY: 'auto', overflowX: 'hidden' }}
          rowSpacing={0}
          spacing={0}
        >
          <Grid item xs={12}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                firstName: client.firstName ? client.firstName : '',
                lastName: client.lastName ? client.lastName : '',
                SUMSworktag: client.SUMSworktag ? client.SUMSworktag : '',
                email: client.email ? client.email : '',
                phoneNumber: client.phoneNumber ? client.phoneNumber : '',
                labName: client.labName ? client.labName : '',
                university: client.university ? client.university : '',
                universityTag: client.universityTag ? client.universityTag : '',
                noOfOrders: client.noOfOrders ? client.noOfOrders : '',
                orderTag: client.orderTag ? client.orderTag : '',
              }}
            >
              {(formikProps) => (
                <Form>
                  <PopupGridItem
                    attribute={'Name'}
                    data={
                      client.firstName
                        ? client.firstName + ' ' + client.lastName
                        : ''
                    }
                  />
                  <PopupGridItem attribute={'Lab name'} data={client.PIName} />
                  <PopupGridItem
                    attribute={'University'}
                    data={client.university}
                  />
                  <PopupGridItem
                    attribute={'University Tag'}
                    data={client.universityTag}
                  />
                  <PopupGridItem
                    attribute={'SUMS worktag'}
                    data={client.SUMSworktag}
                  />
                  <PopupGridItem attribute={'Email'} data={client.email} />
                  <PopupGridItem
                    attribute={'Phone'}
                    data={client.phoneNumber}
                  />
                  <PopupGridItem
                    attribute={'No. of Orders'}
                    data={client.noOfOrders}
                  />
                  <PopupGridItem
                    attribute={'Order tag'}
                    data={client.orderTag}
                  />
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
