import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import DndDropOrder from './DragDropOrder';

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

export default function ActiveOrders(props) {
  let currentOrders = props.currentOrders;

  let activeOrders = [];
  for (let i = 0; i < 7; i++) {
    const attri = 'stage';

    let temp = currentOrders.filter((currentOrder) => {
      let contain = false;
      let title;
      switch (i) {
        case 0:
          title = 'arrived';
          break;
        case 1:
          title = 'pcrprep';
          break;
        case 2:
          title = 'pcrmachine';
          break;
        case 3:
          title = 'purification';
          break;
        case 4:
          title = 'readytoload';
          break;
        case 5:
          title = 'ssmachine';
          break;
        default:
          title = 'resulting';
          break;
      }

      if (
        String(currentOrder[attri])
          .toLowerCase()
          .replace(/\s+/g, '')
          .replace(/-/g, '')
          .includes(title)
      ) {
        contain = true;
      }
      return contain;
    });
    temp.push({ orderCode: '.', temp: true });
    temp.push({ orderCode: '.', temp: true });
    activeOrders.push(temp);
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container align="center" justify="center">
          {Array.from(Array(7)).map((_, index) => (
            <>
              <Grid item lg={12 / 7.2} xs={5.95} md={3.95}>
                <Box sx={{ flexGrow: 1 }}>
                  {index === 0 ? (
                    <Div>{'Arrived'}</Div>
                  ) : index === 1 ? (
                    <Div>{'PCR PREP'}</Div>
                  ) : index === 2 ? (
                    <Div>{'PCR Machine'}</Div>
                  ) : index === 3 ? (
                    <Div>{'Purification'}</Div>
                  ) : index == 4 ? (
                    <Div>{'READY TO LOAD'}</Div>
                  ) : index === 5 ? (
                    <Div>{'SS MACHINE'}</Div>
                  ) : (
                    <Div>{'Resulting'}</Div>
                  )}
                  <DndDropOrder
                    key={index}
                    stage={index}
                    activeOrders={activeOrders}
                    index={index}
                  ></DndDropOrder>
                </Box>
              </Grid>
              <Grid item xs={0.05} key={2 * index + 1}>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ height: '100%', width: '2px' }}
                />
              </Grid>
            </>
          ))}
        </Grid>
      </Box>
    </>
  );
}
