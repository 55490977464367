import { getAllCartridges } from 'utils/api/Cartridges';

export async function getInitialData({
  setGetLoad,
  setData,
  setCartridgesReceived,
  cartridgesReceived,
  user,
}) {
  if (cartridgesReceived === false) {
    try {
      const dataToBeUsed = await getAllCartridges();
      setData(dataToBeUsed);
      setGetLoad(false);
      setCartridgesReceived(true);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
