import { DialogActions, Divider } from '@mui/material';
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { Button } from './UI/Button';
import { NewControlForm } from 'reusable/NewControlForm';

export const AddControlPopup = ({
  open,
  setOpen,
  openNewPopup,
  controls,
  addControl,
}) => {
  const controlsOptions = controls.map((control, i) => ({
    label: control.name,
    key: i,
  }));
  const [selectedControl, setSelectedControl] = useState(null);

  const handleDialogClose = () => {
    setOpen(false);
    setSelectedControl(null);
  };

  const handleSubmit = () => {
    addControl(selectedControl.key);
    handleDialogClose();
  };

  const handleAutocompleteChange = (_, value) => {
    setSelectedControl(value);
  };

  const handleNewControl = () => {
    setOpen(false);
    openNewPopup();
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle>Add New Control</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select which control to use, or create a new control.
        </DialogContentText>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 10,
            padding: 10,
          }}
        >
          <Autocomplete
            fullWidth
            options={controlsOptions}
            value={selectedControl}
            getOptionLabel={(opt) => opt.label}
            style={{ padding: 5 }}
            renderInput={(params) => <TextField {...params} label="Controls" />}
            onChange={handleAutocompleteChange}
          />
          <div style={{ width: '100%' }}>
            <Divider fullWidth>
              <DialogContentText>OR</DialogContentText>
            </Divider>
          </div>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleNewControl}
          >
            Create New Control
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={handleDialogClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!selectedControl}
          onClick={handleSubmit}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const NewControlPopup = ({ open, setOpen, refreshControls }) => {
  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle>Create New Control</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Fill out the following form to create a new control
        </DialogContentText>
        <NewControlForm
          closeDialog={() => setOpen(false)}
          refreshControls={refreshControls}
        />
      </DialogContent>
    </Dialog>
  );
};
