import { Button } from 'reusable/UI/Button';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from 'reusable/UI/Typography';

export const GuidelinesComponent = () => {
  const [showTables, setShowTables] = useState(false);

  return (
    <>
      <div>
        <strong>
          Please review all guidelines before preparing your samples for
          submission.
        </strong>
      </div>
      <ul>
        <li>
          Please submit aliquots for each of your samples*. We advise against
          sending your entire volume of samples.
        </li>
        <li>
          Please submit your samples in PCR strip-tubes or plate(s). Primers may
          be submitted in individual tubes.
        </li>
        <li>
          A submission with a total reaction count in multiples of 4 is
          preferred but not required.
        </li>
        <li>
          Minimum volume per sample is 10µL per primer utilized.
          <ul>
            <li>
              Example: one sample to be run with 2 primers will need to have a
              minimum volume of 20µL.
            </li>
          </ul>
        </li>
        <li>
          If submitting custom primers:
          <ul>
            <li>The required primer concentration is 3.2 µM.</li>
            <li>
              Minimum primer volume per reaction is 5µL.
              <ul>
                <li>
                  Example: submit at least 160µL of a primer to be used in 32
                  reactions
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Refer to Table 1A and Table 1B for template options, as it will be
          needed to fill out the Sample ul (Table 2A).
        </li>
        <li>
          Refer to Table 1C for available read modules in our instruments. The
          maximum guaranteed submitted sample length is 800 basepairs**.
          <br />
          <Button variant="accent" onClick={() => setShowTables(true)}>
            Show Tables
          </Button>
        </li>
        <li>
          For DNA samples or plasmid inserts, provide purified PCR products with
          the corresponding primers.
        </li>
        <li>Plasmids can be submitted as is with the corresponding primers.</li>
      </ul>
      <div>
        *A sample with two primers is two reactions. Clients are billed per
        reaction.
      </div>
      <div>
        **Data depends on the quality, purity, and concentration of the samples
        submitted. Following our sample submission guidelines increases the
        instrument yield of high quality data.
      </div>
      <SpecTables open={showTables} onClose={() => setShowTables(false)} />
    </>
  );
};

const SpecTables = ({ open, onClose }) => {
  const Th = ({ children, ...props }) => (
    <th {...props} style={{ padding: 4, border: '1px solid #008AFC' }}>
      {children}
    </th>
  );

  const Td = ({ children, ...props }) => (
    <td
      {...props}
      style={{
        padding: 4,
        border: '1px solid #008AFC',
        width: '50%',
      }}
    >
      {children}
    </td>
  );

  const Table = ({ children, ...props }) => (
    <table {...props} style={{ width: '100%' }}>
      {children}
    </table>
  );

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md">
      <Grid container sx={{ padding: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h2" textAlign="left" sx={{ paddingX: 2 }}>
            Tables
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ padding: 2 }}>
          <Typography variant="h3">Table 1A</Typography>
          <Table>
            <thead>
              <tr>
                <Th>Template</Th>
                <Th>Quantity</Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Th colSpan={2}>PCR Products</Th>
              </tr>
              <tr>
                <Td>100 - 200 bp</Td>
                <Td>1 - 3 ng/µL</Td>
              </tr>
              <tr>
                <Td>200 - 500 bp</Td>
                <Td>3 - 10 ng/µL</Td>
              </tr>
              <tr>
                <Td>500 - 1000 bp</Td>
                <Td>5 - 20 ng/µL</Td>
              </tr>
              <tr>
                <Td>1000 - 2000 bp</Td>
                <Td>10 - 40 ng/µL</Td>
              </tr>
              <tr>
                <Td>{'>'} 2000 bp</Td>
                <Td>20 - 50 ng/µL</Td>
              </tr>
            </tbody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ padding: 2 }}>
          <Typography variant="h3">Table 1B</Typography>
          <Table>
            <thead>
              <tr>
                <Th>Template</Th>
                <Th>Quantity</Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Td>Single-Stranded</Td>
                <Td>25 - 50 ng/µL</Td>
              </tr>
              <tr>
                <Td>Double-Stranded</Td>
                <Td>150 - 300 ng/µL</Td>
              </tr>
              <tr>
                <Td>Cosmid, BAC</Td>
                <Td>0.5 - 1.0 µg/µL</Td>
              </tr>
              <tr>
                <Td>Bacteria Genomic DNA</Td>
                <Td>2 - 3 µg/µL</Td>
              </tr>
            </tbody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ padding: 2 }}>
          <Typography variant="h3">Table 1C</Typography>
          <Table>
            <thead>
              <tr>
                <Th colSpan={2}>Read Modules</Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Td>Short</Td>
                <Td>1 - 350 bp</Td>
              </tr>
              <tr>
                <Td>Medium</Td>
                <Td>350 - 500 bp</Td>
              </tr>
              <tr>
                <Td>Long</Td>
                <Td>500 - 800 bp</Td>
              </tr>
            </tbody>
          </Table>
        </Grid>
      </Grid>
    </Dialog>
  );
};
