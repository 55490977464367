import { Checkbox, MenuItem, Select } from '@mui/material';
import { ListItemText } from '@mui/material';
import { InputLabel } from '@mui/material';
import { FormControl } from '@mui/material';

export const ResearcherComponent = ({
  filterList,
  onChange,
  index,
  column,
  researchers,
}) => (
  <FormControl>
    <InputLabel htmlFor="select-multiple-chip">Researcher</InputLabel>
    <Select
      multiple
      value={filterList[index]}
      renderValue={(selected) => selected.join(', ')}
      onChange={(event) => {
        filterList[index] = event.target.value;
        onChange(filterList[index], index, column);
      }}
    >
      {researchers.map((item) => (
        <MenuItem key={item} value={item}>
          <Checkbox
            color="primary"
            checked={filterList[index].indexOf(item) > -1}
          />
          <ListItemText primary={item} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
