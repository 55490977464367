import { useState } from 'react';

export const useDraggable = (move, index) => {
  const [draggable, setDraggable] = useState(false);

  const onDragStart = (e) => {
    e.dataTransfer.setData('dragIndex', String(index));
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
    setDraggable(false);

    const movedIndex = Number(e.dataTransfer.getData('dragIndex'));
    move(movedIndex, index);
  };

  return {
    draggable,
    setDraggable,
    draggableProps: {
      draggable: draggable,
      onDragStart: onDragStart,
      onDragOver: onDragOver,
      onDrop: onDrop,
      onMouseUp: () => setDraggable(false),
      onDragEnd: () => setDraggable(false),
    },
  };
};
