import axios from 'axios';

const createComboRun = async (order) => {
  try {
    const response = await axios.put(`/api/order/CCR`, {
      orders: order,
    });
    return response.status;
  } catch (error) {
    console.log('Error creating combo run:', error);
    return 500;
  }
};

const getOrderinCR = async (orderCode) => {
  const res = await axios({
    method: 'GET',
    url: '/api/order/mergedInPlate',
    params: {
      orderCode: orderCode,
    },
  });
  return res;
};

const getComboRun = async (comboRunNo) => {
  const res = await axios({
    url: '/api/order/GCR',
    method: 'GET',
    params: {
      comboRunNo: comboRunNo,
    },
  });
  return res.data;
};

const getAllActiveComboRuns = async () => {
  try {
    const response = await axios.get(`/api/order/GACR`);
    console.log('order/GACR', response);
    return response;
  } catch (error) {
    console.log('Error getting combo run:', error);
    throw error;
  }
};

const updateComboRun = async (values, orderCode) => {
  const res = await axios({
    url: '/api/order/saveCR',
    method: 'PUT',
    data: values,
    params: {
      orderCode: orderCode,
    },
  });
  return res;
};

const updateComboRunStage = async (values, orderCode, comboRunStage) => {
  const res = await axios({
    url: '/api/order/submitCR',
    method: 'PUT',
    data: values,
    params: {
      comboRunNo: orderCode,
      stage: comboRunStage,
    },
  });
  return res;
};

const updateComboRunOrders = async (comboRunNo, orders) => {
  try {
    const response = await axios.put(`/api/order/addOrdersToCR`, null, {
      params: { comboRunNo: comboRunNo, orders: orders },
    });
    return response.status;
  } catch (error) {
    console.log('Error updating orders in combo run:', error);
    return 500;
  }
};

const deleteComboRun = async (comboRunNo) => {
  try {
    const response = await axios.delete(`/api/order/deleteCR`, {
      params: { ...comboRunNo },
    });
    return response.status;
  } catch (error) {
    console.log('Error deleting combo run:', error);
    throw error;
  }
};

const deleteOrderFromCR = async (orderCode) => {
  try {
    const response = await axios.delete(`/api/order/removeOrderFromCR`, {
      params: { ...orderCode },
    });
    return response.status;
  } catch (error) {
    console.log('Error deleting order from combo run:', error);
    throw error;
  }
};

const getUnmergedOrdersInCR = async (comboRunNo) => {
  return await axios({
    url: '/api/order/unmerged',
    method: 'GET',
    params: {
      comboRunNo: comboRunNo,
    },
  });
};

const mergeCR = async (basePlate, pickedPlates) => {
  return await axios({
    url: '/api/order/mergeCR',
    method: 'PUT',
    params: {
      basePlate: basePlate,
      addedPlates: pickedPlates,
    },
  });
};

const unmergeCR = async (orderCode) => {
  return await axios({
    url: '/api/order/unmergeCR', // Update this endpoint to match your API
    method: 'PUT',
    params: {
      orderCode: orderCode,
    },
  });
};

export {
  createComboRun,
  getAllActiveComboRuns,
  getComboRun,
  getOrderinCR,
  updateComboRunOrders,
  updateComboRun,
  updateComboRunStage,
  deleteComboRun,
  deleteOrderFromCR,
  getUnmergedOrdersInCR,
  mergeCR,
  unmergeCR,
};
