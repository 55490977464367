import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

const archiveLog = async (values, displayCatalogNumber) => {
  try {
    const suffix = displayCatalogNumber ? 'all' : 'catolog';
    const res = await axios.put(
      `${API_ENDPOINT}/Inventory/inventoryLog/hide/${suffix}`,
      values
    );
    return res.status;
  } catch (error) {
    console.error('Error archiving log:', error);
    throw error;
  }
};

export { archiveLog };
