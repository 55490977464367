export const ReactionTableVolume = ({ target, conc, width }) => {
  const vol = (target / conc).toFixed(2);

  return (
    <div
      style={{
        width: width ? width : '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {vol}
    </div>
  );
};
