import { useState } from 'react';
import Modal from '@mui/material/Modal';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ClientPopup } from './PopupComponents/ClientPopup';

// uses the "dialog" component on the MUI website

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const ClientsPopup = ({ currentClient }) => {
  const [open, setOpen] = useState(false);

  // BACKEND CALL TO RETRIEVE CLIENT INFORMATION

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <OpenInFullIcon />
      </IconButton>
      <Modal open={open} onClose={handleClose} style={{ display: 'flex' }}>
        <ClientPopup
          currentClient={currentClient}
          handleClose={handleClose}
          viewOnly={true}
        />
      </Modal>
    </div>
  );
};
