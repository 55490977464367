import { Checkbox } from '@mui/material';
import { ListItemText } from '@mui/material';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { FormControl } from '@mui/material';

export const StatusComponent = ({ filterList, index, onChange, column }) => {
  const optionValues = ['Received', 'Processing', 'Completed'];

  return (
    <FormControl>
      <InputLabel htmlFor="select-multiple-chip">Order Status</InputLabel>
      <Select
        multiple
        value={filterList[index]}
        renderValue={(selected) => selected.join(', ')}
        onChange={(event) => {
          filterList[index] = event.target.value;
          onChange(filterList[index], index, column);
        }}
      >
        {optionValues.map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox
              color="primary"
              checked={filterList[index].indexOf(item) > -1}
            />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
