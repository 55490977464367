import { createContext } from 'react';

export const FormMethodContext = createContext({
  updateSangerForm: undefined,
  loadSangerForm: undefined,
  clearSangerForm: undefined,
  uploadExcelFile: undefined,
});

export const SampleMethodContext = createContext({
  addSample: undefined,
  addSampleIndex: undefined,
  updateSample: undefined,
  removeSample: undefined,
  moveSample: undefined,
});

export const PrimerMethodContext = createContext({
  addPrimer: undefined,
  addPrimerIndex: undefined,
  addLabPrimer: undefined,
  updatePrimerMap: undefined,
  updateLabPrimerMap: undefined,
  removePrimer: undefined,
  movePrimer: undefined,
});

