import axios from 'axios';

export const updateFragmentForm = async (fragments) => {
  try {
    let res = await axios({
      url: '/api/orderForm/fragment',
      method: 'POST',
      data: { fragments: fragments },
    });
    return res;
  } catch (error) {
    console.error('Error updating fragment form:', error);
    throw error;
  }
};

export const submitFragmentForm = async ({ fragments }) => {
  try {
    const res = await axios({
      method: 'post',
      url: '/api/upload/clientExcel',
      data: { fragments: fragments },
      params: {
        orderType: 'fragment',
      },
    });
    return res;
  } catch (error) {
    console.error('Error submitting fragment form:', error);
    throw error;
  }
};

export const getFragmentForm = async () => {
  try {
    let res = await axios({
      url: '/api/orderForm/fragment',
      method: 'GET',
    });
    return res;
  } catch (error) {
    console.error('Error getting fragment form:', error);
    throw error;
  }
};

export const uploadFragmentExcel = async (formData) => {
  try {
    let res = await axios({
      url: '/api/fragmentAnalysis/clientFragmentExcel',
      method: 'POST',
      data: formData,
    });
    return res;
  } catch (error) {
    console.error('Error uploading fragment excel file', error);
    throw error;
  }
};
