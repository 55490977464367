import { React, useContext, useEffect, useState } from 'react';
import SangerRowName from './SangerRowName';
import SangerRowPrimers from './SangerRowPrimers';
import SangerRowPremix from './SangerRowPremix';
import SangerRowConcentration from './SangerRowConcentration';
import SangerRowErrors from './SangerRowErrors';
import SangerRowWell from './SangerRowWell';
import SangerRowReadLen from './SangerRowReadLen';
import SangerRowVol from './SangerRowVol';
import SangerRowType from './SangerRowType';
import SangerRowDesiredTemplate from './SangerRowDesiredTemplate';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from 'reusable/UI/IconButton';
import { ErrorVisContext } from '../../../contexts/DataContext';
import { SampleMethodContext } from '../../../contexts/MethodContext';

const SangerSampleRowComponent = ({
  sample,
  index,
  formik,
  primerMap,
  primerColors,
}) => {
  const { showAllErrors } = useContext(ErrorVisContext);

  const { addSampleIndex, removeSample, moveSample } =
    useContext(SampleMethodContext);

  const [draggable, setDraggable] = useState(false);
  const [templateOptions, setTemplateOptions] = useState([]);

  const rowButtonStyle = {
    position: 'absolute',
    display: 'flex',
    transform: 'translateY(-50%)',
  };

  const rowContainerStyle = {
    border: '1px solid #0030579E',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    minHeight: 55,
    paddingRight: 7,
    paddingLeft: 10,
    marginTop: 0,
    paddingTop: 0,
    marginBottom: 0,
  };

  const borderLeft = {
    borderLeft: '1px solid #0030572E',
  };

  const borderRight = {
    borderRight: '1px solid #0030572E',
  };

  const borderLeftRight = { ...borderLeft, ...borderRight };

  function isNumeric(str) {
    return /^\+?\d+$/.test(str);
  }

  function errorConc(concVal) {
    return (
      concVal < 5 || !isNumeric(concVal) || concVal == null || concVal == ''
    );
  }

  function errorName(string) {
    if (typeof string !== 'string') string = String(string);
    return (
      string.length > 70 || string.trim().length === 0 || string.length <= 5
    );
  }

  function errorPrimers(primers, premixed) {
    return !premixed && primers.length < 1;
  }

  function errorReadLen(readLength) {
    return (
      readLength === undefined ||
      readLength === null ||
      !isNumeric(readLength) ||
      readLength < 0 ||
      readLength > 800
    );
  }

  function errorVol(vol, primerRowList, premixed) {
    let requiredVol = premixed
      ? 10
      : (primerRowList.length == 0 ? 1 : primerRowList.length) * 10;
    return vol == null || vol == '' || !isNumeric(vol) || vol < requiredVol;
  }

  const updateTemplateOptions = (type) => {
    let options;
    if (type === 'DNA' || type === 'Plasmid-insert') {
      options = [
        'PCR Product: 100 - 200 bp',
        'PCR Product: 200 - 500 bp',
        'PCR Product: 500 - 1000 bp',
        'PCR Product: 1000 - 2000 bp',
        'PCR Product: >2000 bp',
      ];
    } else {
      options = [
        'Plasmid: Single-stranded',
        'Plasmid: Double-stranded',
        'Plasmid: Cosmid, BAC',
        'Bacterial genomic DNA',
      ];
    }
    setTemplateOptions(options);
    return options;
  };

  useEffect(() => {
    updateTemplateOptions(sample.type);
  }, [sample.type, sample.template]);

  const onDragStart = (event) => {
    event.dataTransfer.setData('dragIndex', String(index));
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event) => {
    event.preventDefault();
    setDraggable(false);

    const movedIndex = Number(event.dataTransfer.getData('dragIndex'));
    moveSample(movedIndex, index);
  };

  return (
    <>
      <tr
        key={sample.id}
        style={rowContainerStyle}
        draggable={draggable}
        onMouseUp={() => setDraggable(false)}
        onDragEnd={() => setDraggable(false)}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <td style={borderRight}>
          <SangerRowWell index={index} />
        </td>
        <td style={{ ...borderLeftRight }}>
          <SangerRowName id={sample.id} data={sample} errorName={errorName} />
        </td>
        <td style={{ ...borderLeftRight, width: 150 }}>
          <SangerRowPrimers
            id={sample.id}
            data={sample}
            errorPrimers={errorPrimers}
            primerMap={primerMap}
            primerColors={primerColors}
          />
        </td>
        <td style={borderLeftRight}>
          <SangerRowPremix sample={sample} />
        </td>
        <td style={{ ...borderLeftRight, width: 120 }}>
          <SangerRowConcentration
            id={sample.id}
            data={sample}
            errorConc={errorConc}
          />
        </td>
        <td style={borderLeftRight}>
          <SangerRowReadLen
            id={sample.id}
            data={sample}
            errorReadLen={errorReadLen}
          />
        </td>
        <td style={borderLeftRight}>
          <SangerRowVol id={sample.id} data={sample} errorVol={errorVol} />
        </td>
        <td style={borderLeftRight}>
          <SangerRowType
            id={sample.id}
            data={sample}
            templateOptions={templateOptions}
            updateTemplateOptions={updateTemplateOptions}
          />
        </td>
        <td style={borderLeft}>
          <SangerRowDesiredTemplate
            id={sample.id}
            data={sample}
            templateOptions={templateOptions}
          />
        </td>
        <td style={{ width: 0, height: '100%', position: 'static' }}>
          <IconButton
            style={{
              ...rowButtonStyle,
              left: 0,
              transform: 'translateY(-50%) translateX(-100%)',
            }}
            onMouseDown={() => setDraggable(true)}
          >
            <DragHandleIcon />
          </IconButton>
          <div style={rowButtonStyle}>
            <IconButton onClick={() => addSampleIndex(index)}>
              <AddIcon />
            </IconButton>
            <IconButton onClick={() => removeSample(sample.id)}>
              <RemoveIcon />
            </IconButton>
          </div>
        </td>
      </tr>
      <SangerRowErrors
        key={`error-${sample.id}`}
        sample={sample}
        index={index}
        showAllErrors={showAllErrors}
        formik={formik}
      />
    </>
  );
};

export default SangerSampleRowComponent;
