import { Button } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import { ControlsApi } from 'utils/api/Controls';
import { ArchiveControlPopup } from './ArchiveControlPopup';
import { EditControlPopup } from './EditControlPopup';

export const ControlsTable = ({ controls, refreshControls }) => {
  const [archiveOpen, setArchiveOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [activeControl, setActiveControl] = useState({});

  const { archiveControl, updateControl } = ControlsApi();

  const confirmArchive = (index) => () => {
    setActiveControl(controls[index]);
    setArchiveOpen(true);
  };

  const doArchiveControl = async () => {
    await archiveControl(activeControl);
    await refreshControls();
  };

  const editControl = (index) => () => {
    setActiveControl(controls[index]);
    setEditOpen(true);
  };

  const submitControl = async (values) => {
    const updatedControl = { ...activeControl, ...values };
    await updateControl(updatedControl);
    await refreshControls();
  };

  const columns = [
    {
      label: 'Name',
      name: 'name',
    },
    {
      label: 'Type',
      name: 'type',
    },
    {
      label: 'Prep Date',
      name: 'prepDate',
    },
    {
      label: 'Template Vol (µL)',
      name: 'templateVol',
    },
    {
      label: 'Primer Vol (µL)',
      name: 'primerVol',
    },
    {
      label: 'Operations',
      name: 'operations',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 8,
              }}
            >
              <Button
                size="small"
                variant="outlined"
                onClick={editControl(dataIndex)}
              >
                Edit
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={confirmArchive(dataIndex)}
              >
                Archive
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'multiselect',
    print: false,
    viewColumns: false,
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },
  };

  return (
    <>
      <MUIDataTable
        title="Controls"
        columns={columns}
        options={options}
        className="ControlsTable"
        data={controls.map((control) => [
          control.name,
          control.type,
          control.prepDate
            ? new Date(control.prepDate).toLocaleDateString('en-us', {
                timeZone: 'UTC',
              })
            : '',
          control.templateVol,
          control.primerVol,
        ])}
      />
      <ArchiveControlPopup
        control={activeControl}
        open={archiveOpen}
        setOpen={setArchiveOpen}
        archive={doArchiveControl}
      />
      <EditControlPopup
        prevControl={activeControl}
        open={editOpen}
        onClose={() => setEditOpen(false)}
        onSubmit={submitControl}
      />
    </>
  );
};
