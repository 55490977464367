import React, { useState, useEffect } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import CheckIcon from '@mui/icons-material/Check';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import ExpandMoreRecent5 from './ExpandMoreRecent5';
import { socket } from 'context/socket';
import { getRecentFiveActions } from 'utils/api/Inventory/Inventory';

async function getInitialData({
  setGetLoad,
  setInitialData,
  setOrderReceived,
  ordersReceived,
}) {
  if (ordersReceived === false) {
    try {
      const dataToBeUsed = await getRecentFiveActions();
      setInitialData(dataToBeUsed);
      setGetLoad(false);
      setOrderReceived(true);
    } catch (error) {
      throw error;
    }
  } else {
    //DO Nothing
  }
}

export default function RecipeReviewCardRecent5(props) {
  const [getLoad, setGetLoad] = useState(true);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const [ordersReceived, setOrderReceived] = useState(false);

  useEffect(() => {
    socket.on('updateOthersProcessingOrder', (msg) => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelfProcessingOrder', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateOthers', (msg) => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelf', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateOtherslog', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelflog', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateAll', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('connect', () => console.log(socket.id));
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => console.log('Server disconnected'));
  }, []);

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setInitialData,
      setOrderReceived,
      ordersReceived,
    }).catch(console.error);
  }, [ordersReceived]);

  const handleClose = () => {
    setSubmitStatus(false);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
        <PropagateLoader />
      </Backdrop>

      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>
      <Card style={{ height: 500 }} sx={{ width: 600 }}>
        <CardContent>
          <Alert variant="filled" severity="info">
            {'Recent 5 Actions'}
          </Alert>
        </CardContent>
        {
          <CardContent>
            <ExpandMoreRecent5 inventoryItems={initialData} />
          </CardContent>
        }
      </Card>
    </Grid>
  );
}
