import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import RotateLoader from 'react-spinners/RotateLoader';
import { unmergeCR } from 'utils/api/ComboRun';

const UnMergeCR = ({ orderCode, onUnmergeCompleted }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleUnmerge = async () => {
    setLoading(true);
    try {
      const res = await unmergeCR(orderCode);
      if (res.status === 200) {
        // You can handle response data or set states as required
        onUnmergeCompleted && onUnmergeCompleted(res.data);
      }
    } catch (error) {
      console.error('Error unmerging:', error);
      // Handle errors as needed
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <div>
      <Button color="primary" variant="contained" onClick={handleOpen}>
        Unmerge
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Unmerge Order</DialogTitle>
        <DialogContent>
          Are you sure you want to unmerge order code {orderCode} from the combo
          run?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleUnmerge} disabled={loading} color="primary">
            {loading ? <RotateLoader size={5} margin={-20} /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UnMergeCR;
