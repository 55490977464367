/**
 * AlertList component that takes a list of alerts and displays them
 * @param {*} alerts a list of alerts to display. Each alert should be an object with a message and a component for which to display the alert
 * @returns
 */
export default function AlertList({ alerts, onClose }) {
  return (
    <div className="d-flex flex-column gap-2">
      {alerts.map((alert, index) => {
        const Alert = alert.component;
        return (
          <Alert
            key={index}
            alertMessage={alert.message}
            onClose={() => onClose(index)}
          />
        );
      })}
    </div>
  );
}
