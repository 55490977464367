import { Tooltip } from '@mui/material';

export const ArchivedTooltipWrapper = ({ archived, children }) => {
  return archived ? (
    <Tooltip title="This control has been archived" arrow>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};
