import axios from 'axios';

export const createLabPrimers = async (values) => {
  try {
    const res = await axios.put('/api/labPrimer/add', values);
    return res;
  } catch (error) {
    console.log('Error creating lab primers:', error);
    throw error;
  }
};

export const getLabPrimers = async () => {
  try {
    var res = await axios({
      url: '/api/labPrimer/all',
      method: 'GET',
    });
    var data = res.data;
    for (var i = 0; i < data.length; i++) {
      data[i]['primerName'] = data[i].primerID + ' ' + data[i].name;
      data[i]['labPrimerId'] = data[i].primerID;
    }
    return data;
  } catch (error) {
    console.log('Error getting lab primers:', error);
    throw error;
  }
};

export const updateLabPrimer = async (values) => {
  try {
    const response = await axios.put('/api/labPrimer/edit', values);
    return response;
  } catch (error) {
    console.log('Error updating lab primer:', error);
    throw error;
  }
};

export const deleteLabPrimer = async (primer) => {
  try {
    const response = await axios.delete('/api/labPrimer/delete', {
      data: primer,
    });
    return response;
  } catch (error) {
    console.log('Error deleting lab primer:', error);
    throw error;
  }
};
