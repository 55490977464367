import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  List,
  ListItem,
  Divider,
  ListItemText,
  TextField,
  Backdrop,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { RotateLoader } from 'react-spinners';
import CheckIcon from '@mui/icons-material/Check';
import { socket } from 'context/socket';
import { createInventory } from 'utils/api/Inventory/Inventory';

const validationSchema = Yup.object({
  name: Yup.string()
    .max(100, "Can't be larger than 100 characters")
    .min(1, 'Must be at least 1 character'),
  totalQuantity: Yup.number()
    .positive('Must be more than 0')
    .integer('Must be an integer'),
  manufacturer: Yup.string()
    .max(20, "Can't be larger than 20 characters")
    .min(1, 'Must be at least 1 character'),
  location: Yup.string()
    .max(200, "Can't be larger than 200 characters")
    .min(1, 'Must be at least 1 character'),
  catologNumber: Yup.string()
    .max(20, "Can't be larger than 20 characters")
    .min(1, 'Must be at least 1 character')
    .required('Required'),
  orderStatus: Yup.string()
    .max(20, "Can't be larger than 20 characters")
    .min(1, 'Must be at least 1 character'),
  ordered: Yup.boolean(),
  triggerAmount: Yup.number()
    .integer('Must be an integer')
    .required('Required'),
  unit: Yup.string()
    .max(20, "Can't be larger than 20 characters")
    .min(1, 'Must be at least 1 character'),
  notes: Yup.string()
    .max(500, "Can't be larger than 500 characters")
    .min(1, 'Must be at least 1 character'),
});

const initialValues = {
  name: '',
  totalQuantity: 0,
  manufacturer: '',
  location: '',
  catologNumber: '',
  orderStatus: '',
  ordered: false,
  triggerAmount: 0,
  unit: '',
  notes: '',
};

const InventoryForm = () => {
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(true);

  const handleSubmit = async (values) => {
    setSubmitLoad(true);
    try {
      const status = await createInventory(values);
      if (status === 201) {
        socket.emit('addInventory', values.catologNumber);
        setSubmitSuccess(true);
        setSubmitResponse('Added successfully!');
      } else if (status === 202) {
        setSubmitSuccess(false);
        setSubmitResponse(
          'Catalog number already exists, please input another one'
        );
      }
    } catch (error) {
      setSubmitSuccess(false);
      setSubmitResponse(
        'Unsuccessful! Please try again later or contact administrator if problem persists'
      );
    }
    setSubmitStatus(true);
    setSubmitLoad(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const renderTextField = (name, label, type = 'text') => (
    <TextField
      id={name}
      name={name}
      label={label}
      type={type}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      InputLabelProps={type === 'number' ? { shrink: true } : undefined}
    />
  );

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={() => setSubmitStatus(false)}
      >
        <Alert
          onClose={() => setSubmitStatus(false)}
          icon={submitSuccess ? <CheckIcon fontSize="inherit" /> : undefined}
          severity={submitSuccess ? 'success' : 'error'}
        >
          {submitResponse}
        </Alert>
      </Backdrop>

      <form onSubmit={formik.handleSubmit}>
        <List
          sx={{
            width: '100%',
            maxWidth: 800,
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 600,
          }}
        >
          {[
            { name: 'catologNumber', label: 'Catalog Number' },
            { name: 'name', label: 'Product Name' },
            { name: 'totalQuantity', label: 'Total Quantity', type: 'number' },
            { name: 'triggerAmount', label: 'Trigger Amount', type: 'number' },
            { name: 'unit', label: 'Unit' },
            { name: 'manufacturer', label: 'Manufacturer' },
            { name: 'location', label: 'Location' },
            { name: 'notes', label: 'Notes' },
          ].map(({ name, label, type }) => (
            <React.Fragment key={name}>
              <ListItem alignItems="flex-start">
                <ListItemText primary={`${label}:`} />
                {renderTextField(name, label, type)}
              </ListItem>
              <Divider component="li" />
            </React.Fragment>
          ))}

          <ListItem alignItems="flex-start">
            <LoadingButton
              size="large"
              type="submit"
              variant="outlined"
              loading={submitLoad}
              loadingIndicator={
                <RotateLoader
                  cssOverride={{ left: '100%' }}
                  size={7}
                  margin={-15}
                />
              }
            >
              Submit
            </LoadingButton>
          </ListItem>
        </List>
      </form>
    </Grid>
  );
};

export default InventoryForm;
