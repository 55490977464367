import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';

export default function SuccessAlert({ alertMessage, onClose }) {
  return (
    <Alert
      icon={<CheckIcon fontSize="inherit" />}
      severity="success"
      onClose={onClose}
    >
      {alertMessage}
    </Alert>
  );
}
