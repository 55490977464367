import React, { useState, useContext, useLayoutEffect, useEffect } from 'react';
import logo from '../../images/logo.png';
import { NavLink, Bars, CloseButton, NavBtn } from './NavBarElements';
import AuthContext from 'utils/auth/auth';
import { Nav as Navs } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './Navbar.css';
import { FaCaretDown } from 'react-icons/fa';

import Dropdown from './Dropdown';
import { AdminMenuItems, HomeMenuItems } from './DropMenuItems';

const options = ['one', 'two', 'three'];
const defaultOption = options[0];
const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const Navbar = () => {
  const [width, height] = useWindowSize();
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [HomeDropDown, setHomeDropDown] = useState(false);

  const onMouseEnter = () => {
    if (width < 960) {
      return false;
    } else {
      return true;
    }
  };

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  if (click) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
  if (click && width > 768) {
    setClick(false);
  }
  return (
    <AuthContext.Consumer>
      {(user) => (
        <nav className="navbar">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src={logo} style={{ height: 75 }} alt="Logo" />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            {click ? <CloseButton /> : <Bars />}
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li
              className="nav-item"
              onMouseEnter={() => setHomeDropDown(onMouseEnter())}
              onMouseLeave={() => setHomeDropDown(false)}
            >
              <NavLink to="/" onClick={closeMobileMenu}>
                Home {width > 768 ? <FaCaretDown /> : <></>}
              </NavLink>
              {HomeDropDown && (
                <Dropdown items={HomeMenuItems} setter={setHomeDropDown} />
              )}
            </li>
            {width < 768 ? (
              <>
                <li className="nav-item">
                  <NavLink to="/aboutUs" onClick={closeMobileMenu}>
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/team" onClick={closeMobileMenu}>
                    Meet the Team
                  </NavLink>
                </li>
              </>
            ) : (
              <></>
            )}
            <li className="nav-item">
              <NavLink to="/Contact-Us" onClick={closeMobileMenu}>
                Contact Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/FAQ" onClick={closeMobileMenu}>
                FAQ
              </NavLink>
            </li>

            {user &&
            user.casUser !== 'Not authenticated' &&
            user.employee === false ? (
              <>
                <li className="nav-item">
                  <NavLink to="/Orders" onClick={closeMobileMenu}>
                    My Orders
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/Upload" onClick={closeMobileMenu}>
                    Upload
                  </NavLink>
                </li>
              </>
            ) : (
              ''
            )}
            {user && user.employee === true ? (
              <>
                <li className="nav-item">
                  <NavLink to="/EmployeeOrders/1" onClick={closeMobileMenu}>
                    Orders
                  </NavLink>
                </li>
              </>
            ) : (
              ''
            )}

            {user && user.employee === true && !user.admin ? (
              <>
                <li className="nav-item">
                  <NavLink to="/inventory" onClick={closeMobileMenu}>
                    Inventory
                  </NavLink>
                </li>
              </>
            ) : (
              ''
            )}

            {user && user.admin === true ? (
              <>
                <li
                  className="nav-item"
                  onMouseEnter={() => setDropdown(onMouseEnter())}
                  onMouseLeave={() => setDropdown(false)}
                >
                  <Link
                    to="/Admin"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    Admin {width > 768 ? <FaCaretDown /> : <></>}
                  </Link>
                  {dropdown && (
                    <Dropdown items={AdminMenuItems} setter={setDropdown} />
                  )}
                </li>
                {width < 768 ? (
                  <>
                    <li className="nav-item">
                      <NavLink to="/billing" onClick={closeMobileMenu}>
                        Billing
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/inventory" onClick={closeMobileMenu}>
                        Inventory
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/executivecommands/0"
                        onClick={closeMobileMenu}
                      >
                        Executive
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/calendar" onClick={closeMobileMenu}>
                        Calendar
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              ''
            )}
            <NavBtn>
              {!user || user.casUser === 'Not authenticated' ? (
                <Navs.Link
                  href={`/cas/authenticate?returnTo=${window.location.pathname}`}
                >
                  Sign In
                </Navs.Link>
              ) : (
                <>
                  <li className="nav-item">
                    <NavLink to="/Profile" onClick={closeMobileMenu}>
                      Hello {user.casUser}
                    </NavLink>
                  </li>
                  <Navs.Link href="/cas/logout">Logout</Navs.Link>
                </>
              )}
            </NavBtn>
          </ul>
        </nav>
      )}
    </AuthContext.Consumer>
  );
};

export default Navbar;
