import { headingStyle } from 'reusable/TableStyles';

export const ReactionTableHeading = () => {
  return (
    <thead>
      <tr>
        <th style={headingStyle}>Well</th>
        <th style={headingStyle}>Sample</th>
        <th style={headingStyle}>Target Conc. (ng)</th>
        <th style={headingStyle}>Conc. (ng/µL)</th>
        <th style={headingStyle}>Volume (µL)</th>
        <th style={headingStyle}>H2O Add</th>
      </tr>
    </thead>
  );
};
