import React from 'react';
import './Message.css';

// for 'announcement' | 'critical' | 'maintanence' respectively
import CampaignIcon from '@mui/icons-material/Campaign';
import WarningIcon from '@mui/icons-material/Warning';
import EngineeringIcon from '@mui/icons-material/Engineering';

const handleClick = (MESSAGE_LINK) => {
  if (MESSAGE_LINK) {
    window.location.href = MESSAGE_LINK;
  }
};

const Message = ({ message }) => {
  const { MESSAGE_TYPE, MESSAGE_TEXT, MESSAGE_LINK } = message;

  const backgroundColor =
    MESSAGE_TYPE === 'announcement'
      ? '#6fbf73'
      : MESSAGE_TYPE === 'critical'
      ? '#ff1744'
      : '#ffa733';

  return (
    // types: announcement - blue, critical - red, maintenance - dark yellow / orange
    <div
      style={{
        backgroundColor: backgroundColor,
      }}
      className={
        MESSAGE_LINK ? 'message-container-clickable' : 'message-container'
      }
      onClick={() => {
        handleClick(MESSAGE_LINK);
      }}
    >
      <div>
        {MESSAGE_TYPE === 'announcement' ? (
          <CampaignIcon style={{ fontSize: 70, paddingRight: 5 }} />
        ) : MESSAGE_TYPE === 'critical' ? (
          <WarningIcon style={{ fontSize: 70, paddingRight: 5 }} />
        ) : (
          <EngineeringIcon style={{ fontSize: 70, paddingRight: 5 }} />
        )}
      </div>
      <p style={{ textAlign: 'center', fontSize: 25 }}>{MESSAGE_TEXT}</p>
    </div>
  );
};

export default Message;
