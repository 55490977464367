import { createSample, deleteSample } from '../utils/api/Samples';
import { getControls as getControlsApi } from '../utils/api/Controls';

export const useControlsApi = (sampleMapId, controls, setSamples) => {
  const getControls = getControlsApi;

  const createControl = async (index) => {
    setSamples((samples) => {
      const control = controls[index];
      const newSamples = [...samples];
      const filledWells = new Set(samples.map((sample) => sample.well));
      const controlSample = {
        type: 'control',
        name: control.name,
        controlPrimerName: '',
        sampleMap: sampleMapId,
        concentration: 0,
        readLength: 0,
        volume: 10,
        controlId: control._id,
      };
      for (let i = 0; i < 96; i++) {
        if (!filledWells.has(i)) {
          controlSample.well = i;
          newSamples.push(controlSample);
          break;
        }
      }
      createSample(controlSample);
      return newSamples;
    });
  };

  const deleteControl = async (controlId) => {
    setSamples((samples) => {
      // remove samples from cloud
      samples
        .filter((sample) => sample.controlId && sample.controlId === controlId)
        .forEach(async (sample) => await deleteSample(sample._id));
      // remvoe samples from samples
      return samples.filter(
        (sample) => !sample.controlId || sample.controlId !== controlId
      );
    });
  };

  return {
    getControls,
    createControl,
    deleteControl,
  };
};

export default useControlsApi;
