export const ReactionTableWater = ({ target, conc, width }) => {
  const targetVol = (target / conc).toFixed(2);
  const vol = (() => {
    let add = 0;
    if (targetVol < 1) add = 1 - targetVol;
    return add.toFixed(2);
  })();

  return (
    <div
      style={{
        width: width ? width : '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {vol}
    </div>
  );
};
