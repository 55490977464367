import React from 'react';
import { Grid, Typography } from '@mui/material';

function LetterQuadrant(props) {
  const element = props.element;

  return (
    <div>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <div style={{ cursor: 'pointer' }}>
            <h1>{element.letter}</h1>
          </div>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center">
          {element.capillaries.map((status, index) => (
            <div key={index}>
              <div
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  margin: '4px 10px 4px 10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor:
                    status === 'good'
                      ? 'green'
                      : status === 'faulty'
                      ? 'yellow'
                      : 'red',
                  cursor: 'pointer',
                  position: 'relative',
                }}
              >
                {' '}
                {index + 1}
              </div>
            </div>
          ))}
        </Grid>
        <Grid item>
          <Typography variant="body1">ID: {element.cartridgeID}</Typography>
        </Grid>
        <br></br>
      </Grid>
    </div>
  );
}

export default LetterQuadrant;
