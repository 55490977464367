import {
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import MUIDataTable from 'mui-datatables';
import MUICustomFilter from 'reusable/MUICustomFilter';
import { socket } from 'context/socket';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { getLogs } from 'utils/api/Inventory/Log/LogPopup';

function filterLogic(catolog, filters) {
  var check = catolog;
  var filteredCato = filters[0];
  if (filteredCato) {
    let result = false;
    filters.forEach((filter) => {
      result = result || filter === check;
    });
    return !result;
  } else {
    return false;
  }
}
async function getInitialData({
  setGetLoad,
  setInitialData,
  setGroupByCatolog,
  inventoryidthis,
  setOrderReceived,
  setGroupByOrderID,
  setGroupByUser,
  ordersReceived,
  whichLog,
}) {
  if (ordersReceived === false) {
    try {
      const smallUrl =
        whichLog === 'the Whole Inventory'
          ? '/Inventory/inventoryLog/all'
          : '/Inventory/inventoryLog';
      const res = await getLogs(
        { params: { inventoryItem: inventoryidthis } },
        smallUrl
      );
      setInitialData(res.dataToBeUsed);
      setGroupByCatolog(res.groupByCatolog);
      setGroupByOrderID(res.groupByOrderID);
      setGroupByUser(res.groupByUser);
      setGetLoad(false);
      setOrderReceived(true);
    } catch (error) {
      console.log('LogPopup.jsx: getInitialData() error');
      throw error;
    }
  }
}

export default function LogPopup(props) {
  const [isChecked, setIsChecked] = useState(false);
  const [getLoad, setGetLoad] = useState(true);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const [groupByCatolog, setGroupByCatolog] = useState([]);
  const [groupByOrderID, setGroupByOrderID] = useState([]);
  const [groupByUser, setGroupByUser] = useState([]);
  const [ordersReceived, setOrderReceived] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let displayCatologNumber = props.whichLog === 'the Whole Inventory';
  let whichLog = props.whichLog;
  let inventoryidthis;
  if (!displayCatologNumber) {
    inventoryidthis = props.products._id;
  }

  const handleCloseSubmit = () => {
    setSubmitStatus(false);
  };

  useEffect(() => {
    socket.on('updateOtherslog', (msg) => {
      setOrderReceived(false);
      enqueueSnackbar(msg, { variant: 'info' });
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelflog', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateOthers', (msg) => {
      setOrderReceived(false);
      enqueueSnackbar(msg, { variant: 'info' });
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelf', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateAll', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateOthersProcessingOrder', (msg) => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelfProcessingOrder', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('connect', () => console.log(socket.id));
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => console.log('Server disconnected'));
  }, []);

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setInitialData,
      setGroupByCatolog,
      inventoryidthis,
      setOrderReceived,
      setGroupByOrderID,
      setGroupByUser,
      ordersReceived,
      whichLog,
    }).catch(console.error);
  }, [ordersReceived]);
  const columns = [
    {
      label: 'Time',
      name: 'time',
      options: {
        filter: true,
        display: 'excluded',
        sortDirection: 'desc',

        customBodyRender: (value) => {
          return value.toLocaleString();
        },

        filterType: 'custom',
        customFilterListRender: (v) => {
          if (v[0] && v[1] && isChecked) {
            return [`Start Date: ${v[0]}`, `End Date: ${v[1]}`];
          } else if (v[0] && v[1] && !isChecked) {
            return `Start Date: ${v[0]}, End Date: ${v[1]}`;
          } else if (v[0]) {
            return `After this date: ${v[0]}`;
          } else if (v[1]) {
            return `Before this date: ${v[1]}`;
          }
          return false;
        },
        filterOptions: {
          names: [],

          logic(date, filters) {
            var check = new Date(date);
            var from = new Date(filters[0]);
            var to = new Date(filters[1]);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            if (filters[0] && filters[1]) {
              return check <= from || check >= to;
            } else if (filters[0]) {
              return check <= from;
            } else if (filters[1]) {
              return check >= to;
            }
            return false;
          },

          display: (filterList, onChange, index, column) => {
            return (
              <div>
                <FormLabel>Time</FormLabel>
                <br></br>
                <FormGroup row>
                  <TextField
                    id="startDate"
                    label="Start Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][0] || ''}
                    onChange={(event) => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '60%', marginRight: '5%' }}
                  />
                  <TextField
                    id="endDate"
                    label="End Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][1] || ''}
                    onChange={(event) => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '60%', marginRight: '5%' }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={(event) => setIsChecked(event.target.checked)}
                      />
                    }
                    label="Separate Values"
                    style={{ marginLeft: '0px' }}
                  />
                </FormGroup>
              </div>
            );
          },
        },
        print: false,
      },
    },
    {
      name: 'user',
      label: 'User',
      options: {
        filter: false,
        display: 'excluded',
        sort: true,
      },
    },

    {
      name: 'userWholeNameCombined',
      label: 'User',

      options: {
        filter: true,
        display: 'excluded',
        sort: true,

        filterType: 'custom',

        filterOptions: {
          names: [],

          logic(catolog, filters) {
            return filterLogic(catolog, filters);
          },

          display: (filterList, onChange, index, column) => {
            return (
              <MUICustomFilter
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                groupByCatolog={groupByUser}
                labelName="User"
                deleteCategory="Deleted User"
              />
            );
          },
        },
      },
    },
    {
      name: 'deletedUserWholeName',
      label: 'Deleted User',
      options: {
        filter: false,
        display: 'excluded',
        sort: true,
      },
    },
    {
      name: 'inventoryItem',
      label: 'Catolog Number(Inventory Item)',

      options: {
        filter: false,
        display: 'excluded',
        sort: true,
      },
    },

    {
      name: 'inventoryItemCombined',
      label: 'Catolog Number(Inventory Item)',

      options: {
        filter: displayCatologNumber,
        display: 'excluded',
        sort: true,

        filterType: 'custom',

        filterOptions: {
          names: [],

          logic(catolog, filters) {
            return filterLogic(catolog, filters);
          },

          display: (filterList, onChange, index, column) => {
            return (
              <MUICustomFilter
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                groupByCatolog={groupByCatolog}
                labelName="Catolog Number(Inventory Item)"
                deleteCategory="Deleted Inventory Item"
              />
            );
          },
        },
      },
    },

    {
      name: 'deletedCatolog',
      label: 'Deleted Catolog Number(Inventory Item)',
      options: {
        filter: false,
        display: 'excluded',
        sort: true,
      },
    },

    {
      name: 'processingOrder',
      label: 'Order ID(Processing Order)',
      options: {
        filter: false,
        display: 'excluded',
        sort: true,
      },
    },

    {
      name: 'orderIDCombined',
      label: 'Order ID(Processing Order)',

      options: {
        filter: true,
        display: 'excluded',
        sort: true,

        filterType: 'custom',

        filterOptions: {
          names: [],

          logic(catolog, filters) {
            return filterLogic(catolog, filters);
          },

          display: (filterList, onChange, index, column) => {
            return (
              <MUICustomFilter
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                groupByCatolog={groupByOrderID}
                labelName="Order ID(Processing Order)"
                deleteCategory="Deleted Order ID"
              />
            );
          },
        },
      },
    },

    {
      name: 'deletedOrderID',
      label: 'Deleted Order ID(Processing Order)',
      options: {
        filter: false,
        display: 'excluded',
        sort: true,
      },
    },

    {
      name: 'operation',
      label: 'Operation',
      options: {
        filter: true,
        display: 'excluded',
        sort: false,
      },
    },
    {
      name: 'specificOperation',
      label: 'Specific Operation',
      options: {
        filter: false,
        display: 'excluded',
        sort: false,
      },
    },

    {
      name: 'field',
      label: 'Field',
      options: {
        filter: false,
        display: 'excluded',
        sort: true,
      },
    },
    {
      name: 'before',
      label: 'Before',
      options: {
        filter: false,
        display: 'excluded',
        sort: false,
      },
    },

    {
      name: 'after',
      label: 'After',
      options: {
        filter: false,
        display: 'excluded',
        sort: false,
      },
    },

    {
      name: 'message',
      label: 'Records',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  const options = {
    filter: true,
    filterType: 'multiselect',
    print: false,
    viewColumns: false,
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
          <PropagateLoader />
        </Backdrop>

        <Backdrop
          sx={{ color: '#fff', zIndex: 1 }}
          open={submitStatus}
          onClick={handleCloseSubmit}
        >
          {submitSucess ? (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              {submitResponse}
            </Alert>
          ) : (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              severity="error"
            >
              {submitResponse}
            </Alert>
          )}
        </Backdrop>
      </Grid>

      <MUIDataTable
        title={'Log for ' + props.whichLog}
        data={initialData}
        columns={columns}
        options={options}
      />
    </div>
  );
}
