import { getOrders } from 'utils/api/ClientOrders';

export const getInitialData = async ({
  setGetLoad,
  setUploadedOrders,
  setOrderReceived,
  ordersReceived,
  user,
}) => {
  if (ordersReceived === false) {
    try {
      const res = await getOrders(user);
      console.log(res);
      let dataToBeUsed = [];
      res.data.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (el === '__v') {
          } else if (el === 'dateUploaded') {
            const timeRemaining =
              Date.now() +
              (86400000 - (Date.now() - new Date(element[el]).getTime()));
            oneRow = { ...oneRow, [el]: element[el] };
            console.log('timeRemaining: ' + timeRemaining);
          } else {
            console.log('key: ' + el + ': inital Data inside: ' + element[el]);
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        dataToBeUsed = [...dataToBeUsed, oneRow];
      });

      setUploadedOrders(dataToBeUsed);
      setGetLoad(false);
      setOrderReceived(true);
    } catch (error) {
      console.log(error);
    }
  }
};
