export const rowContainerStyle = {
  border: '1px solid #0030579E',
  borderRadius: 4,
  backgroundColor: '#FFFFFF',
  height: 55,
  paddingRight: 7,
  paddingLeft: 10,
  marginTop: 0,
  paddingTop: 0,
  marginBottom: 0,
};

export const borderLeft = {
  height: '100%',
  position: 'relative',
  borderLeft: '1px solid #0030572E',
};

export const borderRight = {
  height: '100%',
  position: 'relative',
  borderRight: '1px solid #0030572E',
};

export const borderLeftRight = { ...borderLeft, ...borderRight };

export const headingStyle = {
  color: '#003057',
  padding: '0 6px',
  borderLeft: '1px solid #0030572E',
  borderRight: '1px solid #0030572E',
};

export const rowButtonStyle = {
  position: 'absolute',
  display: 'flex',
  transform: 'translateY(-50%)',
};

export const emptyTableStyle = {
  color: '#003057',
  backgroundColor: '#F2F2F2',
  border: '1px solid #0030579E',
  padding: 5,
};
