import { TextField } from '@mui/material';

export const ReactionTableWell = ({ well }) => {
  const rowLengthX = Math.floor(well / 8);
  const rowLengthY = well % 8;
  var startX = rowLengthX + 1;
  let sX = startX.toString();
  let sY = String.fromCharCode(rowLengthY + 65);
  const wellStr = sY + sX;

  return (
    <TextField
      variant="outlined"
      disabled={true}
      value={wellStr}
      sx={{
        width: 50,
        '& .MuiInputBase-root': { height: 40, borderRadius: 0 },
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
        '& .MuiInputBase-input': {
          textAlign: 'center',
        },
      }}
    />
  );
};
