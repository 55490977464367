import {
  ColorLuminance,
  deterministicallyRearrange,
  stringToColour,
} from 'reusable/Color';
import { IconButton } from 'reusable/UI/IconButton';
import {
  rowContainerStyle,
  borderRight,
  borderLeftRight,
  borderLeft,
  rowButtonStyle,
} from 'reusable/TableStyles';
import { PrimerTableColor } from './PrimerTableColor';
import { PrimerTableName } from './PrimerTableName';
import { PrimerTableVolume } from './PrimerTableVolume';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useDraggable } from 'hooks/useDraggable';

export const PrimerTableRow = ({ primer, movePrimer, index, tableWidth }) => {
  const { setDraggable, draggableProps } = useDraggable(movePrimer, index);

  const primerColor = (() => {
    return ColorLuminance(
      stringToColour(deterministicallyRearrange(primer._id)),
      0.5
    );
  })();

  const rowWidth = tableWidth - 135;

  return (
    <tr style={rowContainerStyle} {...draggableProps}>
      <td style={{ ...borderRight, width: 55 }}>
        <PrimerTableColor color={primerColor} />
      </td>
      <td style={{ ...borderLeftRight }}>
        <PrimerTableName name={primer.name} width={rowWidth * 0.5} />
      </td>
      <td style={{ ...borderLeft }}>
        <PrimerTableVolume
          vol={primer.volume}
          width={rowWidth * 0.5}
          isLabPrimer={primer.number}
        />
      </td>
      <td style={{ width: 0, height: '100%', position: 'static' }}>
        <IconButton
          style={{
            ...rowButtonStyle,
            left: 0,
            transform: 'translateY(-50%) translateX(-100%)',
          }}
          onMouseDown={() => setDraggable(true)}
        >
          <DragHandleIcon />
        </IconButton>
      </td>
    </tr>
  );
};
