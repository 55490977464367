import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { PopupNullOrder } from './PopupImports';
import PopupContent from './PopupContent';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import { socket } from '../../context/socket';
import { getOrder } from 'utils/api/Orders';
import { getOrderinCR } from 'utils/api/ComboRun';
import { getSampleMap } from 'utils/api/SampleMap';

const style = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  p: 4,
};

const loadingStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export const OrderPopup = ({
  currentOrder,
  handleClose,
  viewOnly = false,
  workflow = false,
  modalOpen,
}) => {
  const [order, setOrder] = useState(PopupNullOrder);
  const [sampleMap, setSampleMap] = useState(null);
  const [ordersInCR, setOrdersInCR] = useState([]);
  const [orderReceived, setOrderReceived] = useState(false);
  const [loading, setLoading] = useState(true);
  const orderCode = currentOrder.orderCode;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getOrder({ orderCode: orderCode }).then((data) => {
      setLoading(false);
      setOrder(data);
      setOrderReceived(true);
      getSampleMap(data.sampleMap).then((sampleMap) => {
        setSampleMap(sampleMap);
      });
    });
  }, [orderReceived]);

  async function updateOrderinCR() {
    if (order.isComboRun) {
      const res = await getOrderinCR(order.orderCode);
      if (res.status == 200) {
        setOrdersInCR(res.data);
      }
    }
  }

  useEffect(() => {
    updateOrderinCR();
  }, [order, orderReceived]);

  useEffect(() => {
    socket.on('updateOrder', (updatedOrderCode) => {
      if (updatedOrderCode === orderCode) {
        setOrderReceived(false);
        enqueueSnackbar(
          'Another person has updated ' + orderCode + '. Changes loaded',
          { variant: 'info' }
        );
      }
    });
  }, []);
  useEffect(() => {
    socket.on('saveCR', (updatedCR) => {
      if (updatedCR === currentOrder.comboRunNo) {
        setOrderReceived(false);
        enqueueSnackbar(
          'Another person has updated CR' +
            currentOrder.comboRunNo +
            '. Changes loaded',
          { variant: 'info' }
        );
      }
    });
  }, []);

  useEffect(() => {
    socket.on('updateSaveCR', (updatedCR) => {
      if (updatedCR === currentOrder.comboRunNo) {
        setOrderReceived(false);
      }
    });
  }, []);

  useEffect(() => {
    socket.once('orderSubmitted', (updatedOrderCode) => {
      if (viewOnly) {
        setOrderReceived(false);
        enqueueSnackbar(
          'Another person has updated CR' +
            currentOrder.comboRunNo +
            '. Changes loaded',
          { variant: 'info' }
        );
      } else if (updatedOrderCode === orderCode && modalOpen === true) {
        handleClose();
        enqueueSnackbar(orderCode + ' has been moved', { variant: 'info' });
      }
    });
  }, []);

  useEffect(() => {
    socket.once('submitCR', (comboRunNo) => {
      if (viewOnly) {
        setOrderReceived(false);
        enqueueSnackbar(
          'Another person has updated CR' +
            currentOrder.comboRunNo +
            '. Changes loaded',
          { variant: 'info' }
        );
      } else if (comboRunNo === currentOrder.comboRunNo && modalOpen === true) {
        handleClose();
        enqueueSnackbar(orderCode + ' has been moved', { variant: 'info' });
      }
    });
  }, []);

  if (loading) {
    return (
      <Box sx={loadingStyle}>
        <PropagateLoader cssOverride={{ margin: '0' }} />
      </Box>
    );
  } else if (order.stage !== '') {
    return (
      <Grid
        container
        rowSpacing={0}
        spacing={0}
        style={{
          maxWidth: '85%',
          minHeight: '60vh',
          margin: 'auto',
          width: '580px',
          maxHeight: '680px',
        }}
        sx={style}
      >
        <PopupContent
          order={order}
          sampleMap={sampleMap}
          ordersInCR={ordersInCR}
          handleClose={handleClose}
          viewOnly={viewOnly}
          setOrderReceived={setOrderReceived}
          workflow={workflow}
        />
      </Grid>
    );
  } else {
    return <></>;
  }
};
