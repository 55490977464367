import {
  FormGroup,
  FormLabel,
  FormControl,
  ListItemText,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';

import MUIDataTable from 'mui-datatables';
import BillingPopup from 'reusable/BillingPopup';
import { socket } from 'context/socket';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { getBillingAll } from 'utils/api/Billing';

async function getInitialData({
  setGetLoad,
  setData,
  setOrderReceived,
  ordersReceived,
}) {
  if (ordersReceived === false) {
    try {
      const res = await getBillingAll();
      let dataToBeUsed = [];
      res.data.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (el == '__v') {
          } else {
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        dataToBeUsed = [...dataToBeUsed, oneRow];
      });

      setData(dataToBeUsed);
      setGetLoad(false);
      setOrderReceived(true);
    } catch (error) {
      console.log(error);
    }
  } else {
    //DO Nothing
  }
}

function BillingTable(props) {
  const [isChecked, setIsChecked] = useState(false);
  const [getLoad, setGetLoad] = useState(true);
  const [data, setData] = useState([]);
  const [ordersReceived, setOrderReceived] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    socket.on('updateOthersbilling', (msg) => {
      //console.log("run");
      setOrderReceived(false);
      enqueueSnackbar(msg, { variant: 'info' });
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelfbilling', () => {
      //console.log("run");
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setData,
      setOrderReceived,
      ordersReceived,
    }).catch(console.error);
  }, [ordersReceived]);

  useEffect(() => {
    socket.on('connect', () => console.log(socket.id));
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => console.log('Server disconnected'));
  }, []);

  const columns = [
    {
      label: 'Order Number',
      name: 'orderCode',
      options: {
        filter: false,
      },
    },
    {
      label: 'SUMS ID',
      name: 'SUMSBilling',
      options: {
        filter: false,
      },
    },
    {
      label: 'SUMS Group',
      name: 'SUMSGroupName',
      options: {
        filter: false,
      },
    },
    {
      label: 'PI',
      name: 'labName',
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `PI: ${v ? v.replace(/^(\w).* (.*)$/, '$1. $2') : ''}`,
        },
        filterType: 'dropdown',
      },
    },
    {
      label: 'Billing Status',
      name: 'billingStatus',
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => `Billing Status: ${v.map((l) => l.toUpperCase())}`,
          update: (filterList, filterPos, index) => {
            console.log('update');
            console.log(filterList, filterPos, index);
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          logic: (billingStatus, filters, row) => {
            if (filters.length) return !filters.includes(billingStatus);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            const optionValues = ['Billed', 'Free'];
            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">
                  Billing Status
                </InputLabel>
                <Select
                  multiple
                  value={filterList[index]}
                  renderValue={(selected) => selected.join(', ')}
                  onChange={(event) => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox
                        color="primary"
                        checked={filterList[index].indexOf(item) > -1}
                      />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            console.log(order);
            let val1;
            let val2;
            if (obj1.data === 'Free') {
              val1 = 3;
            } else if (obj1.data === 'Pending') {
              val1 = 2;
            } else if (obj1.data === 'Billed') {
              val1 = 1;
            }

            if (obj2.data === 'Free') {
              val2 = 3;
            } else if (obj2.data === 'Pending') {
              val2 = 2;
            } else if (obj2.data === 'Billed') {
              val2 = 1;
            }

            //console.log("val1: "+val1)
            //console.log("val2: "+val2)
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        },
      },
    },
    {
      label: 'Billing Cycle',
      name: 'billingCycle',
      options: {
        filter: true,
        display: 'true',
        filterType: 'dropdown',
        customFilterListOptions: {
          render: (v) => `Billing Cycle: ${v}`,
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let ayear = obj1.data.match(/\d+/g)[0];
            console.log('ayear:' + ayear);
            let byear = obj2.data.match(/\d+/g)[0];
            if (byear < ayear) {
              return -1 * (order === 'asc' ? 1 : -1);
            } else if (byear > ayear) {
              return 1 * (order === 'asc' ? 1 : -1);
            } else {
              let aterm = -1;
              if (obj1.data.toLowerCase().includes('fall')) {
                aterm = 3;
              } else if (obj1.data.toLowerCase().includes('summer')) {
                aterm = 2;
              } else if (obj1.data.toLowerCase().includes('spring')) {
                aterm = 1;
              }
              let bterm = -1;
              if (obj2.data.toLowerCase().includes('fall')) {
                bterm = 3;
              } else if (obj2.data.toLowerCase().includes('summer')) {
                bterm = 2;
              } else if (obj2.data.toLowerCase().includes('spring')) {
                bterm = 1;
              }
              if (bterm < aterm) {
                return -1 * (order === 'asc' ? 1 : -1);
              }
              if (bterm > aterm) {
                return 1 * (order === 'asc' ? 1 : -1);
              }
              return 0;
            }
          };
        },
      },
    },
    // the next column will be based off of a formula (so noOfSamples multiplied by some number)
    {
      label: 'Price',
      name: 'price',
      options: {
        filter: false,
      },
    },
    {
      label: 'Date of Submission',
      name: 'date',
      options: {
        filter: true,
        display: 'excluded',
        sortDirection: 'desc',
        customBodyRender: (value) => {
          return new Date(value).toDateString();
        },
        filterType: 'custom',
        customFilterListRender: (v) => {
          if (v[0] && v[1] && isChecked) {
            return [`Start Date: ${v[0]}`, `End Date: ${v[1]}`];
          } else if (v[0] && v[1] && !isChecked) {
            return `Start Date: ${v[0]}, End Date: ${v[1]}`;
          } else if (v[0]) {
            return `After this date: ${v[0]}`;
          } else if (v[1]) {
            return `Before this date: ${v[1]}`;
          }
          return false;
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            var check = new Date(date);
            var from = new Date(filters[0]);
            var to = new Date(filters[1]);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            if (filters[0] && filters[1]) {
              return check <= from || check >= to;
            } else if (filters[0]) {
              return check <= from;
            } else if (filters[1]) {
              return check >= to;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Date of Submission</FormLabel>
              <br></br>
              <FormGroup row>
                <TextField
                  id="startDate"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][0] || ''}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '60%', marginRight: '5%' }}
                />
                <TextField
                  id="endDate"
                  label="End Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][1] || ''}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '60%', marginRight: '5%' }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={(event) => setIsChecked(event.target.checked)}
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: '0px' }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      label: 'Active',
      name: 'active',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    // each row must be able to expand into a popup that contains all the order information
    // I achieved this through making a separate column next to the order number that only contains buttons for expanding that row
    {
      label: '',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          return (
            <IconButton>
              <BillingPopup currentOrder={data[value]} />
            </IconButton>
          );
        },
      },
    },
  ];

  // const data = [
  //   // dummy data: [orderCode, SUMSBilling, labName, billingStatus, billingCycle, price, dateDroppedOff, active]
  //   ['ABC1','XYZ0123', 'Styczynski', 'Billed', 'Summer 2022', 4.40, '07/17/2022', true],
  //   ['BCD_03', 'SJA2419', 'Glass', 'Billed', 'Spring 2024', 17.75, '04/23/2024', true],
  //   ['ABC4', 'DIG4890', 'Styczynski', 'Billed', 'Fall 2022', 5.50, '10/12/2022', true],
  //   ['CCC6', 'QOI1298', 'Christine', 'Billed', 'Fall 2022', 13.50, '11/01/2022', true],
  //   ['CCC6', 'QOI1298', 'Christine', 'Billed', 'Fall 2023', 13.50, '11/01/2023', true],
  //   ['CCC6', 'QOI1298', 'Christine', 'Billed', 'Fall 2024', 13.50, '11/01/2024', true],
  //   ['NNN7', 'LKS1289', 'Nancy', 'Free', 'Spring 2023', 0, '03/21/2023', true],
  //   ['NNN7', 'LKS1289', 'Nancy', 'Free', 'Spring 2022', 0, '03/21/2022', true],
  //   ['BCD_08', 'KHJ2379', 'Glass', 'Free', 'Fall 2023', 0, '09/02/2023', true],
  //   ['CCC9', 'QOI2910', 'Christine', 'Billed', 'Summer 2021', 80.00, '06/30/2021', true],
  //   ['BCD_10','KJH3913', 'Glass', 'Billed', 'Fall 2021', 30.50, '11/12/2021', true],
  //   ['NNN11', 'CXZ2098', 'Nancy', 'Billed', 'Fall 2021', 90.00, '10/29/2021', true]
  // ]

  // dummy data but as an array of objects
  /** 
  const data = [
    {orderCode: 'ABC1', SUMSBilling: 'XYZ0123', labName: 'Styczynski', billingStatus: 'Billed', billingCycle: 'Summer 2022', price: 12.00, dateDroppedOff: '07/17/2022', active: true},
    {orderCode: 'BCD_03', SUMSBilling: 'SJA2419', labName: 'Glass', billingStatus:'Billed', billingCycle: 'Spring 2024', price: 8.00, dateDroppedOff: '04/23/2024', active: true },
    {orderCode: 'ABC4', SUMSBilling: 'DIG4890', labName: 'Styczynski', billingStatus: 'Billed', billingCycle: 'Fall 2022', price: 90.00, dateDroppedOff: '11/01/2022', active: true},
    {orderCode: 'NNN7', SUMSBilling: 'LKS1289', labName: 'Nancy', billingStatus: 'Free', billingCycle: 'Spring 2022', price: 10.00, dateDroppedOff: '03/21/2022', active: true},
    {orderCode: 'BCD_08', SUMSBilling: 'KHJ2379', labName: 'Glass', billingStatus: 'Free', billingCycle: 'Spring 2022', price: 100.00, dateDroppedOff: '03/29/2022', active: true}
  ];
*/
  const options = {
    filter: true,
    filterType: 'multiselect',
    print: false,
    viewColumns: false,
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
          <PropagateLoader />
        </Backdrop>
      </Grid>
      <MUIDataTable
        title={'All Orders'}
        data={data.map((item) => {
          return [
            item.orderCode,
            item.SUMSBilling,
            item.SUMSGroupName,
            item.labName,
            item.billingStatus,
            item.billingCycle,
            item.price,
            item.dateDroppedOff,
            item.active,
          ];
        })}
        columns={columns}
        options={options}
      />
    </div>
  );
}

export default BillingTable;
