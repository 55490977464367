import { useDraggable } from 'hooks/useDraggable';
import { IconButton } from 'reusable/UI/IconButton';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  borderLeft,
  borderLeftRight,
  borderRight,
  rowButtonStyle,
  rowContainerStyle,
} from 'reusable/TableStyles';
import { ArchivedTooltipWrapper } from './ArchivedTooltipWrapper';

export const ControlTableRow = ({
  control,
  index,
  deleteControl,
  moveControl,
  tableWidth,
}) => {
  const { setDraggable, draggableProps } = useDraggable(moveControl, index);
  const rowWidth = tableWidth - 80;

  /**
   * Capitalize the type of primer
   */
  const capitalize = (str) => {
    return str[0].toUpperCase() + str.slice(1);
  };

  const date = new Date(control.prepDate);
  const rowStyle = {
    ...rowContainerStyle,
    textDecoration: control.archived ? 'line-through' : 'none',
    opacity: control.archived ? 0.6 : 1,
  };

  return (
    <ArchivedTooltipWrapper archived={control.archived}>
      <tr style={rowStyle} {...draggableProps}>
        <td style={{ ...borderRight, padding: 5, width: rowWidth * 0.3 }}>
          {control.name}
        </td>
        <td style={{ ...borderLeftRight, padding: 5, width: rowWidth * 0.2 }}>
          {capitalize(control.type)}
        </td>
        <td style={{ ...borderLeftRight, padding: 5, width: rowWidth * 0.2 }}>
          {control.prepDate ? date.toLocaleDateString() : '--'}
        </td>
        <td style={{ ...borderLeftRight, padding: 5, width: rowWidth * 0.15 }}>
          {control.templateVol}
        </td>
        <td style={{ ...borderLeft, padding: 5, width: rowWidth * 0.15 }}>
          {control.primerVol ? control.primerVol : '--'}
        </td>
        <td style={{ width: 0, height: '100%', position: 'static' }}>
          <IconButton
            style={{
              ...rowButtonStyle,
              left: 0,
              transform: 'translateY(-50%) translateX(-100%)',
            }}
            onMouseDown={() => setDraggable(true)}
          >
            <DragHandleIcon />
          </IconButton>
          <div style={rowButtonStyle}>
            <IconButton onClick={deleteControl}>
              <RemoveIcon />
            </IconButton>
          </div>
        </td>
      </tr>
    </ArchivedTooltipWrapper>
  );
};
