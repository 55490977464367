import React, { useState, useContext } from 'react';
import './DropOff.css';
import logo from 'images/logo.png';
import axios from 'axios';
import VerificationInput from 'react-verification-input';
import PropagateLoader from 'react-spinners/PropagateLoader';
import AuthContext from 'utils/auth/auth';
import { socket } from 'context/socket';
const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

function ErorrPopup(props) {
  return <h1 className="error"> {props.error} </h1>;
}

function Popup(props) {
  return (
    <div className="popup_outter">
      <div className="popup_inner">
        <h3 style={{ textAlign: 'center' }}>Successful!</h3>
        <h1 style={{ textAlign: 'center', fontSize: 25 }}>
          Your sample has been dropped off at:
        </h1>
        <p className="locationCode">{props.code}</p>
        <h1 style={{ textAlign: 'center', fontSize: 20 }}>
          Your order should be updated, please let us know if any issues occur.
          Refresh to drop off another order.
        </h1>
      </div>
    </div>
  );
}

const DropOff = (props) => {
  const user = useContext(AuthContext);
  const [value, setValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [pathname, setPathname] = useState(window.location.pathname);
  const [location, setLocation] = useState(props.location);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPopUp, setShowPopUp] = useState(false);
  const [loading, setLoading] = useState(false);

  window.onpopstate = () => {
    setPathname(window.location.pathname);
  };

  const handleChange = (input) => {
    setIsInvalid(false);
    setValue(input.toUpperCase());
    if (input.length === 7) {
      handleSubmit(input.toUpperCase());
    }
  };

  const clearData = () => {
    setValue('');
  };

  function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }

  const handleSubmit = async (inputtedCode) => {
    setLoading(true);
    await sleep(1e3);
    if (inputtedCode === '') {
      setShowPopUp(false);
      setIsInvalid(true);
      setErrorMessage('Please enter a code');
    } else {
      axios
        .get(API_ENDPOINT + '/clientOrders/uploadedOrders', {
          params: { username: user.casUser },
        })
        .then((res) => {
          if (res.status === 200) {
            //console.log("res.data: " + res.data);
            axios({
              method: 'put',
              url: API_ENDPOINT + '/drop_off',
              data: { orderCode: inputtedCode, location: location },
            })
              .then((res) => {
                if (res.status === 200) {
                  setShowPopUp(true);
                  setIsInvalid(false);
                }
                return res.data;
              })
              .catch(function (error) {
                setShowPopUp(false);
                setIsInvalid(true);
                // message if file type is not supported
                if (error.response.status === 409) {
                  setErrorMessage('Code has already been entered');
                } else if (error.response.status === 400) {
                  setErrorMessage(
                    'Code does not exist, please enter a valid code'
                  );
                } else {
                  setErrorMessage(
                    'Unknown error ocurred, please contact administor'
                  );
                  console.log(error);
                }
              });
            socket.emit('updateUploadedOrders');
          }
        });
    }
    clearData();
    setLoading(false);
  };
  return (
    <div
      className="pages"
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <img src={logo} width="350" height="105" alt="logo" />
      {showPopUp ? (
        <Popup code={location} />
      ) : (
        <>
          <p className="center_text" style={{ paddingTop: '2em' }}>
            Your current location is:
          </p>
          <p className="bold_center_text">{props.location}</p>
          <br></br>
          <p className="center_text" style={{ paddingBottom: '0.5em' }}>
            Enter your drop off code.
          </p>
          <div className="dropOffInputDiv">
            <VerificationInput
              // removeDefaultStyles
              classNames={{
                character: 'dropOffInput',
                characterSelected: 'character--selected',
              }}
              autoFocus={true}
              length={7}
              placeholder={''}
              value={value}
              onChange={handleChange}
              inputProps={{
                disabled: loading,
              }}
            />
          </div>
          <div className="dropOffLoader">
            {loading ? <PropagateLoader /> : null}
          </div>
        </>
      )}
      {isInvalid ? (
        <div className="center_text">
          <ErorrPopup error={errorMessage} />
        </div>
      ) : null}
      <div className="center_text">
        <p className="contactDrop">contact us: sanger@ibb.gatech.edu</p>
      </div>
    </div>
  );
};

export default DropOff;
