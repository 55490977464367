import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { memo, useContext } from 'react';
import { ErrorVisContext } from '../../../contexts/DataContext';
import { SampleMethodContext } from '../../../contexts/MethodContext';

const SangerRowType = memo(
  ({ id, data, templateOptions, updateTemplateOptions }) => {
    const { updateSample } = useContext(SampleMethodContext);
    const { submitShowError } = useContext(ErrorVisContext);
    const typeOptions = ['DNA', 'Plasmid-insert', 'Plasmid'];

    const handleChange = (newData, id) => {
      if (newData.type != null) {
        if (
          newData.type === 'Plasmid' &&
          (data.type === 'DNA' || data.type === 'Plasmid-insert')
        ) {
          newData.template = '';
        }
        if (
          (newData.type === 'DNA' || newData.type === 'Plasmid-insert') &&
          data.type === 'Plasmid'
        ) {
          newData.template = '';
        }
      }
      // const tempData = { ...data, ...newData };
      console.log(newData);
      updateSample(newData, id);
    };

    return (
      <Autocomplete
        disablePortal
        disableClearable={true}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        id="type"
        variant="outlined"
        options={typeOptions}
        value={data.type}
        onBlur={(e) => {
          var newTouched = [...data.touched];
          newTouched[5] = true;
          handleChange({ touched: newTouched }, id);
        }}
        onChange={async (e, currData) => {
          var newTouched = [...data.touched];
          newTouched[5] = true;
          handleChange({ type: currData, touched: newTouched }, id);
          updateTemplateOptions(currData, templateOptions);
        }}
        sx={{
          width: '100%',
          '& .MuiFormControl-root': {
            border: 'none',
          },
          '& .MuiOutlinedInput-root': {
            '& .MuiInputBase-input': {
              width: '100%',
            },
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: 'none',
            },
            '&.Mui-focused fieldset': {
              border: 'none',
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Type"
            inputProps={{
              ...params.inputProps,
              readOnly: true,
            }}
            error={
              (data.touched[5] || submitShowError) &&
              (data.type === undefined || data.type === '')
            }
          />
        )}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id &&
      prevProps.data.type === nextProps.data.type
    );
  }
);

export default SangerRowType;
