import { headingStyle } from 'reusable/TableStyles';

export const MasterMixTableHeading = () => {
  return (
    <thead>
      <tr>
        <th style={{ ...headingStyle }}>Name</th>
        <th style={{ ...headingStyle }}># of Reactions</th>
        <th style={{ ...headingStyle }}>Volume (µL)</th>
      </tr>
    </thead>
  );
};
