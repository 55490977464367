import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

export const useLogin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // Initial data loading
    axios
      .get(API_ENDPOINT + '/user/new')
      .then((res) => {
        if (res.status === 200) {
          //Do nothing
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 302) {
          // Need to add information to user
          navigate('/profile', { replace: true });
        }
      });
  }, []);
};
