import { FaBars, FaTimes } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const NavLink = styled(Link)`
color: #000;
  text-decoration: none;
  padding: 1.4rem;
  margin-right: 2px;
  margin-left: 2px;
  height: 100%;
  transition: all 0.2s ease-in-out;
  &.active {
    color: #B3A369;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: #003057;
    color: #fff;
    border-radius: 4px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    color:#fff;
    &:hover {
      background-color: #1888ff;
      border-radius: 0;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #000;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const CloseButton = styled(FaTimes)`
  display: none;
  color: #000;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  justify-content: flex-end;
  flex: 1 200px;
  font-size: 1.2em;
`;
