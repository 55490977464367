import { createTheme, ThemeProvider } from '@mui/material';
import MuiTypography from '@mui/material/Typography';

const typographyTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#003057',
        },
        h1: {
          padding: 10,
          fontSize: 80,
          fontWeight: 900,
          textAlign: 'center',
        },
        h2: {
          padding: 5,
          fontSize: 40,
          fontWeight: 500,
          textAlign: 'center',
        },
        h3: {
          padding: 5,
          fontSize: 28,
          fontWeight: 500,
          textAlign: 'center',
        },
        h4: {
          padding: 2,
          fontSize: 18,
          fontWeight: 400,
          textAlign: 'center',
        },
      },
    },
  },
});

export const Typography = ({ children, ...props }) => (
  <ThemeProvider theme={typographyTheme}>
    <MuiTypography {...props}>{children}</MuiTypography>
  </ThemeProvider>
);
