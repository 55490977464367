import { Button, TextField, Divider, Select, MenuItem, InputLabel, FormControl, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import React, { useState, useEffect } from 'react';
import UserAutoComplete from './UsersDropDown'; 
import OrderStageAutocomplete from './OrdersStagesDropDown';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getOrdersForCalendar, getAllUsers, createCalendarEvent, getAllCalendarPresets, createCalendarPreset, getColorCategory} from 'utils/api/Calendar'; 

const NewEventForm = ({ startDate, endDate, setDisplayModalNew }) => {
    // Variables and States used throughout this file
    const [title, setTitle] = useState();
    const [description, setDescription] = useState(); 
    const [startDateTime, setStartDateTime] = useState(startDate);
    const [endDateTime, setEndDateTime] = useState(endDate);
    const [preset, setPreset] = useState(''); 
    const originalPresetArray = ["Pickup - Default, CANNOT customize", "PCR Prep - Default, CANNOT customize", "Purification - Default, CANNOT customize", "Maintenance - Default, CANNOT customize", "New Preset"]; 
    const [presetArray, setPresetArray] = useState(["Pickup", "PCR Prep", "Purification", "Maintenance", "New Preset"]); 
    const [customPresetsObj, setCustomPresetsObj] = useState(); 
    const [eventColor, setEventColor] = useState(''); 
    const [eventColorObj, setEventColorObj] = useState(''); 
    const [customColorArray, setCustomColorArray] = useState(''); 
    const originalColorArr = []; 
    const [eventColorArray, setEventColorArray] = useState([]); 
    const [ordersForCalendar, setOrdersForCalendar] = useState([]); 
    const [originalOrdersForCalendar, setOriginalOrdersForCalendar] = useState([]); 
    const [usersForCalendar, setUsersForCalendar] = useState([]); 
    const [loadingOrders, setLoadingOrders] = useState(true);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const testArray = ["One", "Two", "Three"];
    const [openUsers, setOpenUsers] = React.useState(false);
    const [openOrder, setOpenOrder] = React.useState(false); 
    const [submitLoadUsers, setSubmitLoadUsers] = useState(false);
    const [submitLoadOrder, setSubmitLoadOrder] = useState(false); 
    const loadingUsersDropdown = openUsers && usersForCalendar.length === 0;  
    const loadingOrderDropdown = openOrder && ordersForCalendar.length === 0;  
    const [selectedUsers, setSelectedUsers] = useState();
    const [selectedOrder, setSelectedOrder] = useState(); 
    const [taskLocation, setTaskLocation] = useState(); 
    const [displayModal, setDisplayModal] = useState(false);
    
    // Handeling methods
    // Those useEffect functions help initialize startDateTime and endDateTime based on the chosen slot
    useEffect(() => { setStartDateTime(startDate) }, [startDate] ); 
    useEffect(() => { setEndDateTime(endDate) }, [endDate] ); 
    useEffect(() => {
        fetchOrdersForCalendar(); 
        fetchUsersForCalendar(); 
        fetchColorCategory(); 
    }, []); 
    useEffect(() => { fetchPresetsForCalendar() }, [displayModal] ); 

    const fetchOrdersForCalendar = async () => {
        const res = await getOrdersForCalendar(); 
        if (res.status === 200) {
            setOrdersForCalendar(res.data); 
            setOriginalOrdersForCalendar(res.data); 
            setLoadingOrders(false);
        } else {
            console.log('Error in getting Orders for calendar...')
        }
    }; 

    const fetchUsersForCalendar = async () => {
        const res = await getAllUsers(); 
        if (res.status === 200) {
            setUsersForCalendar(res.data); 
            setLoadingUsers(false);
        } else {
            console.log('Error in getting Users for calendar...')
        }
    }; 

    const fetchPresetsForCalendar = async () => {
        const res = await getAllCalendarPresets(); 
        if (res.status === 200) {
            let newPresetArray = originalPresetArray; 
            let customPresetsTitle = []; 
            for (let i = 0; i < res.data.length; ++i) {
                customPresetsTitle.push(res.data[i].title); 
            }
            newPresetArray.splice.apply(newPresetArray, [newPresetArray.indexOf("New Preset"), 0].concat(customPresetsTitle)); 
            setPresetArray(newPresetArray); 
            setCustomPresetsObj(res.data); 
        } else {
            console.log('Error in getting Presets for calendar...')
        }
    }; 

    const fetchColorCategory = async () => {
        const res = await getColorCategory(); 
        if (res.status === 200) {
            setCustomColorArray(res.data); 
            let originalArr = originalColorArr; 
            for (let i = 0; i < res.data.length; ++i) originalArr.splice(originalArr.indexOf("+"), 0, {color: res.data[i].color, category: res.data[i].category}); 
            setEventColorArray(originalArr); 
        } else {
            console.log('Error in getting Color-Category for calendar...'); 
        }
    }

    const formik = useFormik({
        initialValues: {
          users: [], 
          order: []
        },
        validationSchema: Yup.object({
          users: Yup.array()
            .min(1, 'Must choose at least 1 user/technician')
            .required('Required'), 
          order: Yup.array()
            .min(1, 'Must choose only ONE order')
            .max(1, 'Must choose only ONE order')
            .required('Required')
        }), 
        onSubmit: async (values) => {
            let stageOfOrder = ""
            let orderChosenCode = ""
            let orderDropLocation = ""
            let orderNoOfSamples = null; 
            for (let i = 0; i < ordersForCalendar.length; ++i) {
                if (selectedOrder[0]._id === ordersForCalendar[i]._id) {
                    stageOfOrder = ordersForCalendar[i].stage; 
                    orderChosenCode = ordersForCalendar[i].orderCode; 
                    orderDropLocation = ordersForCalendar[i].dropLocation ? ordersForCalendar[i].dropLocation : "N/A"; 
                    orderNoOfSamples = ordersForCalendar[i].noOfSamples; 
                    break; 
                }
            }
    
            let finalDescription = ""
            if (description === undefined) {
                finalDescription = "" + "Order: " + orderChosenCode + "\nSample Count: " + orderNoOfSamples + "\nDrop Location: " + orderDropLocation + "\nWork format: In person." + "\nLocation: " + taskLocation; 
            } else {
                finalDescription = description + "\nOrder: " + orderChosenCode + "\nSample Count: " + orderNoOfSamples + "\nDrop Location: " + orderDropLocation + "\nWork format: In person." + "\nLocation: " + taskLocation; 
            }
    
            let selectedUsersIds = []; 
            for (let i = 0; i < selectedUsers.length; ++i) {
                selectedUsersIds.push(selectedUsers[i]._id); 
            }
            
            const event = {
                orderId: selectedOrder[0]._id,
                receivingUserId: selectedUsersIds, 
                start: startDateTime,
                end: endDateTime,
                title: title,
                stage: stageOfOrder, 
                description: finalDescription, 
                eventColor: eventColor, 
                location: taskLocation, 
                taskStatus: "IN PROGRESS" // Newly created event will always have this status
            };
            const res = await createCalendarEvent(event);
    
    
            if (res.status === 200) {
                alert('Create New Event Successfully!');
                setDisplayModalNew(false);
                setSubmitLoadUsers(true); 
                setSubmitLoadOrder(true); 
            } else {
                alert('Error in creating new event!');
                setSubmitLoadUsers(false); 
                setSubmitLoadOrder(false); 
            }
        }
    });
    
    const handleChangeUsers = (event, newValues) => {
        formik.setFieldValue('users', newValues);
        setSelectedUsers(newValues); 
    };

    const handleChangeOrder = (event, newValue) => {
        formik.setFieldValue('order', newValue); 
        setSelectedOrder(newValue); 
    }

    const handleChangePreset = (event) => {
        setPreset(event.target.value); 
        // Change Title according to the chosen Preset
        // Change list of Orders according to the chosen Preset
        switch(event.target.value) {
            case "Pickup":
                setTitle("Pickup (Order #)"); 
                let newPickupArr = []; 
                for (let i = 0; i < originalOrdersForCalendar.length; ++i) {
                    let currentStage = originalOrdersForCalendar[i].stage
                    if (currentStage === 'pickup' || currentStage === 'Arrived') newPickupArr.push(originalOrdersForCalendar[i]); 
                }
                setOrdersForCalendar(newPickupArr); 
                // Set everything back to default
                formik.setFieldValue('users', []); 
                setSelectedUsers([]); 
                formik.setFieldValue('order', []); 
                setSelectedOrder([]); 
                setTaskLocation(""); 
                setDescription(""); 
                setEventColor(""); 
                setEventColorObj(); 
                setStartDateTime(startDate); 
                setEndDateTime(endDate); 
                break; 
            case "PCR Prep":
                setTitle("PCR Prep (Order #)");
                let newPrepArr = []; 
                for (let i = 0; i < originalOrdersForCalendar.length; ++i) {
                    let currentStage = originalOrdersForCalendar[i].stage
                    if (currentStage === 'PCR Prep') newPrepArr.push(originalOrdersForCalendar[i]); 
                }
                setOrdersForCalendar(newPrepArr); 
                // Set everything back to default
                formik.setFieldValue('users', []); 
                setSelectedUsers([]); 
                formik.setFieldValue('order', []); 
                setSelectedOrder([]); 
                setTaskLocation(""); 
                setDescription(""); 
                setEventColor(""); 
                setEventColorObj(); 
                setStartDateTime(startDate); 
                setEndDateTime(endDate); 
                break; 
            case  "Purification":
                setTitle("Purification (Order #)"); 
                let newPurificationArr = []; 
                for (let i = 0; i < originalOrdersForCalendar.length; ++i) {
                    let currentStage = originalOrdersForCalendar[i].stage
                    if (currentStage === 'Purification') newPurificationArr.push(originalOrdersForCalendar[i]); 
                }
                setOrdersForCalendar(newPurificationArr); 
                // Set everything back to default
                formik.setFieldValue('users', []); 
                setSelectedUsers([]); 
                formik.setFieldValue('order', []); 
                setSelectedOrder([]); 
                setTaskLocation(""); 
                setDescription(""); 
                setEventColor(""); 
                setEventColorObj(); 
                setStartDateTime(startDate); 
                setEndDateTime(endDate); 
                break; 
            case "Maintenance": 
                setTitle("Maintenance Shift");
                setOrdersForCalendar(originalOrdersForCalendar); 
                // Set everything back to default
                formik.setFieldValue('users', []); 
                setSelectedUsers([]); 
                formik.setFieldValue('order', []); 
                setSelectedOrder([]); 
                setTaskLocation(""); 
                setDescription(""); 
                setEventColor(""); 
                setEventColorObj(); 
                setStartDateTime(startDate); 
                setEndDateTime(endDate); 
                break;  
            case "New Preset":
                setTitle("New Preset Name*"); 
                // Set everything back to default
                formik.setFieldValue('users', []); 
                setSelectedUsers([]); 
                formik.setFieldValue('order', []); 
                setSelectedOrder([]); 
                setTaskLocation(""); 
                setDescription(""); 
                setEventColor(""); 
                setEventColorObj(); 
                setStartDateTime(startDate); 
                setEndDateTime(endDate); 
                break; 
            default: 
                for (let i = 0; i < customPresetsObj.length; ++i) {
                    if (customPresetsObj[i].title === event.target.value) {
                        let chosenPreset = customPresetsObj[i]; 
                        setTitle(chosenPreset.eventName); 
                        
                        // Set color category related variables 
                        setEventColor(chosenPreset.eventColor); 
                        for (let i = 0; i < eventColorArray.length; ++i) {
                            let currObj = eventColorArray[i]; 
                            if (currObj.color === chosenPreset.eventColor) {
                                setEventColorObj(currObj); 
                                break; 
                            }
                        }
                        
                        setStartDateTime(DateTime.fromISO(chosenPreset.start)); // If not doing conversion to DateTime, duration will be invalid
                        setEndDateTime(DateTime.fromISO(chosenPreset.end)); // If not doing conversion to DateTime, duration will be invalid
                        setTaskLocation(chosenPreset.location); 
                        setDescription(chosenPreset.description); 
                        let selectedUsersArr = []; 
                        for (let i = 0; i < chosenPreset.receivingUserId.length; ++i) {
                            let currUserId = chosenPreset.receivingUserId[i]; 
                            for (let j = 0; j < usersForCalendar.length; ++j) {
                                if (usersForCalendar[j]._id === currUserId) {
                                    selectedUsersArr.push(usersForCalendar[j]); 
                                    break; 
                                }
                            }
                        }
                        formik.setFieldValue('users', selectedUsersArr); 
                        setSelectedUsers(selectedUsersArr); 
                        formik.setFieldValue('order', []); 
                        setSelectedOrder([]); 
                        break; 
                    }
                }
        }
    }

    const handleChangeEventColor = (event) => {
        setEventColorObj(event.target.value); 
        setEventColor(event.target.value.color);  
    }

    const handleCreateNewPreset = async (e) => {
        let selectedUsersIds = []; 
        for (let i = 0; i < selectedUsers.length; ++i) {
            selectedUsersIds.push(selectedUsers[i]._id); 
        }
        
        const newPreset = {
            title: title, 
            eventName: title, 
            eventColor: eventColor, 
            start: startDateTime, 
            end: endDateTime, 
            description: description,
            location: taskLocation, 
            receivingUserId: selectedUsersIds
        }

        const res = await createCalendarPreset(newPreset);

        if (res.status === 200) {
            alert('Create New Preset Successfully!');
            setDisplayModalNew(false); 
        } else {
            alert('Error in creating new preset!');
            setDisplayModalNew(false); 
        }
    }

    // Start rendering...
    if (loadingOrders || loadingUsers) {
        return (
            <h2>Loading Data for Calendar ...</h2>
        )
    }

    return ( 
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <h2>New Event</h2>
            <FormControl sx={{width: "100%", mb: 3}}>
                <InputLabel id="color-select-bar">Color & Category</InputLabel>
                <Select
                    id="color-select-bar"
                    value={eventColorObj}
                    onChange={handleChangeEventColor}
                >
                    {eventColorArray.length !== 0 ? eventColorArray.map((value, index) => {
                        return  <MenuItem key={index} value={value} style={{textAlign: 'center'}}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <Box
                                            sx={{
                                            width: 20,
                                            height: 20,
                                            borderRadius: 1,
                                            bgcolor: value.color,
                                            mr: 1
                                            }}
                                        />
                                        <div>
                                            {"- " + value.category}
                                        </div>
                                    </div>
                                </MenuItem>
                    }) : <MenuItem>No Available Color & Category</MenuItem>}
            
                </Select>
            </FormControl>
            <FormControl sx={{width: "100%", mb: 3}}>
                <InputLabel id="preset-select-bar">Select Preset</InputLabel>
                <Select
                    id="preset-select-bar"
                    value={preset}
                    onChange={handleChangePreset}
                    sx={{
                        color: preset === 'New Preset' ? '#003057' : 'default', 
                        fontWeight: preset === 'New Preset' ? 600 : 'default'
                    }}
                >
                    {presetArray.map((value, index) => {
                        return <MenuItem key={index} 
                                        value={value} 
                                        style={{
                                            color: value === 'New Preset' ? '#003057' : 'default', 
                                            fontWeight: value === 'New Preset' ? 600 : 'default'
                                        }}
                                >
                                {value}
                            </MenuItem>
                    })}
                </Select>
            </FormControl>
            <TextField
                label="Event Name"
                onChange={(e) => setTitle(e.target.value)}
                required
                variant="outlined"
                color="secondary"
                type="Title"
                sx={{ mb: 3, width: '100%' }}
                fullWidth={false}
                value={title || ''}
            />
            <Divider component="li" style={{marginBottom: 15, borderBottomWidth: 3, borderBottomColor: 'gray'}}/>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateTimePicker
                label="Start"
                value={startDateTime}
                onChange={(newValue) => setStartDateTime(newValue)}
                renderInput={(params) => <TextField {...params} sx={{ mb: 3 }} />}
                />
                <DateTimePicker
                label="End"
                value={endDateTime}
                onChange={(newValue) => setEndDateTime(newValue)}
                renderInput={(params) => <TextField {...params} sx={{ mb: 3 }} />}
                />
            </LocalizationProvider>
            <h6>Duration:</h6>
                <div style={{marginBottom: 15}}>
                   {(endDateTime - startDateTime) > 0 ? ((endDateTime - startDateTime) / (60 * 60 * 1000)).toFixed(2) + " hour(s)"
                    : "Invalid Duration"
                   }
            </div>
            <TextField
                label="Location"
                onChange={(e) => setTaskLocation(e.target.value)}
                required
                variant="outlined"
                color="secondary"
                type="Title"
                sx={{ mb: 3 }}
                fullWidth
                value={taskLocation || ''}
            />
            <Divider component="li" style={{marginBottom: 15, borderBottomWidth: 3, borderBottomColor: 'gray'}}/>
            <div style={{marginBottom: 15}} >
                <h6>Choose an order to assign:</h6>
                <OrderStageAutocomplete
                    value={formik.values.order}
                    onChange={(event, value) => handleChangeOrder(event, value)}
                    open={openOrder}
                    setOpen={setOpenOrder}  // This prop assumes that the OrderAutocomplete component accepts this prop to handle open state changes
                    submitLoad={submitLoadOrder}
                    options={ordersForCalendar}
                    loading={loadingOrderDropdown}
                    error={formik.touched.order && Boolean(formik.errors.order)}
                    helperText={formik.touched.order && formik.errors.order}
                />
            </div>
            <div style={{marginBottom: 15}} >
                <h6>Assign to technicians:</h6>
                <UserAutoComplete
                    value={formik.values.users}
                    onChange={(event, value) => handleChangeUsers(event, value)}
                    open={openUsers}
                    setOpen={setOpenUsers}  // This prop assumes that the OrderAutocomplete component accepts this prop to handle open state changes
                    submitLoad={submitLoadUsers}
                    options={usersForCalendar}
                    loading={loadingUsersDropdown}
                    error={formik.touched.users && Boolean(formik.errors.users)}
                    helperText={formik.touched.users && formik.errors.users}
                />
            </div>
            <Divider component="li" style={{marginBottom: 15, borderBottomWidth: 3, borderBottomColor: 'gray'}}/>
            <TextField
                label="Notes"
                onChange={(e) => setDescription(e.target.value)}
                variant="outlined"
                color="secondary"
                sx={{ mb: 3 }}
                type="Title"
                fullWidth
                value={description || ''}
                multiline={true}
                rows={5}
            />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center'}}>
                <Button 
                    variant="contained"
                    type="submit" 
                    style={{backgroundColor: "#008AFC", color: 'white', marginBottom: 15}} 
                >
                    Create Event
                </Button>
                <div>
                    <u style={{ color: '#B3B3B3', cursor: 'pointer' }} onClick={handleCreateNewPreset}>
                        Add Preset
                    </u>
                </div>
            </div>
        </form>
    );
    
};

export default NewEventForm;