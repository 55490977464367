import React from 'react';
import { Grid, Typography } from '@mui/material';
import StatusChangePopup from './StatusChangePopup';
import ReassignLetterPopup from './ReassignLetterPopup';

function LetterQuadrant(props) {
  const element = props.element;
  var cartridgeID = element.cartridgeID;
  if (cartridgeID.length > 6) {
    cartridgeID = `${cartridgeID.slice(0, 6)}-${cartridgeID.slice(6)}`;
  }

  return (
    <div>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <ReassignLetterPopup element={element} />
        </Grid>
        <Grid item container justifyContent="center" alignItems="center">
          {element.capillaries.map((status, index) => (
            <div key={index}>
              <div>
                <StatusChangePopup
                  element={element}
                  index={index}
                  status={status}
                />
              </div>
            </div>
          ))}
        </Grid>
        <Grid item>
          <Typography variant="body1">ID: {cartridgeID}</Typography>
        </Grid>
        <br></br>
      </Grid>
    </div>
  );
}

export default LetterQuadrant;

