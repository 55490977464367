import { memo, useContext } from 'react';
import TextField from '@mui/material/TextField';
import { DebounceInput } from 'react-debounce-input';
import InputAdornment from '@mui/material/InputAdornment';
import { SampleMethodContext } from '../../../contexts/MethodContext';
import { ErrorVisContext } from '../../../contexts/DataContext';

const SangerRowReadLen = memo(
  ({ id, data, errorReadLen }) => {
    const { updateSample } = useContext(SampleMethodContext);
    const { submitShowError } = useContext(ErrorVisContext);

    return (
      <DebounceInput
        debounceTimeout={500}
        minLength={1}
        element={TextField}
        required
        id="read-len"
        placeholder={'Read'}
        variant="standard"
        InputProps={{
          disableUnderline: true,
          inputmode: 'numeric',
          pattern: '[0-9]*',
          endAdornment: (
            <InputAdornment position="end" sx={{ paddingRight: 1 }}>
              bp
            </InputAdornment>
          ),
        }}
        value={data.readLen}
        error={
          (data.touched[3] || submitShowError) && errorReadLen(data.readLen)
        }
        onBlur={(e) => {
          var newTouched = [...data.touched];
          newTouched[3] = true;
          updateSample({ touched: newTouched }, id);
        }}
        onChange={async (e) => {
          var newTouched = [...data.touched];
          newTouched[3] = true;
          updateSample(
            {
              readLen: e.target.value,
              touched: newTouched,
            },
            id
          );
        }}
        sx={{
          '& .MuiInputBase-input': {
            width: '100%',
            margin: '10px',
          },
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id &&
      prevProps.data.readLen === nextProps.data.readLen
    );
  }
);

export default SangerRowReadLen;
