import { Button } from 'reusable/UI/Button';
import { useNavigate } from 'react-router-dom';

export const StepsComponent = () => {
  const navigate = useNavigate();

  return (
    <>
      <ol>
        <li>
          Please fill out sample details in the form below. Your data will be
          saved across different sessions.
        </li>
        <li>
          To upload a completed excel file, click the "UPLOAD EXCEL" button and
          follow the instructions on the form.
        </li>
        <li>
          If on a per request* basis, submit a request for Sanger Sequencing
          through SUMS (www.sums.gatech.edu)
        </li>
        <li>
          Include a paper copy of the drop off code with your samples upon your
          submission.
        </li>
        <li>
          The first 24 samples to be dropped off before 9:30 AM will be
          processed as same-day samples. Samples submitted after 9:30 AM or
          those
        </li>
      </ol>
      <div>
        *A “per request basis” means that clients are responsible for submitting
        their sequencing requests through SUMS to bypass semesterly billing.
      </div>
      <Button variant="accent" onClick={() => navigate('/FAQ')}>
        FAQ Page
      </Button>
    </>
  );
};
