import { MapLabel } from './MapLabel';
import { Well } from './Well';

export const MapRow = ({
  samples,
  setSamples,
  row,
  broken,
  usedSections,
  move,
  dragging,
  setDragging,
  canDrag,
  width,
}) => {
  const contentWidth = width - 40;

  const getRowChar = (i = row) => {
    return String.fromCharCode('A'.charCodeAt(0) + i - 1);
  };

  return (
    <tr>
      <MapLabel char={getRowChar()} />
      {[...Array(12)].map((_, i) => {
        const sampleIdx = row - 1 + i * 8;
        const sample = samples.find(
          (sample) => sample && sample.well === sampleIdx
        );
        return (
          <td
            key={i}
            style={{
              border: 'solid 1px #8D8D8D',
              width: width ? '' : '8%',
              height: 60,
            }}
          >
            <Well
              sample={sample}
              move={move}
              index={sampleIdx}
              broken={broken}
              usedSections={usedSections}
              dragging={dragging}
              setDragging={setDragging}
              canDrag={canDrag}
              width={width ? contentWidth / 12 : undefined}
            />
          </td>
        );
      })}
    </tr>
  );
};
