import { Typography } from 'reusable/UI/Typography';

export const MapNumRow = () => {
  return (
    <tr>
      <td></td>
      {[...Array(12)].map((_, i) => (
        <td key={i}>
          <Typography variant="h4">{i + 1}</Typography>
        </td>
      ))}
    </tr>
  );
};
