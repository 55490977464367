import { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PickUp from './components/PickUp';
import ActiveOrders from './components/ActiveOrders';
import Completed from './components/Completed';
import { SnackbarProvider } from 'notistack';
import { socket, SocketContext } from '../../context/socket';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import CRActionsBar from './components/CRActionsBar';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CreateComboRun from './components/CreateComboRun';
import EditComboRun from './components/EditComboRun';
import AuthContext from 'utils/auth/auth';
import MergeCRPopup from './components/MergeCRPopup';
import { AddPopup } from 'reusable/AddPopup/AddPopup';
import { getActiveOrders } from 'utils/api/Orders';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
async function setActiveOrders(
  setCurrentOrders,
  ordersReceived,
  setOrderReceived
) {
  if (ordersReceived === false) {
    const data = await getActiveOrders();
    setCurrentOrders(data);
    const timer = await sleep(500);
    setOrderReceived(true);
    return;
  }
}

export default function EmployeeOrders(props) {
  const user = useContext(AuthContext);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [ordersReceived, setOrderReceived] = useState(false);
  const [value, setValue] = useState(0);
  const [openCCR, setOpenCCR] = useState(false);
  const [openECR, setOpenECR] = useState(false);
  const [mergeCR, setMergeCR] = useState(true);
  const { tabIndex } = useParams();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    navigate(`/EmployeeOrders/${newValue}`);
  };
  useEffect(() => {
    setValue(parseInt(tabIndex));
  }, [tabIndex]);

  socket.on('updateAll', (update) => {
    setOrderReceived(false);
  });

  useEffect(() => {
    setActiveOrders(setCurrentOrders, ordersReceived, setOrderReceived);
  }, [ordersReceived]);

  useEffect(() => {
    socket.on('connect', () => console.log(socket.id));
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => console.log('Server disconnected'));
  }, []);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={!ordersReceived}
        invisible={true}
      >
        <PropagateLoader />
      </Backdrop>
      <SocketContext.Provider value={socket}>
        <SnackbarProvider maxSnack={3}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Pick-up" {...a11yProps(0)} />
                    <Tab label="Active" {...a11yProps(1)} />
                    <Tab label="Completed" {...a11yProps(2)} />
                    <div style={{ marginLeft: 'auto' }}></div>
                  </Tabs>
                </Grid>
                <Grid item xs={12} sm={9}>
                  {value === 1 ? (
                    <Grid container>
                      {user.admin === true ? (
                        <>
                          <div style={{ marginLeft: 'auto' }}></div>
                          <CRActionsBar />
                          <div
                            style={{ paddingRight: '50px', paddingTop: '6px' }}
                          >
                            <AddPopup type="order" />
                          </div>
                        </>
                      ) : (
                        <>
                          <div style={{ paddingTop: '6px' }}>
                            <MergeCRPopup />
                          </div>
                        </>
                      )}
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Box>
            <TabPanel value={value} index={0}>
              <PickUp currentOrders={currentOrders} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ActiveOrders
                currentOrders={currentOrders}
                mergeCR={mergeCR}
                setMergeCR={setMergeCR}
              />
            </TabPanel>

            <TabPanel value={value} index={2}>
              <Completed currentOrders={currentOrders} />
            </TabPanel>
          </Box>
        </SnackbarProvider>
      </SocketContext.Provider>
    </Grid>
  );
}
