import { useState } from 'react';
import SampleListView from './SampleListView';
import numToWell from '../../utils/numToWell';
import { TextField } from '@mui/material';

import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;
const SampleListViewCharged = (props) => {
  const orderCode = props.orderCode;
  const orderRerunType = props.orderRerunType;
  const [billingNotes, setBillingNotes] = useState({});

  const handleBillingNotesChange = (id, value) => {
    setBillingNotes((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  // const [chargedSamples, setChargedSamples] = useState([]);

  async function setChargedSamples(chargedSamples) {
    const res = await axios.post(
      API_ENDPOINT + '/samples/updateChargedSamples',
      {
        orderCode: orderCode,
        chargedSamples: chargedSamples ?? [],
      }
    );
  }

  async function updateBillingNotes() {
    const res = await axios.post(API_ENDPOINT + '/samples/updateBillingNotes', {
      billingNotes: billingNotes ?? [],
    });
  }

  const columnsBilling = [
    {
      field: 'well',
      headerName: 'Well',
      width: 20,
      valueGetter: (params) => {
        return numToWell(params.row.well);
      },
    },
    { field: 'name', headerName: 'Sample Name', width: 300 },
    {
      field: 'rerunDates',
      headerName: 'Rerun(s)',
      width: 200,
      renderCell: (params) => {
        if (
          params.row.rerunChildrenDates &&
          params.row.rerunChildrenDates.length != 0
        ) {
          return (
            <div>
              {params.row.rerunChildrenDates.map((dateString, index) => {
                const date = new Date(dateString);
                const formattedDate = `${
                  date.getMonth() + 1
                }/${date.getDate()}/${date.getFullYear() % 100}`;
                return <div key={index}>{formattedDate}</div>;
              })}
            </div>
          );
        } else {
          return '-';
        }
      },
    },
    {
      field: 'billingNotes',
      headerName: 'Notes',
      width: 400,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <TextField
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            value={billingNotes[id] || ''}
            onChange={(e) => handleBillingNotesChange(id, e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
          />
        );
      },
    },
  ];

  return (
    <SampleListView
      title={'Charged Samples - Billing'}
      buttonTitle={'Sample Pricing'}
      selectKey={'charged'}
      orderCode={orderCode}
      selectFunction={setChargedSamples}
      updateNotesFunction={updateBillingNotes}
      columnsBilling={columnsBilling}
      orderRerunType={orderRerunType}
      setBillingNotes={setBillingNotes}
    />
  );
};

export default SampleListViewCharged;
