import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

export default function ExpandMoreForOneStatus(props) {
  const handleClickOpen = (selectedOrderStatus) => {
    return (event) => {
      props.CatologFilter(selectedOrderStatus);
    };
  };

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 2000,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 450,
      }}
    >
      {props.inventoryItems.length === 0 ? (
        <></>
      ) : (
        props.inventoryItems.map((item1) => (
          <>
            <ListItem key={item1._id} alignItems="flex-start">
              <ListItemButton onClick={handleClickOpen(item1.catologNumber)}>
                <ListItemText
                  primary={
                    <>
                      <span>{'Catolog#: ' + item1.catologNumber}</span>
                      <br></br>
                      <span>{item1.name}</span>
                    </>
                  }
                  primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                  secondary={
                    <>
                      <span>
                        {'Processing Total Quantity: ' +
                          item1.processingQuantity}
                      </span>

                      <br></br>
                      <span>{'Total Quantity: ' + item1.totalQuantity}</span>
                      <br></br>
                      <span>{'Trigger Quantity: ' + item1.triggerAmount}</span>
                    </>
                  }
                />
              </ListItemButton>
            </ListItem>
          </>
        ))
      )}
    </List>
  );
}
