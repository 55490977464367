import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

const filterHelper = (res) => {
  let dataToBeUsed = [];
  res.data.forEach((element) => {
    let oneRow = {};
    Object.keys(element).forEach((el) => {
      if (el !== '__v') {
        oneRow = { ...oneRow, [el]: element[el] };
      }
    });
    dataToBeUsed = [...dataToBeUsed, oneRow];
  });
  return dataToBeUsed;
};

const getInventory = async () => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/inventory`);
    return filterHelper(res);
  } catch (error) {
    console.log('Error getting inventory', error);
    throw error;
  }
};

const updateInventory = async (item) => {
  try {
    const { status } = await axios.put(
      `${API_ENDPOINT}/inventory/update`,
      item
    );
    return status;
  } catch (error) {
    console.error('Error updating inventory:', error);
    throw error;
  }
};

const deleteInventory = async (item) => {
  try {
    const res = await axios.delete(`${API_ENDPOINT}/inventory/delete`, item);
    return res.status;
  } catch (error) {
    console.error('Error deleting inventory:', error);
    throw error;
  }
};

const createInventory = async (item) => {
  try {
    const res = await axios.post(`${API_ENDPOINT}/inventory/add`, item);
    return res.status;
  } catch (error) {
    console.error('Error creating inventory:', error);
    throw error;
  }
};

const getSpecificOrder = async (item) => {
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/Inventory/specificOrderStatus`,
      item
    );
    return filterHelper(res);
  } catch (error) {
    console.error('Error getting specific order:', error);
    throw error;
  }
};

const getRecentFiveActions = async () => {
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/Inventory/inventoryLog/all/5`,
      {}
    );
    return filterHelper(res);
  } catch (error) {
    console.error('Error getting recent five actions:', error);
    throw error;
  }
};

export {
  getInventory,
  updateInventory,
  deleteInventory,
  createInventory,
  getSpecificOrder,
  getRecentFiveActions,
};
