import SangerPrimerRowColor from './SangerPrimerRowColor';
import SangerPrimerRowName from './SangerPrimerRowName';
import SangerPrimerRowVol from './SangerPrimerRowVol';
import SangerPrimerRowErrors from './SangerPrimerRowErrors';
import { useContext, useState } from 'react';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from 'reusable/UI/IconButton';
import { PrimerMethodContext } from '../../../contexts/MethodContext';

const SangerPrimerRowComponent = ({
  primer,
  primerKey,
  index,
  primerColors,
  formik,
}) => {
  const { addPrimerIndex, removePrimer, movePrimer } =
    useContext(PrimerMethodContext);

  const [draggable, setDraggable] = useState(false);

  const rowButtonStyle = {
    position: 'absolute',
    display: 'flex',
    transform: 'translateY(-50%)',
  };

  const rowContainerStyle = {
    border: '1px solid #0030579E',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    paddingRight: 10,
    paddingLeft: 5,
    margin: 0,
  };

  const borderLeft = {
    borderLeft: '1px solid #0030572E',
  };

  const borderRight = {
    borderRight: '1px solid #0030572E',
  };

  const handleDragStart = (e) => {
    e.dataTransfer.setData('dragIndex', index);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDraggable(false);

    const movedIndex = Number(e.dataTransfer.getData('dragIndex'));
    movePrimer(movedIndex, index);
  };

  return (
    <>
      <tr
        style={rowContainerStyle}
        draggable={draggable}
        onMouseUp={() => setDraggable(false)}
        onDragEnd={() => setDraggable(false)}
        onDragOver={(e) => e.preventDefault()}
        onDragStart={handleDragStart}
        onDrop={handleDrop}
      >
        <td style={borderRight}>
          <SangerPrimerRowColor
            data={primer}
            primerKey={primerKey}
            primerColors={primerColors}
          />
        </td>
        <td style={{ ...borderLeft, ...borderRight }}>
          <SangerPrimerRowName data={primer} primerKey={primerKey} />
        </td>
        <td style={borderLeft}>
          <SangerPrimerRowVol data={primer} primerKey={primerKey} />
        </td>
        {/* Using a td with width: 0 because otherwise you can't control the extra space in the row. */}
        <td style={{ width: 0, height: '100%', position: 'static' }}>
          <IconButton
            style={{
              ...rowButtonStyle,
              left: 0,
              transform: 'translateY(-50%) translateX(-100%)',
            }}
            onMouseDown={() => setDraggable(true)}
          >
            <DragHandleIcon />
          </IconButton>
          <div style={rowButtonStyle}>
            <IconButton onClick={() => addPrimerIndex(index)}>
              <AddIcon />
            </IconButton>
            <IconButton onClick={() => removePrimer(primerKey)}>
              <RemoveIcon />
            </IconButton>
          </div>
        </td>
      </tr>
      <SangerPrimerRowErrors data={primer} index={index} formik={formik} />
    </>
  );
};

export default SangerPrimerRowComponent;
