import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LogPopup from './LogPopup.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Tooltip from '@mui/material/Tooltip';
// uses the "dialog" component on the MUI website

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function LogButtonPopup(props) {
  const [open, setOpen] = useState(false);

  // BACKEND CALL TO RETRIEVE ORDER INFORMATION

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let cartridgeID = props.products.cartridgeID;
  if (cartridgeID.length > 6) {
    cartridgeID = `${cartridgeID.slice(0, 6)}-${cartridgeID.slice(6)}`;
  }

  return (
    <>
      <Tooltip title={'Log for ' + cartridgeID}>
        <IconButton onClick={handleClickOpen}>
          <LibraryBooksIcon />
        </IconButton>
      </Tooltip>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Container>
            <Row>
              <LogPopup products={props.products} whichLog={props.whichLog} />
            </Row>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}></Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
