export const Actions = [
  {
    name: 'Rearrange Samples',
    action: (samples, cartridge, _) => {
      if (!cartridge || !cartridge.capillaries) return samples;
      let availableWells = Array.from({ length: 192 }, (_, i) => i);
      // extra 96 wells are to help prevent removing samples
      cartridge.capillaries.forEach((capillary, i) => {
        if (capillary === 'broken') {
          const mod = i % 4;
          availableWells = availableWells.filter(
            (well) => well % 4 !== mod || well > 96
          );
        }
      });
      samples.sort((a, b) => a.well - b.well);
      return samples.map((sample, i) => ({
        ...sample,
        well: availableWells[i],
      }));
    },
    error: (samples, cartridge) => {
      let working = 4;
      if (cartridge && cartridge.capillaries)
        cartridge.capillaries.forEach(
          (capillary) => (working -= capillary === 'broken' ? 1 : 0)
        );
      return samples.length > 24 * working
        ? 'This action will remove samples'
        : null;
    },
  },
  {
    name: 'Sort Samples',
    action: (samples, _, primers) => {
      const typeOrder = ['sanger', 'fragment', 'premixed', 'control'];
      const availableWells = [];
      samples.forEach((sample) => availableWells.push(sample.well));
      availableWells.sort((a, b) => a - b);
      samples.sort((first, second) => {
        if (first.type !== second.type) {
          return typeOrder.indexOf(first.type) - typeOrder.indexOf(second.type);
        }
        if (first.primer !== second.primer) {
          const firstPrimer = primers.findIndex(
            (primer) => primer._id === first.primer
          );
          const secondPrimer = primers.findIndex(
            (primer) => primer._id === second.primer
          );
          return firstPrimer - secondPrimer;
        }
        return first.well - second.well;
      });
      return samples.map((sample, i) => ({
        ...sample,
        well: availableWells[i],
      }));
    },
    error: () => null,
  },
];
