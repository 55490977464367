import axios from 'axios';

export const downloadExcel = (fileNo) => {
  return axios({
    url: '/api/upload/download',
    method: 'GET',
    responseType: 'blob', // important
    params: {
      fileType: fileNo,
    },
    onDownloadProgress: (progressEvent) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      ); // you can use this to show user percentage of file downloaded
    },
  });
};
