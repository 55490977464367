import {
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import MUIDataTable from 'mui-datatables';
import { socket } from 'context/socket';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { getCartridgeLog } from 'utils/api/Cartridges';

async function getInitialData({
  setGetLoad,
  setInitialData,
  setGroupByCartridge,
  setGroupByOrderCode,
  setGroupByUser,
  setCartridgesReceived,
  cartridgesReceived,
  whichLog,
}) {
  if (cartridgesReceived === false) {
    try {
      const dataToBeUsed = await getCartridgeLog(whichLog);
      setInitialData(dataToBeUsed[0]);
      setGroupByCartridge(dataToBeUsed[1]);
      setGroupByOrderCode(dataToBeUsed[2]);
      setGroupByUser(dataToBeUsed[3]);
      setGetLoad(false);
      setCartridgesReceived(true);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default function LogPopup(props) {
  const [isChecked, setIsChecked] = useState(false);
  const [getLoad, setGetLoad] = useState(true);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const [groupByCartridge, setGroupByCartridge] = useState([]);
  const [groupByOrderCode, setGroupByOrderCode] = useState([]);
  const [groupByUser, setGroupByUser] = useState([]);
  const [cartridgesReceived, setCartridgesReceived] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let cartridgeID = props.products.cartridgeID;
  if (cartridgeID.length > 6) {
    cartridgeID = `${cartridgeID.slice(0, 6)}-${cartridgeID.slice(6)}`;
  }
  let displayCatologNumber = props.whichLog === 'the Whole Inventory';
  let whichLog = props.whichLog;
  let inventoryidthis;
  if (!displayCatologNumber) {
    inventoryidthis = props.products._id;
  }

  const handleCloseSubmit = () => {
    setSubmitStatus(false);
  };

  useEffect(() => {
    socket.on('updateOtherslog', (msg) => {
      setCartridgesReceived(false);
      enqueueSnackbar(msg, { variant: 'info' });
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelflog', () => {
      setCartridgesReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateOthers', (msg) => {
      setCartridgesReceived(false);
      enqueueSnackbar(msg, { variant: 'info' });
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelf', () => {
      setCartridgesReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateAll', () => {
      setCartridgesReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('connect', () => console.log(socket.id));
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => console.log('Server disconnected'));
  }, []);

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setInitialData,
      setGroupByCartridge,
      setGroupByOrderCode,
      setGroupByUser,
      setCartridgesReceived,
      cartridgesReceived,
      whichLog,
    }).catch(console.error);
  }, [cartridgesReceived]);

  const columns = [
    {
      label: 'Time',
      name: 'time',
      options: {
        filter: true,
        sortDirection: 'desc',
        customBodyRender: (value) => {
          return value.toLocaleString();
        },
        filterType: 'custom',
        customFilterListRender: (v) => {
          if (v[0] && v[1] && isChecked) {
            return [`Start Date: ${v[0]}`, `End Date: ${v[1]}`];
          } else if (v[0] && v[1] && !isChecked) {
            return `Start Date: ${v[0]}, End Date: ${v[1]}`;
          } else if (v[0]) {
            return `After this date: ${v[0]}`;
          } else if (v[1]) {
            return `Before this date: ${v[1]}`;
          }
          return false;
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            var check = new Date(date);
            var from = new Date(filters[0]);
            var to = new Date(filters[1]);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            if (filters[0] && filters[1]) {
              return check <= from || check >= to;
            } else if (filters[0]) {
              return check <= from;
            } else if (filters[1]) {
              return check >= to;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => {
            return (
              <div>
                <FormLabel>Time</FormLabel>
                <br></br>
                <FormGroup row>
                  <TextField
                    id="startDate"
                    label="Start Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][0] || ''}
                    onChange={(event) => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '60%', marginRight: '5%' }}
                  />
                  <TextField
                    id="endDate"
                    label="End Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][1] || ''}
                    onChange={(event) => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '60%', marginRight: '5%' }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={(event) => setIsChecked(event.target.checked)}
                      />
                    }
                    label="Separate Values"
                    style={{ marginLeft: '0px' }}
                  />
                </FormGroup>
              </div>
            );
          },
        },
        print: false,
      },
    },
    {
      name: 'user',
      label: 'User',
      options: {
        filter: false,
        sort: true,
        display: 'excluded',
      },
    },
    {
      name: 'cartridge',
      label: 'Cartridge ID',
      options: {
        filter: false,
        sort: true,
        display: 'excluded',
      },
    },
    {
      name: 'amount',
      label: 'Amount',
      options: {
        filter: false,
        sort: true,
        display: 'excluded',
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        filter: false,
        sort: true,
        display: 'excluded',
      },
    },
    {
      name: 'capillaryChanged',
      label: 'Capillary Changed',
      options: {
        filter: false,
        sort: true,
        display: 'excluded',
      },
    },
    {
      name: 'order',
      label: 'Order',
      options: {
        filter: false,
        sort: true,
        display: 'excluded',
      },
    },
    {
      name: 'message',
      label: 'Records',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'multiselect',
    print: false,
    viewColumns: false,
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
          <PropagateLoader />
        </Backdrop>

        <Backdrop
          sx={{ color: '#fff', zIndex: 1 }}
          open={submitStatus}
          onClick={handleCloseSubmit}
        >
          {submitSucess ? (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              {submitResponse}
            </Alert>
          ) : (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              severity="error"
            >
              {submitResponse}
            </Alert>
          )}
        </Backdrop>
      </Grid>

      <MUIDataTable
        title={'Log for ' + cartridgeID}
        data={initialData}
        columns={columns}
        options={options}
      />
    </div>
  );
}
