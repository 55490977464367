import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import { SnackbarProvider } from 'notistack';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AuthProvider } from 'utils/auth/auth';
import ContactUs from './features/ContactUs/ContactUs.js';
import HomePage from './features/Static/HomePage/HomePage';
import Faq from './features/FAQ/FaqComponent';
import Sanger from './features/SubmissionForm/Sanger';
import Fragment from './features/SubmissionForm/Fragment';
import NotFoundPage from './features/NotFound/NotFoundPage.jsx';
import LoginPage from './features/Login/LoginPage';
import DropOffCode from './features/DropOff/DropOffCode';
import {
  PrivateRoute,
  PrivateRouteAdmin,
  PrivateRouteEmployee,
} from './utils/auth/privateRoute';
import WithFooter from './reusable/Footer/WithFooter';
import WithMessage from './reusable/Message/WithMessage';
import WithNav from './reusable/Navbar/WithNav';
import WithoutNav from './reusable/Navbar/WithoutNav';
import SampleMap from './features/SampleMap/SampleMap';
import AboutUs from './features/Static/AboutUs/AboutUs';
import { Admin } from './features/Admin/Admin';
import Billing from './features/Billing/Billing';
import EmployeeOrders from './features/EmployeeOrders/EmployeeOrders';
import { ExecutiveCommands } from './features/Executive/ExecutiveCommands';
import Inventory from './features/Inventory/Inventory';
import Pricing from './features/Pricing/Pricing.js';
import UploadSelect from './features/SubmissionForm/UploadSelect';
import DropOff from './features/DropOff/DropOff.js';
import Team from 'features/Team/Team';
import { DownloadFile } from 'features/DownloadFile/DownloadFile';
import Profile from 'features/Profile/Profile';
import { ProtectedPage } from 'features/ProtectedPage/ProtectedPage';
import Orders from 'features/ClientOrders/Orders';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from 'features/ErrorPage/ErrorPage';
import CustomCalendar from 'features/Calendar/CustomCalendar';

const App = () => {
  return (
    <DndProvider debugMode={true} backend={HTML5Backend}>
      <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <AuthProvider>
              <Routes>
                <Route element={<WithoutNav />}>
                  <Route
                    path="samplemap/:sampleMapId"
                    element={<SampleMap stage={'some random stage'} />}
                  />
                  <Route
                    path="/Drop_off/EBB"
                    element={<DropOff location="EBB" />}
                  />
                  <Route
                    path="/Drop_off/IBB"
                    element={<DropOff location="IBB" />}
                  />
                  <Route
                    path="/Drop_off/ES&T"
                    element={<DropOff location="ES&T" />}
                  />
                  <Route
                    path="/Drop_off/MoSE"
                    element={<DropOff location="MoSE" />}
                  />
                  <Route
                    path="/Drop_off/cherry_emerson"
                    element={<DropOff location="Cherry Emerson" />}
                  />
                  <Route
                    path="/Drop_off/Marcus"
                    element={<DropOff location="Marcus" />}
                  />
                </Route>
                <Route element={<WithMessage />}>
                  <Route element={<WithNav />}>
                    <Route element={<WithFooter />}>
                      <Route exact path="/" element={<HomePage />} />
                      <Route path="/Aboutus" element={<AboutUs />} />
                      <Route path="/Contact-Us" element={<ContactUs />} />
                      <Route path="/Faq" element={<Faq />} />
                      <Route path="/Upload" element={<UploadSelect />} />
                      <Route path="/Upload/Sanger" element={<Sanger />} />
                      <Route path="/Upload/Fragment" element={<Fragment />} />
                      <Route path="/Team" element={<Team />} />
                      <Route path="/pricing" element={<Pricing />} />
                      <Route path="/profile" element={<PrivateRoute />}>
                        <Route path="/profile" element={<Profile />} />
                      </Route>
                      <Route path="/Orders" element={<PrivateRoute />}>
                        <Route path="/Orders" element={<Orders />} />
                      </Route>
                      <Route
                        path="/EmployeeOrders/:tabIndex"
                        element={<PrivateRouteEmployee />}
                      >
                        <Route
                          path="/EmployeeOrders/:tabIndex"
                          element={<EmployeeOrders />}
                        />
                      </Route>
                      <Route path="/Admin" element={<PrivateRouteAdmin />}>
                        <Route path="/Admin" element={<Admin />} />
                      </Route>
                      <Route path="/Inventory" element={<PrivateRoute />}>
                        {/* <Route path="/Inventory" element={<PrivateRouteAdmin />}> */}
                        <Route path="/Inventory" element={<Inventory />} />
                      </Route>
                      <Route path="/Billing" element={<PrivateRouteAdmin />}>
                        <Route path="/Billing" element={<Billing />} />
                      </Route>
                      <Route path="/Calendar" element={<PrivateRouteAdmin />}>
                        <Route path="/Calendar" element={<CustomCalendar />} />
                      </Route>
                      <Route
                        path="/ExecutiveCommands/:tabIndex"
                        element={<PrivateRouteAdmin />}
                      >
                        <Route
                          path="/ExecutiveCommands/:tabIndex"
                          element={<ExecutiveCommands />}
                        />
                      </Route>
                      <Route path="/Drop_off_code" element={<PrivateRoute />}>
                        <Route
                          path="/Drop_off_code"
                          element={<DropOffCode />}
                        />
                      </Route>
                      <Route
                        path="/download/:downloadId"
                        element={<DownloadFile />}
                      />
                      <Route path="/Login" element={<LoginPage />} />
                      <Route
                        path="/protectedPage"
                        element={<ProtectedPage />}
                      />
                      <Route path="/*" element={<NotFoundPage />} />
                    </Route>
                  </Route>
                </Route>
              </Routes>
            </AuthProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </SnackbarProvider>
    </DndProvider>
  );
};

export default App;
