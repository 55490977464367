export const PackagingComponent = () => (
  <>
    <div><strong>Please review all guidelines before preparing your samples
      for
      submission.</strong></div>
    <ol>
      <li>
        Ensure that all samples/containers and primers are labelled
        appropriately and their configuration accurately represents that of the
        completed submission form.
      </li>
      <li>
        Protect samples by carefully sealing plate(s) and ensuring that tubes
        are fully closed before submission.
      </li>
      <li>
        Submit your samples and primers, if any, in a small bag, and include a
        slip of paper with your given drop-off code for identification.
      </li>
      <li>
        For samples that are shipped directly to the Core for processing:
        <ul>
          <li>
            Do not place your plate or tubes loosely inside the transport
            container
          </li>
          <li>
            Shipment of samples on ice is recommended
          </li>
        </ul>
      </li>
    </ol>
    <div>
      Sample Storage Policy: Samples will not be returned. All unused samples
      will be discarded 28 days after the data is released.
    </div>
  </>
);