import { Typography } from 'reusable/UI/Typography';

export const MapLabel = ({ char }) => {
  const mapLabelStyle = {
    fontWeight: 500,
  };

  return (
    <td style={mapLabelStyle}>
      <Typography variant="h4" textAlign="right" sx={{ width: 40, pr: 2 }}>
        {char}
      </Typography>
    </td>
  );
};
