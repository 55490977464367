import { Grid } from '@mui/material';
import labImage from 'images/homepage-img10.jpeg';
import FaqButton from './FaqButton';
import './FaqComponent.css';
import { getData } from './FaqData';

const FaqOverview = ({ showFaqCategoryAt, visibility }) => {
  const visibilityClass = visibility
    ? 'faq__overview--hidden'
    : 'faq__overview--shown';

  const data = getData(null);

  return (
    <div className={`faq__overview ${visibilityClass}`}>
      <img
        src={labImage}
        alt="Lab technician operating equipment"
        width={'100%'}
      />
      <div className="faq__grid-container">
        <Grid container spacing={3} justifyContent="center">
          {data.map((category, index) => (
            <Grid item md key={index}>
              <FaqButton onClick={() => showFaqCategoryAt(index)}>
                {category.title}
              </FaqButton>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default FaqOverview;
