import React, { useState, useEffect } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import CheckIcon from '@mui/icons-material/Check';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import ExpandMoreForOneStatus from './ExpandMoreForOneStatus';
import Button from '@mui/material/Button';
import { socket } from 'context/socket';
import { getSpecificOrder } from 'utils/api/Inventory/Inventory';

async function getInitialData({
  setGetLoad,
  setInitialData,
  orderStatus,
  setOrderReceived,
  ordersReceived,
}) {
  if (ordersReceived === false) {
    try {
      const dataToBeUsed = await getSpecificOrder({
        params: { orderStatus: orderStatus },
      });
      setInitialData(dataToBeUsed);
      setGetLoad(false);
      setOrderReceived(true);
    } catch (error) {
      throw error;
    }
  } else {
    //DO Nothing
  }
}

export default function RecipeReviewCard(props) {
  const handleClickOpen = (selectedOrderStatus) => {
    return (event) => {
      props.deliverOrderStatus(selectedOrderStatus);
    };
  };

  const [getLoad, setGetLoad] = useState(true);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const [ordersReceived, setOrderReceived] = useState(false);

  let orderStatus = props.orderStatus;

  useEffect(() => {
    socket.on('updateOthersProcessingOrder', (msg) => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelfProcessingOrder', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateOthers', (msg) => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelf', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateAll', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('connect', () => console.log(socket.id));
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => console.log('Server disconnected'));
  }, []);

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setInitialData,
      orderStatus,
      setOrderReceived,
      ordersReceived,
    }).catch(console.error);
  }, [ordersReceived]);

  const handleClose = () => {
    setSubmitStatus(false);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
        <PropagateLoader />
      </Backdrop>

      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>
      <Card style={{ height: 500 }} sx={{ maxWidth: 350 }}>
        <CardContent>
          {props.orderStatus === 'Need to Order' ? (
            <Button size="small" onClick={handleClickOpen('Need to Order')}>
              <Alert variant="filled" severity="error">
                {initialData.length + ' Need to Order'}
              </Alert>
            </Button>
          ) : props.orderStatus === 'In Stock' ? (
            <Button size="small" onClick={handleClickOpen('In Stock')}>
              <Alert variant="filled" severity="success">
                {initialData.length + ' In Stock'}
              </Alert>
            </Button>
          ) : (
            <Button size="small" onClick={handleClickOpen('Running Low')}>
              <Alert variant="filled" severity="warning">
                {initialData.length + ' Running Low'}
              </Alert>
            </Button>
          )}
        </CardContent>
        {
          <CardContent>
            <ExpandMoreForOneStatus
              inventoryItems={initialData}
              CatologFilter={props.CatologFilter}
            />
          </CardContent>
        }
      </Card>
    </Grid>
  );
}
