import { Button, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { downloadOrder, orderFileExists } from 'utils/api/File';

export const DownloadFileComponent = ({ orderCode, completed }) => {
  const [fileExists, setFileExists] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setFileExists(await orderFileExists(orderCode));
      } catch (error) {
        console.error('Error finding existing files');
      }
    })();
  }, []);

  const download = async () => {
    try {
      const response = await downloadOrder(orderCode);

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'test.zip');
      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <DisableableButton
      disabled={!completed || !fileExists}
      tooltip={
        completed
          ? 'File for order not found'
          : "Order hasn't been completed yet"
      }
      onClick={download}
    >
      Download
    </DisableableButton>
  );
};

const DisableableButton = ({ disabled, tooltip, children, ...props }) => {
  const InternalButton = ({ children, props }) => {
    return <Button {...props}>{children}</Button>;
  };

  return disabled ? (
    <Tooltip title={tooltip}>
      <span>
        <InternalButton props={{ ...props, disabled: true }}>
          {children}
        </InternalButton>
      </span>
    </Tooltip>
  ) : (
    <InternalButton props={props}>{children}</InternalButton>
  );
};
