import './Form.css';

export default function FormInput(props) {
  return (
    <div className="form__input-container">
      <label className="form__label" htmlFor={props.id}>
        {props.label}
      </label>
      <input
        className="form__input"
        type={props.type}
        id={props.id}
        onChange={props.onChange}
      ></input>
    </div>
  );
}
