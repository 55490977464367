import { useState } from 'react';
import SampleListView from './SampleListView';

import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

const SampleListViewRerun = (props) => {
  const orderCode = props.orderCode;
  const orderRerunType = props.orderRerunType;
  const title = props.buttonTitle;
  async function createRerun(samples) {
    const res = await axios.post(API_ENDPOINT + '/order/rerun', {
      orderCode: orderCode,
      rerunType: title === 'Internal' ? 'internal' : 'external',
      samples: samples,
    });
  }

  return (
    <SampleListView
      buttonTitle={title}
      orderCode={orderCode}
      selectFunction={createRerun}
      orderRerunType={orderRerunType}
    />
  );
};

export default SampleListViewRerun;
