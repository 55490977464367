import axios from 'axios';

export const downloadFile = async (downloadId) => {
  return await axios.get(`/api/download/${encodeURIComponent(downloadId)}`, {
    responseType: 'arraybuffer',
  });
};

export const downloadOrder = async (orderCode) => {
  return await axios.get(`/api/download/order/${orderCode}`, {
    responseType: 'arraybuffer',
  });
};

export const getDownloadInfo = async (downloadId) => {
  try {
    const res = await axios.get(`/api/download/info/${downloadId}`);
    return { status: 'success', data: res.data };
  } catch (error) {
    console.error('Error getting download info', error.message);
    if (error.response.status === 404) {
      console.log(404);
      return { status: 'not found', message: 'download not found' };
    } else {
      return { status: 'error', message: 'internal server error' };
    }
  }
};

export const orderFileExists = async (orderCode) => {
  try {
    const res = await axios.get(`/api/download/info/order/${orderCode}`);
    console.log(res.data);
    return res.data.exists;
  } catch (error) {
    return { status: 'error', message: 'internal server error' };
  }
};
