import axios from 'axios';

const createOrder = async (values) => {
  try {
    const response = await axios.post(`/api/order/newAdminOrder`, values);
    return response;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

const createAdminOrder = async (isFilePicked, formData, user, values) => {
  try {
    const response = await axios({
      method: 'post',
      url: isFilePicked
        ? '/api/order/newAdminOrderWithFile'
        : '/api/order/newAdminOrder',
      data: formData,
      params: {
        username: user.username,
        submissionType: values.submissionType,
        sangerNoOfSamples: values.sangerNoOfSamples,
        fragmentNoOfSamples: values.fragmentNoOfSamples,
      },
    });
    return response;
  } catch (error) {
    console.error('Error creating admin order:', error);
    throw error;
  }
};

const getOrder = async (params = {}) => {
  try {
    const response = await axios.get(`/api/order`, {
      params: { ...params },
    });
    return response.data;
  } catch (error) {
    console.log('Error getting order:', error);
    throw error;
  }
};

const getActiveOrders = async (params = {}) => {
  try {
    const response = await axios.get(`/api/order/active`, {
      params: { ...params },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching active orders:', error);
    throw error;
  }
};

const getCompletedOrders = async () => {
  try {
    const response = await axios.get(`/api/order/completed`);
    let dataToBeUsed = [];
    response.data.forEach((element) => {
      let oneRow = {};
      Object.keys(element).forEach((el) => {
        if (el == '__v') {
        } else {
          oneRow = { ...oneRow, [el]: element[el] };
        }
      });
      dataToBeUsed = [...dataToBeUsed, oneRow];
    });
    return dataToBeUsed;
  } catch (error) {
    console.error('Error fetching completed orders:', error);
    throw error;
  }
};

const updateOrder = async (values, orderCode, stage, noEmail) => {
  try {
    const response = await axios({
      url: '/api/order/save',
      method: 'PUT',
      data: values,
      params: {
        orderCode: orderCode,
        stage: stage,
        shouldEmail: !noEmail,
      },
    });
    return response;
  } catch (error) {
    console.error('Error updating order:', error);
    throw error;
  }
};

const updateOrderStage = async (values, orderCode, stage, noEmail) => {
  try {
    const response = await axios({
      url: '/api/order/submit',
      method: 'PUT',
      data: values,
      params: {
        orderCode: orderCode,
        stage: stage,
        noEmail: noEmail,
      },
    });
    return response;
  } catch (error) {
    console.error('Error updating order:', error);
    throw error;
  }
};

const deleteOrder = async (orderId) => {
  try {
    const response = await axios.delete(`/api/order/deleteAdminOrder`, {
      params: { id: orderId },
    });
    return response;
  } catch (e) {
    console.error('Error deleting order:', e);
    throw e;
  }
};

const moveOrder = async (order, stage) => {
  await axios({
    url: '/api/order/moveOrder',
    method: 'PUT',
    params: {
      orderCode: order.orderCode,
      stage: stage,
      isComboRun: order.isComboRun,
      comboRunNo: order.comboRunNo,
      currentStage: order.stage,
    },
  });
};

export {
  createOrder,
  createAdminOrder,
  getActiveOrders,
  getCompletedOrders,
  moveOrder,
  getOrder,
  updateOrder,
  updateOrderStage,
  deleteOrder,
};
