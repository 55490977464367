import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import { socket } from 'context/socket';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';

import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from 'react-spinners/RotateLoader';

import { updateCreatedProcessingOrder } from 'utils/api/Inventory/ProcessingOrder/ProcessingOrder';

export default function PopupUpdate(props) {
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);

  const validationSchema = Yup.object({
    orderID: Yup.string()
      .max(100, "Can't be larger than 100 characters")
      .min(1, 'Must be at least 1 character'),
    trackingNumber: Yup.number().integer('Must be a integer'),
    quantity: Yup.number()
      .positive('Must be more than 0')
      .integer('Must be a integer'),
    notes: Yup.string()
      .max(500, "Can't be larger than 500 characters")
      .min(1, 'Must be at least 1 character'),
  });

  const formik = useFormik({
    initialValues: props.products,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitLoad(true);
      try {
        var status = await updateCreatedProcessingOrder(values);
        if (status === 201) {
          socket.emit('updateEverythingProcessingOrder', values.orderID);
          setSubmitSucess(true);
          setSubmitResponse('Update sucessfully!');
        } else if (status === 202) {
          setSubmitSucess(false);
          setSubmitResponse('order ID already exist, please input another one');
        }
      } catch {
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      setSubmitStatus(true);
      setSubmitLoad(false);
    },
  });

  const handleClose = () => {
    setSubmitStatus(false);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>

      <form onSubmit={formik.handleSubmit}>
        <List
          sx={{
            width: '100%',
            maxWidth: 800,
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 600,
          }}
        >
          <ListItem alignItems="flex-start">
            <ListItemText primary={`Order ID:`} />
            <TextField
              id="orderID"
              name="orderID"
              label="Order ID"
              value={formik.values.orderID}
              onChange={formik.handleChange}
              error={formik.touched.orderID && Boolean(formik.errors.orderID)}
              helperText={formik.touched.orderID && formik.errors.orderID}
            />
          </ListItem>

          <Divider component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText primary={`Tracking Number:`} />

            <TextField
              id="trackingNumber"
              name="trackingNumber"
              label="Tracking Number"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.trackingNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.trackingNumber &&
                Boolean(formik.errors.trackingNumber)
              }
              helperText={
                formik.touched.trackingNumber && formik.errors.trackingNumber
              }
            />
          </ListItem>

          <Divider component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText primary={`Quantity:  `} />
            <TextField
              id="quantity"
              name="quantity"
              label="Quantity"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.quantity}
              onChange={formik.handleChange}
              error={formik.touched.quantity && Boolean(formik.errors.quantity)}
              helperText={formik.touched.quantity && formik.errors.quantity}
            />
          </ListItem>

          <Divider component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText primary={`Notes:`} />
            <TextField
              id="notes"
              name="notes"
              label="Notes"
              multiline
              value={formik.values.notes}
              onChange={formik.handleChange}
              error={formik.touched.notes && Boolean(formik.errors.notes)}
              helperText={formik.touched.notes && formik.errors.notes}
            />
          </ListItem>

          <Divider component="li" />
          <ListItem alignItems="flex-start">
            <LoadingButton
              size="large"
              classes="profileSubmit"
              type="submit"
              variant="outlined"
              // onClick={handleClick}
              loading={submitLoad}
              loadingIndicator={
                <RotateLoader
                  cssOverride={{ left: '100%' }}
                  size={7}
                  margin={-15}
                />
              }
            >
              Submit
            </LoadingButton>
          </ListItem>
        </List>
      </form>
    </Grid>
  );
}
