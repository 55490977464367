import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from 'react-spinners/RotateLoader';
import { socket } from 'context/socket';
import { updateInventory } from 'utils/api/Inventory/Inventory';
import { ClickAwayListener } from '@mui/material';

export default function TotalQuantityRender(props) {
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [displayQSaveButton, setDisplayQSaveButton] = useState(false);
  let thisinventory = props.thisinventory;

  const handleClickAway = () => {
    setDisplayQSaveButton(false);
  };

  const handleClose = () => {
    setSubmitStatus(false);
  };

  const validationSchema = Yup.object({
    totalQuantity: Yup.number()
      .positive('Must be more than 0')
      .integer('Must be a integer'),
  });

  const formik = useFormik({
    initialValues: thisinventory,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitLoad(true);
      try {
        var status = await updateInventory(values);
        if (status === 201) {
          socket.emit('updateInventory', values.catologNumber);
          setSubmitSucess(true);
          setSubmitResponse('Update sucessfully!');
        } else if (status === 202) {
          setSubmitSucess(false);
          setSubmitResponse(
            'catolog number already exist, please input another one'
          );
        }
      } catch {
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      setSubmitStatus(true);
      setDisplayQSaveButton(false);
      setSubmitLoad(false);
    },
  });

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>
      <ClickAwayListener onClickAway={handleClickAway}>
        <form onSubmit={formik.handleSubmit}>
          <>
            <TextField
              id="totalQuantity"
              name="totalQuantity"
              type="number"
              size="small"
              sx={{
                width: 70,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.totalQuantity}
              onChange={formik.handleChange}
              error={
                formik.touched.totalQuantity &&
                Boolean(formik.errors.totalQuantity)
              }
              helperText={
                formik.touched.totalQuantity && formik.errors.totalQuantity
              }
              onClick={() => {
                setDisplayQSaveButton(true);
              }}
            />
            <br></br>
            <br></br>
            {displayQSaveButton ? (
              <>
                <LoadingButton
                  size="small"
                  classes="profileSubmit"
                  type="submit"
                  variant="outlined"
                  loading={submitLoad}
                  loadingIndicator={
                    <RotateLoader
                      cssOverride={{ left: '100%' }}
                      size={7}
                      margin={-15}
                    />
                  }
                >
                  Submit
                </LoadingButton>
                <br></br>
                <br></br>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setDisplayQSaveButton(false);
                  }}
                  size="small"
                >
                  {' '}
                  Cancel
                </Button>
              </>
            ) : (
              <></>
            )}
          </>
        </form>
      </ClickAwayListener>
    </Grid>
  );
}
