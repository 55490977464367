import { memo, useContext } from 'react';
import TextField from '@mui/material/TextField';
import { DebounceInput } from 'react-debounce-input';
import InputAdornment from '@mui/material/InputAdornment';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { PrimerMethodContext } from '../../../contexts/MethodContext';
import { ErrorVisContext } from '../../../contexts/DataContext';

const SangerPrimerRowVol = memo(
  ({ data, primerKey }) => {
    const { updatePrimerMap } = useContext(PrimerMethodContext);

    const { showAllErrors, submitShowError } = useContext(ErrorVisContext);

    return (
      <>
        {data.labPrimer ? (
          <AllInclusiveIcon
            sx={{
              position: 'absolute',
              marginTop: 1,
              marginLeft: 2,
            }}
          />
        ) : (
          <></>
        )}
        <DebounceInput
          debounceTimeout={500}
          minLength={1}
          element={TextField}
          id="vol"
          placeholder={data.labPrimer ? '' : 'Volume'}
          disabled={data.labPrimer}
          variant="outlined"
          sx={{
            '& .MuiInputBase-root': { height: 40, borderRadius: 0, width: 100 },
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
          }}
          InputProps={{
            inputmode: 'numeric',
            pattern: '[0-9]*',
            endAdornment: (
              <InputAdornment
                style={{ size: 10, marginLeft: -11, marginRight: -10 }}
                position="end"
              >
                µl
              </InputAdornment>
            ),
          }}
          value={data.vol}
          onChange={async (e) => {
            var newTouched = [...data.touched];
            newTouched[1] = true;
            updatePrimerMap(primerKey, {
              vol: e.target.value,
              touched: newTouched,
            });
          }}
          onBlur={(e) => {
            var newTouched = [...data.touched];
            newTouched[1] = true;
            updatePrimerMap(primerKey, { touched: newTouched });
          }}
          error={(data.touched[1] || submitShowError) && showAllErrors}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id && prevProps.data.vol === nextProps.data.vol
    );
  }
);

export default SangerPrimerRowVol;
