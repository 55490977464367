import Box from '@mui/material/Box';
import { Typography } from 'reusable/UI/Typography';
import { Divider } from 'reusable/UI/Divider';

export const SangerFormHeading = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h1">Sanger Sequencing Order</Typography>
      <Divider />
      <Typography variant="h2">
        Before adding data, please read each section below
      </Typography>
    </Box>
  );
};
