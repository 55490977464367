import { Box } from '@mui/material';
import { useState } from 'react';
import { CustomLink } from './CustomLink';

const priceColor = 'rgb(125, 170, 185)';
const blueTextColor = 'rgb(0, 48, 87)';
const lightBlueTextColor = 'rgb(0, 138, 252)';
const titleBackgroundColor = 'rgb(233, 235, 230)';
const detailBackgroundColor = 'rgb(246, 249, 245)';

export const Rectangle = ({ imageSrc, title, description, linkTo }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <CustomLink to={linkTo}>
      <Box
        display="flex"
        width="80vw"
        height="40vh"
        borderColor={blueTextColor}
        border={2}
        mb={2}
        onMouseEnter={() => setIsHovered(true)} // Set isHovered to true when mouse enters
        onMouseLeave={() => setIsHovered(false)} // Set isHovered to false when mouse leaves
        sx={{
          '&:hover': {
            bgcolor: priceColor,
            borderColor: lightBlueTextColor,
            borderWidth: 3,
            '& h3': { color: detailBackgroundColor },
            '& p': { color: titleBackgroundColor },
          },
          transition: 'background-color 0.3s, border-color 0.3s',
        }}
      >
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor={isHovered ? priceColor : titleBackgroundColor}
          width="40%"
          height="100%"
        >
          <img
            src={imageSrc}
            alt={title}
            style={{
              width: '100%',
              height: '100%',
              opacity: isHovered ? '25%' : '100%',
            }}
          />
        </Box>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          pl={2}
          bgcolor={isHovered ? priceColor : detailBackgroundColor}
        >
          <h3
            style={{
              color: isHovered ? detailBackgroundColor : blueTextColor,
              fontSize: 65,
              margin: 0,
            }}
          >
            {title}
          </h3>
          <p
            style={{ color: isHovered ? detailBackgroundColor : blueTextColor }}
          >
            {description}
          </p>
        </Box>
      </Box>
    </CustomLink>
  );
};
