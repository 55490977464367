import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OrderAutocomplete from './OrderAutocomplete';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import RotateLoader from 'react-spinners/RotateLoader';
import Backdrop from '@mui/material/Backdrop';
import { useSnackbar } from 'notistack';
import {
  getAllActiveComboRuns,
  getUnmergedOrdersInCR,
  mergeCR,
} from 'utils/api/ComboRun';

const MergeCRPopup = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [comboRuns, setComboRuns] = useState([]);
  const [currentCR, setCurrentCR] = useState(0);
  const [unMergedOrders, setUnMergedOrders] = useState([]);
  const [pickedOrders, setPickedOrders] = useState([]);
  const [basePlate, setBasePlate] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentView, setCurrentView] = useState('default'); // 'default', 'mergeOrdersCR', etc.
  const [submitLoad, setSubmitLoad] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setCurrentView('default'); // reset the view when closing the popup
  };

  const handleCRClick = (comboRunNo) => {
    setCurrentView('mergeOrdersCR');
    getUnMergedOrdersInCR(comboRunNo);
  };

  async function getComboRuns() {
    setLoading(true);
    const res = await getAllActiveComboRuns();
    if (res.status === 200) {
      const comboRunOrders = res.data;
      setComboRuns(comboRunOrders);
    }
    setLoading(false);
  }

  async function getUnMergedOrdersInCR(comboRunNo) {
    setLoading(true);
    setCurrentCR(comboRunNo);
    const res = await getUnmergedOrdersInCR(comboRunNo);
    if (res.status === 200) {
      console.log(res.data);
      setUnMergedOrders(res.data);
    }
    setLoading(false);
  }

  async function mergeOrdersInCr() {
    setLoading(true);
    var pickedPlates = [];
    for (let i in pickedOrders) {
      pickedPlates.push(pickedOrders[i].orderCode);
    }
    console.log('MERGE ORDERS');
    console.log(pickedPlates);
    const res = await mergeCR(basePlate, pickedPlates);
    if (res.status == 200) {
      setCurrentView('default');
      enqueueSnackbar(
        `Merged successfuly! New Plate is ${res.data.orderCode}`,
        { variant: 'success' }
      );
    }
    setLoading(false);
  }

  useEffect(() => {
    setPickedOrders([]);
    const newOptions = unMergedOrders.filter(
      (order) => order.orderCode !== basePlate
    );
    setUnMergedOrders(newOptions);
  }, [basePlate]);

  const handleChange = (event, newValues) => {
    console.log(newValues);
    setPickedOrders(newValues);
  };

  useEffect(() => {
    getComboRuns();
    setPickedOrders([]);
    setUnMergedOrders([]);
    setBasePlate('');
  }, [isOpen]);

  return (
    <div>
      <Button color="primary" onClick={handleOpen} style={{ all: 'unset' }}>
        Merge CR
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <Backdrop open={loading}>
          <RotateLoader />
        </Backdrop>
        <DialogTitle>Merge combo run orders</DialogTitle>
        <DialogContent>
          <Grid container style={{ minWidth: '70vh', margin: 'auto' }}>
            {currentView === 'default' && (
              <>
                <Alert severity="info" style={{ marginBottom: 20 }}>
                  This is where you can merge orders within the same combo run
                  onto a singular plate. Please choose a combo run first. Only
                  current active combo runs can be selected
                </Alert>
                <Alert severity="warning" style={{ marginBottom: 20 }}>
                  Until we make sure this works in real-time, please make sure
                  only one person is merging orders at a single time, and always
                  reopen this popup to reload the latest data so nothing
                  crashes. Thanks
                </Alert>
              </>
            )}
            {currentView === 'default' &&
              comboRuns.map((combo, i) => (
                <>
                  <Grid key={i} item xs={4} align="center">
                    <Button
                      disabled={loading}
                      variant="contained"
                      style={{ marginBottom: 5 }}
                      onClick={() => handleCRClick(combo.comboRunNo)}
                    >
                      CR{combo.comboRunNo}
                    </Button>
                  </Grid>
                </>
              ))}
            {currentView === 'mergeOrdersCR' &&
              (unMergedOrders.length <= 1 ? (
                <>
                  All orders seem to have been merged onto a singular plate for
                  CR{currentCR}
                </> // or replace with some JSX you want to render when there's 1 or 0 orders
              ) : (
                <>
                  <b>Choose a base plate</b>, this will be the plate that others
                  are merged INTO
                  <br></br>
                  <br></br>
                  <Grid xs={12}>
                    {unMergedOrders.map((order, i) => (
                      <Grid item xs={3}>
                        <Button
                          variant="contained"
                          style={{ marginBottom: 5 }}
                          disabled={loading}
                          onClick={() => {
                            setCurrentView('basePlatePicked');
                            setBasePlate(order.orderCode);
                          }}
                        >
                          {order.orderCode}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ))}
            {currentView === 'basePlatePicked' && (
              <>
                <Grid item xs={2.5}>
                  Base Plate:
                  <br></br>
                  <b>{basePlate}</b>
                </Grid>
                <Grid xs={2.5}>
                  <ArrowBackIcon />
                </Grid>
                <Grid xs={7}>
                  <OrderAutocomplete
                    value={pickedOrders}
                    onChange={(event, value) => handleChange(event, value)}
                    open={open}
                    setOpen={setOpen} // This prop assumes that the OrderAutocomplete component accepts this prop to handle open state changes
                    submitLoad={submitLoad}
                    options={unMergedOrders}
                    loading={loading}
                    // error={}
                    // helperText={}
                  />
                </Grid>
                <Grid>
                  <LoadingButton
                    size="medium"
                    type="submit"
                    variant="outlined"
                    onClick={() => mergeOrdersInCr()}
                    loading={loading}
                    // loading={submitLoad}
                    // disabled={saveLoad || adminOnly}
                    loadingIndicator={
                      <RotateLoader
                        cssOverride={{ left: '100%' }}
                        size={7}
                        margin={-15}
                      />
                    }
                  >
                    Merge Orders
                  </LoadingButton>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {currentView === 'mergeOrdersCR' && (
            <Button
              onClick={() => {
                setCurrentView('default');
                getComboRuns();
              }}
              color="primary"
            >
              Back
            </Button>
          )}
          {currentView === 'basePlatePicked' && (
            <Button
              onClick={() => {
                setCurrentView('mergeOrdersCR');
                getUnMergedOrdersInCR(currentCR);
              }}
              color="primary"
            >
              Back
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MergeCRPopup;
