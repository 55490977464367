export const universities = [
  { title: 'Augusta University', abbr: 'AU' },
  { title: 'Clark Atlanta University', abbr: 'CU' },
  { title: 'Emory University', abbr: 'EU' },
  { title: 'Georgia Institute of Technology', abbr: 'GT' },
  { title: 'Georgia State University', abbr: 'GSU' },
  { title: 'Mercer University', abbr: 'MU' },
  { title: 'Morehouse College', abbr: 'MC' },
  { title: 'University of Georgia', abbr: 'UGA' },
];
