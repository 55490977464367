import { createTheme, ThemeProvider } from '@mui/material';
import MuiTextField from '@mui/material/TextField';

const textFieldTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {},
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: 'solid 1px ',
            borderRadius: 4,
          },
        },
        {
          props: { size: 'large' },
          style: {
            width: '100%',
          },
        },
      ],
    },
  },
});

export const TextField = ({ children, ...props }) => (
  <ThemeProvider theme={textFieldTheme}>
    <MuiTextField {...props}>{children}</MuiTextField>
  </ThemeProvider>
);
