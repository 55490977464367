import axios from 'axios';

const createPI = async (values) => {
  try {
    const res = await axios.post('/api/pi/add', values);
    return res;
  } catch (error) {
    console.log('Error creating researcher:', error);
    throw error;
  }
};

const getPIResearchers = async (user) => {
  return await axios.get('/api/pi/researchers', {
    params: {
      username: user.casUser, // search for users with casUser as PI
    },
  });
};

const getAllPIs = async () => {
  try {
    const res = await axios.get('/api/pi/all');
    let dataToBeUsed = [];
    res.data.forEach((element) => {
      let oneRow = {};
      Object.keys(element).forEach((el) => {
        if (el == '__v') {
        } else {
          oneRow = { ...oneRow, [el]: element[el] };
        }
      });
      dataToBeUsed = [...dataToBeUsed, oneRow];
    });
    return dataToBeUsed;
  } catch (error) {
    console.log('Error getting all PIs:', error);
    throw error;
  }
};

const getPIByUniversity = async (universityName) => {
  return await axios({
    url: '/api/pi/university',
    method: 'GET',
    params: {
      university: universityName,
    },
  });
};

const getResearcher = async (piid) => {
  try {
    const res = await axios({
      url: '/api/pi/researchersByID',
      method: 'GET',
      params: {
        piid: piid,
      },
    });
    return res;
  } catch (error) {
    console.log('Error getting researcher:', error);
    throw error;
  }
};

const updatePI = async (values) => {
  try {
    const response = await axios.put('/api/pi/edit', values);
    return response;
  } catch (error) {
    console.log('Error deleting PI:', error);
    throw error;
  }
};

const updatePIAssignment = async (values) => {
  try {
    const response = await axios.put('/api/pi/reassign', values);
    return response;
  } catch (error) {
    console.log('Error updating PI assignment PI:', error);
    throw error;
  }
};

const deletePI = async (PI) => {
  try {
    const response = await axios.delete('/api/pi/delete', {
      data: PI,
    });
    return response;
  } catch (error) {
    console.log('Error deleting PI:', error);
    throw error;
  }
};

export {
  createPI,
  getAllPIs,
  getResearcher,
  getPIResearchers,
  updatePI,
  deletePI,
  updatePIAssignment,
  getPIByUniversity,
};
