import { React } from 'react';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import FragmentRowName from './FragmentRowComponents/FragmentRowName';
import FragmentRowVol from './FragmentRowComponents/FragmentRowVol';
import FragmentRowType from './FragmentRowComponents/FragmentRowType';
import FragmentRowSizeStandard from './FragmentRowComponents/FragmentRowSizeStandard';
import FragmentRowDyeSet from './FragmentRowComponents/FragmentRowDyeSet';
import FragmentRowRunModule from './FragmentRowComponents/FragmentRowRunModule';
import SangerRowWell from '../SangerForm/SampleComponents/SangerRowWell';
import FragmentRowErrors from './FragmentRowComponents/FragmentRowErrors';
import { Grid } from '@mui/material';
import { IconButton } from 'reusable/UI/IconButton';

const FragmentRowComponent = ({
  data,
  index,
  updateFragment,
  removeFragment,
  addNewFragmentIndex,
  submitShowError,
  showAllErrors,
  formik,
  id,
}) => {
  function isNumeric(str) {
    return /^\+?\d+$/.test(str);
  }

  function errorName(string) {
    return (
      string.length > 70 || string.trim().length === 0 || string.length <= 5
    );
  }

  function errorVol(vol) {
    return (
      vol == null || vol == '' || !isNumeric(vol) || (vol >= 20 && vol <= 40)
    );
  }

  return (
    <div key={data.id}>
      <Grid
        container
        style={{
          paddingRight: 7,
          paddingLeft: 10,
          marginTop: 0,
          paddingTop: 0,
          marginBottom: 0,
        }}
      >
        <SangerRowWell index={index} />
        <FragmentRowName
          id={data.id}
          data={data}
          index={index}
          updateFragment={updateFragment}
          submitShowError={submitShowError}
          errorName={errorName}
        />
        <FragmentRowVol
          id={data.id}
          data={data}
          index={index}
          updateFragment={updateFragment}
          submitShowError={submitShowError}
          errorVol={errorVol}
        />

        <FragmentRowType
          id={data.id}
          data={data}
          index={index}
          updateFragment={updateFragment}
          submitShowError={submitShowError}
        />

        <FragmentRowSizeStandard
          id={data.id}
          data={data}
          index={index}
          updateFragment={updateFragment}
          submitShowError={submitShowError}
        />

        <FragmentRowDyeSet
          id={data.id}
          data={data}
          index={index}
          updateFragment={updateFragment}
          submitShowError={submitShowError}
        />

        <FragmentRowRunModule
          id={data.id}
          data={data}
          index={index}
          updateFragment={updateFragment}
          submitShowError={submitShowError}
        />

        <Grid>
          <IconButton onClick={() => addNewFragmentIndex(id)}>
            <AddCircleIcon />
          </IconButton>
        </Grid>

        <Grid>
          <IconButton onClick={() => removeFragment(id)}>
            <RemoveCircle />
          </IconButton>
        </Grid>
        <FragmentRowErrors
          id={data.id}
          data={data}
          index={index}
          updateFragment={updateFragment}
          submitShowError={submitShowError}
          showAllErrors={showAllErrors}
          formik={formik}
          isNumeric={isNumeric}
          errorName={errorName}
          errorVol={errorVol}
        />
      </Grid>
    </div>
  );
};

export default FragmentRowComponent;
