import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import RotateLoader from 'react-spinners/RotateLoader';
import { getResearcher } from 'utils/api/Pi';
import { createUser } from 'utils/api/User';

const filter = createFilterOptions();

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

async function getResearchers(
  setResearchers,
  setResearchersReceived,
  researchersReceived,
  piid
) {
  if (researchersReceived === false) {
    try {
      const res = await getResearcher(piid);
      const data = res.data;
      if (data.length === 0) {
        data.push(['', 'No options']);
      }
      var employees = data.map((item) => ({
        username: item[0],
        fullName: item[1],
      }));
      var researchers = employees.map((option) => {
        const fullName = option.fullName.split(' ');
        const firstLetter = fullName[fullName.length - 1][0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
      });
      setResearchers(researchers);
      setResearchersReceived(true);
    } catch (error) {
      console.log(error);
    }
  }
}

export default function ResearcherSelector({
  formikProps,
  setFieldValue,
  disabled,
  researcherExists,
}) {
  const [researchersReceived, setResearchersReceived] = useState(false);
  const [open, setOpen] = useState(false);
  const [loaderOpen, setLoaderOpen] = useState(false);
  const [researchers, setResearchers] = useState([]);
  const loading = loaderOpen && researchers.length === 0;

  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.
      await getResearchers(
        setResearchers,
        setResearchersReceived,
        researchersReceived,
        formikProps.values.piid
      );

      if (active) {
        setResearchers([...researchers]);
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setResearchers([]);
      setResearchersReceived(false);
    }
  }, [loaderOpen]);

  const validationSchema = Yup.object({
    researcherFirst: Yup.string()
      .max(20, 'No more than 20 characters')
      .min(1, 'Must be at least one character')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
      .required('Required'),
    researcherLast: Yup.string()
      .max(20, 'No more than 20 characters')
      .min(1, 'Must be at least one character')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      researcherFirst: '',
      researcherLast: '',
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const username = values.email.split('@')[0];
        const newUser = {
          username: username,
          firstName: values.researcherFirst,
          lastName: values.researcherLast,
          email: values.email,
          phoneNumber: '',
          SUMSworktag: '',
          PIName: formikProps.values.PIName,
          university: formikProps.values.university,
          universityTag: formikProps.values.universityTag,
          piid: formikProps.values.piid,
        };
        await createUser(newUser);
        setFieldValue(
          'researcherName',
          `${values.researcherFirst} ${values.researcherLast}`
        );
        setFieldValue('username', values.email.split('@')[0]);
        setFieldValue('email', values.email);
        handleClose();
      } catch (error) {
        console.error('Error adding user:', error);
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Autocomplete
        disabled={disabled || researcherExists === true}
        open={loaderOpen}
        onOpen={() => {
          setLoaderOpen(true);
        }}
        onClose={() => {
          setLoaderOpen(false);
        }}
        value={formikProps.values.reseacherName}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              setOpen(true);
            });
          } else if (newValue && newValue.inputValue) {
            setOpen(true);
          } else {
            if (newValue === null) {
              setFieldValue('username', '');
              setFieldValue('researcherName', '');
            } else {
              setFieldValue('username', newValue.username);
              setFieldValue('researcherName', newValue.fullName);
            }
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              fullName: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        id="reseacherName"
        options={researchers.sort(
          (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
        )}
        loading={loading}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.fullName;
        }}
        getOptionDisabled={(option) => option.fullName === 'No options'}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        groupBy={(option) => option.firstLetter}
        renderOption={(props, option) => <li {...props}>{option.fullName}</li>}
        sx={{ width: 281 }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={
              formik.touched.reseacherName &&
              Boolean(formik.errors.reseacherName)
            }
            helperText={
              formik.touched.reseacherName && formik.errors.reseacherName
            }
            type="textPI"
            placeholder="Search or add researcher*"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <RotateLoader color="black" size={5} margin={-19} />
                  ) : (
                    <>{formikProps.values.reseacherName}</>
                  )}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Add a New Researcher</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Is the researcher not included in the dropdown list? Please add
              them.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="researcherFirst"
              name="researcherFirst"
              value={formik.values.researcherFirst}
              onChange={formik.handleChange}
              label="First Name"
              type="text"
              variant="standard"
              error={
                formik.touched.researcherFirst &&
                Boolean(formik.errors.researcherFirst)
              }
              helperText={
                formik.touched.researcherFirst && formik.errors.researcherFirst
              }
            />
            <TextField
              margin="dense"
              id="researcherLast"
              name="researcherLast"
              value={formik.values.researcherLast}
              onChange={formik.handleChange}
              label="Last Name"
              type="text"
              variant="standard"
              error={
                formik.touched.researcherLast &&
                Boolean(formik.errors.researcherLast)
              }
              helperText={
                formik.touched.researcherLast && formik.errors.researcherLast
              }
            />
            <TextField
              margin="dense"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              label="Email"
              type="email"
              variant="standard"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
