import { getActiveOrders } from 'utils/api/Orders';
import {
  getSampleMap,
  updateSampleMap as updateSampleMapApi,
} from 'utils/api/SampleMap';
import getOrderCode from 'utils/orderCode';

/**
 * useSampleMapApi is a hook that provides functions to interact with the SampleMap backend API
 *
 * @returns {object} functions - callable functions used to interact with the backend
 */
export const useSampleMapApi = () => {
  const updateSampleMap = updateSampleMapApi;
  // keeping function here in case extra functionality needs to be added

  const setMapData = async (
    id,
    setSamples,
    setPrimers,
    setOrders,
    setCartridge,
    setOrderCode,
    setColorMap,
    setNotes,
    setInstrument,
    setBlock
  ) => {
    const sampleMap = await getSampleMap(id);
    const activeOrders = await getActiveOrders();
    const order = activeOrders.find((order) => order.sampleMap === id);

    const map = {};
    map['blue'] = undefined;
    map['green'] = undefined;
    map['yellow'] = undefined;
    map['orange'] = undefined;
    let i = 0;
    for (const key in map) {
      if (i >= sampleMap.primers.length) {
        break;
      }
      map[key] = sampleMap.primers[i]._id;
      i += 1;
    }

    sampleMap.samples.sort((a, b) =>
      a.well < b.well ? -1 : a.well > b.well ? 1 : 0
    );
    setSamples(sampleMap.samples);
    setPrimers(sampleMap.primers);
    setOrders(sampleMap.orders);
    setCartridge(sampleMap.cartridge ? sampleMap.cartridge._id : null);
    setInstrument(sampleMap.instrument ? sampleMap.instrument : 'SeqStudio');
    setBlock(sampleMap.block);
    setOrderCode(getOrderCode(order));
    setColorMap(map);
    setNotes(sampleMap.notes);
  };

  return {
    getSampleMap,
    updateSampleMap,
    setMapData,
  };
};
