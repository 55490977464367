import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { socket } from 'context/socket';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import DeleteLogDateRange from './DeleteLogDateRange';
import { archiveLog } from 'utils/api/Inventory/Log/DeleteLogDateRange';

// uses the "dialog" component on the MUI website
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DeleteLog(props) {
  const [open, setOpen] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  let displayCatologNumber = props.whichLog === 'the Whole Inventory';
  let inventoryItem;
  if (!displayCatologNumber) {
    inventoryItem = props.products._id;
  }

  async function handleDeleteLog() {
    setSubmitLoad(true);
    var values = { from: new Date('August 19, 1975 23:15:30') };
    if (props.hideRange == 'ALL LOGS') {
      values = { ...values, to: new Date() };
    } else if (props.hideRange == 'LOGS AFTER 30 DAYS') {
      let today = new Date();
      today.setDate(today.getDate() - 30);
      values = { ...values, to: today };
    } else if (props.hideRange == 'LOGS AFTER 60 DAYS') {
      let today = new Date();
      today.setDate(today.getDate() - 60);
      values = { ...values, to: today };
    } else {
      let today = new Date();
      today.setDate(today.getDate() - 90);
      values = { ...values, to: today };
    }
    try {
      if (!displayCatologNumber) {
        values = { ...values, _id: inventoryItem };
      }
      const status = await archiveLog(values, displayCatologNumber);
      if (status === 201) {
        socket.emit('deletelog', values._id, values.from, values.to);
        setSubmitSucess(true);
        setSubmitResponse('Archived Log Sucessfully!');
      }
    } catch {
      setSubmitSucess(false);
      setSubmitResponse(
        'Unsucessful! Please try again later or contact adminsitrator if problem persists'
      );
    }
    setSubmitStatus(true);
    setSubmitLoad(false);
  }

  const handleCloseS = () => {
    setSubmitStatus(false);
  };

  const handleClickOpen = () => {
    setOpen(true);

    if (
      props.hideRange == 'ALL LOGS' ||
      props.hideRange == 'LOGS AFTER 30 DAYS' ||
      props.hideRange == 'LOGS AFTER 90 DAYS' ||
      props.hideRange == 'LOGS AFTER 60 DAYS'
    ) {
      handleDeleteLog();
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: 1 }}
          open={submitStatus}
          onClick={handleCloseS}
        >
          {submitSucess ? (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              {submitResponse}
            </Alert>
          ) : (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              severity="error"
            >
              {submitResponse}
            </Alert>
          )}
        </Backdrop>

        {props.hideRange == 'ALL LOGS' ||
        props.hideRange == 'LOGS AFTER 30 DAYS' ||
        props.hideRange == 'LOGS AFTER 60 DAYS' ||
        props.hideRange == 'LOGS AFTER 90 DAYS' ? (
          <Button size="large" variant="outlined" onClick={handleClickOpen}>
            {'ARCHIVE ' + props.hideRange}
          </Button>
        ) : (
          <div>
            <DeleteLogDateRange
              hideRange={props.hideRange}
              products={props.products}
              whichLog={props.whichLog}
            />
          </div>
        )}
      </Grid>
    </div>
  );
}
