import React, { useState } from 'react';
import { Form, Formik, useField } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from 'react-spinners/RotateLoader';
import { editCartridge } from 'utils/api/Cartridges';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  float: 'left',
}));

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="formError">{meta.error}</div>
      ) : null}
    </>
  );
};

const IDTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [value, setValue] = useState(field.value);

  const handleInputChange = (e) => {
    const input = e.target.value;
    let formattedValue = input;

    if (input.length > 6 && !input.includes('-')) {
      formattedValue = `${input.slice(0, 6)}-${input.slice(6)}`;
    }

    setValue(formattedValue);
    field.onChange(e);
  };

  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input
        className="text-input"
        {...field}
        {...props}
        value={value}
        onChange={handleInputChange}
      />
      {meta.touched && meta.error ? (
        <div className="formError">{meta.error}</div>
      ) : null}
    </>
  );
};

function formatDate(dateString) {
  return dateString ? dateString.split('T')[0] : undefined;
}

export default function PopupUpdate(props) {
  const [getLoad, setGetLoad] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const idRegExp = /^[0-9- ]+$/;
  const statusOptions = ['good', 'broken'];
  var cartridgeID = props.cartridges.cartridgeID;
  if (cartridgeID.length > 6) {
    cartridgeID = `${cartridgeID.slice(0, 6)}-${cartridgeID.slice(6)}`;
  }

  console.log(props.cartridges);

  const initialData = {
    _id: props.cartridges._id,
    cartridgeID: cartridgeID,
    letter: props.cartridges.letter,
    injections: props.cartridges.injections,
    datePurchased: formatDate(props.cartridges.datePurchased),
    firstUseDate: formatDate(props.cartridges.firstUseDate),
    expirationDate: formatDate(props.cartridges.expirationDate),
    status1: props.cartridges.capillaries[0],
    status2: props.cartridges.capillaries[1],
    status3: props.cartridges.capillaries[2],
    status4: props.cartridges.capillaries[3],
  };

  const handleClose = () => {
    setSubmitStatus(false);
  };

  const validationSchema = Yup.object({
    cartridgeID: Yup.string()
      .min(2, 'Must be at least 2 characters')
      .max(20, "Can't be larger than 20 characters")
      .matches(idRegExp, 'Invalid Cartridge ID')
      .required('Required'),
    letter: Yup.string()
      .max(1, 'No more than 1 character')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed'),
    datePurchased: Yup.date()
      .required('Required')
      .max(new Date(), 'Date of purchase must be in the past'),
    expirationDate: Yup.date().required('Required'),
    capillaries: Yup.array().of(Yup.string()).min(4).max(4),
  });

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
        <PropagateLoader />
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
              window.location.reload(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>
      <Formik
        initialValues={initialData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setSubmitLoad(true);
          try {
            var { status } = await editCartridge(values);
            await sleep(1e3);
            if (status === 202) {
              setSubmitSucess(false);
              setSubmitResponse(
                'Cannot add a duplicate cartridge. Please try another ID.'
              );
              setSubmitStatus(true);
            }
            if (status === 201) {
              setSubmitSucess(true);
              setSubmitResponse('Changes successfully submitted!');
            }
          } catch {
            setSubmitSucess(false);
            setSubmitResponse(
              'Unsuccessful! Please try again later or contact administrator if problem persists'
            );
          }
          setSubmitStatus(true);
          setSubmitLoad(false);
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={0} width={562}>
              <Grid item xs={12} sm={6}>
                <Item>
                  <IDTextInput
                    sx={{ width: 276 }}
                    disabled={submitLoad}
                    label="Cartridge ID"
                    name="cartridgeID"
                    type="text"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    sx={{ width: 100 }}
                    disabled={submitLoad}
                    label="Assigned Letter ID"
                    name="letter"
                    type="text"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    // sx={{ width: 200 }}
                    disabled={submitLoad}
                    label="Injections"
                    name="injections"
                    type="number"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    // sx={{ width: 276 }}
                    disabled={submitLoad}
                    label="Purchase Date"
                    name="datePurchased"
                    type="date"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    // sx={{ width: 276 }}
                    disabled={submitLoad}
                    label="First Use Date"
                    name="firstUseDate"
                    type="date"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    // sx={{ width: 276 }}
                    disabled={submitLoad}
                    label="Expiration Date"
                    name="expirationDate"
                    type="date"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <label> Select status for capillary 1.</label>
                  <br></br>
                  <Autocomplete
                    sx={{ width: 200 }}
                    disabled={submitLoad}
                    value={props.values.status1}
                    id="status1"
                    name="status1"
                    onChange={(e, value) => {
                      props.setFieldValue('status1', value);
                    }}
                    disableClearable
                    options={statusOptions}
                    getOptionLabel={(option) => {
                      if (option == 'good') {
                        return 'Good';
                      } else {
                        return 'Broken';
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Status" />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <label> Select status for capillary 2.</label>
                  <br></br>
                  <Autocomplete
                    sx={{ width: 200 }}
                    disabled={submitLoad}
                    value={props.values.status2}
                    id="status2"
                    name="status2"
                    onChange={(e, value) => {
                      props.setFieldValue('status2', value);
                    }}
                    disableClearable
                    options={statusOptions}
                    getOptionLabel={(option) => {
                      if (option == 'good') {
                        return 'Good';
                      } else {
                        return 'Broken';
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Status" />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <label> Select status for capillary 3.</label>
                  <br></br>
                  <Autocomplete
                    sx={{ width: 200 }}
                    disabled={submitLoad}
                    value={props.values.status3}
                    id="status3"
                    name="status3"
                    onChange={(e, value) => {
                      props.setFieldValue('status3', value);
                    }}
                    disableClearable
                    options={statusOptions}
                    getOptionLabel={(option) => {
                      if (option == 'good') {
                        return 'Good';
                      } else {
                        return 'Broken';
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Status" />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <label> Select status for capillary 4.</label>
                  <br></br>
                  <Autocomplete
                    sx={{ width: 200 }}
                    disabled={submitLoad}
                    value={props.values.status4}
                    id="status4"
                    name="status4"
                    onChange={(e, value) => {
                      props.setFieldValue('status4', value);
                    }}
                    disableClearable
                    options={statusOptions}
                    getOptionLabel={(option) => {
                      if (option == 'good') {
                        return 'Good';
                      } else {
                        return 'Broken';
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Status" />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <Box>
                    <LoadingButton
                      size="large"
                      classes="profileSubmit"
                      type="submit"
                      variant="outlined"
                      loading={submitLoad}
                      loadingIndicator={
                        <RotateLoader
                          cssOverride={{ left: '100%' }}
                          size={7}
                          margin={-15}
                        />
                      }
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

