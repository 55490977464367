import { getDroppedOrders as getDroppedOrdersApi } from 'utils/api/ClientOrders';

export const getDroppedOrders = async ({
  setMyData,
  setMyOrderReceived,
  myOrdersReceived,
  user,
}) => {
  if (myOrdersReceived === false) {
    try {
      const res = await getDroppedOrdersApi(user);
      let myData = [];
      res.data.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (el === '__v') {
          } else {
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        myData = [...myData, oneRow];
      });
      setMyData(myData);
      setMyOrderReceived(true);
    } catch (error) {
      console.log(error);
    }
  }
};
