import { MenuItem, TextField } from '@mui/material';
import { forwardRef } from 'react';
import LetterQuadrant from './LetterQuadrant';

export const MachineDropdown = forwardRef(
  ({ instrument, setInstrument }, ref) => {
    return (
      <TextField
        value={instrument}
        onChange={(e) => setInstrument(e.target.value)}
        select
        label="Instrument"
        sx={{ minWidth: 200 }}
        ref={ref}
      >
        <MenuItem value="SeqStudio" onMouseDown={(e) => e.stopPropagation()}>
          SeqStudio
        </MenuItem>
        <MenuItem
          value="SeqStudio Flex"
          onMouseDown={(e) => e.stopPropagation()}
        >
          SeqStudio Flex
        </MenuItem>
      </TextField>
    );
  }
);

export const LabelDropdown = forwardRef(
  (
    { instrument, cartridge, setCartridge, cartridgeList, block, setBlock },
    ref
  ) => {
    return instrument === 'SeqStudio' ? (
      <TextField
        value={cartridge}
        onChange={(e) => setCartridge(e.target.value)}
        select
        label="Cartridge"
        SelectProps={{
          renderValue: (selected) => {
            const selectedCartridge = cartridgeList.find(
              (cartridge) => cartridge._id === selected
            );
            return selectedCartridge ? selectedCartridge.letter : '';
          },
        }}
        sx={{ minWidth: 200 }}
        ref={ref}
      >
        {cartridgeList &&
          cartridgeList.map((cartridge, index) => (
            <MenuItem
              value={cartridge._id}
              onMouseDown={(e) => e.stopPropagation()}
              key={index}
            >
              <LetterQuadrant element={cartridge} />
            </MenuItem>
          ))}
      </TextField>
    ) : (
      <TextField
        value={block}
        onChange={(e) => setBlock(e.target.value)}
        select
        label="Block"
        sx={{ minWidth: 200 }}
        ref={ref}
      >
        <MenuItem value="A" onMouseDown={(e) => e.stopPropagation()}>
          A
        </MenuItem>
        <MenuItem value="B" onMouseDown={(e) => e.stopPropagation()}>
          B
        </MenuItem>
        <MenuItem value="C" onMouseDown={(e) => e.stopPropagation()}>
          C
        </MenuItem>
        <MenuItem value="D" onMouseDown={(e) => e.stopPropagation()}>
          D
        </MenuItem>
      </TextField>
    );
  }
);
