import TextField from '@mui/material/TextField';
import { Autocomplete, Grid } from '@mui/material';
import { memo } from 'react';

const FragmentRowDyeSet = memo(({ data, updateFragment, id }) => {
  const typeOptions = [
    'D (DS-30)',
    'E5 (DS-02)',
    'F (DS-32)',
    'G5 (DS-33)',
    'J6 (DS-36)',
  ];

  const handleChange = (newData, id) => {
    console.log(newData);
    updateFragment(newData, id);
  };

  return (
    <Grid item alignItems="stretch" style={{ display: 'flex', width: 140 }}>
      <Autocomplete
        disablePortal
        disableClearable={true}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        id="dyeSet"
        variant="outlined"
        options={typeOptions}
        value={data.dyeSet}
        onBlur={(e) => {
          var newTouched = [...data.touched];
          newTouched[4] = true;
          handleChange({ touched: newTouched }, id);
        }}
        onChange={async (e, currData) => {
          var newTouched = [...data.touched];
          newTouched[4] = true;
          handleChange({ dyeSet: currData, touched: newTouched }, id);
        }}
        sx={{
          width: 150,
          '& .MuiInputBase-root': { height: 40, borderRadius: 0 },
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Dye Set"
            inputProps={{
              ...params.inputProps,
              readOnly: true,
              style: { marginTop: -5, marginLeft: -10, marginRight: -10 },
            }}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              fontSize: 14,
              marginLeft: -12,
              marginRight: -12,
            }}
          >
            {option}
          </li>
        )}
      />
    </Grid>
  );
});

export default FragmentRowDyeSet;

