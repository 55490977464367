import { DateTime } from 'luxon';
import { Calendar, luxonLocalizer } from 'react-big-calendar';
import { Modal, Box, Button } from '@mui/material/';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Container from 'react-bootstrap/Container';
import sampleCalendarEvents from './sampleCalendarEvents';
import './CustomCalendar.css';
import {
  getOrdersForCalendar,
  getAllOrders,
  getAllCalendarEvents,
  getAllUsers,
} from 'utils/api/Calendar';
import EventForm from './EventForm';
import NewEventForm from './NewEventForm';
import TechnicianPage from './TechnicianPage';
import CustomPresetForm from './CustomPresetForm';

const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 7 });

const CustomCalendar = (props) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalNew, setDisplayModalNew] = useState(false);
  const [displayTechnicianPage, setDisplayTechnicianPage] = useState(false);
  const [displayModalCustomPreset, setDisplayModalCustomPreset] =
    useState(false);
  const [chosenEvent, setChosenEvent] = useState();
  const [myEvents, setEvents] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loadingAll, setLoadingAll] = useState(true);
  const [usersAvailable, setUsersAvailable] = useState();
  const [assignedOrders, setAssignedOrders] = useState();
  const [unassignedOrders, setUnassignedOrders] = useState();
  const [usernameObjTasks, setUsernameObjTasks] = useState();
  const [chosenUser, setChosenUser] = useState();

  useEffect(() => {
    fetchDataForCalendarTable();
  }, [displayModal, displayModalNew, displayTechnicianPage]);

  const fetchDataForCalendarTable = async () => {
    const res = await getAllUsers();
    const res1 = await getOrdersForCalendar();
    const res2 = await getAllOrders();
    const response = await getAllCalendarEvents();
    const events = response.data;
    for (let event of events) {
      event.id = event._id;
      event.start = new Date(event.start);
      event.end = new Date(event.end);
    }
    // console.log('refetched events', events);
    setEvents([...sampleCalendarEvents, ...events]);

    let ordersAssigned = [];
    let usernameUserObjArr = [];
    for (let i = 0; i < res.data.length; ++i) {
      usernameUserObjArr.push([res.data[i].username, res.data[i]]);
    }
    // console.log(usernameObjArr);
    // Data to populate table
    var usersTasksD = {};
    var usernameTasksD = {};
    if (res.status === 200 && res1.status === 200 && res2.status === 200) {
      for (let i = 0; i < res.data.length; ++i) {
        var currUser = res.data[i];
        usersTasksD[
          currUser.firstName + ' ' + currUser.lastName + ':' + currUser.username
        ] = [];
        usernameTasksD[currUser.username] = [];
        for (let j = 0; j < response.data.length; ++j) {
          if (response.data[j].receivingUserId.includes(currUser._id)) {
            var currOrderCode = null;
            for (let k = 0; k < res2.data.length; ++k) {
              if (response.data[j].orderId === res2.data[k]._id) {
                currOrderCode = res2.data[k].orderCode;
                ordersAssigned.push(currOrderCode);
                break;
              }
            }
            if (
              usersTasksD[
                currUser.firstName +
                  ' ' +
                  currUser.lastName +
                  ':' +
                  currUser.username
              ].length === 0
            ) {
              usersTasksD[
                currUser.firstName +
                  ' ' +
                  currUser.lastName +
                  ':' +
                  currUser.username
              ].push(currOrderCode);
              usernameTasksD[currUser.username].push(currOrderCode);
            } else {
              usersTasksD[
                currUser.firstName +
                  ' ' +
                  currUser.lastName +
                  ':' +
                  currUser.username
              ].push(currOrderCode);
              usernameTasksD[currUser.username].push('; ' + currOrderCode);
            }
          }
        }
      }
      // console.log(usernameTasksD);
      let usersTasksArr = Object.entries(usersTasksD);
      let usernameTasksArr = Object.entries(usernameTasksD);
      let usersFree = [];
      for (let i = 0; i < usersTasksArr.length; ++i) {
        if (usersTasksArr[i][1].length === 0) {
          usersFree.push(usersTasksArr[i][0]);
        }
      }
      let ordersNotAssigned = [];
      for (let i = 0; i < res1.data.length; ++i) {
        if (!ordersAssigned.includes(res1.data[i].orderCode)) {
          ordersNotAssigned.push(res1.data[i].orderCode);
        }
      }

      // usernameUserObjArr after this step will be [[username, userObj, [assignedTasksOrderCode], [assignedTasksOrderCodeCalendarObjMap]]]
      let orderCodeCalendarObjMap = [];
      for (let i = 0; i < response.data.length; ++i) {
        let currentEvent = response.data[i];
        for (let j = 0; j < res2.data.length; ++j) {
          if (currentEvent.orderId === res2.data[j]._id) {
            orderCodeCalendarObjMap.push([
              res2.data[j].orderCode,
              currentEvent,
            ]);
            break;
          }
        }
      }
      for (let i = 0; i < usernameTasksArr.length; ++i) {
        for (let j = 0; j < usernameUserObjArr.length; ++j) {
          if (usernameTasksArr[i][0] === usernameUserObjArr[j][0]) {
            console.log(i, usernameTasksArr[i], usersTasksArr[i]);
            console.log('');
            let currTasksArr = usernameTasksArr[i][1];
            let currTasksArrNoSemi = usersTasksArr[i][1]; // Using this one to avoid having semicolon between order codes.
            usernameUserObjArr[j].push(currTasksArr);
            for (let k = 0; k < currTasksArrNoSemi.length; ++k) {
              for (let g = 0; g < orderCodeCalendarObjMap.length; ++g) {
                if (currTasksArrNoSemi[k] === orderCodeCalendarObjMap[g][0]) {
                  if (typeof usernameUserObjArr[j][3] === 'undefined') {
                    usernameUserObjArr[j][3] = [];
                    usernameUserObjArr[j][3].push([
                      currTasksArrNoSemi[k],
                      orderCodeCalendarObjMap[g][1],
                    ]);
                  } else
                    usernameUserObjArr[j][3].push([
                      currTasksArrNoSemi[k],
                      orderCodeCalendarObjMap[g][1],
                    ]);
                  break;
                }
              }
            }
            break;
          }
        }
      }
      // console.log(usernameUserObjArr);
      setUsernameObjTasks(usernameUserObjArr);
      setUnassignedOrders(ordersNotAssigned);
      setAssignedOrders(ordersAssigned);
      setUsersAvailable(usersFree);
      setLoadingAll(false);
    } else {
      console.log('Error in getting Data for calendar table...');
    }
  };

  const handleSelectSlot = ({ start, end }) => {
    let startNew = DateTime.fromJSDate(start);
    let endNew = DateTime.fromJSDate(start);
    let startNewer = startNew.set({ hour: 9 });
    let endNewer = endNew.set({ hour: 10 });
    setStartDate(startNewer);
    setEndDate(endNewer);
    setDisplayModalNew(true);
  };

  const handleSelectEvent = (event) => {
    setChosenEvent(event);
    setDisplayModal(true);
  };

  const handleSelectTechnician = (event) => {
    setDisplayTechnicianPage(true);
    // console.log(event.target.getAttribute('value'));
    let chosenUser = usernameObjTasks[event.target.getAttribute('value')];
    setChosenUser(chosenUser);
  };

  const handleOpenMangePresets = (event) => {
    setDisplayModalCustomPreset(true);
  };

  if (loadingAll) {
    return <h2>Loading Data for Calendar ...</h2>;
  }

  return (
    <>
      <div className="calendarView">
        <div className="tablesContainer">
          <div className="usersTable">
            <table>
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Assigned Orders</th>
                </tr>
                {usernameObjTasks.map((value, idx) => {
                  return (
                    <tr key={idx}>
                      <td
                        onClick={handleSelectTechnician}
                        id="selectableName"
                        value={idx}
                      >
                        {value[1].firstName + ' ' + value[1].lastName}
                      </td>
                      <td>{value[2]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="availabilityTablesContainer">
            <div className="availableUsersTable">
              <table>
                <tbody>
                  <tr>
                    <th>Available Employees</th>
                  </tr>
                  {usersAvailable.map((value, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{value.split(':')[0]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="unassignedOrdersTable">
              <table>
                <tbody>
                  <tr>
                    <th>Unassigned Orders</th>
                  </tr>
                  {unassignedOrders.map((value, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{value}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: '#008AFC',
              color: 'white',
              marginTop: 3,
              marginLeft: '12px',
              width: '100%',
            }}
            onClick={handleOpenMangePresets}
          >
            Manage Event Preset
          </Button>
        </div>
        <Container className="calendarContainer">
          <div className="height600">
            <Calendar
              localizer={localizer}
              events={myEvents}
              // defaultView={Views.WEEK}
              onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSelectSlot}
              selectable
              eventPropGetter={(event) => {
                const backgroundColor = event.eventColor
                  ? event.eventColor
                  : 'gray';
                return { style: { backgroundColor } };
              }}
            />
          </div>
        </Container>
        {/* Modal for Update Event */}
        <Modal
          open={displayModal}
          onClose={() => {
            setDisplayModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal">
            <EventForm
              chosenEvent={chosenEvent}
              setDisplayModal={setDisplayModal}
            />
          </Box>
        </Modal>
        {/* Modal for New Event  */}
        <Modal
          open={displayModalNew}
          onClose={() => {
            setDisplayModalNew(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal">
            <NewEventForm
              startDate={startDate}
              endDate={endDate}
              setDisplayModalNew={setDisplayModalNew}
            />
          </Box>
        </Modal>
        {/* Modal for Technician's Details Page */}
        <Modal
          open={displayTechnicianPage}
          onClose={() => {
            setDisplayTechnicianPage(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal" style={{ width: '50%' }}>
            <TechnicianPage
              assignedOrders={assignedOrders}
              unassignedOrders={unassignedOrders}
              loadingAll={loadingAll}
              chosenUser={chosenUser}
              setDisplayTechnicianPage={setDisplayTechnicianPage}
            />
          </Box>
        </Modal>
        {/* Modal for Manage Event Preset Page */}
        <Modal
          open={displayModalCustomPreset}
          onClose={() => {
            setDisplayModalCustomPreset(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal">
            <CustomPresetForm
              setDisplayModalCustomPreset={setDisplayModalCustomPreset}
            />
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default CustomCalendar;

