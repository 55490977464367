import Checkbox from '@mui/material/Checkbox';
import { memo, useContext } from 'react';
import Box from '@mui/material/Box';
import { SampleMethodContext } from '../../../contexts/MethodContext';

const SangerRowPremix = memo(
  ({ sample }) => {
    const { updateSample } = useContext(SampleMethodContext);

    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Checkbox
          checked={sample.premixed}
          onChange={(e) => {
            if (e.target.checked) {
              updateSample(
                {
                  premixed: e.target.checked,
                  primerRowList: [],
                },
                sample.id
              );
            } else {
              updateSample(
                {
                  premixed: e.target.checked,
                },
                sample.id
              );
            }
          }}
        />
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id &&
      prevProps.sample.premixed === nextProps.sample.premixed
    );
  }
);

export default SangerRowPremix;
