import axios from 'axios';

export const getBillingAll = async () => {
  return await axios.get('/api/billing/all');
};

export const markBilled = async (idss) => {
  return await axios.put('/api/billing/markBilled', idss);
};

export const getBillingPending = async () => {
  return await axios.get('/api/billing/pending');
};
