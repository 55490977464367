import axios from 'axios';

export const deleteOrder = async (dropOffCode) => {
  return await axios.delete('/api/clientOrders/delete/all', {
    data: { dropOffCode: dropOffCode },
  });
};

export const getOrders = async (user) => {
  return await axios.get('/api/clientOrders/uploadedOrders', {
    params: {
      username: user.casUser,
    },
  });
};

export const getDroppedOrders = async (user) => {
  return await axios.get('/api/clientOrders/droppedOffOrder', {
    params: {
      username: user.casUser,
    },
  });
};

export const getPIOrders = async (user) => {
  return await axios.get('/api/clientOrders/piOrders', {
    params: {
      username: user.casUser,
    },
  });
};

export const getSamples = async (orderCode, dropOffCode) => {
  return await axios({
    method: 'get',
    url: '/api/order/samples',
    params: {
      orderCode: orderCode,
      dropOffCode: dropOffCode,
    },
  });
};
