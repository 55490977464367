import axios from 'axios';

const createUser = async (newUser) => {
  try {
    const response = await axios.put('/api/user/add', newUser);
    return response;
  } catch (error) {
    console.log('Error creating user:', error);
    throw error;
  }
};

const getOrCreateUser = async () => {
  return await axios.get('/api/user');
};

const getNewUser = async (user) => {
  return await axios.get(`/api/user/new`, {
    username: user.casUser,
  });
};

const getUserPer = async (username) => {
  try {
    const response = await axios({
      url: '/api/user/per',
      method: 'GET',
      params: {
        username: username,
      },
    });
    return response;
  } catch (error) {
    console.log('Error getting user:', error);
    throw error;
  }
};

const getAllUsers = async () => {
  try {
    const response = await axios.get(`/api/user/all`);
    let dataToBeUsed = [];
    response.data.forEach((element) => {
      let oneRow = {};
      Object.keys(element).forEach((el) => {
        if (el == '__v') {
        } else {
          oneRow = { ...oneRow, [el]: element[el] };
        }
      });
      dataToBeUsed = [...dataToBeUsed, oneRow];
    });
    return dataToBeUsed;
  } catch (error) {
    console.log('Error getting all users:', error);
    throw error;
  }
};

const editUser = async (values) => {
  try {
    const response = await axios.put('/api/user/edit', values);
    return response;
  } catch (error) {
    console.log('Error updating user:', error);
    throw error;
  }
};

const updateUser = async (values) => {
  return await axios.put('/api/user/update', values);
};

const deleteUser = async (user) => {
  try {
    const response = await axios.delete(`/api/user/delete`, {
      data: user,
    });
    return response;
  } catch (error) {
    console.log('Error deleting user:', error);
    throw error;
  }
};

const reassignUser = async (values) => {
  return await axios.put('/api/user/reassign', values);
};

export {
  createUser,
  getUserPer,
  getAllUsers,
  getNewUser,
  editUser,
  deleteUser,
  reassignUser,
  getOrCreateUser,
  updateUser,
};
