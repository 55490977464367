import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { PopupNullClient } from './ClientPopupImports';
import { ClientPopupContent } from './ClientPopupContent';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Box from '@mui/material/Box';
import { getUserPer } from 'utils/api/User';

import '../../../styles/ClientPopupStyles.css';

const style = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  p: 4,
};

const loadingStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const getClient = async (username) => {
  const res = await getUserPer(username);
  res.data = res.data === '' ? { username: username } : res.data;
  return res.data;
};

export const ClientPopup = ({
  currentClient,
  handleClose,
  viewOnly = false,
}) => {
  const [client, setClient] = useState(PopupNullClient);
  const [loading, setLoading] = useState(true);
  const [clientReceived, setClientReceived] = useState(false);
  const clientUsername = currentClient.username;

  useEffect(() => {
    getClient(clientUsername).then((data) => {
      setLoading(false);
      setClient(data);
      setClientReceived(true);
    });
  }, [clientReceived]);

  if (loading) {
    return (
      <Box sx={loadingStyle}>
        <PropagateLoader cssOverride={{ margin: '0' }} />
      </Box>
    );
  } else if (client.username !== '') {
    return (
      <Grid
        className="popup"
        container
        rowSpacing={0}
        spacing={0}
        style={{ maxWidth: 550, minHeight: '60vh', margin: 'auto' }}
        sx={style}
      >
        <ClientPopupContent
          client={client}
          handleClose={handleClose}
          viewOnly={viewOnly}
          setClientReceived={setClientReceived}
        />
      </Grid>
    );
  } else {
    return <></>;
  }
};
