import { useState } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button } from 'reusable/UI/Button';
import { putControls } from 'utils/api/Controls';

export const NewControlForm = ({ closeDialog, refreshControls }) => {
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Control name is required'),
    type: Yup.string()
      .oneOf(['instrument', 'process'])
      .required('Control type is required')
      .nullable(),
    prepDate: Yup.date()
      .nullable()
      .typeError('Prep date must be a valid date')
      .when('type', {
        is: 'instrument',
        then: Yup.date().required(
          'Preparation date is required for instrument controls'
        ),
        otherwise: Yup.date().nullable(),
      }),
    templateVol: Yup.number()
      .nullable()
      .typeError('Template volume must be a valid number')
      .min(0, 'Template volume can not be less than 0 µL')
      .required('Control template volume is required'),
    primerVol: Yup.number()
      .nullable()
      .typeError('Primer volume must be a valid number')
      .min(0, 'Primer volume can not be less than 0 µL')
      .when('type', {
        is: 'process',
        then: Yup.number().required(
          'Primer volume is required for process controls'
        ),
        otherwise: Yup.number().nullable(),
      }),
  });

  const initialValues = {
    name: '',
    type: null,
    prepDate: null,
    templateVol: null,
    primerVol: null,
  };

  return (
    <div style={{ paddingTop: 20 }}>
      <Formik
        onSubmit={async (values) => {
          setIsLoadingForm(true);
          try {
            const res = putControls(values);
            if (res.status === 201) {
              setIsLoadingForm(false);
              closeDialog();
            }
            await refreshControls();
          } catch (error) {
            console.error(error);
            setIsLoadingForm(false);
            setErrorDialogOpen(true);
          }
        }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnMount
        initialValues={initialValues}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  variant="outlined"
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      width: '100%',
                      minHeight: 30,
                    },
                  }}
                  label="Control Name"
                  helperText={touched.name && errors.name}
                  error={touched.name && Boolean(errors.name)}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="type"
                  value={values.type}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value === 'instrument') {
                      setFieldValue('primerVol', null);
                    } else {
                      setFieldValue('prepDate', null);
                    }
                  }}
                  onBlur={handleBlur}
                  helperText={touched.type && errors.type}
                  error={touched.type && Boolean(errors.type)}
                  select
                  label="Control Type"
                  sx={{ width: '100%' }}
                >
                  <MenuItem value="instrument">Instrument</MenuItem>
                  <MenuItem value="process">Process</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="prepDate"
                    label="Preparation Date"
                    value={values.prepDate}
                    onChange={(date) =>
                      handleChange({
                        target: { name: 'prepDate', value: date },
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: '100%' }}
                        name="prepDate"
                        onBlur={handleBlur}
                        helperText={
                          values.type === 'instrument' &&
                          touched.prepDate &&
                          errors.prepDate
                        }
                        error={
                          values.type === 'instrument' &&
                          touched.prepDate &&
                          Boolean(errors.prepDate)
                        }
                      />
                    )}
                    slotProps={{
                      textField: {
                        helperText: 'MM/DD/YYYY',
                      },
                      field: { clearable: true },
                    }}
                    disabled={values.type !== 'instrument'}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="templateVol"
                  label="Template Vol."
                  type="number"
                  value={values.templateVol}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    width: '100%',
                    minHeight: 8,
                    '& .MuiInputBase-input': {
                      width: '100%',
                      minHeight: 30,
                    },
                  }}
                  onBlur={handleBlur}
                  helperText={touched.templateVol && errors.templateVol}
                  error={touched.templateVol && Boolean(errors.templateVol)}
                  disabled={!values.type}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="primerVol"
                  label="Primer Vol."
                  type="number"
                  value={values.primerVol}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    width: '100%',
                    minHeight: 8,
                    '& .MuiInputBase-input': {
                      width: '100%',
                      minHeight: 30,
                    },
                  }}
                  onBlur={handleBlur}
                  helperText={
                    values.type === 'process' &&
                    touched.primerVol &&
                    errors.primerVol
                  }
                  error={
                    values.type === 'process' &&
                    touched.primerVol &&
                    Boolean(errors.primerVol)
                  }
                  disabled={values.type !== 'process'}
                />
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    gap: 10,
                  }}
                >
                  <Button variant="text" onClick={closeDialog}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={Object.keys(errors).length > 0}
                    onClick={handleSubmit}
                  >
                    Create
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};
