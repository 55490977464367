import React, { useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import './HomePage.css';
import { InView } from 'react-intersection-observer';

const HomeInstruct = (props) => {
  const refs = useRef([]);

  const [inViews, setInViews] = useState([]);

  const handleInView = (inView, index, id) => {
    const newInViews = [...inViews];
    newInViews[index] = { ...newInViews[index], [id]: inView };
    setInViews(newInViews);
  };

  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent={'center'}
      marginBottom={10}
      className="instruct__container"
      wrap={'wrap'}
    >
      <>
        {props.items.map((item, index) => {
          refs.current[index] = [React.createRef(), React.createRef()];
          return (
            <Grid
              item
              xs
              key={item.id}
              className={`
                ${
                  inViews[index] && inViews[index].first
                    ? 'instruct__animation--fade-in'
                    : 'instruct__animation--fade-out'
                }
                instruct__animation-delay--${index}
              `}
            >
              <div className="instruct__block">
                <div className="instruct__header" ref={refs.current[index][0]}>
                  <InView
                    threshold={1}
                    triggerOnce={true}
                    onChange={(inView) => handleInView(inView, index, 'first')}
                  >
                    <div>
                      <text>0{index + 1} /</text> {item.title}
                    </div>
                  </InView>
                </div>
                <div className="instruct__text" ref={refs.current[index][1]}>
                  <InView
                    threshold={0.5}
                    triggerOnce={true}
                    onChange={(inView) => handleInView(inView, index, 'second')}
                  >
                    <div>{item.text}</div>
                  </InView>
                </div>
                <InView
                  threshold={0.5}
                  triggerOnce={true}
                  onChange={(inView) => handleInView(inView, index, 'third')}
                >
                  <div>
                    <div className="instruct__footer">{item.footer}</div>
                  </div>
                </InView>
              </div>
            </Grid>
          );
        })}
      </>
    </Grid>
  );
};

export default HomeInstruct;
