import React, { useContext, useState } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { socket } from '../../context/socket';
import Grid from '@mui/material/Grid';
import RotateLoader from 'react-spinners/RotateLoader';
import { Form, Formik, useField } from 'formik';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import AuthContext from 'utils/auth/auth';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './styles/styles.css';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  float: 'left',
}));

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="formError">{meta.error}</div>
      ) : null}
    </>
  );
};

const IDTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [value, setValue] = useState(field.value);

  const handleInputChange = (e) => {
    const input = e.target.value;
    let formattedValue = input;

    if (input.length > 6 && !input.includes('-')) {
      formattedValue = `${input.slice(0, 6)}-${input.slice(6)}`;
    }

    setValue(formattedValue);
    field.onChange(e);
  };

  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input
        className="text-input"
        {...field}
        {...props}
        value={value}
        onChange={handleInputChange}
      />
      {meta.touched && meta.error ? (
        <div className="formError">{meta.error}</div>
      ) : null}
    </>
  );
};

export default function AddCartridge(props) {
  const idRegExp = /^[0-9- ]+$/;
  const [getLoad, setGetLoad] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const statusOptions = ['good', 'broken'];
  const user = useContext(AuthContext);

  // whether the expiration date has been clicked on
  const [touchedExpiration, setTouchedExpiration] = useState(false);

  const handleClose = () => {
    setSubmitStatus(false);
  };

  const getCurrentDate = (monthDiff = 0) => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1 + monthDiff).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const initialData = {
    cartridgeID: '',
    letter: '',
    datePurchased: getCurrentDate(),
    firstUseDate: getCurrentDate(),
    expirationDate: getCurrentDate(6),
    status1: 'good',
    status2: 'good',
    status3: 'good',
    status4: 'good',
    injections: 250,
  };

  const validationSchema = Yup.object({
    cartridgeID: Yup.string()
      .min(2, 'Must be at least 2 characters')
      .max(20, "Can't be larger than 20 characters")
      .matches(idRegExp, 'Invalid Cartridge ID')
      .required('Required'),
    datePurchased: Yup.date()
      .required('Required')
      .max(new Date(), 'Date of purchase must be in the past'),
    expirationDate: Yup.date().required('Required'),
    letter: Yup.string()
      .max(1, 'No more than 1 character')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed'),
    capillaries: Yup.array().of(Yup.string()).min(4).max(4),
    injections: Yup.number().required('Required'),
  });

  const calcExpirationDate = (datePurchasedStr, firstUseDateStr) => {
    const datePurchased = new Date(datePurchasedStr);
    datePurchased.setFullYear(datePurchased.getFullYear() + 1); // 1 year since purchase date
    const firstUseDate = new Date(firstUseDateStr);
    firstUseDate.setMonth(firstUseDate.getMonth() + 6); // 6 months since first use date
    const expirationDate =
      datePurchased < firstUseDate ? datePurchased : firstUseDate;

    function formatDate(date) {
      const year = date.getFullYear();
      let month = (date.getMonth() + 1).toString(); // Months are zero-based, so add 1
      let day = date.getDate().toString();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return `${year}-${month}-${day}`;
    }

    return formatDate(expirationDate);
  };

  return (
    <Grid
      className="add"
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
        <PropagateLoader />
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
              window.location.reload(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>
      <Formik
        initialValues={initialData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setSubmitLoad(true);
          try {
            var res = await axios.put(
              API_ENDPOINT + '/cartridge/create',
              values
            );
            var status = res.status;
            await sleep(1e3);
            if (status === 202) {
              setSubmitSucess(false);
              setSubmitResponse(
                'Cannot add a duplicate cartridge. Please try another ID.'
              );
              setSubmitStatus(true);
            }
            if (status === 201) {
              socket.emit('addCartridge', values);
              setSubmitSucess(true);
              setSubmitResponse('Cartridge successfully added!');
              setSubmitStatus(true);
            }
          } catch {
            setSubmitSucess(false);
            setSubmitResponse(
              'Unsuccessful! Please try again later or contact administrator if problem persists'
            );
            setSubmitStatus(true);
          }
          setSubmitLoad(false);
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={0} width={600}>
              <Grid item xs={12} sm={6}>
                <Item>
                  <IDTextInput
                    sx={{ width: 276 }}
                    disabled={submitLoad}
                    label="Cartridge ID"
                    name="cartridgeID"
                    type="text"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    sx={{ width: 276 }}
                    disabled={submitLoad}
                    label="Assigned Letter ID"
                    name="letter"
                    type="text"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    sx={{ width: 276 }}
                    disabled={submitLoad}
                    label="Injections"
                    name="injections"
                    type="number"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    sx={{ width: 276 }}
                    disabled={submitLoad}
                    label="Date Purchased"
                    name="datePurchased"
                    onChange={(e) => {
                      const { value } = e.target;
                      props.setFieldValue('datePurchased', value);
                      if (!touchedExpiration)
                        props.setFieldValue(
                          'expirationDate',
                          calcExpirationDate(value, props.values.firstUseDate)
                        );
                    }}
                    type="date"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    sx={{ width: 276 }}
                    disabled={submitLoad}
                    onChange={(e) => {
                      const { value } = e.target;
                      props.setFieldValue('firstUseDate', value);
                      if (!touchedExpiration)
                        props.setFieldValue(
                          'expirationDate',
                          calcExpirationDate(props.values.datePurchased, value)
                        );
                    }}
                    label="First Use Date"
                    name="firstUseDate"
                    type="date"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    sx={{ width: 276 }}
                    style={{ opacity: touchedExpiration ? 1 : 0.5 }}
                    disabled={submitLoad}
                    onMouseDown={() => setTouchedExpiration(true)}
                    label="Expiration Date"
                    name="expirationDate"
                    type="date"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <label> Select status for capillary 1.</label>
                  <br></br>
                  <Autocomplete
                    sx={{ width: 276 }}
                    disabled={submitLoad}
                    value={props.values.status1}
                    id="status1"
                    name="status1"
                    onChange={(e, value) => {
                      props.setFieldValue('status1', value);
                    }}
                    disableClearable
                    options={statusOptions}
                    getOptionLabel={(option) => {
                      if (option == 'good') {
                        return 'Good';
                      } else {
                        return 'Broken';
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Status" />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <label> Select status for capillary 2.</label>
                  <br></br>
                  <Autocomplete
                    sx={{ width: 276 }}
                    disabled={submitLoad}
                    value={props.values.status2}
                    id="status2"
                    name="status2"
                    onChange={(e, value) => {
                      props.setFieldValue('status2', value);
                    }}
                    disableClearable
                    options={statusOptions}
                    getOptionLabel={(option) => {
                      if (option == 'good') {
                        return 'Good';
                      } else {
                        return 'Broken';
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Status" />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <label> Select status for capillary 3.</label>
                  <br></br>
                  <Autocomplete
                    sx={{ width: 276 }}
                    disabled={submitLoad}
                    value={props.values.status3}
                    id="status3"
                    name="status3"
                    onChange={(e, value) => {
                      props.setFieldValue('status3', value);
                    }}
                    disableClearable
                    options={statusOptions}
                    getOptionLabel={(option) => {
                      if (option == 'good') {
                        return 'Good';
                      } else {
                        return 'Broken';
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Status" />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <label> Select status for capillary 4.</label>
                  <br></br>
                  <Autocomplete
                    sx={{ width: 276 }}
                    disabled={submitLoad}
                    value={props.values.status4}
                    id="status4"
                    name="status4"
                    onChange={(e, value) => {
                      props.setFieldValue('status4', value);
                    }}
                    disableClearable
                    options={statusOptions}
                    getOptionLabel={(option) => {
                      if (option == 'good') {
                        return 'Good';
                      } else {
                        return 'Broken';
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Status" />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <Box>
                    <LoadingButton
                      size="large"
                      classes="profileSubmit"
                      type="submit"
                      variant="outlined"
                      loading={submitLoad}
                      loadingIndicator={
                        <RotateLoader
                          cssOverride={{ left: '100%' }}
                          size={7}
                          margin={-15}
                        />
                      }
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};
