import { memo, useContext } from 'react';
import TextField from '@mui/material/TextField';
import { DebounceInput } from 'react-debounce-input';
import InputAdornment from '@mui/material/InputAdornment';
import { SampleMethodContext } from '../../../contexts/MethodContext';
import { ErrorVisContext } from '../../../contexts/DataContext';

const SangerRowConcentration = memo(
  ({ id, data, errorConc }) => {
    const { updateSample } = useContext(SampleMethodContext);
    const { submitShowError } = useContext(ErrorVisContext);

    return (
      <DebounceInput
        key={data.id[5]}
        debounceTimeout={500}
        minLength={1}
        element={TextField}
        required
        id="conc"
        placeholder={'Conc'}
        variant="standard"
        InputProps={{
          disableUnderline: true,
          inputmode: 'numeric',
          pattern: '[0-9]*',
          endAdornment: (
            <InputAdornment position="end" sx={{ paddingRight: 1 }}>
              ng/µl
            </InputAdornment>
          ),
        }}
        value={data.concVal}
        sx={{
          '& .MuiInputBase-input': {
            width: '100%',
            padding: '10px',
          },
        }}
        error={(data.touched[2] || submitShowError) && errorConc(data.concVal)}
        onBlur={(e) => {
          var newTouched = [...data.touched];
          newTouched[2] = true;
          updateSample({ touched: newTouched }, id);
        }}
        onChange={async (e) => {
          var newTouched = [...data.touched];
          newTouched[2] = true;
          updateSample(
            {
              concVal: e.target.value,
              touched: newTouched,
            },
            id
          );
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id &&
      prevProps.data.concVal === nextProps.data.concVal &&
      prevProps.data.touched[2] === nextProps.data.touched[2]
    );
  }
);

export default SangerRowConcentration;
