import {
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import CustomToolbar from './CustomToolBar';
import MUIDataTable from 'mui-datatables';
import BillingPopup from 'reusable/BillingPopup';
import { socket } from 'context/socket';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { getBillingPending } from 'utils/api/Billing';

async function getInitialData({
  setGetLoad,
  setData,
  setOrderReceived,
  ordersReceived,
}) {
  if (ordersReceived === false) {
    try {
      const res = await getBillingPending();
      let dataToBeUsed = [];
      res.data.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (el == '__v') {
          } else {
            //console.log("key: " + el+": inital Data inside: " + element[el]);
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        dataToBeUsed = [...dataToBeUsed, oneRow];
      });

      setData(dataToBeUsed);
      setGetLoad(false);
      setOrderReceived(true);
    } catch (error) {
      console.log(error);
    }
  } else {
    //DO Nothing
  }
}

function PendingTable(props) {
  const [isChecked, setIsChecked] = useState(false);
  const [getLoad, setGetLoad] = useState(true);
  const [data, setData] = useState([]);
  const [ordersReceived, setOrderReceived] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    socket.on('updateOthersbilling', (msg) => {
      //console.log("run");
      setOrderReceived(false);
      enqueueSnackbar(msg, { variant: 'info' });
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelfbilling', () => {
      //console.log("run");
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('connect', () => console.log(socket.id));
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => console.log('Server disconnected'));
  }, []);
  useEffect(() => {
    getInitialData({
      setGetLoad,
      setData,
      setOrderReceived,
      ordersReceived,
    }).catch(console.error);
  }, [ordersReceived]);

  const columns = [
    {
      label: 'Order Number',
      name: 'orderCode',
      options: {
        filter: false,
      },
    },
    {
      label: 'SUMS ID',
      name: 'SUMSBilling',
      options: {
        filter: false,
      },
    },
    {
      label: 'SUMS Group',
      name: 'SUMSGroupName',
      options: {
        filter: false,
      },
    },
    {
      label: 'PI',
      name: 'labName',
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `PI: ${v ? v.replace(/^(\w).* (.*)$/, '$1. $2') : ''}`,
        },
        filterType: 'dropdown',
      },
    },
    {
      label: 'Billing Status',
      name: 'billingStatus',
      options: {
        filter: false,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            console.log(order);
            let val1;
            let val2;
            if (obj1.data === 'Free') {
              val1 = 3;
            } else if (obj1.data === 'Pending') {
              val1 = 2;
            } else if (obj1.data === 'Billed') {
              val1 = 1;
            }

            if (obj2.data === 'Free') {
              val2 = 3;
            } else if (obj2.data === 'Pending') {
              val2 = 2;
            } else if (obj2.data === 'Billed') {
              val2 = 1;
            }

            console.log('val1: ' + val1);
            console.log('val2: ' + val2);
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        },
      },
    },
    {
      label: 'Billing Cycle',
      name: 'billingCycle',
      options: {
        filter: true,
        display: 'true',
        filterType: 'dropdown',
        customFilterListOptions: {
          render: (v) => `Billing Cycle: ${v}`,
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let ayear = obj1.data.match(/\d+/g)[0];
            console.log('ayear:' + ayear);
            let byear = obj2.data.match(/\d+/g)[0];
            if (byear < ayear) {
              return -1 * (order === 'asc' ? 1 : -1);
            } else if (byear > ayear) {
              return 1 * (order === 'asc' ? 1 : -1);
            } else {
              let aterm = -1;
              if (obj1.data.toLowerCase().includes('fall')) {
                aterm = 3;
              } else if (obj1.data.toLowerCase().includes('summer')) {
                aterm = 2;
              } else if (obj1.data.toLowerCase().includes('spring')) {
                aterm = 1;
              }
              let bterm = -1;
              if (obj2.data.toLowerCase().includes('fall')) {
                bterm = 3;
              } else if (obj2.data.toLowerCase().includes('summer')) {
                bterm = 2;
              } else if (obj2.data.toLowerCase().includes('spring')) {
                bterm = 1;
              }
              if (bterm < aterm) {
                return -1 * (order === 'asc' ? 1 : -1);
              }
              if (bterm > aterm) {
                return 1 * (order === 'asc' ? 1 : -1);
              }
              return 0;
            }
          };
        },
      },
    },
    // the next column will be based off of a formula (so noOfSamples multiplied by some number)
    {
      label: 'Price',
      name: 'price',
      options: {
        filter: false,
      },
    },
    {
      label: 'Date of Submission',
      name: 'date',
      options: {
        filter: true,
        display: 'excluded',
        sortDirection: 'desc',
        customBodyRender: (value) => {
          return new Date(value).toDateString();
        },
        filterType: 'custom',
        customFilterListRender: (v) => {
          if (v[0] && v[1] && isChecked) {
            return [`Start Date: ${v[0]}`, `End Date: ${v[1]}`];
          } else if (v[0] && v[1] && !isChecked) {
            return `Start Date: ${v[0]}, End Date: ${v[1]}`;
          } else if (v[0]) {
            return `After this date: ${v[0]}`;
          } else if (v[1]) {
            return `Before this date: ${v[1]}`;
          }
          return false;
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            var check = new Date(date);
            var from = new Date(filters[0]);
            var to = new Date(filters[1]);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            if (filters[0] && filters[1]) {
              return check <= from || check >= to;
            } else if (filters[0]) {
              return check <= from;
            } else if (filters[1]) {
              return check >= to;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Date of Submission</FormLabel>
              <br></br>
              <FormGroup row>
                <TextField
                  id="startDate"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][0] || ''}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '60%', marginRight: '5%' }}
                />
                <TextField
                  id="endDate"
                  label="End Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][1] || ''}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '60%', marginRight: '5%' }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={(event) => setIsChecked(event.target.checked)}
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: '0px' }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      label: 'Active',
      name: 'active',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    // each row must be able to expand into a popup that contains all the order information
    // I achieved this through making a separate column next to the order number that only contains buttons for expanding that row
    {
      label: ' ',
      name: 'orderCode',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          return (
            <IconButton>
              <BillingPopup currentOrder={data[value]} />
            </IconButton>
          );
        },
      },
    },
  ];

  // const data = [
  //   // dummy data: [orderCode, SUMSBilling, labName, billingStatus, billingCycle, price, dateDroppedOff, active]
  //   // pending orders do not have to be completed, but they should be dropped off in order to appear in this table
  //   ['BCD_02', 'ABC2395', 'Glass', 'Pending', 'Fall 2022', 12.00, '09/02/2022', true,],
  //   ['PPP_05', 'MBX4908', 'Prausnitz', 'Pending', 'Spring 2026', 8.00, '02/26/2026', true],
  //   ['BCD_13', 'ASD2109', 'Glass', 'Pending', 'Spring 2023', 32.00, '04/01/2023', true],
  //   ['SAJ_20', 'JLA2394', 'Bakr', 'Pending', 'Fall 2023', 231.00, '10/20/2023', true],
  //   ['CCC_12', 'KJL2139', 'Christine', 'Pending', 'Spring 2023', 130.00, '03/29/2023', true],
  //   ['BCD_02', 'ABC2395', 'Glass', 'Pending', 'Fall 2023', 12.00, '09/02/2023', true],
  //   ['PPP_05', 'MBX4908', 'Prausnitz', 'Pending', 'Spring 2025', 8.00, '02/26/2025', true],
  //   ['BCD_13', 'ASD2109', 'Glass', 'Pending', 'Spring 2024', 32.00, '04/01/2024', true],
  //   ['SAJ_20', 'JLA2394', 'Bakr', 'Pending', 'Fall 2022', 231.00, '10/20/2022', true],
  //   ['CCC_12', 'KJL2139', 'Christine', 'Pending', 'Spring 2024', 130.00, '03/29/2024', true],
  //   ['BCD_13', 'ASD2109', 'Glass', 'Pending', 'Spring 2022', 32.00, '04/01/2022', true],
  //   ['SAJ_20', 'JLA2394', 'Bakr', 'Pending', 'Fall 2023', 231.00, '10/20/2023', true],
  //   ['CCC_12', 'KJL2139', 'Christine', 'Pending', 'Spring 2025', 130.00, '03/29/2025', true]
  // ]

  // creates custom buttons for marking orders as billed or free
  // will eventually need to do a backend call to update the order's billing status
  /** 
  const handleBilled = (selectedRows) => {
    return ()=> {
      window.alert("click! current selected rows" + typeof selectedRows)
    }
    /** 
    return async (event) => {
    
      
      setSubmitLoad(true);
      try {
          var res = await axios.put(API_ENDPOINT + '/billing/markBilled', { data:thisinventory});
          
          var status = res.status
          
          console.log("status"+ status)
          if (status === 201) {
            //socket.emit('deleteInventory', thisinventory.catologNumber,user.firstName,user.lastName);
            console.log("status == 201")
              setSubmitSucess(true);
              setSubmitResponse("mark as billed sucessfully!")
  
          }
      } catch {
        console.log("error")
          setSubmitSucess(false);
          setSubmitResponse("Unsucessful! Please try again later or contact adminsitrator if problem persists")
      }
      
  console.log('submit');
  console.log(thisinventory);
  setSubmitStatus(true);
  setSubmitLoad(false);
  }
    

  }
  */
  /** 
  const customToolbar = ({ selectedRows }) => {
    //return () => {
      return (
        <ButtonGroup variant="contained" aria-label="outlined primary button group">
          <Button onClick = {handleBilled(selectedRows)}>
            Mark Order(s) as Billed
          </Button>
          <br></br>
          <Button>
            Mark Order(s) as Free
          </Button>
        </ButtonGroup>
      )
    //}
  };
  */
  /**handleFree(selectedRows)*/
  const options = {
    filter: true,
    filterType: 'multiselect',
    print: false,
    viewColumns: false,
    responsive: 'standard',
    selectToolbarPlacement: 'above',
    customToolbarSelect: (selectedRows) => (
      <CustomToolbar data={data} selectedRows={selectedRows} />
    ),
    //customToolbarSelect: customToolbar,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },
  };
  /** 
  // dummy data but as an array of objects
  const data = [
    {orderCode: 'BCD_02', SUMSBilling: 'ABC2395', labName: 'Glass', billingStatus: 'Pending', billingCycle: 'Fall 2022', price: 12.00, dateDroppedOff: '09/02/2022', active: true},
    {orderCode: 'PPP_05', SUMSBilling: 'MBX4908', labName: 'Prausnitz', billingStatus:'Pending', billingCycle: 'Spring 2026', price: 8.00, dateDroppedOff: '02/26/2026', active: true },
    {orderCode: 'BCD_13', SUMSBilling: 'ASD2109', labName: 'Glass', billingStatus: 'Pending', billingCycle: 'Spring 2023', price: 90.00, dateDroppedOff: '04/01/2023', active: true},
    {orderCode: 'SAJ_20', SUMSBilling: 'JLA2394', labName: 'Bakr', billingStatus: 'Pending', billingCycle: 'Fall 2023', price: 10.00, dateDroppedOff: '10/20/2023', active: true},
    {orderCode: 'CCC_12', SUMSBilling: 'KJL2139', labName: 'Christine', billingStatus: 'Pending', billingCycle: 'Spring 2023', price: 100.00, dateDroppedOff: '03/29/2023', active: true}
  ];
*/
  return (
    <MUIDataTable
      title={'Pending Orders'}
      // MUIDataTable code only works for simple arrays :/
      // the following code maps out an array of objects as a simple array
      data={data.map((item) => {
        return [
          item.orderCode,
          item.SUMSBilling,
          item.SUMSGroupName,
          item.labName,
          item.billingStatus,
          item.billingCycle,
          item.price,
          item.dateDroppedOff,
          item.active,
        ];
      })}
      columns={columns}
      options={options}
    />
  );
}

export default PendingTable;
