export var DragDropOverlayType;
(function (DragDropOverlayType) {
  DragDropOverlayType['IllegalMoveHover'] = 'Illegal';
  DragDropOverlayType['LegalMoveHover'] = 'Legal';
  DragDropOverlayType['PossibleMove'] = 'Possible';
})(DragDropOverlayType || (DragDropOverlayType = {}));
export const DragDropOverlay = ({ type }) => {
  const color = getOverlayColor(type);
  return (
    <div
      className="overlay"
      role={type}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 2,
        opacity: 0.5,
        backgroundColor: color,
      }}
    />
  );
};
function getOverlayColor(type) {
  switch (type) {
    case DragDropOverlayType.IllegalMoveHover:
      return 'red';
    case DragDropOverlayType.LegalMoveHover:
      return 'green';
    case DragDropOverlayType.PossibleMove:
      return 'yellow';
  }
}
