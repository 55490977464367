/**
 * Updates the order code based on the current order for the FRONTEND.
 * DB View:
 * AVBND1
    ├── AVBND1-1 (internal)
    │    └── AVBND1-2 (internal)
    │        └── AVBND1-3 (internal)
    |
    |
    ├── AVBND1_2 (external)
    |    └── AVBND1_2-1 (internal)
    |    └── AVBND1_2-2 (internal)
    |
    ├── AVBND1-4 (internal)

    Frontend View:
 * AVBND1
    ├── AVBND1_IRR1 (internal)
    │   └── AVBND1_IRR2 (internal)
    │       └── AVBND1_IRR3 (internal)
    |
    |
    ├── AVBND1_ERR (external)
    |    └── AVBND1_ERR-1 (internal)
    |    └── AVBND1_ERR-2 (internal)
    |
    ├── AVBND1_IRR4 (internal)

 * @param {Object} currOrder - The current order object.
 * @returns {string} - The order code.
 */
export default function getOrderCode(currOrder) {
  let { orderCode, isComboRun, isRerun, rerunType, externalFlag } = currOrder;

  if (isComboRun === true) {
    orderCode = `CR${currOrder.comboRunNo}\n${orderCode}`;
  }

  let suffix = rerunType === 'internal' ? '_IRR' : '_ERR';

  if (isRerun === true) {
    if (rerunType === 'internal') {
      const dash = orderCode.indexOf('-');
      let originalOrderCode = orderCode.slice(0, dash);

      if (externalFlag) {
        originalOrderCode = orderCode.slice(0, dash - 1);
        suffix = 'ERR-';
      }

      orderCode = originalOrderCode + suffix + orderCode.slice(dash + 1);
    } else {
      //external
      const indicator = orderCode.indexOf('_');
      orderCode = orderCode.slice(0, indicator) + suffix;
    }
  }

  return orderCode;
}
