import { useState } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import RotateLoader from 'react-spinners/RotateLoader';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { editCapillary } from 'utils/api/Cartridges';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  float: 'left',
}));

export default function StatusChange(props) {
  const [getLoad, setGetLoad] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const element = props.element;
  const statusOptions = ['good', 'broken'];
  const oldStatus = element.capillaries[props.index];
  const initialData = { oldStatus: oldStatus, newStatus: '' };
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);

  const handleClose = () => {
    setSubmitStatus(false);
  };

  const validationSchema = Yup.object({
    oldStatus: Yup.string().required('Required'),
    newStatus: Yup.string().required('Required'),
  });

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
        <PropagateLoader />
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
              window.location.reload(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>
      <Formik
        initialValues={initialData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          values.index = props.index;
          values._id = element._id;
          setSubmitLoad(true);
          try {
            var { status } = await editCapillary(values);
            await sleep(1e3);
            if (status === 201) {
              setSubmitSucess(true);
              setSubmitResponse('Changes sucessfully submitted!');
            }
          } catch {
            setSubmitSucess(false);
            setSubmitResponse(
              'Unsucessful! Please try again later or contact adminsitrator if problem persists'
            );
          }
          setSubmitStatus(true);
          setSubmitLoad(false);
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={0} width={250}>
              <Grid item xs={12}>
                <Item>
                  <TextField
                    disabled
                    label="Old Status"
                    name="oldStatus"
                    type="text"
                    value={oldStatus}
                    sx={{ width: 200 }}
                  />
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <label> Select a new capillary status.</label>
                  <br></br>
                  <Autocomplete
                    sx={{ width: 200 }}
                    disabled={submitLoad}
                    id="newStatus"
                    name="newStatus"
                    onChange={(e, value) => {
                      props.setFieldValue('newStatus', value);
                    }}
                    disableClearable
                    options={statusOptions}
                    getOptionLabel={(option) => {
                      if (option == 'good') {
                        return 'Good';
                      } else {
                        return 'Broken';
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="New Status" />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <Box>
                    <LoadingButton
                      size="large"
                      classes="profileSubmit"
                      type="submit"
                      variant="outlined"
                      loading={submitLoad}
                      loadingIndicator={
                        <RotateLoader
                          cssOverride={{ left: '100%' }}
                          size={7}
                          margin={-15}
                        />
                      }
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}
