function numToWell(index) {
  const rowLengthX = Math.floor(index / 8);
  const rowLengthY = index % 8;
  var startX = rowLengthX + 1;
  let sX = startX.toString();
  let sY = String.fromCharCode(rowLengthY + 65);
  const well = sY + sX;
  return well;
}

export default numToWell;

