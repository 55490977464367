import OrderButton from './OrderButton';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

export default function PickUp(props) {
  let currentOrders = props.currentOrders;
  const attri = 'stage';
  let pickupOrders = currentOrders.filter((currentOrder) => {
    let contain = false;
    if (
      String(currentOrder[attri])
        .toLowerCase()
        .replace(/\s+/g, '')
        .replace(/-/g, '')
        .includes('pickup')
    ) {
      contain = true;
    }
    return contain;
  });

  let pickUpOrdersAfterLocation = [];
  for (let i = 0; i < 6; i++) {
    const attri = 'dropLocation';

    let temp = pickupOrders.filter((currentOrder) => {
      let contain = false;
      let title;
      if (i === 0) {
        title = 'marcus';
      } else if (i === 1) {
        title = 'cherryemerson';
      } else if (i === 2) {
        title = 'mose';
      } else if (i === 3) {
        title = 'ibb';
      } else if (i === 4) {
        title = 'es&t';
      }

      if (
        String(currentOrder[attri])
          .toLowerCase()
          .replace(/\s+/g, '')
          .replace(/-/g, '')
          .includes(title)
      ) {
        contain = true;
      }
      return contain;
    });
    temp.push({ orderCode: '.' });
    temp.push({ orderCode: '.' });
    pickUpOrdersAfterLocation.push(temp);
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 1 }} align="center" justify="center">
        {Array.from(Array(5)).map((_, index) => (
          <>
            <Grid item xs={2.24} key={2 * index}>
              <Box sx={{ flexGrow: 1 }}>
                {index === 0 ? (
                  <Div>{'Marcus'}</Div>
                ) : index === 1 ? (
                  <Div>{'Cherry Emerson'}</Div>
                ) : index === 2 ? (
                  <Div>{'MoSE'}</Div>
                ) : index === 3 ? (
                  <Div>{'IBB'}</Div>
                ) : (
                  <Div>{'ES&T'}</Div>
                )}
                <Grid
                  container
                  spacing={{ xs: 1, sm: 3 }}
                  columns={{ xs: 1, lg: 2 }}
                >
                  {pickUpOrdersAfterLocation[index].map((currentOrder) => (
                    <Grid item xs={1} lg={1} key={currentOrder.name}>
                      <OrderButton currentOrder={currentOrder} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            {index < 6 && index !== 4 ? (
              <Grid item xs={0.2} key={2 * index + 1}>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ height: '100%', width: '2px' }}
                />
              </Grid>
            ) : (
              <Grid item xs={0} key={index + 1}></Grid>
            )}
          </>
        ))}
      </Grid>
    </Box>
  );
}
