import { Button, DialogActions } from '@mui/material';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export const ArchiveControlPopup = ({ control, open, setOpen, archive }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleArchive = async () => {
    await archive();
    handleClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Confirm Archive</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to archive this control ({control.name})?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outline" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleArchive}>
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};
