import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

const getLogs = async (inventoryItem, url) => {
  try {
    const res = await axios.get(`${API_ENDPOINT}${url}`, inventoryItem);

    let dataToBeUsed = [];
    let groupByCatolog = new Map();
    let groupByOrderID = new Map();
    let groupByUser = new Map();

    res.data.forEach((element) => {
      let oneRow = {};
      Object.keys(element).forEach((el) => {
        if (el === 'user') {
          if (element[el] !== null) {
            let name =
              `${element[el].firstName} ${element[el].lastName}`.trim();
            if (!name) {
              name = element[el].deletedUserWholeName;
            }
            oneRow = { ...oneRow, user: name };
          }
        } else if (el === 'inventoryItem') {
          const cat = element[el].catologNumber;
          oneRow = { ...oneRow, inventoryItem: cat };
        } else if (el === 'processingOrder' && element[el] !== null) {
          let oid = element[el].orderID;
          if (!oid || oid.trim() === '') {
            oid = element[el].deletedOrderID;
          }
          oneRow = { ...oneRow, processingOrder: oid };
        } else {
          oneRow = { ...oneRow, [el]: element[el] };
        }
      });

      if (oneRow.deletedCatolog) {
        oneRow = { ...oneRow, inventoryItemCombined: oneRow.deletedCatolog };
        groupByCatolog.set(oneRow.deletedCatolog, {
          catolog: oneRow.deletedCatolog,
          deleted: 'Deleted Inventory Item',
        });
      } else if (oneRow.inventoryItem) {
        oneRow = { ...oneRow, inventoryItemCombined: oneRow.inventoryItem };
        groupByCatolog.set(oneRow.inventoryItem, {
          catolog: oneRow.inventoryItem,
          deleted: 'Existing Inventory Item',
        });
      }

      if (oneRow.deletedOrderID) {
        oneRow = { ...oneRow, orderIDCombined: oneRow.deletedOrderID };
        groupByOrderID.set(oneRow.deletedOrderID, {
          catolog: oneRow.deletedOrderID,
          deleted: 'Deleted Order ID',
        });
      } else if (oneRow.orderID) {
        oneRow = { ...oneRow, orderIDCombined: oneRow.orderID };
        groupByOrderID.set(oneRow.orderID, {
          catolog: oneRow.orderID,
          deleted: 'Existing Order ID',
        });
      }

      if (oneRow.deletedUserWholeName) {
        oneRow = {
          ...oneRow,
          userWholeNameCombined: oneRow.deletedUserWholeName,
        };
        groupByUser.set(oneRow.deletedUserWholeName, {
          catolog: oneRow.deletedUserWholeName,
          deleted: 'Deleted User',
        });
      } else if (oneRow.user) {
        oneRow = { ...oneRow, userWholeNameCombined: oneRow.user };
        groupByUser.set(oneRow.user, {
          catolog: oneRow.user,
          deleted: 'Existing User',
        });
      }

      dataToBeUsed = [...dataToBeUsed, oneRow];
    });

    groupByCatolog = Array.from(groupByCatolog.values());
    groupByOrderID = Array.from(groupByOrderID.values());
    groupByUser = Array.from(groupByUser.values());

    return { dataToBeUsed, groupByCatolog, groupByOrderID, groupByUser };
  } catch (error) {
    console.error('Error getting logs:', error);
    throw error;
  }
};

export { getLogs };
