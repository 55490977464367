import { DialogContent } from '@mui/material';
import { DialogActions } from '@mui/material';
import { Alert } from '@mui/material';
import { DialogTitle } from '@mui/material';
import { Button } from 'reusable/UI/Button';

export const UploadResponse = (props) => {
  const error = props.error;
  const setError = props.setError;
  const warning = props.warning;
  const primerErrors = props.primerErrors;
  const sampleErrors = props.sampleErrors;
  const handleClose = props.handleClose;
  const setSubmitted = props.setSubmitted;

  return (
    <>
      <DialogTitle>Upload Excel File for Bulk Data</DialogTitle>
      {error ? (
        <div>
          <Alert severity="error">
            Error: Please upload again with lab's excel file, or contact
            adminsitrator if issue persists.
          </Alert>
        </div>
      ) : (
        <>
          {warning && (
            <>
              <Alert severity="warning">
                We've retained as much data as possible, however, we've found
                some errors, please review them below before uploading again or
                edit the form directly. Any remaining errors will be showcased
                on the online form.
              </Alert>
              {/* Warning message */}
              <DialogContent dividers style={{ overflowY: 'auto' }}>
                <ul>
                  {sampleErrors.map((well, index) => (
                    <li key={index}>
                      <strong>Well: {well.well}</strong>
                      <ul>
                        {well.msg.map((error, i) => (
                          <li key={i}>{error}</li>
                        ))}
                      </ul>
                    </li>
                  ))}
                  {primerErrors.map((primer, index) => (
                    <li key={index}>
                      <strong>Primer ID: {primer.id}</strong>
                      <ul>
                        <li>{primer.msg}</li>
                      </ul>
                    </li>
                  ))}
                </ul>
              </DialogContent>
            </>
          )}

          {!warning && !error && (
            <>
              <Alert severity="success">
                File uploaded and read sucessfully! No errors found.
                <br></br>
                <strong>
                  Please review the sample list for detailed errors.
                </strong>
              </Alert>
            </>
          )}
        </>
      )}
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Return to form
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setSubmitted(false);
            setError(false);
          }}
        >
          Try Again
        </Button>
      </DialogActions>
    </>
  );
};
