import { memo, useContext } from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { ErrorVisContext } from '../../../contexts/DataContext';
import { SampleMethodContext } from '../../../contexts/MethodContext';

const SangerRowDesiredTemplate = memo(
  ({ id, data, templateOptions }) => {
    const { updateSample } = useContext(SampleMethodContext);
    const { submitShowError } = useContext(ErrorVisContext);

    return (
      <Autocomplete
        disablePortal
        disableClearable={true}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        id="template"
        variant="outlined"
        disabled={data.type === '' || data.type === undefined}
        options={templateOptions}
        value={data.template}
        onBlur={(e) => {
          var newTouched = [...data.touched];
          newTouched[6] = true;
          updateSample({ touched: newTouched }, id);
        }}
        onChange={async (e, currData) => {
          var newTouched = [...data.touched];
          newTouched[6] = true;
          updateSample({ template: currData, touched: newTouched }, id);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Desired Template"
            inputProps={{
              ...params.inputProps,
              readOnly: true,
              shrink: false,
              notched: false,
            }}
            error={
              (data.touched[6] || submitShowError) &&
              (data.template === undefined || data.template === '')
            }
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              fontSize: 12,
              marginLeft: -12,
              marginRight: -12,
            }}
          >
            {option}
          </li>
        )}
        classes={{
          popupIndicator: 'sample-table__move-popup-indicator',
        }}
        sx={{
          '& .MuiFormControl-root': {
            border: 'none',
          },
          '& .MuiOutlinedInput-root': {
            '& .MuiInputBase-input': {
              width: '100%',
            },
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: 'none',
            },
            '&.Mui-focused fieldset': {
              border: 'none',
            },
          },
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    // nextProps.updateTemplateOptions(nextProps.data.type);
    return (
      prevProps.id === nextProps.id &&
      prevProps.data.template === nextProps.data.template &&
      prevProps.data.type === nextProps.data.type &&
      prevProps.templateOptions === nextProps.templateOptions
    );
  }
);

export default SangerRowDesiredTemplate;
