import Box from '@mui/material/Box';
import SangerLabPrimerPopup from './PrimerComponents/SangerLabPrimerPopup';
import { PrimerTableHeadings } from './PrimerComponents/SangerPrimerTableHeadings';
import SangerPrimerRowComponent from './PrimerComponents/SangerPrimerRowComponent';
import { Typography } from 'reusable/UI/Typography';
import { Button } from 'reusable/UI/Button';
import { useContext } from 'react';
import { PrimerMethodContext } from '../../contexts/MethodContext';
import { EmptyTableRow } from 'reusable/UI/EmptyTableRow';

export const SangerPrimerTable = ({
  primerOrder,
  primerMap,
  labPrimersMap,
  primerColors,
  formik,
}) => {
  const { addPrimer, addLabPrimer } = useContext(PrimerMethodContext);

  return (
    <Box>
      <Typography variant="h2" textAlign="left" sx={{ marginLeft: 5 }}>
        Primer Table
      </Typography>
      <table
        style={{
          position: 'relative',
          width: 'calc(100% - 120px)',
          left: 40,
        }}
      >
        <PrimerTableHeadings />
        <tbody>
          {primerOrder.length > 0 ? (
            primerOrder.map((key, i) => {
              const primer = primerMap.get(key);
              if (!primer) return <></>;
              return (
                <SangerPrimerRowComponent
                  key={primer.id}
                  primerKey={key}
                  index={i}
                  primer={primer}
                  primerColors={primerColors}
                  formik={formik}
                />
              );
            })
          ) : (
            <EmptyTableRow cols={3} />
          )}
        </tbody>
      </table>
      <Box style={{ marginLeft: 20, padding: 20, display: 'flex', gap: 10 }}>
        <Button variant="contained" color="secondary" onClick={addPrimer}>
          Add New Primer
        </Button>
        <SangerLabPrimerPopup
          addNewLabPrimer={addLabPrimer}
          pickedLabPrimersMap={labPrimersMap}
        />
      </Box>
    </Box>
  );
};
