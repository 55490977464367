async function createSample({
  well,
  name,
  primer,
  controlPrimerName,
  concentration,
  readLength,
  volume,
  type,
  sampleMap,
  order,
  controlId,
}) {
  return fetch(`/api/samples`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      well,
      name,
      primer,
      controlPrimerName,
      concentration,
      readLength,
      volume,
      type,
      sampleMap,
      order,
      controlId,
    }),
  });
}

async function updateSample(
  id,
  {
    well,
    name,
    primer,
    controlPrimerName,
    concentration,
    readLength,
    volume,
    type,
    sampleMap,
    order,
    controlId,
  }
) {
  if (!id) throw new Error('id for sample to update cannot be undefined');
  return fetch(`/api/samples/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      well,
      name,
      primer,
      controlPrimerName,
      concentration,
      readLength,
      volume,
      type,
      sampleMap,
      order,
      controlId,
    }),
  });
}

async function deleteSample(id) {
  if (!id) throw new Error('id for sample to delete cannot be undefined');
  return fetch(`/api/samples/${id}`, {
    method: 'DELETE',
  });
}

export { createSample, updateSample, deleteSample };
