import React from 'react';
import Button from '@mui/material/Button';

const NewTabButton = ({ to, label, ...props }) => {
  const handleClick = () => {
    if (to) {
      const newWindow = window.open(to, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  };

  return (
    <Button onClick={handleClick} {...props}>
      {label}
    </Button>
  );
};

export default NewTabButton;
