import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Button } from 'reusable/UI/Button.jsx';
import { MenuUpload } from './ExcelPopupComponents/MenuUpload';
import { UploadResponse } from './ExcelPopupComponents/UploadResponse';

export default function ExcelUploadPopup({ uploadExcelFile, formType }) {
  const [open, setOpen] = React.useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [primerErrors, setPrimerErrors] = useState([]);
  const [sampleErrors, setSampleErrors] = useState([]);
  const orderType =
    formType === 'sanger' ? 'Sanger Sequencing' : 'Fragment Analysis';

  const handleClickOpen = () => {
    setOpen(true);
    setError(false);
  };

  const handleClose = () => {
    setOpen(false);
    setWarning(false);
    setError(false);
    setSubmitted(false);
  };

  return (
    <>
      <Button variant="contained" color="secondary" onClick={handleClickOpen}>
        Upload Excel
      </Button>
      <Dialog open={open} onClose={handleClose}>
        {!submitted ? (
          <MenuUpload
            uploadExcelFile={uploadExcelFile}
            handleClose={handleClose}
            setError={setError}
            setWarning={setWarning}
            setPrimerErrors={setPrimerErrors}
            setSampleErrors={setSampleErrors}
            setSubmitted={setSubmitted}
            orderType={orderType}
            formType={formType}
          />
        ) : (
          <UploadResponse
            uploadExcelFile={uploadExcelFile}
            handleClose={handleClose}
            error={error}
            warning={warning}
            primerErrors={primerErrors}
            sampleErrors={sampleErrors}
            setWarning={setWarning}
            setError={setError}
            setSubmitted={setSubmitted}
          />
        )}
      </Dialog>
    </>
  );
}
