import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import ErrorFormPopup from './components/ErrorFormPopup';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import { ColorLuminance, stringToColour } from 'reusable/Color';
import { SangerInfoDropdown } from './components/SangerForm/SangerInfoDropdown';
import Backdrop from '@mui/material/Backdrop';
import RotateLoader from 'react-spinners/RotateLoader';
import { SangerFormHeading } from './components/SangerForm/SangerFormHeading';
import { SangerPrimerTable } from './components/SangerForm/SangerPrimerTable';
import { SangerClientNotes } from './components/SangerForm/SangerClientNotes';
import { SangerSampleTable } from './components/SangerForm/SangerSampleTable';
import { SangerFormSubmit } from './components/SangerForm/SangerFormControls';
import {
  FormMethodContext,
  PrimerMethodContext,
  SampleMethodContext,
} from './contexts/MethodContext';
import { ErrorVisContext } from './contexts/DataContext';
import { useLogin } from 'hooks/useLogin';
import {
  getSangerForm,
  submitSangerForm,
  updateSangerForm,
  uploadSangerExcel,
} from 'utils/api/SangerForm';

export const strReturnOnlyNumbersPositive = (str) => {
  let val = parseInt(str.replace(/[^0-9]/g, ''));
  if (isNaN(val)) {
    val = 0;
  }
  if (val < 0) {
    val = 0;
  }
  return val;
};

export const emptySampleData = {
  id: uuidv4(),
  sampleName: '',
  primerRowList: [],
  premixed: false,
  concVal: '',
  readLen: '',
  vol: '',
  type: '',
  template: '',
  touched: new Array(7).fill(false),
}; // Object that represents an empty sample

export const emptyPrimerData = {
  id: uuidv4(),
  primerName: '',
  vol: '',
  count: 0,
  labPrimer: false,
  labPrimerId: 0,
  touched: new Array(2).fill(false),
}; // Object that represents an empty primer

const Sanger = () => {
  useLogin();

  const navigate = useNavigate(); // Navigation handling
  const { enqueueSnackbar, closeSnackbar } = useSnackbar(); // Snackbar for notifications

  const SangerValidationSchema = Yup.object().shape({
    samples: Yup.array().of(
      Yup.object({
        sampleName: Yup.string()
          .max(70, 'Name should be 70 characters or less')
          .required('Sample Name is required'),
        primerRowList: Yup.array()
          .required('Primers must be selected')
          .test({
            name: 'min',
            exclusive: false,
            params: {},
            message: `At least one primer is required or sample must be premixed`,
            test: function (value) {
              // You can access the price field with `this.parent`.
              return value.length >= (this.parent.premixed ? 0 : 1);
            },
          }),
        premixed: Yup.bool().required('Checkebox must be inputted'),
        concVal: Yup.number()
          .typeError('Concentration must be a number')
          .min(1, 'Concentration should be greater than 1 ng')
          .required('Concentration is required'),
        readLen: Yup.number()
          .typeError('Read length must be a number')
          .min(0, 'Read Length cannot be lower than 0 bp')
          .max(800, 'Read Length should be no greater than 800 bp')
          .required('Read Length is required'),
        vol: Yup.number()
          .typeError('Volume must be a number')
          .required('Volume is required')
          // .nullable(true)
          .test({
            name: 'min',
            exclusive: false,
            params: {},
            message:
              'Volume must be be at least 10 µl or 10 µl per primer declared',
            test: function (value) {
              // You can access the price field with `this.parent`.
              let val =
                this.parent.primerRowList.length === 0
                  ? 1
                  : this.parent.primerRowList.length;
              return value >= parseFloat(val * 10);
            },
          }),
        type: Yup.string().required('A Type must be picked'),
        template: Yup.string().required('A Template must be picked'),
      })
    ),
    primerMapToList: Yup.array().of(
      Yup.object({
        key: Yup.number(),
        count: Yup.number().min(
          1,
          'Primer must be used at least once, otherwise delete the entry'
        ),
        labPrimer: Yup.bool(),
        labPrimerId: Yup.number().when('labPrimer', {
          is: true,
          then: Yup.number().required(),
        }),
        primerName: Yup.string()
          .min(5, 'Name should be 5 characters or greater')
          .max(50, 'Name should be 50 characters or less')
          .required('Primer name is required')
          .test(
            'unique-primerName',
            'Primer name must be unique',
            function (primerName) {
              const { parent } = this;
              const key = parent.key;
              const duplicate = primerList.filter(
                (item) =>
                  item.primerName === primerName && Number(item.key) !== key
              );
              return duplicate.length === 0;
            }
          ),
        vol: Yup.mixed().when('labPrimer', {
          is: false, // If labPrimer is false
          then: Yup.number()
            .required('Volume is required')
            .typeError('Volume must be a number')
            .nullable(true)
            .test({
              name: 'min',
              exclusive: false,
              params: {},
              message: `Volume must be be at least 5 ul every time it's used in a sample`,
              test: function (value) {
                // You can access the price field with `this.parent`.
                return value >= parseFloat(this.parent.count * 5);
              },
            }),
          otherwise: Yup.mixed(),
        }),
      })
    ),
  }); // Form validation with Formik

  const [samples, setSamples] = useState([
    {
      id: uuidv4(),
      sampleName: '',
      primerRowList: [],
      premixed: false,
      concVal: '',
      readLen: '',
      vol: '',
      type: '',
      template: '',
      touched: new Array(7).fill(false),
    },
  ]); // List of samples

  const [submitShowError, setSubmitShowError] = useState(false); // Whether to show all errors because form submitted
  const [showAllErrors, setShowAllErrors] = useState(true); // Whether to show all errors (controlled by button)

  const [primerMap, setPrimerMap] = useState(new Map()); // Primers as a map
  const [primerList, setPrimerList] = useState([]); // Primers as a list
  const [primerOrder, setPrimerOrder] = useState([]); // List of the order of the primers, containing the keys of the primer map

  const [labPrimersMap, setLabPrimersMap] = useState(new Map()); // Map of lab primers that have been picked
  const [reactionCount, setReactionCount] = useState(0); // Reaction count
  const [isLoadingForm, setIsLoadingForm] = useState(true); // Whether the form is being loaded
  const [errorPopup, setErrorPopup] = useState(false); // Whether the error popup is visible

  const [clientNotes, setClientNotes] = useState(''); // Client comments

  const primerColors = useRef(new Map());

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: { samples, primerMapToList: primerList },
    validationSchema: SangerValidationSchema,
    onSubmit: async (values, errors) => {
      setIsLoadingForm(true);
      try {
        const res = submitSangerForm(samples, primerMap, clientNotes);
        if (res.status === 200) {
          navigate('/Drop_off_code', {
            state: { dropOffCode: res.data.dropOffCode },
          });
        }
      } catch (error) {
        console.log('ALERT ERROR');
        console.log(error);
        alert(
          JSON.stringify(
            'Error. You have either reached the maximum limit of 5 orders or an issue has occurred. Please check your orders and drop them off. Otherwise, contact us if' +
              ' the issue persists'
          )
        );
      }
      setIsLoadingForm(false);
    },
  }); // Formik validation object

  useEffect(() => {
    // Generate primer colors map
    for (let i = 0; i <= 96; i++) {
      let stringCode = 'MECL';
      for (let j = 0; j < 6; j++) {
        stringCode = stringCode.concat(i);
      }
      stringCode = stringCode.concat('SANGER');
      let color = stringToColour(stringCode);
      const darkerColor = ColorLuminance(color, 0.5);
      primerColors.current.set(i.toString(), darkerColor);
    }
  }, []);

  /**
   * Show errors if there are form submission errors.
   */
  const handleErrorPopup = useCallback(async () => {
    const errors = await formik.validateForm();
    setSubmitShowError(true);
    if (Object.keys(errors).length > 0) {
      setErrorPopup(true);
    } else {
      formik.submitForm();
    }
  }, [formik, setSubmitShowError, setErrorPopup]);

  /**
   * Update the primer list given a primer map
   */
  const updatePrimerListFromMap = useCallback(
    (primerMap) => {
      // Update primerList whenever primerMap is updated
      const keyList = Array.from(primerMap.keys()); // List of the keys of the primers
      let primerMapToListTemp = [];
      for (let i = 0; i < keyList.length; i++) {
        primerMapToListTemp.push({
          key: keyList[i],
          primerName: primerMap.get(keyList[i])
            ? primerMap.get(keyList[i]).primerName
            : '',
          vol: primerMap.get(keyList[i])
            ? parseInt(primerMap.get(keyList[i]).vol)
            : 0,
          count: primerMap.get(keyList[i])
            ? primerMap.get(keyList[i]).count
            : 0,
          labPrimer: primerMap.get(keyList[i])
            ? primerMap.get(keyList[i]).labPrimer
            : false,
          labPrimerId: primerMap.get(keyList[i])
            ? primerMap.get(keyList[i]).labPrimerId
            : 0,
        });
      }
      setPrimerList(primerMapToListTemp);
      return primerMapToListTemp;
    },
    [setPrimerList]
  );

  const updateLabPrimerMapFromList = useCallback(
    (primerList) => {
      let newLabPrimerMap;
      setLabPrimersMap((labPrimerMap) => {
        newLabPrimerMap = new Map(labPrimerMap);
        primerList.forEach((eachPrimer) => {
          if (eachPrimer.labPrimerId) {
            newLabPrimerMap.set(eachPrimer.labPrimerId, true);
          }
        });
        return newLabPrimerMap;
      });
      return newLabPrimerMap;
    },
    [setLabPrimersMap]
  );

  useEffect(() => {
    // update primer list whenever primer map is updated
    updatePrimerListFromMap(primerMap);
  }, [primerMap, updatePrimerListFromMap]);

  useEffect(() => {
    updateSangerForm(primerMap, samples);
  }, [primerMap, samples, updateSangerForm]);

  /**
   * Load the form from the cloud
   */
  const loadSangerForm = useCallback(async () => {
    const res = await getSangerForm();
    if (res.status === 200) {
      let newPrimersKeys = Object.keys(res.data.primers[0]);
      let currPrimerMap = res.data.primers[0];
      let newPrimerMap = new Map();
      for (let primerKey in newPrimersKeys) {
        newPrimerMap.set(
          String(newPrimersKeys[primerKey]),
          currPrimerMap[newPrimersKeys[primerKey]]
        );
      }
      setPrimerMap(new Map(newPrimerMap));
      updateLabPrimerMapFromList(updatePrimerListFromMap(newPrimerMap));
      setSamples(res.data.samples);
      setPrimerOrder(newPrimersKeys);
      setIsLoadingForm(false);
      if (res.data.samples.length !== 0 || newPrimersKeys.length !== 0) {
        enqueueSnackbar('Your last saved data was loaded!', {
          letiant: 'info',
        });
      }
    }
  }, [
    setPrimerMap,
    setSamples,
    setPrimerOrder,
    setIsLoadingForm,
    enqueueSnackbar,
  ]);

  /**
   * Clear the entire form
   */
  const clearSangerForm = useCallback(() => {
    setPrimerMap(new Map());
    setPrimerList([]);
    setPrimerOrder([]);
    setLabPrimersMap(new Map());
    setSamples([]);
  }, [setPrimerMap, setPrimerList, setPrimerOrder, setSamples]);

  useEffect(() => {
    // Load the form whenever the page is loaded
    loadSangerForm();
  }, []);

  /**
   * Load submission form from excel file
   * @param selectedFile File to load form from
   */
  const uploadExcelFile = useCallback(
    async (selectedFile) => {
      const formData = new FormData();
      formData.append('clientSangerExcelUploadBody', selectedFile);
      const res = await uploadSangerExcel(formData);
      if (res.status === 200) {
        const newPrimers = new Map(Object.entries(res.data.primers.primerData));
        const newSamples = res.data.samples.sampleData;
        setPrimerMap(new Map(newPrimers));
        setSamples(newSamples);
        const newPrimerOrder = [];
        for (const [key, primers] of newPrimers.entries()) {
          newPrimerOrder.push(key);
        }
        setPrimerOrder(newPrimerOrder);
        setSubmitShowError(true);
      }
      return res;
    },
    [setPrimerMap, setPrimerOrder, setSamples, setSubmitShowError]
  );

  useEffect(() => {
    // Update reaction count and primer map whenever primer list or samples are updated
    let reactCountNum = 0;
    let primerCount = new Map();
    for (let i = 0; i < samples.length; i++) {
      reactCountNum += samples[i].primerRowList.length;
      if (samples[i].premixed) {
        reactCountNum += 1;
      }
      for (let j = 0; j < samples[i].primerRowList.length; j++) {
        let currentKey = samples[i].primerRowList[j];
        if (primerCount.get(currentKey) === undefined) {
          primerCount.set(currentKey, 1);
        } else {
          primerCount.set(currentKey, primerCount.get(currentKey) + 1);
        }
      }
    }
    setPrimerMap((primerMap) => {
      let primersCopy = primerMap;
      Array.from(primersCopy.keys()).forEach((key) => {
        let tempData = primersCopy.get(key);
        let count = 0;
        if (primerCount.has(key)) {
          count = primerCount.get(key);
        }
        tempData = { ...tempData, ...{ count: count } };
        primersCopy.set(key, tempData);
      });
      updatePrimerListFromMap(primersCopy);
      return primersCopy;
    });
    setReactionCount(reactCountNum);
  }, [setPrimerMap, samples, updatePrimerListFromMap]);

  /**
   * Add a new sample at a given index
   * @param rowIndex index to insert the sample at
   */
  const addSampleIndex = useCallback(
    (rowIndex) => {
      //assume you converted data to right format/structure
      setSamples((samples) => {
        const newSamples = [...samples];
        newSamples.splice(rowIndex, 0, { ...emptySampleData, id: uuidv4() });
        return newSamples;
      });
    },
    [setSamples]
  );

  /**
   * Add a new sample to the end of the sample list
   */
  const addSample = useCallback(() => {
    setSamples((samples) => {
      const newSamples = [...samples];
      newSamples.push({ ...emptySampleData, id: uuidv4() });
      return newSamples;
    });
  }, [setSamples]);

  /**
   * Update a specific field for a sample with the given id
   * @param updatedField  field to update
   * @param id            ID of the sample
   */
  const updateSample = useCallback(
    (updatedField, id) => {
      console.log(updatedField);
      setSamples((prevSamples) =>
        prevSamples.map(
          (item) =>
            item.id === id
              ? { ...item, ...updatedField } // Create a new object with the updated value
              : item // Leave the item unchanged if it's not the one to update
        )
      );
    },
    [setSamples]
  );

  /**
   * Remove the sample with a specific id
   * @param id  id of the sample to remove
   */
  const removeSample = useCallback(
    (id) => {
      setSamples((prevSamples) => prevSamples.filter((item) => item.id !== id));
    },
    [setSamples]
  );

  /**
   * Move a sample from startIdx to endIdx
   * @param startIdx  index of sample to move
   * @param endIdx    index to move sample to
   */
  const moveSample = useCallback(
    (startIdx, endIdx) => {
      setSamples((samples) => {
        const newSamples = [...samples];
        const movedSample = newSamples[startIdx];
        newSamples.splice(startIdx, 1);
        newSamples.splice(endIdx, 0, movedSample);
        return newSamples;
      });
    },
    [setSamples]
  );

  /*** primer manipulations */

  /**
   * Update the primer map with values from the object
   * @param key       Key of the primer to edit
   * @param valueObj  Object containing values to add/override
   */
  const updatePrimerMap = useCallback(
    (key, valueObj) => {
      setPrimerMap((primerMap) => {
        const currentObj = primerMap.get(key) || {};
        const updatedObj = { ...currentObj, ...valueObj };
        primerMap.set(key, updatedObj);
        const newPrimerMap = new Map(primerMap);
        updatePrimerListFromMap(newPrimerMap);
        return newPrimerMap;
      });
    },
    [setPrimerMap]
  );

  /**
   * Add a new primer
   */
  const addPrimer = useCallback(() => {
    setPrimerMap((primerMap) => {
      let index = 0;
      while (primerMap.has(index.toString())) {
        // Find first unoccupied index
        index += 1;
      }
      const newPrimerMap = new Map(primerMap);
      newPrimerMap.set(index.toString(), { ...emptyPrimerData, id: uuidv4() });
      updatePrimerListFromMap(newPrimerMap);
      setPrimerOrder((primerOrder) => {
        const newPrimerOrder = [...primerOrder];
        newPrimerOrder.push(index.toString());
        return newPrimerOrder;
      });
      return newPrimerMap;
    });
  }, [setPrimerMap, setPrimerOrder, updatePrimerListFromMap]);

  /**
   * Add a primer at a specific index
   */
  const addPrimerIndex = useCallback(
    (index) => {
      setPrimerMap((primerMap) => {
        let i = 0;
        while (primerMap.has(i.toString())) {
          i += 1;
        }
        const newPrimerMap = new Map(primerMap);
        newPrimerMap.set(i.toString(), { ...emptyPrimerData, id: uuidv4() });
        setPrimerOrder((primerOrder) => {
          const newPrimerOrder = [...primerOrder];
          newPrimerOrder.splice(index, 0, i.toString());
          return newPrimerOrder;
        });
        return newPrimerMap;
      });
    },
    [setPrimerMap, setPrimerOrder]
  );

  /**
   * Update the map of the lab primers that have been added
   * @param key key of the lab primer
   */
  const updateLabPrimerMap = useCallback(
    (key) => {
      setLabPrimersMap((pickedLabPrimersMap) => {
        const newLabPrimers = new Map(pickedLabPrimersMap);
        newLabPrimers.set(key, true);
        return newLabPrimers;
      });
    },
    [setLabPrimersMap]
  );

  /**
   * Add a new lab primer
   * @param data
   */
  const addLabPrimer = useCallback(
    (data) => {
      let index = 0;
      setPrimerMap((primerMap) => {
        const newPrimerMap = new Map(primerMap);
        data.forEach((labPrimer) => {
          while (primerMap.has(index.toString())) {
            // Find first unoccupied map index
            index += 1;
          }
          const cur = index;
          setPrimerOrder((primerOrder) => {
            const newPrimerOrder = [...primerOrder];
            newPrimerOrder.push(cur.toString());
            return newPrimerOrder;
          });
          labPrimer.labPrimerId = labPrimer.primerID;
          labPrimer.labPrimer = true;
          const primerDataCopy = {
            ...emptyPrimerData,
            ...labPrimer,
            id: uuidv4(),
          };
          newPrimerMap.set(index.toString(), primerDataCopy);
          setLabPrimersMap((pickedLabPrimersMap) => {
            if (!pickedLabPrimersMap.has(labPrimer.primerID)) {
              return new Map(pickedLabPrimersMap.set(labPrimer.primerID, true));
            }
            return pickedLabPrimersMap;
          });
          index += 1;
        });
        updatePrimerListFromMap(newPrimerMap);
        return newPrimerMap;
      });
    },
    [setPrimerOrder, setPrimerMap, setLabPrimersMap]
  );

  /**
   * Remove a primer
   * @param key key of the primer to remove
   */
  const removePrimer = useCallback(
    (key) => {
      setPrimerMap((primerMap) => {
        const currPrimer = primerMap.get(key);
        if (currPrimer.labPrimer) {
          setLabPrimersMap((labPrimersMap) => {
            const pickedLabPrimersMapCopy = new Map(labPrimersMap);
            pickedLabPrimersMapCopy.delete(currPrimer.labPrimerId);
            return pickedLabPrimersMapCopy;
          });
        }
        setPrimerOrder((primerOrder) => {
          const newPrimerOrder = [...primerOrder];
          for (let i = 0; i < primerOrder.length; i++) {
            if (primerOrder[i] === key) {
              newPrimerOrder.splice(i, 1);
              break;
            }
          }
          return newPrimerOrder;
        });
        primerMap.delete(key);
        return new Map(primerMap);
      });
      setSamples((samples) => {
        const newSamples = [...samples];
        for (let i = 0; i < newSamples.length; i++) {
          let primerListInSample = newSamples[i].primerRowList;
          const index = primerListInSample.indexOf(key);
          if (index > -1) {
            // only splice array when item is found
            primerListInSample.splice(index, 1); // 2nd parameter means remove one item only
            newSamples[i].primerRowList = primerListInSample;
          }
        }
        return newSamples;
      });
    },
    [setPrimerMap, setSamples, setLabPrimersMap, setPrimerOrder]
  );

  /**
   * Move the key of a primer from startIdx to endIdx in the primer order list
   * @param startIdx  index of the primer to move
   * @param endIdx    end position of the primer
   */
  const movePrimer = useCallback(
    (startIdx, endIdx) => {
      setPrimerOrder((primerOrder) => {
        const movedPrimer = primerOrder[startIdx];
        const newPrimerOrder = [...primerOrder];
        newPrimerOrder.splice(startIdx, 1);
        newPrimerOrder.splice(endIdx, 0, movedPrimer);
        return newPrimerOrder;
      });
    },
    [setPrimerOrder]
  );

  // Memoized values for DataContext
  const dataContextValue = useMemo(
    () => ({
      showAllErrors: showAllErrors,
      submitShowError: submitShowError,
    }),
    [showAllErrors, submitShowError]
  );

  const formContextValue = useMemo(
    () => ({
      updateSangerForm: updateSangerForm,
      loadSangerForm: loadSangerForm,
      clearSangerForm: clearSangerForm,
      uploadExcelFile: uploadExcelFile,
    }),
    [updateSangerForm, loadSangerForm, clearSangerForm, uploadExcelFile]
  );

  const sampleContextValue = useMemo(
    () => ({
      addSample: addSample,
      addSampleIndex: addSampleIndex,
      updateSample: updateSample,
      removeSample: removeSample,
      moveSample: moveSample,
      setShowAllErrors: setShowAllErrors,
    }),
    [
      addSample,
      addSampleIndex,
      updateSample,
      removeSample,
      moveSample,
      setShowAllErrors,
    ]
  );

  const primerContextValue = useMemo(
    () => ({
      addPrimer: addPrimer,
      addPrimerIndex: addPrimerIndex,
      addLabPrimer: addLabPrimer,
      updatePrimerMap: updatePrimerMap,
      updateLabPrimerMap: updateLabPrimerMap,
      removePrimer: removePrimer,
      movePrimer: movePrimer,
    }),
    [
      addPrimer,
      addPrimerIndex,
      addLabPrimer,
      updatePrimerMap,
      updateLabPrimerMap,
      removePrimer,
      movePrimer,
    ]
  );

  return (
    <ContextProvider
      formContextValue={formContextValue}
      sampleContextValue={sampleContextValue}
      primerContextValue={primerContextValue}
      dataContextValue={dataContextValue}
    >
      <Grid
        container
        justifyItems="center"
        rowSpacing={5}
        sx={{ width: '90%', margin: '0 auto' }}
      >
        <Grid item xs={12}>
          <SangerFormHeading />
        </Grid>
        <Grid item xs={12}>
          <SangerInfoDropdown />
        </Grid>
        {/* TODO: Put warning here in case screen size is too small */}
        <Grid item md={6} xs={12}>
          <SangerPrimerTable
            primerOrder={primerOrder}
            primerMap={primerMap}
            labPrimersMap={labPrimersMap}
            primerColors={primerColors.current}
            formik={formik}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SangerClientNotes
            clientNotes={clientNotes}
            setClientNotes={setClientNotes}
          />
        </Grid>
        <Grid item xs={12}>
          <SangerSampleTable
            samples={samples}
            formik={formik}
            reactionCount={reactionCount}
            primerMap={primerMap}
            primerColors={primerColors.current}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginRight: 10 }}>
          <SangerFormSubmit
            onUpdate={updateSangerForm}
            onSubmit={handleErrorPopup}
          />
        </Grid>
      </Grid>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={isLoadingForm}>
        <RotateLoader />
      </Backdrop>
      <ErrorFormPopup
        open={errorPopup}
        handler={setErrorPopup}
        samples={formik.errors.samples}
        primerList={formik.errors.primerMapToList}
        primerData={primerList}
      />
    </ContextProvider>
  );
};

const ContextProvider = ({
  formContextValue,
  sampleContextValue,
  primerContextValue,
  dataContextValue,
  children,
}) => (
  <FormMethodContext.Provider value={formContextValue}>
    <SampleMethodContext.Provider value={sampleContextValue}>
      <PrimerMethodContext.Provider value={primerContextValue}>
        <ErrorVisContext.Provider value={dataContextValue}>
          {children}
        </ErrorVisContext.Provider>
      </PrimerMethodContext.Provider>
    </SampleMethodContext.Provider>
  </FormMethodContext.Provider>
);

export default Sanger;
