import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { memo, useContext, useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { ErrorVisContext } from '../../../contexts/DataContext';
import { SampleMethodContext } from '../../../contexts/MethodContext';

const CHIP_MAX_WIDTH = 100;
const CHIP_ICON_WIDTH = 30;

const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EllipsisText = (props) => {
  const { children } = props;

  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: CHIP_MAX_WIDTH - CHIP_ICON_WIDTH,
      }}
    >
      {children}
    </div>
  );
};

const SangerRowPrimers = memo(
  ({ id, data, errorPrimers, primerMap, primerColors }) => {
    const { reactionCount, submitShowError } = useContext(ErrorVisContext);

    const { updateSample } = useContext(SampleMethodContext);

    const reactCountLimit = reactionCount >= 96;

    const [selectedPrimers, setSelectedPrimers] = useState([]);
    useEffect(() => {
      let selectedPrimersCopy = [];
      data.primerRowList.forEach((key) => {
        if (primerMap.get(key) !== undefined) {
          selectedPrimersCopy.push({
            key: key,
            primerName: primerMap.get(key).primerName,
          });
        }
      });
      setSelectedPrimers(selectedPrimersCopy);
    }, [data.primerRowList.length]);

    const updatePrimerListFromMap = (primerMap) => {
      // Update primerList whenever primerMap is updated
      const keyList = Array.from(primerMap.keys()); // List of the keys of the primers
      let primerMapToListTemp = [];
      for (let i = 0; i < keyList.length; i++) {
        primerMapToListTemp.push({
          key: keyList[i],
          primerName: primerMap.get(keyList[i])
            ? primerMap.get(keyList[i]).primerName
            : '',
          vol: primerMap.get(keyList[i])
            ? parseInt(primerMap.get(keyList[i]).vol)
            : 0,
          count: primerMap.get(keyList[i])
            ? primerMap.get(keyList[i]).count
            : 0,
          labPrimer: primerMap.get(keyList[i])
            ? primerMap.get(keyList[i]).labPrimer
            : false,
          labPrimerId: primerMap.get(keyList[i])
            ? primerMap.get(keyList[i]).labPrimerId
            : 0,
        });
      }
      return primerMapToListTemp;
    };

    const [primerList, setPrimerList] = useState([]);

    useEffect(() => {
      setPrimerList(updatePrimerListFromMap(primerMap));
    }, [primerMap]);

    return (
      <Autocomplete
        freeSolo
        multiple
        disableCloseOnSelect
        size="small"
        id="primerList"
        variant="outlined"
        options={primerList}
        value={selectedPrimers}
        disabled={primerList.length === 0 || data.premixed}
        onBlur={(e) => {
          let newTouched = [...data.touched];
          newTouched[1] = true;
          updateSample({ touched: newTouched }, id);
        }}
        // getOptionDisabled={option => option.primerName == ''}
        getOptionLabel={(option) => option.primerName}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        getOptionDisabled={(option) =>
          reactCountLimit && !data.primerRowList.includes(option.key)
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              style={{
                backgroundColor: primerColors.get(option.key),
                height: 22,
              }}
              variant="outlined"
              // label={<div style={{fontSize: 15}}>{primers.get(option.key) ? primers.get(option.key).primerName : ""}</div>}
              label={
                <EllipsisText>
                  {primerMap.get(option.key)
                    ? primerMap.get(option.key).primerName
                    : ''}
                </EllipsisText>
              }
              {...getTagProps({ index })}
            />
          ))
        }
        onChange={async (e, currData, reason, detail) => {
          if (reason === 'remove-option') {
            console.log(detail.option);
            console.log('DETAIL OPTION');
          }
          let newList = [];
          currData.forEach((dataSelected, index) => {
            if (typeof dataSelected === 'object') {
              newList.push(dataSelected.key);
            }
          });
          let newTouched = [...data.touched];
          newTouched[1] = true;
          updateSample(
            {
              primerRowList: newList,
              touched: newTouched,
            },
            id
          );
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{ backgroundColor: primerColors.get(option.key) }}
          >
            <Checkbox
              icon={uncheckedIcon}
              checkedIcon={checkedIcon}
              style={{ marginLeft: -20, marginRight: -5 }}
              checked={selected}
            />
            <div
              style={{ flexWrap: 'wrap', wordWrap: 'break-word', width: 140 }}
            >
              {option.primerName}
            </div>
          </li>
        )}
        sx={{
          '& .MuiFormControl-root': {
            border: 'none',
            width: '100%',
            '& .MuiInputBase-input': {
              width: '100%',
            },
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: 'none',
            },
            '&.Mui-focused fieldset': {
              border: 'none',
            },
          },
          flex: 1,
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onBlur={() => {
              let newTouched = [...data.touched];
              newTouched[1] = true;
              updateSample({ touched: newTouched }, id);
            }}
            placeholder={data.premixed ? 'Sample is premixed' : 'Primers'}
            error={
              (data.touched[1] || submitShowError) &&
              errorPrimers(data.primerRowList, data.premixed)
            }
          />
        )}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id &&
      prevProps.data.primerRowList === nextProps.data.primerRowList &&
      prevProps.data.touched[1] === nextProps.data.touched[1] &&
      prevProps.data.premixed === nextProps.data.premixed &&
      prevProps.primerMap === nextProps.primerMap &&
      prevProps.primerList === nextProps.primerList
    );
  }
);

export default SangerRowPrimers;
