import {
  Button,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import UserAutoComplete from './UsersDropDown';
import {
  getAllOrders,
  getAllUsers,
  cancelCalendarEvent,
  deleteCalendarEvent,
  updateCalendarEvent,
  getAllCalendarEvents,
} from 'utils/api/Calendar';
import { DateTime } from 'luxon';

const EventForm = ({ chosenEvent, setDisplayModal }) => {
  // Variables and States used throughout this file
  const [title, setTitle] = useState(chosenEvent.title);
  // const [startDateTime, setStartDateTime] = useState(chosenEvent.start);
  const [startDateTime, setStartDateTime] = useState(
    DateTime.fromJSDate(chosenEvent.start)
  );
  // const [endDateTime, setEndDateTime] = useState(chosenEvent.end);
  const [endDateTime, setEndDateTime] = useState(
    DateTime.fromJSDate(chosenEvent.end)
  );
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [eventOrderCode, setEventOrderCode] = useState();
  const [eventStage, setEventStage] = useState();
  const [eventDropLocation, setEventDropLocation] = useState();
  const [eventNoOfSamples, setEventNoOfSamples] = useState();
  const [usersForCalendar, setUsersForCalendar] = useState();
  const [currentUsersArr, setCurrentUsersArr] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [taskLocation, setTaskLocation] = useState(chosenEvent.location);
  const [taskStatus, setTaskStatus] = useState(chosenEvent.taskStatus);
  const taskStatusOptions = ['IN PROGRESS', 'PROCESSED'];
  const [openUsers, setOpenUsers] = React.useState(false);
  const [submitLoadUsers, setSubmitLoadUsers] = useState(false);
  const loadingUsersDropdown = openUsers && usersForCalendar.length === 0;
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [originalStage, setOriginalStage] = useState();

  // Handeling methods
  useEffect(() => {
    fetchOrdersForCalendar();
    fetchUsersForCalendar();
  }, []);

  const fetchOrdersForCalendar = async () => {
    const res = await getAllOrders();
    const res1 = await getAllCalendarEvents();
    if (res.status === 200 && res1.status === 200) {
      setLoadingOrders(false);
      // Add this section to retrieve the current event's orderCode and stage
      for (let i = 0; i < res.data.length; ++i) {
        if (res.data[i]._id === chosenEvent.orderId) {
          setEventOrderCode(res.data[i].orderCode);
          setEventStage(res.data[i].stage);
          setEventDropLocation(
            res.data[i].dropLocation ? res.data[i].dropLocation : 'N/A'
          );
          setEventNoOfSamples(res.data[i].noOfSamples);
          break;
        }
      }
      for (let i = 0; i < res1.data.length; ++i) {
        if (chosenEvent.orderId === res1.data[i].orderId) {
          setOriginalStage(res1.data[i].stage);
          break;
        }
      }
    } else {
      console.log('Error in getting Orders for calendar...');
    }
  };

  const fetchUsersForCalendar = async () => {
    const res = await getAllUsers();
    let currentUsers = [];
    if (res.status === 200) {
      // Add this section to retrieve the current technicicans information
      for (let i = 0; i < chosenEvent.receivingUserId.length; ++i) {
        for (let j = 0; j < res.data.length; ++j) {
          if (chosenEvent.receivingUserId[i] === res.data[j]._id) {
            currentUsers.push(res.data[j]);
            break;
          }
        }
      }
      setCurrentUsersArr(currentUsers);
      setUsersForCalendar(res.data);
      setLoadingUsers(false);
    } else {
      console.log('Error in getting Users for calendar...');
    }
  };

  const handleCancel = async (e) => {
    e.preventDefault();

    let isUsersChange = false;
    let finalDescription =
      'Order: ' +
      eventOrderCode +
      '\nSample Count: ' +
      eventNoOfSamples +
      '\nDropLocation: ' +
      eventDropLocation;

    const event = {
      id: chosenEvent._id,
      eventUid: chosenEvent.uid,
      orderId: chosenEvent.orderId,
      receivingUserId: chosenEvent.receivingUserId,
      start: startDateTime,
      end: endDateTime,
      title: title,
      stage: chosenEvent.stage,
      description: finalDescription,
      changeUsers: isUsersChange,
      newUsers: null,
      sequence: chosenEvent.sequence,
    };

    const res = await cancelCalendarEvent(event);
    if (res.status === 200) {
      alert('Cancel Event Successfully!');
      window.location.reload();
    } else {
      alert('something went wrong!');
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const event = {
      id: chosenEvent._id,
    };
    const res = await deleteCalendarEvent(event);
    if (res.status === 200) {
      alert('Delete Event Successfully!');
      setDisplayModal(false);
      setSubmitLoadUsers(true);
    } else {
      alert('something went wrong!');
      setSubmitLoadUsers(false);
    }
  };

  const handleUpdateWithoutEmail = async (e) => {
    e.preventDefault();

    // Determine if Users/Technicians are being changed
    let isUsersChange = false;
    if (selectedUsers.length > 0) {
      isUsersChange = true;
    }

    let finalDescription =
      'Order: ' +
      eventOrderCode +
      '\nSample Count: ' +
      eventNoOfSamples +
      '\nDropLocation: ' +
      eventDropLocation +
      '\nWork format: In person.' +
      '\nLocation: ' +
      taskLocation;

    const event = {
      id: chosenEvent._id,
      eventUid: chosenEvent.uid,
      orderId: chosenEvent.orderId,
      receivingUserId: chosenEvent.receivingUserId,
      start: startDateTime,
      end: endDateTime,
      title: title,
      stage: chosenEvent.stage,
      description: finalDescription,
      changeUsers: isUsersChange,
      newUsers: selectedUsers,
      sequence: chosenEvent.sequence,
      location: taskLocation,
      taskStatus: taskStatus,
      sendEmail: false,
    };
    const res = await updateCalendarEvent(event);
    if (res.status === 200) {
      alert('Update Successfully!');
      setDisplayModal(false);
      setSubmitLoadUsers(true);
    } else {
      alert('something went wrong!');
      setSubmitLoadUsers(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      users: [],
    },
    onSubmit: async (values) => {
      // Determine if Users/Technicians are being changed
      let isUsersChange = false;
      if (selectedUsers.length > 0) {
        isUsersChange = true;
      }

      let finalDescription =
        'Order: ' +
        eventOrderCode +
        '\nSample Count: ' +
        eventNoOfSamples +
        '\nDropLocation: ' +
        eventDropLocation +
        '\nWork format: In person.' +
        '\nLocation: ' +
        taskLocation;

      const event = {
        id: chosenEvent._id,
        eventUid: chosenEvent.uid,
        orderId: chosenEvent.orderId,
        receivingUserId: chosenEvent.receivingUserId,
        start: startDateTime,
        end: endDateTime,
        title: title,
        stage: chosenEvent.stage,
        description: finalDescription,
        changeUsers: isUsersChange,
        newUsers: selectedUsers,
        sequence: chosenEvent.sequence,
        location: taskLocation,
        taskStatus: taskStatus,
        sendEmail: true,
      };
      const res = await updateCalendarEvent(event);
      if (res.status === 200) {
        alert('Update Successfully!');
        setDisplayModal(false);
        setSubmitLoadUsers(true);
      } else {
        alert('something went wrong!');
        setSubmitLoadUsers(false);
      }
    },
  });

  const handleChangeUsers = (event, newValues) => {
    formik.setFieldValue('users', newValues);
    setSelectedUsers(newValues);
  };

  const handleChangeTaskStatus = (event) => {
    setTaskStatus(event.target.value);
  };

  // Start rendering...
  if (loadingOrders || loadingUsers) {
    return <h2>Loading Data for Calendar ...</h2>;
  }

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <h2>{title}</h2>
      <TextField
        label="Event Name"
        onChange={(e) => setTitle(e.target.value)}
        required
        variant="outlined"
        color="secondary"
        type="Title"
        sx={{ mb: 3 }}
        fullWidth
        value={title}
      />
      <Divider
        component="li"
        style={{
          marginBottom: 15,
          borderBottomWidth: 3,
          borderBottomColor: 'gray',
        }}
      />
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-us">
        <DateTimePicker
          label="Start"
          value={startDateTime}
          onChange={(newValue) => setStartDateTime(newValue)}
          renderInput={(params) => <TextField {...params} sx={{ mb: 3 }} />}
        />
        <DateTimePicker
          label="End"
          value={endDateTime}
          onChange={(newValue) => setEndDateTime(newValue)}
          renderInput={(params) => <TextField {...params} sx={{ mb: 3 }} />}
        />
      </LocalizationProvider>
      <h6>Duration:</h6>
      <div style={{ marginBottom: 15 }}>
        {endDateTime - startDateTime > 0
          ? ((endDateTime - startDateTime) / (60 * 60 * 1000)).toFixed(2) +
            ' hour(s)'
          : 'Invalid Duration'}
      </div>
      <TextField
        label="Location"
        onChange={(e) => setTaskLocation(e.target.value)}
        variant="outlined"
        color="secondary"
        type="Title"
        sx={{ mb: 3 }}
        fullWidth
        value={taskLocation}
      />
      <div style={{ marginBottom: 15 }}>
        <FormControl sx={{ width: '45%' }}>
          <InputLabel id="task-status-bar">Task Status</InputLabel>
          <Select
            id="taks-status-bar"
            value={taskStatus}
            onChange={handleChangeTaskStatus}
            sx={{
              color: taskStatus === 'PROCESSED' ? 'green' : 'red',
              fontWeight: 500,
            }}
          >
            {taskStatusOptions.map((value, index) => {
              return (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <h6>
        After changing time/location, click "Update" button below to update.
      </h6>
      <Divider
        component="li"
        style={{
          marginBottom: 15,
          borderBottomWidth: 3,
          borderBottomColor: 'gray',
        }}
      />
      <h6>Order being assigned & Original stage:</h6>
      <div style={{ marginBottom: 15 }}>
        {eventOrderCode + ' - Stage: ' + originalStage}
      </div>
      <h6>Order's current stage:</h6>
      <div style={{ marginBottom: 15 }}>{'Current Stage: ' + eventStage}</div>
      <h6>Current assigned technicians:</h6>
      <div style={{ marginBottom: 15 }}>
        {currentUsersArr.length > 0
          ? currentUsersArr.map((eachUser) => {
              return (
                <div> {eachUser.firstName + ' ' + eachUser.lastName} </div>
              );
            })
          : 'None'}
      </div>
      <Divider
        component="li"
        style={{
          marginBottom: 15,
          borderBottomWidth: 3,
          borderBottomColor: 'gray',
        }}
      />
      <div style={{ marginBottom: 15 }}>
        <h5>Change the technicians responsible for this task</h5>
        <h6>
          First: Cancel this event before choosing new technicians, page will be
          reloaded:
        </h6>
        <Button
          variant="contained"
          type="submit"
          onClick={handleCancel}
          style={{
            marginBottom: 10,
            backgroundColor: '#008AFC',
            color: 'white',
          }}
        >
          Cancel This Event
        </Button>
        <h6>
          Second: Assign to new technicians, then click "Update & Send Email"
          button below:
        </h6>
        <UserAutoComplete
          value={formik.values.users}
          onChange={(event, value) => handleChangeUsers(event, value)}
          open={openUsers}
          setOpen={setOpenUsers} // This prop assumes that the OrderAutocomplete component accepts this prop to handle open state changes
          submitLoad={submitLoadUsers}
          options={usersForCalendar}
          loading={loadingUsersDropdown}
          error={formik.touched.users && Boolean(formik.errors.users)}
          helperText={formik.touched.users && formik.errors.users}
        />
      </div>
      <Button
        variant="contained"
        type="submit"
        style={{ marginBottom: 15, backgroundColor: '#008AFC', color: 'white' }}
      >
        Update & Send Email
      </Button>
      <Button
        variant="contained"
        type="submit"
        onClick={handleUpdateWithoutEmail}
        style={{ marginBottom: 15, backgroundColor: '#008AFC', color: 'white' }}
      >
        Update Without Sending Email
      </Button>
      <Divider
        component="li"
        style={{
          marginBottom: 15,
          borderBottomWidth: 3,
          borderBottomColor: 'gray',
        }}
      />
      <div style={{ marginBottom: 15 }}>
        <h5>Delete this event from the Calendar</h5>
        <h6>
          First: Cancel this event using the "Cancel This Event" button above,
          page will be reloaded.
        </h6>
        <h6>
          Second: Click "Delete This Event" button below to remove this event
          from the calendar:
        </h6>
        <Button
          variant="contained"
          type="submit"
          onClick={handleDelete}
          style={{
            marginBottom: 10,
            backgroundColor: '#008AFC',
            color: 'white',
          }}
        >
          Delete This Event
        </Button>
      </div>
    </form>
  );
};

export default EventForm;
