import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default function ExpandMoreRecent5(props) {
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 2000,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 450,
      }}
    >
      {props.inventoryItems.length === 0 ? (
        <></>
      ) : (
        props.inventoryItems.map((item1) => (
          <>
            <ListItem key={item1._id} alignItems="flex-start">
              <ListItemText
                primary={
                  <>
                    <span>{item1.message}</span>
                  </>
                }
                primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
              />
            </ListItem>
          </>
        ))
      )}
    </List>
  );
}
