import { getPIOrders } from 'utils/api/ClientOrders';

export const getInitialPIData = async (
  setData,
  setOrderReceived,
  ordersReceived,
  user
) => {
  if (ordersReceived === false) {
    try {
      const res = await getPIOrders(user);
      let dataToBeUsed = [];
      res.data.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (el == '__v') {
          } else {
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        dataToBeUsed = [...dataToBeUsed, oneRow];
      });
      setData(dataToBeUsed);
      setOrderReceived(true);
    } catch (error) {
      console.log(error);
    }
  }
};
