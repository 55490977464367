import axios from 'axios';

export const getAllEmployees = async (adminTable) => {
  try {
    const res = await axios({
      method: 'GET',
      url: '/api/employee/allEmployee',
      params: {
        admin: adminTable,
        returnAsArray: false,
      },
    });
    let dataToBeUsed = [];
    res.data.forEach((element) => {
      let oneRow = {};
      Object.keys(element).forEach((el) => {
        if (el == '__v') {
        } else {
          oneRow = { ...oneRow, [el]: element[el] };
        }
      });
      dataToBeUsed = [...dataToBeUsed, oneRow];
    });
    return dataToBeUsed;
  } catch (error) {
    console.log('Error getting all employees:', error);
    throw error;
  }
};

export const deleteEmployee = async (employee) => {
  try {
    const response = await axios.delete('/api/employee/delete', {
      data: employee,
    });
    return response;
  } catch (error) {
    console.log('Error deleting employee:', error);
    throw error;
  }
};
