import React, { useState, useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import UploadedOrders from './components/UploadedOrders';
import { useNavigate } from 'react-router-dom';
import CurrentOrdersTable from './components/CurrentOrdersTable';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { SnackbarProvider } from 'notistack';
import { socket, SocketContext } from 'context/socket';
import Grid from '@mui/material/Grid';

import { useSnackbar } from 'notistack';
import AuthContext from 'utils/auth/auth';
import { FormControl } from '@mui/material';
import { RadioGroup } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { Radio } from '@mui/material';
import BillingSummaryChart from './components/BillingSummary/BillingSummaryChart';
import CurrentResearchersTable from './components/Researchers/CurrentResearchersTable';
import { getNewUser } from 'utils/api/User';
import { getIsPI } from './utils/getIsPI';
import { getDroppedOrders } from './utils/getDroppedOrders';
import { TabPanel } from './components/TabPanel';
import { getInitialPIData } from './utils/getInitialPIData';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const Orders = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [current, setCurrent] = React.useState('all');
  const [data, setData] = useState([]);
  const [myData, setMyData] = useState([]);
  const [ordersReceived, setOrderReceived] = useState(false);
  const [myOrdersReceived, setMyOrderReceived] = useState(false);

  const [isPI, setIsPI] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const user = useContext(AuthContext);

  getNewUser(user).catch(function (error) {
    if (error.response.status === 302) {
      navigate('/profile', { replace: true });
    }
  });

  useEffect(() => {
    getIsPI(user.casUser).then((data) => {
      setIsPI(data);
    });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCurrentTableChange = (event, newValue) => {
    setCurrent(newValue);
  };

  useEffect(() => {
    socket.on('updateOthersUploadedOrders', (msg) => {
      setOrderReceived(false);
      enqueueSnackbar(msg, { variant: 'info' });
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelfUploadedOrders', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('connect', () => console.log(socket.id));
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => console.log('Server disconnected'));
  }, []);

  useEffect(() => {
    getInitialPIData({
      setData,
      setOrderReceived,
      ordersReceived,
      user,
    }).catch(console.error);
  }, [ordersReceived]);

  useEffect(() => {
    getDroppedOrders({
      setMyData,
      setMyOrderReceived,
      myOrdersReceived,
      user,
    }).catch(console.error);
  }, [myOrdersReceived]);

  if (!isPI) {
    return (
      <Container>
        <Row>
          <UploadedOrders />
        </Row>
        <br></br>
        <br></br>
        <Row>
          <CurrentOrdersTable orders={myData} />
        </Row>
      </Container>
    );
  } else {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Row>
          <UploadedOrders />
        </Row>
        <br></br>
        <SocketContext.Provider value={socket}>
          <SnackbarProvider maxSnack={3}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Orders" {...a11yProps(0)} />
                  <Tab label="Researchers" {...a11yProps(1)} />
                  <Tab label="Billing Summary" {...a11yProps(2)} />
                  <div style={{ marginLeft: 'auto' }}></div>
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Container>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="all"
                      onChange={handleCurrentTableChange}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio color="primary" />}
                        label="All Orders"
                      />
                      <FormControlLabel
                        value="onlyMy"
                        control={<Radio color="primary" />}
                        label="Only My Orders"
                      />
                    </RadioGroup>
                  </FormControl>
                  <br></br>
                  <br></br>
                  <Row>
                    {current === 'all' ? (
                      <CurrentOrdersTable orders={data} />
                    ) : (
                      <CurrentOrdersTable orders={myData} />
                    )}
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Container>
                  <Row>
                    <CurrentResearchersTable />
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <BillingSummaryChart orders={data} />
              </TabPanel>
            </Box>
          </SnackbarProvider>
        </SocketContext.Provider>
      </Grid>
    );
  }
};

export default Orders;
