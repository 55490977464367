import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import './Dropdown.css';

function Dropdown(props) {
    const [click, setClick] = useState(false);

    const handleClick = () => props.setter(false);
    
    return (
        // click ? 
        // <>
        // </>
        // :
        <>
            <ul 
                onClick={handleClick}
                className='dropdown-menu'
            >
                {props.items.map((item, index) => {
                    return (
                        <li key={index}>
                            <Link 
                                className={item.cName} 
                                to={item.path} 
                                onClick={() => setClick(false)}
                            >
                                {item.title}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </>
    );
}

export default Dropdown;