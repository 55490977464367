import { headingStyle } from 'reusable/TableStyles';

export const PrimerTableHeading = () => {
  return (
    <thead>
      <tr>
        <th style={headingStyle}></th>
        <th style={headingStyle}>Primer Name</th>
        <th style={headingStyle}>Volume</th>
      </tr>
    </thead>
  );
};
