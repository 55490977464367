import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import RotateLoader from 'react-spinners/RotateLoader';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Fragment } from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const OrderAutocomplete = ({
  value,
  options,
  loading,
  error,
  helperText,
  onChange,
  open,
  setOpen,
  submitLoad,
}) => {
  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      sx={{ width: 300 }}
      open={open}
      multiple
      disableCloseOnSelect
      disabled={submitLoad}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => option.orderCode}
      getOptionSelected={(option, value) =>
        option.orderCode === value.orderCode
      }
      options={options}
      loading={loading}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            disabled={true}
            sx={{
              '&.Mui-disabled': {
                color: '#2196f3',
              },
            }}
          />
          {String(option.orderCode)}
          <div style={{ marginLeft: 'auto' }}>
            {String(' count: ' + option.noOfSamples)}
          </div>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          type="CCRMultiSelect"
          error={error}
          helperText={helperText}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <RotateLoader color="black" size={5} margin={-19} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default OrderAutocomplete;
