import CreateComboRun from './CreateComboRun';
import EditComboRun from './EditComboRun';
import MergeCRPopup from './MergeCRPopup';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';

export default function CRActionsBar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCCR, setOpenCCR] = useState(false);
  const [openECR, setOpenECR] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ paddingTop: '6px' }}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        CR Actions
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => setOpenCCR(true)}>Create New CR</MenuItem>
        <MenuItem onClick={() => setOpenECR(true)}> Edit CR</MenuItem>
        <MenuItem>
          <MergeCRPopup />
        </MenuItem>
      </Menu>
      <Modal
        open={openCCR}
        onClose={() => setOpenCCR(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CreateComboRun handleClose={() => setOpenCCR()} />
      </Modal>
      <Modal
        open={openECR}
        onClose={() => setOpenECR(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <EditComboRun handleClose={() => setOpenECR()} />
      </Modal>
    </div>
  );
}
