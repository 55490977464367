import { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import NotesRender from './NotesRender';
import EditButtonPopup from './Edit/EditButtonPopup';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from 'react-spinners/RotateLoader';
import { socket } from 'context/socket';
import {
  createProcessingOrder,
  deleteProcessingOrder,
  getProcessingOrder,
  updateDeliveredProcessingOrder,
} from 'utils/api/Inventory/ProcessingOrder/ProcessingOrder';

async function getInitialData({
  setGetLoad,
  setInitialData,
  inventoryidthis,
  setOrderReceived,
  ordersReceived,
}) {
  if (ordersReceived === false) {
    try {
      const dataToBeUsed = await getProcessingOrder({
        params: { inventoryItem: inventoryidthis },
      });
      console.log(dataToBeUsed);
      setInitialData(dataToBeUsed);
      setGetLoad(false);
      setOrderReceived(true);
    } catch (e) {
      throw e;
    }
  }
}

export default function ProcessingOrder(props) {
  const [getLoad, setGetLoad] = useState(true);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const [ordersReceived, setOrderReceived] = useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  let item = props.products;
  let inventoryidthis = item._id;

  useEffect(() => {
    socket.on('updateOthersProcessingOrder', (msg) => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelfProcessingOrder', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('connect', () => console.log(socket.id));
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => console.log('Server disconnected'));
  }, []);

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setInitialData,
      inventoryidthis,
      setOrderReceived,
      ordersReceived,
    }).catch(console.error);
  }, [ordersReceived]);

  const handleDelete = (thisinventory) => {
    return async () => {
      setSubmitLoad(true);
      try {
        const status = await deleteProcessingOrder({ data: thisinventory });
        if (status === 201) {
          socket.emit('deleteProcessingOrder', thisinventory.orderID);
          socket.emit(
            'deleteProcessingOrderstoInventory',
            thisinventory.orderID,
            item.catologNumber
          );
          setSubmitSucess(true);
          setSubmitResponse('Cancel sucessfully!');
        }
      } catch {
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      setSubmitStatus(true);
      setDisplayConfirmationModal(false);
      setSubmitLoad(false);
    };
  };

  const handleToggle = (values) => {
    return async (event) => {
      setSubmitLoad(true);
      try {
        let updated = { ...values, delivered: event.target.checked };
        const status = await updateDeliveredProcessingOrder(updated);
        if (status === 201) {
          socket.emit('updateProcessingOrder', updated.orderID);
          socket.emit(
            'updateProcessingOrderstoInventory',
            updated.orderID,
            item.catologNumber
          );
          setSubmitSucess(true);

          setSubmitResponse('mark the order as delivered sucessfully!');
        }
      } catch {
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      setSubmitStatus(true);
      setSubmitLoad(false);
    };
  };

  const validationSchema = Yup.object({
    quantity: Yup.number()
      .positive('Must be more than 0')
      .integer('Must be a integer'),
    delivered: Yup.boolean(),
    trackingNumber: Yup.string()
      .max(20, "Can't be larger than 20 characters")
      .min(1, 'Must be at least 1 character'),
    orderID: Yup.string()
      .max(20, "Can't be larger than 20 characters")
      .min(1, 'Must be at least 1 character'),
    notes: Yup.string()
      .max(500, "Can't be larger than 500 characters")
      .min(1, 'Must be at least 1 character'),
  });

  const formik = useFormik({
    initialValues: {
      quantity: 0,
      delivered: false,
      notes: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitLoad(true);
      try {
        let updated = { ...values, inventoryItem: inventoryidthis };
        const status = await createProcessingOrder(updated);
        if (status === 201) {
          socket.emit('addProcessingOrder', values.orderID);
          socket.emit(
            'addProcessingOrderstoInventory',
            values.orderID,
            item.catologNumber
          );
          setSubmitSucess(true);
          setSubmitResponse('Add order sucessfully!');
        } else if (status === 202) {
          setSubmitSucess(false);
          setSubmitResponse('Order ID already exist, please input another one');
        }
      } catch {
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      setSubmitStatus(true);
      setSubmitLoad(false);
    },
  });

  const handleClose = () => {
    setSubmitStatus(false);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
        <PropagateLoader />
      </Backdrop>

      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>

      <List
        sx={{
          width: '100%',
          maxWidth: 2000,
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 600,
        }}
      >
        {initialData.length === 0 ? (
          <></>
        ) : (
          initialData.map((item1) => (
            <>
              <ListItem key={item1._id} alignItems="flex-start">
                <ListItemText
                  primary={
                    <>
                      {' '}
                      <span>{'Order ID: ' + item1.orderID}</span>
                      <br></br>
                      <span>{'Tracking Number: ' + item1.trackingNumber}</span>
                      <br></br>
                      <span>{'Quantity: ' + item1.quantity}</span>
                      <br></br>
                      <div>
                        <span>{'Notes: '}</span>
                        <NotesRender thisinventory={item1} attri="notes" />
                      </div>
                    </>
                  }
                  primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                  secondary={`Ordered on ${new Date(
                    item1.orderDate
                  ).toDateString()}`}
                />
                <div>
                  <FormControlLabel
                    style={{ marginLeft: 15, marginRight: 15 }}
                    control={
                      <Switch edge="end" onChange={handleToggle(item1)} />
                    }
                    label="Delivered"
                  />
                </div>
                <br></br>
                <div>
                  <Button
                    variant="outlined"
                    onClick={handleDelete(item1)}
                    size="small"
                  >
                    Cancel Order
                  </Button>

                  <EditButtonPopup products={item1} />
                </div>
              </ListItem>
            </>
          ))
        )}
        <form onSubmit={formik.handleSubmit}>
          <Divider component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText primary={` Order ID:`} />
            <TextField
              id="orderID"
              name="orderID"
              label="Order ID"
              style={{ marginLeft: 15, marginRight: 15 }}
              value={formik.values.orderID}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.orderID)}
              helperText={formik.touched.name && formik.errors.orderID}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText primary={`Tracking Number:`} />
            <TextField
              id="trackingNumber"
              name="trackingNumber"
              label="Tracking Number"
              style={{ marginLeft: 15, marginRight: 15 }}
              value={formik.values.trackingNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.name && Boolean(formik.errors.trackingNumber)
              }
              helperText={formik.touched.name && formik.errors.trackingNumber}
            />
          </ListItem>
          <ListItem
            alignItems="flex-start"
            style={{ justifyContent: 'space-between' }}
          >
            <ListItemText primary={`Quantity:`} />

            <TextField
              style={{ marginLeft: 15, marginRight: 15 }}
              name="quantity"
              id="quantity"
              label="Quantity"
              type="number"
              size="medium"
              InputLabelProps={{
                shrink: true,
                startAdornment: (
                  <InputAdornment position="start">kg</InputAdornment>
                ),
              }}
              value={formik.values.quantity}
              onChange={formik.handleChange}
              error={formik.touched.quantity && Boolean(formik.errors.quantity)}
              helperText={formik.touched.quantity && formik.errors.quantity}
            />
          </ListItem>

          <ListItem alignItems="flex-start">
            <ListItemText primary={`Notes:`} />
            <TextField
              id="notes"
              name="notes"
              label="Notes"
              multiline
              value={formik.values.notes}
              onChange={formik.handleChange}
              error={formik.touched.notes && Boolean(formik.errors.notes)}
              helperText={formik.touched.notes && formik.errors.notes}
            />
          </ListItem>

          <ListItem
            alignItems="flex-start"
            style={{ justifyContent: 'space-between' }}
          >
            <LoadingButton
              size="medium"
              classes="profileSubmit"
              type="submit"
              variant="outlined"
              loading={submitLoad}
              loadingIndicator={
                <RotateLoader
                  cssOverride={{ left: '100%' }}
                  size={7}
                  margin={-15}
                />
              }
            >
              Make a new Order
            </LoadingButton>
          </ListItem>
        </form>
      </List>
    </Grid>
  );
}
