import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import TableMUI from './TableMUI';
import Dashboard from './components/Dashboard/Dashboard';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { socket, SocketContext } from 'context/socket';
import { useSnackbar } from 'notistack';
import { SnackbarProvider } from 'notistack';
import { getInventory } from 'utils/api/Inventory/Inventory';

async function getInitialData({
  setGetLoad,
  setProducts,
  setData,
  setOrderReceived,
  ordersReceived,
}) {
  if (ordersReceived === false) {
    try {
      const dataToBeUsed = await getInventory();
      setProducts(dataToBeUsed);
      setData(dataToBeUsed);
      setGetLoad(false);
      setOrderReceived(true);
    } catch (error) {
      throw error;
    }
  }
}

export default function Inventory(props) {
  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [getLoad, setGetLoad] = useState(true);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [ordersReceived, setOrderReceived] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [filterListCato, setFilterListCato] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const deliverOrderStatus = (temp) => {
    if (JSON.stringify(temp) == '[]') {
      setFilterList([]);
    } else {
      setFilterList([temp]);
    }
  };

  const CatologFilter = (temp) => {
    if (JSON.stringify(temp) == '[]') {
      setFilterListCato([]);
    } else {
      setFilterListCato([temp]);
    }
  };
  useEffect(() => {
    socket.on('updateOthers', (msg) => {
      setOrderReceived(false);
      enqueueSnackbar(msg, { variant: 'info' });
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelf', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateAll', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('connect', () => console.log(socket.id));
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => console.log('Server disconnected'));
  }, []);

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setProducts,
      setData,
      setOrderReceived,
      ordersReceived,
      products,
      enqueueSnackbar,
    }).catch(console.error);
  }, [ordersReceived]);

  const handleCloseSubmit = () => {
    setSubmitStatus(false);
  };

  return (
    <SocketContext.Provider value={socket}>
      <SnackbarProvider maxSnack={3}>
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={0}
          margin={0}
        >
          <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
            <PropagateLoader />
          </Backdrop>

          <Backdrop
            sx={{ color: '#fff', zIndex: 1 }}
            open={submitStatus}
            onClick={handleCloseSubmit}
          >
            {submitSucess ? (
              <Alert
                onClose={() => {
                  setSubmitStatus(false);
                }}
                icon={<CheckIcon fontSize="inherit" />}
                severity="success"
              >
                {submitResponse}
              </Alert>
            ) : (
              <Alert
                onClose={() => {
                  setSubmitStatus(false);
                }}
                severity="error"
              >
                {submitResponse}
              </Alert>
            )}
          </Backdrop>
          <br></br>
          <br></br>

          <Container>
            <Row>
              <Dashboard
                deliverOrderStatus={deliverOrderStatus}
                CatologFilter={CatologFilter}
              />
            </Row>

            <br></br>
            <br></br>

            <Row>
              <TableMUI
                products={products}
                filterList={filterList}
                filterListCato={filterListCato}
                deliverOrderStatus={deliverOrderStatus}
                CatologFilter={CatologFilter}
              />
            </Row>
          </Container>
        </Grid>
      </SnackbarProvider>
    </SocketContext.Provider>
  );
}
