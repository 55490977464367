import { ResponsiveBar } from '@nivo/bar';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import AuthContext from 'utils/auth/auth';
import React, { useState, useContext, useEffect } from 'react';
import BillingSummaryTable from './BillingSummaryTable';

const CustomBarLabel = ({ bars }) => {
  const labelMargin = 20;
  return bars.map(({ data: { value }, x, width, y }) => {
    return (
      <g transform={`translate(${x}, ${y - labelMargin})`}>
        <text
          x={width / 2}
          y={labelMargin / 2}
          textAnchor="middle"
          alignmentBaseline="central"
          style={{
            fontSize: 12,
            fill: 'rgb(51, 51, 51)',
          }}
        >
          {value}
        </text>
      </g>
    );
  });
};

async function getInitialData({
  setGetLoad,
  setData,
  setCostsReceived,
  costsReceived,
  orders,
  setTableData,
}) {
  if (costsReceived === false) {
    try {
      let dataToBeUsed = [];
      orders.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (
            el == 'noOfSamples' ||
            el == 'billingCycle' ||
            el == 'billingStatus' ||
            el == 'fragmentNoOfSamples' ||
            el == 'sangerNoOfSamples' ||
            el == 'price'
          ) {
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        dataToBeUsed = [...dataToBeUsed, oneRow];
      });

      dataToBeUsed = calculateCosts(dataToBeUsed, setTableData);
      setData(dataToBeUsed);
      setGetLoad(false);
      setCostsReceived(true);
    } catch (error) {
      console.log(error);
    }
  } else {
    //DO Nothing
  }
}

// calculate costs by adding price per order
function calculateCosts(dataToBeUsed, setTableData) {
  var semesterSort = require('semester-sort');
  let semesters = [];
  let records = [];
  let result = {};
  dataToBeUsed.forEach((element) => {
    if (element.billingCycle in result) {
      result[element.billingCycle]['fragmentNoOfSamples'] =
        result[element.billingCycle]['fragmentNoOfSamples'] +
        element.fragmentNoOfSamples;
      result[element.billingCycle]['sangerNoOfSamples'] =
        result[element.billingCycle]['sangerNoOfSamples'] +
        element.sangerNoOfSamples;
      // handle orders with no price variable by just adding 0
      if (element.price === undefined) {
      } else {
        result[element.billingCycle]['pCost'] =
          element.billingStatus === 'Pending'
            ? result[element.billingCycle]['pCost'] + element.price
            : result[element.billingCycle]['pCost'] + 0;
        result[element.billingCycle]['bCost'] =
          element.billingStatus === 'Billed'
            ? result[element.billingCycle]['bCost'] + element.price
            : result[element.billingCycle]['bCost'] + 0;
        result[element.billingCycle]['cost'] =
          result[element.billingCycle]['cost'] + element.price;
      }
    } else {
      let row = {
        billingCycle: element.billingCycle,
        fragmentNoOfSamples: element.fragmentNoOfSamples,
        sangerNoOfSamples: element.sangerNoOfSamples,
        pCost: 0,
        bCost: 0,
        cost: 0,
      };
      if (element.price === undefined) {
      } else {
        row.pCost = element.billingStatus === 'Pending' ? element.price : 0;
        row.bCost = element.billingStatus === 'Billed' ? element.price : 0;
        row.cost = element.price;
      }
      result = { ...result, [element.billingCycle]: row };
      semesters = [...semesters, element.billingCycle];
    }
  });

  semesters = semesters.sort(semesterSort);
  Object.keys(result).forEach((el) => {
    records = [...records, result[el]];
  });
  records.sort(
    (a, b) =>
      semesters.indexOf(a.billingCycle) - semesters.indexOf(b.billingCycle)
  );
  setTableData(records);

  if (records.length > 3) {
    records = records.slice(records.length - 4);
    records[3]['billingCycle'] = records[3]['billingCycle'] + ' (current)';
  } else {
    records[records.length - 1]['billingCycle'] =
      records[records.length - 1]['billingCycle'] + ' (current)';
  }
  console.log(records);

  return records;
}

export default function BillingSummaryChart(props) {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const orders = props.orders;
  const [costsReceived, setCostsReceived] = useState(false);
  const [getLoad, setGetLoad] = useState(true);
  const [checked, setChecked] = React.useState([true, true, true, true, true]);
  const keys = [
    'fragmentNoOfSamples',
    'sangerNoOfSamples',
    'pCost',
    'bCost',
    'cost',
  ];
  const [subKeys, setSubKeys] = useState(keys);
  const colors = ['#e2cd74', '#95baa5', '#cc9987', '#8db3b7', '#7080a0'];
  const [subColors, setSubColors] = useState(colors);

  const user = useContext(AuthContext);

  const handleChangeAll = (event) => {
    const checkedStatus = event.target.checked;
    setChecked([
      checkedStatus,
      checkedStatus,
      checkedStatus,
      checkedStatus,
      checkedStatus,
    ]);
    if (checkedStatus) {
      setSubKeys(keys);
      setSubColors(colors);
    } else {
      setSubKeys([null]);
    }
  };
  const handleChange = (event, index) => {
    var temp = [];
    var tempKeys = [];
    var tempColors = [];

    for (let i = 0; i < checked.length; i++) {
      temp[i] = checked[i];
      if (index == i) {
        temp[i] = event.target.checked;
      }
      if (temp[i]) {
        tempKeys.push(keys[i]);
        tempColors.push(colors[i]);
      }
    }
    setChecked(temp);
    setSubKeys(tempKeys);
    setSubColors(tempColors);
  };

  const children = (
    <FormGroup aria-label="position" row>
      <FormControlLabel
        value="fragment"
        control={
          <Checkbox
            checked={checked[0]}
            onChange={(event) => {
              handleChange(event, 0);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
              color: '#e2cd74',
              '&.Mui-checked': {
                color: '#e2cd74',
              },
            }}
          />
        }
        label="Fragment"
        labelPlacement="bottom"
      />
      <FormControlLabel
        value="sanger"
        control={
          <Checkbox
            checked={checked[1]}
            onChange={(event) => {
              handleChange(event, 1);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
              color: '#95baa5',
              '&.Mui-checked': {
                color: '#95baa5',
              },
            }}
          />
        }
        label="Sanger"
        labelPlacement="bottom"
      />
      <FormControlLabel
        value="pending"
        control={
          <Checkbox
            checked={checked[2]}
            onChange={(event) => {
              handleChange(event, 2);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
              color: '#cc9987',
              '&.Mui-checked': {
                color: '#cc9987',
              },
            }}
          />
        }
        label="Pending"
        labelPlacement="bottom"
      />
      <FormControlLabel
        value="billed"
        control={
          <Checkbox
            checked={checked[3]}
            onChange={(event) => {
              handleChange(event, 3);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
              color: '#8db3b7',
              '&.Mui-checked': {
                color: '#8db3b7',
              },
            }}
          />
        }
        label="Billed"
        labelPlacement="bottom"
      />
      <FormControlLabel
        value="total"
        control={
          <Checkbox
            checked={checked[4]}
            onChange={(event) => {
              handleChange(event, 4);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
              color: '#7080a0',
              '&.Mui-checked': {
                color: '#7080a0',
              },
            }}
          />
        }
        label="Total"
        labelPlacement="bottom"
      />
    </FormGroup>
  );

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setData,
      setCostsReceived,
      costsReceived,
      orders,
      setTableData,
    }).catch(console.error);
  }, [costsReceived]);

  useEffect(() => {}, [checked]);

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
          <PropagateLoader />
        </Backdrop>
        <div style={{ padding: '10px' }}>
          <h3>Billing Summary Chart</h3>
        </div>
        <FormGroup aria-label="position" row>
          <FormControlLabel
            label="All"
            labelPlacement="bottom"
            control={
              <Checkbox
                sx={{
                  color: '#c0c0c0',
                  '&.Mui-checked': {
                    color: '#c0c0c0',
                  },
                }}
                checked={
                  checked[0] &&
                  checked[1] &&
                  checked[2] &&
                  checked[3] &&
                  checked[4]
                }
                indeterminate={
                  !checked &&
                  (checked[0] ||
                    checked[1] ||
                    checked[2] ||
                    checked[3] ||
                    checked[4])
                }
                onChange={handleChangeAll}
              />
            }
          />
          {children}
        </FormGroup>
      </Grid>
      <br></br>
      <div style={{ height: 480 }}>
        <ResponsiveBar
          groupMode="grouped"
          colors={subColors}
          margin={{ top: 30, right: 150, bottom: 50, left: 50 }}
          data={data}
          keys={subKeys}
          layout={'vertical'}
          indexBy="billingCycle"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          padding={0.2}
          layers={[
            'grid',
            'axes',
            'bars',
            CustomBarLabel,
            'markers',
            'legends',
          ]}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 0,
            format: (value) => <tspan style={{ fontSize: 15 }}>{value}</tspan>,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 8,
            tickRotation: 20,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          tooltip={({ id, value, color }) => (
            <div
              style={{
                padding: 12,
                color,
                background: '#222222',
              }}
            >
              {id.includes('fragment') ? (
                <strong>Fragment: {value} Samples</strong>
              ) : (
                <></>
              )}
              {id.includes('sanger') ? (
                <strong>Sanger: {value} Samples</strong>
              ) : (
                <></>
              )}
              {id === 'pCost' ? (
                <strong>Pending: ${value}</strong>
              ) : id === 'bCost' ? (
                <strong>Billed: ${value}</strong>
              ) : id === 'cost' ? (
                <strong>Total: ${value}</strong>
              ) : (
                <></>
              )}
            </div>
          )}
          enableLabel={false}
          legends={[
            {
              dataFrom: 'keys',
              data: keys.map((id, index) => ({
                color: colors[index],
                id,
                label:
                  id === 'fragmentNoOfSamples'
                    ? 'Fragment Samples'
                    : id === 'sangerNoOfSamples'
                    ? 'Sanger Samples'
                    : id === 'pCost'
                    ? 'Pending Cost'
                    : id === 'bCost'
                    ? 'Billed Cost'
                    : 'Total Cost',
              })),
              anchor: 'right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 12,
              itemWidth: 90,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 10,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
      <br></br>
      <br></br>
      <BillingSummaryTable orders={tableData} />
    </div>
  );
}
