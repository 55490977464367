import requestMECLserviceOnSums from 'files/Requesting a Molecular Evolution Core service on SUMS.pdf';
import primerTableImage from 'images/faqPrimerTable.png';

/**
 * Convert the given parameters into a cost row object.
 * @param sangerSequencingCost cost per sample for Sanger sequencing
 * @param quantity amount of samples allowed
 * @param fragmentAnalysisCost cost per sample of fragment analysis
 * @returns costRow object
 */
function createCostRow(sangerSequencingCost, quantity, fragmentAnalysisCost) {
  return { seq: sangerSequencingCost, quantity, frag: fragmentAnalysisCost };
}

const costTable = [
  createCostRow('$5.00/Sample', '<20 samples', '$4.00/Sample'),
  createCostRow('$4.00/Sample', '20-96 samples', '$4.00/Sample'),
  createCostRow('$3.50/Sample', '>96 samples', '$3.50/Sample'),
];

export function getData(goToRow) {
  return [
    {
      title: 'Services Offered',
      rows: [
        {
          title: <b>What services are provided overall?</b>,
          content: (
            <ul className="faq__list ">
              <li>
                <b>Sanger sequencing</b>
              </li>
              <ul className="faq__list ">
                <li>
                  The client submits purified PCR products or plasmids, and we
                  do the rest!
                </li>
              </ul>
              <li>
                <b>Fragment analysis</b>
              </li>
              <ul className="faq__list ">
                <li>
                  The client submits prepped samples, and we load onto our
                  machine!
                </li>
                <li>
                  The client specifies their desire to run fragment analysis in
                  addition to their submitted Sanger sequencing samples, and we
                  do the rest!
                </li>
              </ul>
            </ul>
          ),
        },
      ],
    },
    {
      title: 'Costs',
      rows: [
        {
          title: <b>How much does this cost?</b>,
          content: (
            <>
              <table className="faq__cost-table">
                <thead>
                  <tr>
                    <th>Sanger Sequencing</th>
                    <th>Quantity</th>
                    <th>Fragment Analysis</th>
                  </tr>
                </thead>
                <tbody>
                  {costTable.map((row, index) => (
                    <tr key={index}>
                      <td>{row.seq}</td>
                      <td>{row.quantity}</td>
                      <td>{row.frag}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="faq__cost-table-note">
                Once 480+ samples have been sequenced per a billing cycle, each
                sample is priced at $3.50 until the start of the next billing
                cycle.
                <br />
                *First 4 samples free for new customers
              </div>
            </>
          ),
        },
      ],
    },
    {
      title: 'Submissions',
      rows: [
        {
          title: <b>How do I submit samples/requests?</b>,
          content: (
            <ul className="faq__list ">
              <li>This depends on your billing preferences</li>
              <ul className="faq__list ">
                <li>
                  If you would like to be billed at the end of each billing
                  cycle, please specify this during your first submission.
                  That's it! We bill according to the work tag specified during
                  submission, so please double check your work tag. We recommend
                  this option for clients planning on submitting on a weekly
                  basis.
                  <br></br>
                  <br></br>
                  <ul className="faq__list ">
                    <em style={{ fontFamily: 'Roboto', fontWeight: 'lighter' }}>
                      Please keep in mind that this option allows you the
                      opportunity of reaching our special sample price for each
                      billing cycle. Once a laboratory has submitted 480+
                      samples, all samples are charged at $3.50 per sample at
                      the beginning of the next billing cycle (see price chart
                      for more info).
                    </em>
                  </ul>
                </li>
                <br></br>
                <li>
                  If you would like to sequence with us on a per request basis,
                  we recommend submitting a sequencing request via our Shared
                  User Management System (SUMS) which bills after every
                  completed service. Please make sure that you use the correct
                  Service ID depending on your submission’s sample count. Our
                  tag options are displayed below:
                  <ul className="faq__list ">
                    <br></br>
                    <li>MEC0021: Sanger Sequencing (Less than 20 samples)</li>
                    <li>MEC0079: Sanger Sequencing (20-95 samples)</li>
                    <li>MEC0080: Sanger Sequencing (96 samples and over)</li>
                    <li>MEC0081: Fragment Analysis</li>
                  </ul>
                </li>
              </ul>
              <br></br>
              <li>
                Please see the following guide on How to Request an MEC Service
                on SUMS:
                <br></br>
                <a href={requestMECLserviceOnSums} download>
                  Requesting a Molecular Evolution Core service on SUMS
                </a>
              </li>
              <li>
                Don’t have a work-tag? Click this link for more info{' '}
                <item className="faq button">
                  <button onClick={() => goToRow(6, 0)}>
                    What if I don't have a SUMS work-tag?
                  </button>
                </item>
              </li>
            </ul>
          ),
        },
        {
          title: <b>Where do I submit samples/requests?</b>,
          content: (
            <>
              <li>Sample submissions are a two-step process:</li>
              <ol>
                <li>
                  Complete the submission form and upload it to our website.
                  Keep track of the code that is generated; it will be needed
                  during drop off.
                </li>
                <br></br>
                <li>
                  Select one of our 6 convenient drop off locations, scan its QR
                  code, and input the generated code from your submission form
                  obtained during the website uploading process. You should
                  receive a confirmation email that we have received your
                  samples.
                </li>
                <br></br>
                <li>
                  That's it! You should receive an email with your sample data
                  in 1 to 3 business days depending on the time and queue of
                  your drop-off. For our processing schedule, visit this link:
                  [INSERT LINK TO OPTIMAL DROP-OFF TIME]
                </li>
              </ol>
            </>
          ),
        },
      ],
    },
    {
      title: 'Samples/Primers',
      rows: [
        {
          title: (
            <b>
              What are the sample submission requirements? How many base pairs
              can I sequence?
            </b>
          ),
          content: (
            <>
              <li>Template Types:</li>
              <ul className="faq__list ">
                Please refer to the following table for concentration
                requirements for your samples. We recommend adhering to the
                upper part of each concentration range for better results.
                Please note that the measurements are in ng/µL.
              </ul>
              <li>Read Modules</li>
              <ul className="faq__list ">
                <li>
                  Your read module depends on the length of the region you wish
                  to sequence
                </li>
                <ul className="faq__list ">
                  <li>Short: 1 to 350 bp</li>
                  <li>Medium: 350 to 500 bp</li>
                  <li>Long: 500 to 800 bp</li>
                </ul>
                <br></br>
                <li>Concentration?</li>
                <br></br>
                <li>Volumes</li>
                <ul className="faq__list ">
                  <li>
                    Sample(s): We require a minimum volume of 10 µL per sample
                  </li>
                  <li>
                    Primer(s): We require a minimum volume of 5 µL per reaction
                    (for both forward and reverse)
                  </li>
                </ul>
                <br></br>
                <li>Primer Specs</li>
                <ul className="faq__list ">
                  <li>
                    If you are submitting your own primers, we require them to
                    be at a concentration of 3.2µM.
                  </li>
                  <li>
                    Please submit 5µL per reaction (both forward and reverse)
                    for each primer
                    <br></br>
                    <br></br>
                    <ul className="faq__list ">
                      <em>
                        Primers at a concentration of 3.2µM can be stored at 4˚C
                        for up to 3 months. We recommend storing your primer
                        stock in -80˚C otherwise.
                      </em>
                    </ul>
                  </li>
                </ul>
                <br></br>
                <li>On ice/packaging?</li>
                <ul className="faq__list ">
                  <li>
                    Protect samples by carefully sealing plates and wrapping
                    tubes. Be sure to label your samples and include a physical
                    copy of your completed submission form during drop-off.
                  </li>
                  <li>
                    If shipping from outside of Georgia Tech, we recommend
                    shipping your samples on ice. Do not place your plate
                    loosely inside the transport container.
                  </li>
                </ul>
                <br></br>
                <li>Tubes/plates?</li>
                <ul className="faq__list ">
                  <li>
                    Please submit your samples in PCR-strip tubes or plates.
                  </li>
                </ul>
                <br></br>
                <li>When to prep samples, how soon to submit?</li>
                <ul className="faq__list ">
                  <li>
                    While DNA is relatively stable, please be conscious of your
                    sample prep date and storage conditions before submitting.
                    We require an average of your overall sample quality metrics
                    to get an idea of your samples’ purity. Impure samples lead
                    to poor data quality.
                  </li>
                </ul>
              </ul>
            </>
          ),
        },
        {
          title: (
            <b>
              Why is it recommended that I submit my samples in multiples of 4?
            </b>
          ),
          content: (
            <>
              <li>
                Our SeqStudio and SeqStudio Flex systems utilize a 4- and
                8-capillary mechanism, respectively. We combine multiple clients
                on a single plate and add controls to compare quality metrics,
                but any well that does not have a sample or control is a wasted
                well. We do not, however, require samples to be submitted in
                multiples of 4.
              </li>
            </>
          ),
        },
        {
          title: <b>Who provides the primers? </b>,
          content: (
            <>
              <li>
                Clients can select from the Core’s list of available primers
                (see the following link: [INSERT LINK]), or they can submit
                their own (see the following link for primer specs:
                <item className="faq button">
                  <button onClick={() => goToRow(3, 0)}>here</button>
                </item>
                )
              </li>
              <br></br>
              <li>What primers does the Core offer?</li>
              <ul className="faq__list ">
                <li>
                  <img src={primerTableImage} alt="Primer table" width="100%" />
                </li>
              </ul>
            </>
          ),
        },
        {
          title: <b>How do I run the same sample with multiple primers?</b>,
          content: (
            <>
              <li>
                If you would like to run the same sample with multiple primers,
                please indicate which primer(s) correspond to each sample on
                your submission form via the primer columns.
              </li>
              <ul className="faq__list ">
                <em>
                  Ex: If one sample is run with two primers (typically forward
                  and reverse primers), it will be charged as two reactions.
                </em>
              </ul>
            </>
          ),
        },
        {
          title: <b>Can I get my samples back once submitted?</b>,
          content: (
            <>
              <li>
                Samples will not be returned. All unused samples will be
                discarded 28 days after the data has been released.
              </li>
              <ul className="faq__list ">
                <li>
                  If you would like your samples to be returned, please contact{' '}
                  <a href="mailto:sanger@ibb.gatech.edu">
                    sanger@ibb.gatech.edu
                  </a>{' '}
                  directly.
                </li>
              </ul>
            </>
          ),
        },
      ],
    },
    {
      title: 'Results',
      rows: [
        {
          title: <b>What is the turnaround time?</b>,
          content: (
            <>
              <li>
                The turnaround time is between 0 to 2 business days. Be sure to
                submit your samples <b>before 4:00 PM</b> to be included in the
                next day’s run.
              </li>
            </>
          ),
        },
        {
          title: <b>How do I receive the sequencing data?</b>,
          content: (
            <>
              <li>
                Your data will be sent to the email that is associated with the
                request in the form of a zip file.
              </li>
              <ul className="faq__list ">
                <li>
                  Files within the .zip file are either “.ab1” (Sanger) or
                  “.fsa/.csv” (fragment analysis) files.
                </li>
              </ul>
            </>
          ),
        },
      ],
    },
    {
      title: 'Reruns',
      rows: [
        {
          title: <b>Why did my samples fail?</b>,
          content: (
            <>
              <li>
                Most samples fail due to low concentrations, low purity, or
                incorrect primers. Primer-primer dimerization may occur
                depending on the GC-content of your primers. Optimal GC-content
                for samples is between 40 and 60%; anything that exceeds this
                may lead to lower quality results. See the following link for
                more information on optimizing your primer design:
                <br></br>
                <a href="https://www.thermofisher.com/blog/behindthebench/pcr-primer-design-tips/">
                  https://www.thermofisher.com/blog/behindthebench/pcr-primer-design-tips/
                </a>
              </li>
              <br></br>
              <li>
                DMSO has been shown to improve PCR reactions for high GC-content
                samples. Please add 5% DMSO to your samples if they have a high
                GC-content.
              </li>
              <br></br>
              <li>
                Please visit the following resource to further troubleshoot your
                run:
                <br></br>
                <a href="https://dnacore.mgh.harvard.edu/new-cgi-bin/site/pages/sequencing_pages/seq_troubleshooting.jsp">
                  https://dnacore.mgh.harvard.edu/new-cgi-bin/site/pages/sequencing_pages/seq_troubleshooting.jsp
                </a>
              </li>
              <li>
                Please visit the following link for a look at our Re-Run Policy.
              </li>
            </>
          ),
        },
        {
          title: (
            <b>
              What do I do if my samples fail? How do I rerun samples? Do I need
              to re-submit the same samples?
            </b>
          ),
          content: (
            <>
              <li>
                The Core will re-run the samples that clients specify based on
                the original tube label. If the client adheres to the sample
                requirements, the Core will charge for re-runs based on the
                respective results of each sample:
              </li>
              <ul className="faq__list ">
                <li>
                  If the samples produce different results, the Core will assume
                  responsibility for the run.
                </li>
                <li>
                  If the samples produce results that are coherent with the
                  first run, the Core will charge the client for the respective
                  re-run.
                </li>
              </ul>
              <li>
                Here at the Core, we check the concentration and purity ratios
                of each sample to establish a baseline and run samples against
                an industry control. <b>No further submission is necessary.</b>{' '}
                The Molecular Evolution Core reserves the right to charge a
                re-run if sample requirements are not initially met by the
                client. No re-run requests will be accepted after samples have
                been discarded (28 days).
              </li>
            </>
          ),
        },
      ],
    },
    {
      title: 'SUMS',
      rows: [
        {
          title: <b>What if I don't have a SUMS work-tag?</b>,
          content: (
            <>
              <li>
                Before you can create an account on our website, you will need
                an active work-tag associated with your project. Your PI will
                need to create an account/billing group if your lab is not
                already in the system, and login at least once to verify.
              </li>
              <ul className="faq__list ">
                <li>
                  To solicit a SUMS account, please visit the following link and
                  follow the instructions detailed:
                  <br></br>
                  <a href="https://sums.gatech.edu/ViewExtendedContent.aspx?ContentURL=GTIContact">
                    https://sums.gatech.edu/ViewExtendedContent.aspx?ContentURL=GTIContact
                  </a>
                </li>
                <br></br>
                <li>
                  The following link will explain how to create a new SUMS
                  billing group: <br></br>
                  <a href="http://protolab.gvu.gatech.edu/how-to/create-billing-groups.">
                    http://protolab.gvu.gatech.edu/how-to/create-billing-groups.
                  </a>
                  <br></br>
                  <br></br>
                  <ul className="faq__list ">
                    <em>
                      {' '}
                      Relevant information here includes Permission Types,
                      Requesting a New Group, and Adding Researchers to Your
                      Group.
                    </em>
                  </ul>
                </li>
              </ul>
            </>
          ),
        },
        {
          title: <b>What if I don’t have a GT login?</b>,
          content: (
            <>
              <li>If you’re part of USG...</li>
              <ul className="faq__list ">
                <li>
                  See{' '}
                  <item className="faq button">
                    <button onClick={() => goToRow(6, 0)}>
                      What if I don't have a SUMS work-tag?
                    </button>
                  </item>
                </li>
              </ul>
              <li>
                If you’re not a part of the Georgia Research Alliance (GRA):
              </li>
              <ul className="faq__list ">
                <li>
                  External academic or industry clients must also follow the
                  SUMS creation protocol with the temporary GT login given (see{' '}
                  <item className="faq button">
                    <button onClick={() => goToRow(6, 0)}>
                      What if I don't have a SUMS work-tag?
                    </button>
                  </item>
                  ). We charge an{' '}
                  <a
                    href={'https://osp.gatech.edu/rates'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    overhead
                  </a>{' '}
                  between 35.9 and 58.2% depending on designation: academic
                  external institutions and industry. Overhead charge
                  negotiations can be carried out.
                </li>
                <br></br>
                <li>
                  Georgia Research Alliance (GRA) member institutions are
                  considered internal academic clients. The following
                  institutions are members of GRA and receive Georgia Tech
                  pricing:
                  <ul className="faq__list ">
                    <li>Georgia Tech</li>
                    <li>Emory University</li>
                    <li>Georgia State University</li>
                    <li>University of Georgia</li>
                    <li>Morehouse College</li>
                    <li>Clark Atlanta University</li>
                    <li>Augusta University</li>
                    <li>Mercer University</li>
                  </ul>
                </li>
              </ul>
            </>
          ),
        },
        {
          title: <b>What if my colleague also wants to submit samples?</b>,
          content: (
            <>
              <li>
                Each researcher must create their own account via our website
                with their associated project work-tag number. They will be
                associated with their PI, and their submitted samples will go
                toward the lab’s total sample count during each billing cycle
                period.
              </li>
            </>
          ),
        },
      ],
    },
    {
      title: 'Demos/Trainings',
      rows: [
        {
          title: <b>Do you provide demos/trainings?</b>,
          content: (
            <>
              <li>
                If you wish to be trained on an instrument, please submit a
                training request via SUMS.
              </li>
              <li>
                If you wish to tour the facility, please contact{' '}
                <a href="mailto:sanger@ibb.gatech.edu">sanger@ibb.gatech.edu</a>{' '}
                to get in touch with team and schedule a tour.
              </li>
              <li>
                If you wish to join the Sanger team, be on the lookout for
                position openings via our Career Center.
              </li>
            </>
          ),
        },
        {
          title: (
            <b>
              Who do I contact about the samples/request/website issues or
              changes?
            </b>
          ),
          content: (
            <>
              <li>
                Please direct all communication to our primary email:{' '}
                <a href="mailto:sanger@ibb.gatech.edu">sanger@ibb.gatech.edu</a>
              </li>
            </>
          ),
        },
      ],
    },
  ];
}
