import { memo, useContext } from 'react';
import TextField from '@mui/material/TextField';
import { DebounceInput } from 'react-debounce-input';
import InputAdornment from '@mui/material/InputAdornment';
import { SampleMethodContext } from '../../../contexts/MethodContext';
import { ErrorVisContext } from '../../../contexts/DataContext';

const SangerRowVol = memo(
  ({ id, data, errorVol }) => {
    const { updateSample } = useContext(SampleMethodContext);
    const { submitShowError } = useContext(ErrorVisContext);

    return (
      <DebounceInput
        debounceTimeout={500}
        minLength={1}
        element={TextField}
        id="vol"
        placeholder={'Vol'}
        variant="standard"
        value={data.vol}
        error={
          (data.touched[4] || submitShowError) &&
          errorVol(data.vol, data.primerRowList, data.premixed)
        }
        InputProps={{
          disableUnderline: true,
          inputmode: 'numeric',
          pattern: '[0-9]*',
          endAdornment: (
            <InputAdornment position="end" sx={{ paddingRight: 1 }}>
              µl
            </InputAdornment>
          ),
        }}
        onBlur={(e) => {
          let newTouched = [...data.touched];
          newTouched[4] = true;
          updateSample({ touched: newTouched }, id);
        }}
        onChange={async (e) => {
          let newTouched = [...data.touched];
          newTouched[4] = true;
          updateSample(
            {
              vol: e.target.value,
              touched: newTouched,
            },
            id
          );
        }}
        sx={{
          '& .MuiInputBase-input': {
            width: '100%',
            padding: '10px',
          },
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id && prevProps.data.vol === nextProps.data.vol
    );
  }
);

export default SangerRowVol;
