import React, { useState, useEffect, useContext } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import RotateLoader from 'react-spinners/RotateLoader';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';

import AuthContext from 'utils/auth/auth';
import { deletePI, getAllPIs } from 'utils/api/Pi';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  float: 'left',
}));

async function getPIs({ setGetLoad, setData, setPIsReceived, pisReceived }) {
  if (pisReceived === false) {
    try {
      const dataToBeUsed = await getAllPIs();
      setData(dataToBeUsed);
      setGetLoad(false);
      setPIsReceived(true);
    } catch (error) {
      console.log(error);
    }
  }
}

export default function RemovePI(props) {
  const [getLoad, setGetLoad] = useState(false);
  const [data, setData] = useState([]);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [pisReceived, setPIsReceived] = useState(false);
  const initialData = { pi: '', newPI: '' };
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [piMatches, setPIMatches] = useState(false);
  const user = useContext(AuthContext);

  const handleClose = () => {
    setSubmitStatus(false);
  };

  const validationSchema = Yup.object({
    pi: Yup.string().required('Required'),
    newPI: Yup.string().required('Required'),
  });

  useEffect(() => {
    getPIs({ setGetLoad, setData, setPIsReceived, pisReceived, user }).catch(
      console.error
    );
  }, [pisReceived]);
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
        <PropagateLoader />
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
              window.location.reload(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>
      <Formik
        initialValues={initialData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setSubmitLoad(true);
          try {
            const res = await deletePI(values);
            const status = res.status;
            await sleep(1e3);
            if (status === 202) {
              setPIMatches(true);
              setSubmitSucess(false);
              setSubmitResponse(
                'Cannot replace PI with the same PI reference. Please try another.'
              );
            }
            if (status === 201) {
              setSubmitSucess(true);
              setPIMatches(false);
              setSubmitResponse('PI sucessfully removed and replaced!');
            }
          } catch {
            setSubmitSucess(false);
            setSubmitResponse(
              'Unsucessful! Please try again later or contact adminsitrator if problem persists'
            );
          }
          setSubmitStatus(true);
          setSubmitLoad(false);
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={0} width={400}>
              <Grid item xs={12}>
                <Item>
                  <label> Search for PI by name or ID.</label>
                  <br></br>
                  <Autocomplete
                    sx={{ width: 370 }}
                    id="pi"
                    name="pi"
                    onChange={(e, value) => {
                      props.setFieldValue('pi', value.PIName);
                      props.setFieldValue('piid', value.piid);
                      props.setFieldValue('piUniversity', value.university);
                      props.setFieldValue(
                        'piUniversityTag',
                        value.universityTag
                      );
                    }}
                    disableClearable
                    options={data}
                    getOptionLabel={(option) => {
                      return option.piid + ': ' + option.PIName;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search for PI"
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <label>
                    {' '}
                    Search for a new PI by name or ID to replace the old one.
                  </label>
                  <br></br>
                  <Autocomplete
                    sx={{ width: 370 }}
                    id="newPI"
                    name="newPI"
                    onChange={(e, value) => {
                      props.setFieldValue('newPI', value.PIName);
                      props.setFieldValue('newPIid', value.piid);
                      props.setFieldValue('newPIUniversity', value.university);
                      props.setFieldValue(
                        'newPIUniversityTag',
                        value.universityTag
                      );
                    }}
                    disableClearable
                    options={data}
                    getOptionLabel={(option) => {
                      return option.piid + ': ' + option.PIName;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search for PI"
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                  />
                  {piMatches ? (
                    <div className="usernameWarning">
                      Cannot replace PI with the same PI reference. Please try
                      another.
                    </div>
                  ) : (
                    <></>
                  )}
                </Item>
              </Grid>
              {/* <Grid item xs={12} sm={8}></Grid> */}
              <Grid item xs={12} sm={6}>
                <Item>
                  <Box>
                    <LoadingButton
                      size="large"
                      classes="profileSubmit"
                      type="submit"
                      variant="outlined"
                      loading={submitLoad}
                      loadingIndicator={
                        <RotateLoader
                          cssOverride={{ left: '100%' }}
                          size={7}
                          margin={-15}
                        />
                      }
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}
