import axios from 'axios';

export const getSampleMap = async (id) => {
  try {
    const response = await axios.get(`/api/sampleMaps/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching sample map:', error);
    throw error;
  }
};

export const updateSampleMap = async (id, properties) => {
  try {
    const response = await axios.patch(`/api/sampleMaps/${id}`, properties, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating sample map:', error);
    throw error;
  }
};
