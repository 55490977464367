import { useContext } from 'react';
import { useDrop } from 'react-dnd';
import Grid from '@mui/material/Grid';
import OrderButton from './OrderButton';
import { DragDropOverlay, DragDropOverlayType } from './DragDropOverlay.js';
import AuthContext from 'utils/auth/auth';
import { moveOrder } from 'utils/api/Orders';

export default function DndDropOrder(props) {
  const user = useContext(AuthContext);

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'order',
    drop: (item) => moveOrder(item.id, props.stage),
    canDrop: (item) => canMoveOrder(item.id, props.stage),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  const canMoveOrder = (order, stageNo) => {
    if (order.submissionType === 'fragment') {
      if (stageNo >= 1 && stageNo <= 3) {
        return false;
      }
    }
    if (user.admin === false) {
      if (order.stage === 'SS Machine' || order.stage === 'Resulting') {
        return false;
      }
      if (stageNo <= 4) {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <div
      ref={drop}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      {isOver && !canDrop && (
        <DragDropOverlay type={DragDropOverlayType.IllegalMoveHover} />
      )}
      {!isOver && canDrop && (
        <DragDropOverlay type={DragDropOverlayType.PossibleMove} />
      )}
      {isOver && canDrop && (
        <DragDropOverlay type={DragDropOverlayType.LegalMoveHover} />
      )}
      <Grid
        container
        columnGap={0}
        spacing={{ xs: 1, sm: 1 }}
        columns={{ xs: 1, lg: 2, md: 2 }}
      >
        {props.activeOrders[props.index].map((currentOrder) => (
          <Grid item xs={1} lg={1} key={currentOrder.name}>
            <OrderButton
              key={currentOrder.name}
              currentOrder={currentOrder}
              UpdateCurrentOrder={props.UpdateCurrentOrder}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
