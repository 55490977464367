import axios from 'axios';

export const getCartridgesByLetter = async () => {
  try {
    const res = await axios.get('/api/cartridge/byLetter');
    return res.data;
  } catch (error) {
    console.log('Error getting cartridge by letter:', error);
  }
};

export const getAllCartridges = async () => {
  try {
    const res = await axios.get('/api/cartridge/all');
    let dataToBeUsed = [];
    res.data.forEach((element) => {
      let oneRow = {};
      Object.keys(element).forEach((el) => {
        if (el === '__v') {
        } else {
          oneRow = { ...oneRow, [el]: element[el] };
        }
      });
      dataToBeUsed = [...dataToBeUsed, oneRow];
    });
    return dataToBeUsed;
  } catch (error) {
    console.log('Error getting all cartridges:', error);
  }
};

export const getCartridgeLog = async (log) => {
  try {
    const res = await axios.get('/api/cartridgeLog/per', {
      params: { cartridgeID: log },
    });
    let dataToBeUsed = [];
    let groupByCartridge = new Map();
    let groupByOrderCode = new Map();
    let groupByUser = new Map();
    res.data.forEach((element) => {
      let oneRow = {};
      Object.keys(element).forEach((el) => {
        if (el === 'userRef') {
          if (element[el] !== null) {
            let name = element[el].firstName + ' ' + element[el].lastName;
            if (name === null || name === ' ') {
              name = element[el].deletedUserWholeName;
            }
            oneRow = { ...oneRow, user: name };
          }
        } else if (el === 'cartridge') {
          if (element[el] != null) {
            let cart = element[el].cartridgeID;
            oneRow = { ...oneRow, cartridge: cart };
          }
        } else if (el === 'order') {
          if (element[el] !== null) {
            let orderCode = element[el].orderCode;
            if (
              orderCode === null ||
              orderCode === '' ||
              orderCode === '' ||
              orderCode === ' ' ||
              orderCode === ' '
            ) {
              orderCode = element[el].deletedOrderCode;
            }
            oneRow = { ...oneRow, order: orderCode };
          }
        } else {
          oneRow = { ...oneRow, [el]: element[el] };
        }
      });

      if (oneRow.deletedCartridge) {
        oneRow = { ...oneRow, cartridgeCombined: oneRow.deletedCatolog };
        let CartridgeRow = {
          cartridge: oneRow.deletedCatolog,
          deleted: 'Deleted Cartridge',
        };
        groupByCartridge.set(oneRow.deletedCartridge, CartridgeRow);
      } else if (oneRow.cartridge) {
        oneRow = { ...oneRow, cartridgeCombined: oneRow.cartridge };
        let CartridgeRow = {
          cartridge: oneRow.cartridge,
          deleted: 'Existing Cartridge',
        };
        groupByCartridge.set(oneRow.cartridge, CartridgeRow);
      }

      if (oneRow.deletedOrderCode) {
        oneRow = { ...oneRow, orderCodeCombined: oneRow.deletedOrderCode };
        let OrderRow = {
          order: oneRow.deletedOrderCode,
          deleted: 'Deleted Order',
        };
        groupByOrderCode.set(oneRow.deletedOrderCode, OrderRow);
      } else if (oneRow.order) {
        oneRow = { ...oneRow, orderCodeCombined: oneRow.order };
        let OrderRow = { order: oneRow.order, deleted: 'Existing Order' };
        groupByOrderCode.set(oneRow.order, OrderRow);
      }

      if (oneRow.deletedUserWholeName) {
        oneRow = {
          ...oneRow,
          userWholeNameCombined: oneRow.deletedUserWholeName,
        };
        let userRow = {
          user: oneRow.deletedUserWholeName,
          deleted: 'Deleted User',
        };
        groupByUser.set(oneRow.deletedUserWholeName, userRow);
      } else if (oneRow.user) {
        oneRow = { ...oneRow, userWholeNameCombined: oneRow.user };
        let userRow = { catolog: oneRow.user, deleted: 'Existing User' };
        groupByUser.set(oneRow.user, userRow);
      }
      dataToBeUsed = [...dataToBeUsed, oneRow];
    });

    groupByCartridge = Array.from(groupByCartridge.values());
    groupByOrderCode = Array.from(groupByOrderCode.values());
    groupByUser = Array.from(groupByUser.values());

    return [dataToBeUsed, groupByCartridge, groupByOrderCode, groupByUser];
  } catch (error) {
    console.log('Error getting cartridge log:', error);
  }
};

export const reassignCartridge = async (values) => {
  return await axios.put('/api/cartridge/reassign', values);
};

export const editCapillary = async (values) => {
  return await axios.put('/api/cartridge/editCap', values);
};

export const editCartridge = async (values) => {
  return await axios.put('/api/cartridge/edit', values);
};

export const deleteCartridge = async (cartridge) => {
  return await axios.delete('/api/cartridge/delete', {
    data: cartridge,
  });
};
