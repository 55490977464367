import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './UploadedOrders.css';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import { socket } from 'context/socket';
import { useSnackbar } from 'notistack';
import AuthContext from 'utils/auth/auth';
import React, { useState, useContext, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { UploadedOrdersTable } from './UploadedOrderComponents/UploadedOrdersTable';
import { getInitialData } from '../utils/getInitialData';

export const UploadedOrders = () => {
  const [getLoad, setGetLoad] = useState(true);
  const [ordersReceived, setOrderReceived] = useState(false);
  const [uploadedOrders, setUploadedOrders] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(true);
  const user = useContext(AuthContext);

  useEffect(() => {
    socket.on('updateOthersUploadedOrders', (msg) => {
      setOrderReceived(false);
      enqueueSnackbar(msg, { variant: 'info' });
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelfUploadedOrders', () => {
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('connect', () => console.log(socket.id));
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => console.log('Server disconnected'));
  }, []);

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setUploadedOrders,
      setOrderReceived,
      ordersReceived,
      user,
    }).catch(console.error);
  }, [ordersReceived]);

  const handleClose = () => {
    setSubmitStatus(false);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: 1 }}
          open={submitStatus}
          onClick={handleClose}
        >
          {submitSuccess ? (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              {submitResponse}
            </Alert>
          ) : (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              severity="error"
            >
              {submitResponse}
            </Alert>
          )}
        </Backdrop>
      </Grid>
      <Box sx={{ width: '100%' }}>
        <Row></Row>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
              }}
            >
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Uploaded Orders
              </Typography>
            </Toolbar>
            <Row></Row>
            <Row>
              <Col>
                <UploadedOrdersTable
                  aria-labelledby="tableTitle"
                  uploadedOrders={uploadedOrders}
                  setSubmitLoad={setSubmitLoad}
                  setSubmitSuccess={setSubmitSuccess}
                  setSubmitResponse={setSubmitResponse}
                  setSubmitStatus={setSubmitStatus}
                />
              </Col>
            </Row>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

export default UploadedOrders;
