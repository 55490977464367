import TextField from '@mui/material/TextField';
import { Autocomplete, Grid } from '@mui/material';
import { memo } from 'react';

const FragmentRowSizeStandard = memo(({ data, updateFragment, id }) => {
  const typeOptions = [
    'FragAnalysis',
    'FragAnalysis-long_inj',
    'LongFragAnalysis',
    'SNaPshot',
  ];

  const handleChange = (newData, id) => {
    // const tempData = { ...data, ...newData };
    console.log(newData);
    updateFragment(newData, id);
  };

  return (
    <Grid item alignItems="stretch" style={{ display: 'flex', width: 180 }}>
      <Autocomplete
        disablePortal
        disableClearable={true}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        id="runModule"
        variant="outlined"
        options={typeOptions}
        value={data.runModule}
        onBlur={(e) => {
          var newTouched = [...data.touched];
          newTouched[5] = true;
          handleChange({ touched: newTouched }, id);
        }}
        onChange={async (e, currData) => {
          var newTouched = [...data.touched];
          newTouched[5] = true;
          handleChange({ runModule: currData, touched: newTouched }, id);
        }}
        sx={{
          width: 180,
          '& .MuiInputBase-root': { height: 40, borderRadius: 0 },
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Run Module"
            inputProps={{
              ...params.inputProps,
              readOnly: true,
              style: { marginTop: -5, marginLeft: -10, marginRight: -10 },
            }}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              fontSize: 14,
              marginLeft: -12,
              marginRight: -12,
            }}
          >
            {option}
          </li>
        )}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              fontSize: 12,
              marginLeft: -12,
              marginRight: -12,
            }}
          >
            {option}
          </li>
        )}
      />
    </Grid>
  );
});

export default FragmentRowSizeStandard;

