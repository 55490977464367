import { useState, useEffect } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import RotateLoader from 'react-spinners/RotateLoader';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { getAllCartridges, reassignCartridge } from 'utils/api/Cartridges';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  float: 'left',
}));

async function getCartridges({
  setGetLoad,
  setCartridges,
  setCartridgesReceived,
  cartridgesReceived,
}) {
  if (cartridgesReceived === false) {
    try {
      const dataToBeUsed = await getAllCartridges();
      setCartridges(dataToBeUsed);
      setGetLoad(false);
      setCartridgesReceived(true);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default function StatusChange(props) {
  const [getLoad, setGetLoad] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [cartridges, setCartridges] = useState([]);
  const [cartridgesReceived, setCartridgesReceived] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);

  var oldCartridge = '';
  if (props.element.cartridgeID.length >= 6) {
    oldCartridge = `${props.element.cartridgeID.slice(
      0,
      6
    )}-${props.element.cartridgeID.slice(6)}`;
  } else {
    oldCartridge = props.element.cartridgeID;
  }

  const initialData = { oldCartridge: oldCartridge, newCartridge: '' };

  const handleClose = () => {
    setSubmitStatus(false);
  };

  const validationSchema = Yup.object({
    oldCartridge: Yup.string().required('Required'),
    newCartridge: Yup.string().required('Required'),
  });

  useEffect(() => {
    getCartridges({
      setGetLoad,
      setCartridges,
      setCartridgesReceived,
      cartridgesReceived,
    }).catch(console.error);
  }, [cartridgesReceived]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
        <PropagateLoader />
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
              window.location.reload(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>
      <Formik
        initialValues={initialData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setSubmitLoad(true);
          try {
            var { status } = await reassignCartridge();
            await sleep(1e3);
            if (status === 201) {
              setSubmitSucess(true);
              setSubmitResponse('Letter sucessfully reassigned!');
            }
          } catch {
            setSubmitSucess(false);
            setSubmitResponse(
              'Unsucessful! Please try again later or contact adminsitrator if problem persists'
            );
          }
          setSubmitStatus(true);
          setSubmitLoad(false);
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={0} width={250}>
              <Grid item xs={12}>
                <Item>
                  <TextField
                    disabled
                    label="Old Cartridge"
                    name="oldCartridge"
                    type="text"
                    value={oldCartridge}
                    sx={{ width: 200 }}
                  />
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <label> Select a new cartridge.</label>

                  <br></br>
                  <Autocomplete
                    sx={{ width: 200 }}
                    disabled={submitLoad}
                    id="newCartridge"
                    name="newCartridge"
                    onChange={(e, value) => {
                      if (value.cartridgeID.length >= 6) {
                        props.setFieldValue(
                          'newCartridge',
                          `${value.cartridgeID.slice(
                            0,
                            6
                          )}-${value.cartridgeID.slice(6)}`
                        );
                      } else {
                        props.setFieldValue('newCartridge', value.cartridgeID);
                      }
                    }}
                    disableClearable
                    options={cartridges}
                    getOptionLabel={(option) => {
                      if (option.cartridgeID.length >= 6) {
                        return `${option.cartridgeID.slice(
                          0,
                          6
                        )}-${option.cartridgeID.slice(6)}`;
                      } else {
                        return option.cartridgeID;
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Cartridge"
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <Box>
                    <LoadingButton
                      size="large"
                      classes="profileSubmit"
                      type="submit"
                      variant="outlined"
                      loading={submitLoad}
                      loadingIndicator={
                        <RotateLoader
                          cssOverride={{ left: '100%' }}
                          size={7}
                          margin={-15}
                        />
                      }
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}
