import { Button, ButtonGroup } from '@mui/material';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { socket } from 'context/socket';
import React, { useState } from 'react';
import { markBilled } from 'utils/api/Billing';

export default function CustomToolbar(props) {
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const handleClose = () => {
    setSubmitStatus(false);
  };

  const handleBilled = (selectedRows) => {
    return async (event) => {
      let s = selectedRows.data.map((row, k) => row.dataIndex);
      let id = [];
      s.forEach((e) => {
        id.push(props.data[e]._id);
        console.log(props.data[e]._id);
      });
      let idss = { ids: id };

      setSubmitLoad(true);
      try {
        let { status } = await markBilled(idss);

        console.log('status' + status);
        if (status === 201) {
          socket.emit('markBilled');
          console.log('status == 201');
          setSubmitSucess(true);
          setSubmitResponse('mark as billed sucessfully!');
        }
      } catch {
        console.log('error');
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }

      console.log('submit');
      console.log(idss);
      setSubmitStatus(true);
      setSubmitLoad(false);
    };
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: 1 }}
          open={submitStatus}
          onClick={handleClose}
        >
          {submitSucess ? (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              {submitResponse}
            </Alert>
          ) : (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              severity="error"
            >
              {submitResponse}
            </Alert>
          )}
        </Backdrop>
      </Grid>
      <ButtonGroup
        letiant="contained"
        aria-label="outlined primary button group"
      >
        <Button onClick={handleBilled(props.selectedRows)}>
          Mark Order(s) as Billed
        </Button>
      </ButtonGroup>
    </div>
  );
}
