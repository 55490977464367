import React from 'react';

import sangerImage from 'images/aboutuspage-img01.png';
import fragmentImage from 'images/aboutuspage-img04.png';
import { useLogin } from 'hooks/useLogin';
import { Rectangle } from './components/UploadSelectComponents/Rectangle';

const UploadSelect = () => {
  useLogin();

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <h1 style={{ marginBottom: 10 }}>
        Please select the type of order you'd like to submit
      </h1>
      <Rectangle
        imageSrc={sangerImage}
        title="Sanger Sequencing"
        description="Sanger Sequencing is a pivotal DNA sequencing technique that deciphers genetic code with high precision, enabling groundbreaking advancements in molecular biology and medicine."
        linkTo="/Upload/Sanger"
      />
      <Rectangle
        imageSrc={fragmentImage}
        title="Fragment Analysis"
        description="Fragment Analysis is an advanced genomics technique that sorts and quantifies DNA fragments, enabling detailed genetic variation studies and enhancing diagnostics precision."
        linkTo="/Upload/Fragment"
      />
    </div>
  );
};

export default UploadSelect;
