import { useState } from 'react';
import { socket } from 'context/socket';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from 'react-spinners/RotateLoader';
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { archiveLog } from 'utils/api/Inventory/Log/DeleteLogDateRange';

// uses the "dialog" component on the MUI website

export default function DeleteLogDateRange(props) {
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  let displayCatologNumber = props.whichLog === 'the Whole Inventory';
  let initialData = {
    ...props.products,
    from: new Date('January 01, 2023 00:00:00'),
    to: new Date(),
  };

  const validationSchema = Yup.object().shape({
    from: Yup.date(),
    to: Yup.date()
      .min(Yup.ref('from'), "end time can't be before start time")
      .max(new Date(), "end time can't be after current time"),
  });

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitLoad(true);
      try {
        const status = await archiveLog(values, displayCatologNumber);
        if (status === 201) {
          socket.emit('deletelog', values._id, values._from, values._to);
          setSubmitSucess(true);
          setSubmitResponse('delete logs sucessfully!');
        } else if (status === 202) {
          setSubmitSucess(false);
          setSubmitResponse(
            'catolog number already exist, please input another one'
          );
        }
      } catch {
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      setSubmitStatus(true);
      setSubmitLoad(false);
    },
  });

  const handleCloseS = () => {
    setSubmitStatus(false);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleCloseS}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>

      <form onSubmit={formik.handleSubmit}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            {props.hideRange == 'LOGS BEFORE A DATE' ? null : (
              <DateTimePicker
                label="Archive Logs After This Date"
                value={formik.values.from}
                onChange={(value) => formik.setFieldValue('from', value, true)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={formik.touched.from && Boolean(formik.errors.from)}
                    helperText={formik.touched.from && formik.errors.from}
                  />
                )}
              />
            )}
            {props.hideRange == 'LOGS AFTER A DATE' ? null : (
              <DateTimePicker
                label="Archive Logs Before This Date"
                value={formik.values.to}
                onChange={(value) => formik.setFieldValue('to', value, true)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={formik.touched.to && Boolean(formik.errors.to)}
                    helperText={formik.touched.to && formik.errors.to}
                  />
                )}
              />
            )}
          </Stack>
        </LocalizationProvider>
        <br></br>
        <LoadingButton
          size="large"
          classes="profileSubmit"
          type="submit"
          variant="outlined"
          loading={submitLoad}
          loadingIndicator={
            <RotateLoader
              cssOverride={{ left: '100%' }}
              size={7}
              margin={-15}
            />
          }
        >
          ARCHIVE
        </LoadingButton>
      </form>
    </Grid>
  );
}
