import { TextField, Checkbox } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import React from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MUICustomFilter(props) {
  let filterList = props.filterList;
  let onChange = props.onChange;
  let index = props.index;
  let column = props.column;
  let groupByCatolog = props.groupByCatolog;
  let labelName = props.labelName;
  let deleteCategory = props.deleteCategory;

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      disableCloseOnSelect
      options={groupByCatolog.sort((a, b) =>
        a.deleted === deleteCategory ? -1 : 1
      )}
      groupBy={(option) => option.deleted}
      getOptionLabel={(option) => option.catolog}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            name={option.catolog}
          />
          {option.catolog}
        </li>
      )}
      onChange={(event, newValue) => {
        filterList[index] = [...filterList[index], event.target.name];
        onChange(filterList[index], index, column);
      }}
      renderInput={(params) => (
        <TextField {...params} variant="filled" label={labelName} />
      )}
    />
  );
}
