export const PrimerTableHeadings = () => {
  const headingStyle = {
    color: '#003057',
    padding: '0 16px',
    borderLeft: '1px solid #0030572E',
    borderRight: '1px solid #0030572E',
  };

  return (
    <thead>
    <tr>
      <th style={{ ...headingStyle, width: '40px' }}></th>
      <th style={headingStyle}>
        Primer Name
      </th>
      <th style={{ ...headingStyle, width: 120 }}>
        Volume
      </th>
    </tr>
    </thead>
  );
};