import { memo, useContext } from 'react';
import TextField from '@mui/material/TextField';
import { DebounceInput } from 'react-debounce-input';
import { ErrorVisContext } from '../../../contexts/DataContext';
import { PrimerMethodContext } from '../../../contexts/MethodContext';

const SangerPrimerRowName = memo(
  ({ data, primerKey }) => {
    const { showAllErrors, submitShowError } = useContext(ErrorVisContext);

    const { updatePrimerMap } = useContext(PrimerMethodContext);

    return (
      <DebounceInput
        debounceTimeout={500}
        minLength={1}
        element={TextField}
        required
        id="primer-name"
        placeholder={'Primer Name'}
        variant="outlined"
        sx={{
          width: '100%',
          '& .MuiInputBase-root': {
            height: 40,
            borderRadius: 0,
            width: '100%',
          },
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          '& .MuiInputBase-input': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100% !important', // Can be removed when global css file is fixed
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
        }}
        disabled={data.labPrimer}
        InputLabelProps={{ shrink: false, notched: false }}
        inputProps={{ spellCheck: false }}
        value={data.primerName}
        onChange={async (e) => {
          var newTouched = [...data.touched];
          newTouched[0] = true;
          updatePrimerMap(primerKey, {
            primerName: e.target.value,
            touched: newTouched,
          });
        }}
        onBlur={(e) => {
          var newTouched = [...data.touched];
          newTouched[0] = true;
          updatePrimerMap(primerKey, { touched: newTouched });
        }}
        error={(data.touched[0] || submitShowError) && showAllErrors}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id &&
      prevProps.data.primerName === nextProps.data.primerName
    );
  }
);

export default SangerPrimerRowName;
