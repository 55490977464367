import React from 'react';
import { useLocation } from 'react-router-dom';

const DropOffCode = () => {
  const location = useLocation();
  const dropOffCode = location.state?.dropOffCode;

  if (!dropOffCode) {
    return <div>Unauthorized access. No code available.</div>;
  }

  const Popup = () => (
    <div className="popup_outter">
      <div className="popup_inner">
        <h3 style={{ textAlign: 'center' }}>Upload successful!</h3>
        <h1 style={{ textAlign: 'center' }}>Your drop off code:</h1>
        <p className="code">{dropOffCode}</p>
        <br></br>
        <h1 style={{ textAlign: 'center', fontSize: 30 }}>
          Please keep a copy of this code, you will also find it in your 'My
          Orders' tab. Please drop within 24 hrs or entry will be deleted.
        </h1>
        <h1 style={{ textAlign: 'center', fontSize: 30 }}>
          When dropping off your order, please include a copy of your drop off
          code with your samples!
        </h1>
      </div>
    </div>
  );

  return (
    <div>
      <Popup />
    </div>
  );
};

export default DropOffCode;
