import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

const getProcessingOrder = async (inventory) => {
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/Inventory/processingOrder`,
      inventory
    );
    let dataToBeUsed = [];
    res.data.forEach((element) => {
      let oneRow = {};
      Object.keys(element).forEach((el) => {
        if (el !== '__v') {
          oneRow = { ...oneRow, [el]: element[el] };
        }
      });
      dataToBeUsed = [...dataToBeUsed, oneRow];
    });
    return dataToBeUsed;
  } catch (error) {
    console.error('Error getting processing order:', error);
    throw error;
  }
};

const deleteProcessingOrder = async (inventory) => {
  try {
    const res = await axios.delete(
      `${API_ENDPOINT}/Inventory/processingOrder/delete`,
      inventory
    );
    return res.status;
  } catch (error) {
    console.error('Error deleting processing order:', error);
    throw error;
  }
};

const updateDeliveredProcessingOrder = async (inventory) => {
  try {
    const res = await axios.put(
      `${API_ENDPOINT}/Inventory/processingOrder/update`,
      inventory
    );
    return res.status;
  } catch (error) {
    console.error('Error updating delivered processing order:', error);
    throw error;
  }
};

const updateCreatedProcessingOrder = async (values) => {
  try {
    const res = await axios.put(
      `${API_ENDPOINT}/Inventory/processingOrder/updateEverything`,
      values
    );
    return res.status;
  } catch (error) {
    console.error('Error updating created processing order:', error);
    throw error;
  }
};

const createProcessingOrder = async (inventory) => {
  try {
    const res = await axios.post(
      `${API_ENDPOINT}/Inventory/processingOrder/add`,
      inventory
    );
    return res.status;
  } catch (error) {
    console.error('Error creating processing order:', error);
    throw error;
  }
};

export {
  getProcessingOrder,
  deleteProcessingOrder,
  updateDeliveredProcessingOrder,
  updateCreatedProcessingOrder,
  createProcessingOrder,
};
