import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import DashboardIcon from '@mui/icons-material/Dashboard';

import { useWindowSize } from 'hooks/useWindowSize';

const TeamProfile = (props) => {
  const textColor = 'rgb(3, 68, 89)';
  const [winWidth, winHeight] = useWindowSize();
  const phoneWidth = winWidth < 985;
  const scaleRes = phoneWidth ? 0.6 : 1;
  const marginTopRes = phoneWidth ? 0 : 70;
  const marginRes = phoneWidth ? -60 : 30;
  const fontSizeRole = phoneWidth ? 25 : 17;
  const fontSizeName = phoneWidth ? 30 : 25;
  const fontSizeInfo = phoneWidth ? 24 : 18;
  const extraMargin = phoneWidth ? 110 : 190;
  const iconSize = phoneWidth ? 'large' : 'large';
  const leftTrans = phoneWidth ? '50%' : '55%';

  return (
    <Grid container alignItems={'center'} justifyContent={'center'}>
      <>
        {props.items.map((item, index) => {
          return (
            <>
              <Grid
                item
                xs={6}
                style={{
                  backgroundColor: 'rgb(232, 238, 231)',
                  maxWidth: 600,
                  minWidth: 600,
                  minHeight: 350,
                  borderRadius: 40,
                  position: 'relative',
                  marginTop: marginTopRes,
                  margin: marginRes,
                  padding: 0,
                  display: 'flex',
                  transform: `scale(${scaleRes})`,
                }}
              >
                <img
                  src={item.imageSrc}
                  style={{
                    height: '100%',
                    position: 'absolute',
                    borderBottomLeftRadius: 40,
                    borderTopLeftRadius: 40,
                  }}
                  alt="team member"
                />
                <div
                  style={{
                    textAlign: 'left',
                    top: '50%',
                    left: leftTrans,
                    transform: 'translateY(-50%)',
                    position: 'absolute',
                    fontSize: 18,
                    color: textColor,
                    fontWeight: 'lighter',
                    maxWidth: '100%',
                  }}
                >
                  <div
                    style={{
                      fontSize: fontSizeRole,
                      color: textColor,
                      fontWeight: 'lighter',
                      marginBottom: 5,
                    }}
                  >
                    {item.role}
                  </div>
                  <div
                    style={{
                      fontSize: fontSizeName,
                      color: textColor,
                      fontWeight: 'bold',
                      position: 'absolute',
                      overflow: 'visible',
                    }}
                  >
                    {item.name.length < 15 ? (
                      item.name
                    ) : (
                      <>
                        {item.name.split(' ')[0]}
                        <br></br>
                        {item.name.split(' ')[1]}
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      fontSize: fontSizeInfo,
                      color: textColor,
                      marginTop: extraMargin,
                    }}
                  >
                    {item.email}
                  </div>
                  {/* <div style={{fontSize: fontSizeInfo, color: textColor, marginTop: 5}}>
                {item.phone}
                </div> */}

                  <div style={{ marginLeft: -11 }}>
                    {item.linkedin ? (
                      <>
                        <IconButton sx={{ color: textColor }}>
                          <LinkedInIcon
                            fontSize={iconSize}
                            onClick={(event) =>
                              window.open(item.linkedin, '_blank')
                            }
                          ></LinkedInIcon>
                        </IconButton>
                      </>
                    ) : (
                      <></>
                    )}

                    {item.twitter ? (
                      <>
                        <IconButton sx={{ color: textColor }}>
                          <TwitterIcon
                            fontSize={iconSize}
                            onClick={(event) =>
                              window.open(item.twitter, '_blank')
                            }
                          ></TwitterIcon>
                        </IconButton>
                      </>
                    ) : (
                      <></>
                    )}

                    {item.facebook ? (
                      <>
                        <IconButton sx={{ color: textColor }}>
                          <FacebookIcon
                            fontSize={iconSize}
                            onClick={(event) =>
                              window.open(item.facebook, '_blank')
                            }
                          ></FacebookIcon>
                        </IconButton>
                      </>
                    ) : (
                      <></>
                    )}
                    {item.github ? (
                      <>
                        <IconButton sx={{ color: textColor }}>
                          <GitHubIcon
                            fontSize={iconSize}
                            onClick={(event) =>
                              window.open(item.github, '_blank')
                            }
                          ></GitHubIcon>
                        </IconButton>
                      </>
                    ) : (
                      <></>
                    )}
                    {item.portfolio ? (
                      <>
                        <IconButton sx={{ color: textColor }}>
                          <DashboardIcon
                            fontSize={iconSize}
                            onClick={(event) =>
                              window.open(item.portfolio, '_blank')
                            }
                          />
                        </IconButton>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Grid>
            </>
          );
        })}
      </>
    </Grid>
  );
};

export default TeamProfile;
