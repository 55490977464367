import { memo, useContext } from 'react';
import { isEqual } from 'lodash';
import { Field, FormikProvider } from 'formik';
import { ErrorVisContext } from '../../../contexts/DataContext';

const SangerRowErrors = memo(
  ({ sample, index, formik, showAllErrors }) => {
    const { submitShowError } = useContext(ErrorVisContext);

    const willRenderError = (meta) => {
      return (
        ((sample.touched[0] || submitShowError) &&
          showAllErrors &&
          meta.error.sampleName) ||
        ((sample.touched[1] || submitShowError) &&
          showAllErrors &&
          meta.error.primerRowList) ||
        ((sample.touched[2] || submitShowError) &&
          showAllErrors &&
          meta.error.concVal) ||
        ((sample.touched[3] || submitShowError) &&
          showAllErrors &&
          meta.error.readLen) ||
        ((sample.touched[4] || submitShowError) &&
          showAllErrors &&
          meta.error.vol) ||
        ((sample.touched[5] || submitShowError) &&
          showAllErrors &&
          meta.error.type) ||
        ((sample.touched[6] || submitShowError) &&
          showAllErrors &&
          meta.error.template)
      );
    };

    return (
      <FormikProvider value={formik}>
        <Field name={`samples.${index}`}>
          {({ field, form: { touched, errors }, meta }) =>
            meta.error !== undefined && willRenderError(meta) ? (
              <tr>
                <td colSpan={10}>
                  <ul
                    style={{
                      listStyleType: 'disc',
                      // display: 'list-item',
                      margin: 0,
                      verticalAlign: 'middle',
                      color: '#d8000c',
                      backgroundColor: '#ffd2d2',
                    }}
                  >
                    {(sample.touched[0] || submitShowError) && showAllErrors ? (
                      <li>
                        {meta.error.sampleName ? (
                          <>● {meta.error.sampleName}</>
                        ) : (
                          <></>
                        )}
                      </li>
                    ) : (
                      <></>
                    )}
                    {(sample.touched[1] || submitShowError) && showAllErrors ? (
                      <li>
                        {meta.error.primerRowList ? (
                          <>● {meta.error.primerRowList}</>
                        ) : (
                          <></>
                        )}
                      </li>
                    ) : (
                      <></>
                    )}
                    {(sample.touched[2] || submitShowError) && showAllErrors ? (
                      <li>
                        {meta.error.concVal ? (
                          <>● {meta.error.concVal}</>
                        ) : (
                          <></>
                        )}
                      </li>
                    ) : (
                      <></>
                    )}
                    {(sample.touched[3] || submitShowError) && showAllErrors ? (
                      <li>
                        {meta.error.readLen ? (
                          <>● {meta.error.readLen}</>
                        ) : (
                          <></>
                        )}
                      </li>
                    ) : (
                      <></>
                    )}
                    {(sample.touched[4] || submitShowError) && showAllErrors ? (
                      <li>
                        {meta.error.vol ? <>● {meta.error.vol}</> : <></>}
                      </li>
                    ) : (
                      <></>
                    )}
                    {(sample.touched[5] || submitShowError) && showAllErrors ? (
                      <li>
                        {meta.error.type ? <>● {meta.error.type}</> : <></>}
                      </li>
                    ) : (
                      <></>
                    )}
                    {(sample.touched[6] || submitShowError) && showAllErrors ? (
                      <li>
                        {meta.error.template ? (
                          <>● {meta.error.template}</>
                        ) : (
                          <></>
                        )}
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </td>
              </tr>
            ) : (
              <></>
            )
          }
        </Field>
      </FormikProvider>
    );
  },
  (prevProps, nextProps) => {
    let oldIndex = prevProps.index;
    let newIndex = nextProps.index;
    if (
      nextProps.formik.errors?.samples?.[oldIndex] !==
      prevProps.formik.errors?.samples?.[newIndex]
    ) {
      return false; // re-render
    }
    return (
      prevProps.id === nextProps.id &&
      isEqual(prevProps.sample, nextProps.sample) &&
      isEqual(prevProps.sample.primerRowList, nextProps.sample.primerRowList) &&
      prevProps.sample.touched[0] === nextProps.sample.touched[0] &&
      prevProps.showAllErrors === nextProps.showAllErrors
    );
  }
);

export default SangerRowErrors;
