export default function WarningIcon() {
  //Icon scales to size of parent container
  return (
    <div>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 160 160"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="160"
          height="160"
          rx="80"
          fill="#008AFC"
          fill-opacity="0.3"
        />
        <circle cx="80" cy="80" r="44" stroke="#008AFC" stroke-width="8" />
        <path
          d="M86.4766 53.125L84.7969 91.9922H74.0938L72.4141 53.125H86.4766ZM72.0625 104.102C72.0625 102.227 72.7396 100.664 74.0938 99.4141C75.4479 98.138 77.2057 97.5 79.3672 97.5C81.5286 97.5 83.2734 98.138 84.6016 99.4141C85.9557 100.664 86.6328 102.227 86.6328 104.102C86.6328 105.977 85.9557 107.552 84.6016 108.828C83.2734 110.078 81.5286 110.703 79.3672 110.703C77.2057 110.703 75.4479 110.078 74.0938 108.828C72.7396 107.552 72.0625 105.977 72.0625 104.102Z"
          fill="#008AFC"
        />
      </svg>
    </div>
  );
}
