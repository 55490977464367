import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { updateSample } from 'utils/api/Samples';
import { Button } from 'reusable/UI/Button';
import { Typography } from 'reusable/UI/Typography';
import { Map } from '../Map';

export const ActionsPopup = ({
  action,
  samples,
  cartridge,
  primers,
  setSamples,
  open,
  onClose,
}) => {
  const newSamples = action.action(samples, cartridge, primers);
  const error = action.error(samples, cartridge, primers);

  const performAction = () => {
    onClose();
    setSamples(newSamples);
    newSamples.forEach(async (sample) => {
      await updateSample(sample._id, sample);
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogTitle>{action.name}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <DialogContentText>
            Are you sure you want to perform this action?
          </DialogContentText>
          {error && <Alert severity="error">{error}</Alert>}
          <Grid container spacing={8}>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" textAlign="left">
                Before
              </Typography>
              <Map samples={samples} canDrag={false} scroll={0} variableWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" textAlign="left">
                After
              </Typography>
              <Map
                samples={newSamples}
                canDrag={false}
                scroll={0}
                variableWidth
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: 2, pb: 2 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={error}
          onClick={performAction}
        >
          Perform
        </Button>
      </DialogActions>
    </Dialog>
  );
};
